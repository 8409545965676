.page-multimedia__table {
  border: 0;
  width: 100%;
  td {
    vertical-align: top;
    &:first-child {
      width: 100px;
    }
  }
}

.page-multimedia__speaker {
  color: #777;
}

.page-multimedia__video {
  width: 100%;
  height: auto;
  max-width: 640px;
}

.page-multimedia__video_lang_button_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0 30px 0;
}

.page-multimedia__icon--video-play {
  display: block;
  background: imgurl('common/icons/svg/black/icon-play-video.svg') no-repeat center center;
  width: 40px;
  height: 40px;
  margin-right: 30px;
}

.page-multimedia__video_lang_button_bar {
  text-align: right;
  button {
    font-weight: 700;
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0;
    margin-top: 5px;
    margin-right: 30px;
    +.button {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.page-multimedia__active_button {
  cursor: pointer;
  border-bottom: none;
  color: $colorTxt;
  &:hover {
    color: $colorOrange;
  }
}

.page-multimedia__disabled_button {
  cursor: default;
  border-bottom: solid;
  color: $colorOrange;
}