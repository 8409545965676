@charset "utf-8";
$componentsTitle: '.page-item-search';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */


$fieldSize: 65px;
.item-search__input-holder {
    display: block;
    position: relative;
    width: 100%;
    max-width: 720px;
    margin: -30px auto 50px;
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
    // overflow: hidden;
    /*border-radius: $fieldSize/2;*/
    border: 0;
    // box-shadow: 0 2px 20px rgba(0, 0, 0, 0.04), 0 1px 10px rgba(0, 0, 0, 0.06);
}

.item-search__input-Error {
    color: black;
    font-size: 12px;
    padding: 10px 25px;
    display: block;
    font-weight: bold;
    text-align: left;
    @media screen and (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.item-search__input {
    position: relative;
    width: 100%;
    height: $fieldSize;
    border: 0;
    -webkit-appearance:none;  // fix in IOS 
    -webkit-border-radius:0px; // fix in IOS
    font-weight: bold;
    color: #000;
    &::placeholder {
        color:  #fb5004;
        opacity: 1; 
    }
    &:-ms-input-placeholder {
        color: #fb5004 !important;
        /* IE11 needs the !important flag */
    }
    &::-webkit-search-cancel-button {
        display: none;
    }
    &:focus {
        outline: 2px solid #bbb;
    }
    padding-left:25px;
    padding-right:60px;
    &+.item-search__autocomplete {
        display: none;
    }
    &:focus+.item-search__autocomplete,
    &+.item-search__autocomplete:focus,
    &+.item-search__autocomplete:hover {
        display: block;
    }
    @media screen and (max-width: 767px) {
        height: 50px;
        padding-left:15px;
        padding-right:50px;
    }
    @media screen and (max-width: 400px) {
        font-size: 15px;
    }
}

.item-search__input-btn {
    position: absolute;
    width: 100px;
    height: $fieldSize;
    right: -25px;
    bottom: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    &:before {
        $size: 24px;
        content: '';
        width: $size;
        height: $size;
        background: imgurl('common/icons/svg/orange/icon-search.svg') no-repeat center center;
        background-size: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    @media screen and (max-width: 767px) {
        height: 50px;
    }
}

.item-search__topsearch{
    margin-top: 5px;
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
        margin-top: 10px
    }
    font-size: 12px;
    text-align: center;
}

.item-search__topsearch-title{
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-weight: 700;
    margin: 0;
    margin-right: 25px;
}

.item-search__topsearch-item{
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    margin-right: 15px;
    color: #fff;
}

.item-search__panel {
    width: 92%;

    @media screen and (max-width: 1023px) {
        width: 100%;
    }
}

.item-search__filters {
    margin-bottom: 15px;
    font-size: 0;
    text-align: center;
}

.item-search__filter {
    display: inline-block;
    margin-right: 45px;

    &:last-child {
        margin-right: 0;
    }

    input {
        display: none;

        +label {
            position: relative;
            display: block;
            cursor: pointer;
            @include fontSize(17);
            color: #fff;
        }

        &:checked+label {
            font-weight: bold;
            padding: 3px 0;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background: #fff;
            }
        }
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 10px;
        margin-right: 35px;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */