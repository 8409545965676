@charset "utf-8";
/* Detail page */
.breadcrumb {
  .breadcrumb__container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 18px 2rem 20px;
    display: flex;
    font-size: 20px;
  }
  .breadcrumb__item a {
    color: #444a4f;
  }
  .breadcrumb__item-slash {
    margin: 0 12px;
  }
  .breadcrumb__item--current {
    color: #fc5e08;
    text-transform: capitalize;
  }
}

.heroBanner {
  position: relative;
  .heroBanner__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 57%;
    transform: scaleX(-1);
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .heroBanner__bg::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .heroBanner__bg_wellness {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: right bottom 33%;
  }
  .heroBanner__bg_sus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .heroBanner__bg_sus__mobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
      height: 100%;
      min-height: 350px;
    }
  }
  .heroBanner__bg_sus__mobile__bg {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
      height: 100%;
      min-height: 180px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      color: white;
      position: relative;
    }
  }
  .heroBanner__bg_sus__mobile__wording {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
      height: 100%;
      background: #242655;
    }
  }
  .wellness_color {
    color: #143b5a;
    @media screen and (max-width: 767px) {
      color: white;
    }
  }
  .heroBanner__container {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 100px 2rem 60px;
    color: #fff;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .heroBanner__container__mobile {
    display: none;
    @media screen and (max-width: 767px) {
      position: relative;
      margin: 0 auto;
      padding: 40px 15px;
      color: white;
      display: block;
      height: 60%;
    }
  }
  .heroBanner__title h2 {
    font-size: 42px;
    position: relative;
    text-transform: capitalize;
  }
  .heroBanner__title__mobile {
    font-size: 42px;
    padding: 100px 15px 0;
    position: relative;
    text-transform: capitalize;
  }
  .heroBanner__title__mobile h2 {
    font-size: 42px;
    text-transform: capitalize;
  }
  .heroBanner__content {
    font-size: 16px;
    max-width: 600px;
    line-height: 1.5em;
    margin: 60px 0 0;
    font-weight: bold;
    position: relative;
  }
  .heroBanner__content__mobile {
    display: none;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 1.5em;
      font-weight: bold;
      display: block;
    }
  }
}

@media screen and (max-width: 767px) {
  .heroBanner__bg_sus__mobile__bg__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
  }
}

.threeColItem {
  .threeColItem__bg {
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .threeColItem__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 2rem;
    display: flex;
    column-gap: 24px;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 20px 15px;
    }
  }
  .threeColItem__item {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    padding: 40px 24px;
    border: 1px solid #cacaca;
    align-items: center;
    @media screen and (max-width: 991px) {
      width: calc((100% - 48px) / 3);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .threeColItem__item-icon {
      img {
        width: 90px;
        height: 90px;
        display: block;
        object-fit: contain;
        object-position: center;
        @media screen and (max-width: 767px) {
          width: 60px;
          height: 60px;
        }
      }
    }
    .threeColItem__item-title {
      font-size: 20px;
      margin: 20px 0;
      font-weight: bold;
    }
    .sus_color {
      color: #228d02;
    }
    .wellness_color {
      color: #1494af;
    }
    .innovation_color {
      color: #1594af;
    }
    .threeColItem__item-desc {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

.titleImageRow {
  .titleImageRow__container {
    padding: 40px 2rem 68px;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 767px) {
      padding: 40px 15px;
    }
  }
  .titleImageRow__title {
    font-size: 32px;
    color: #302490;
    text-align: center;
    position: relative;
  }
  .titleImageRow__title_icon {
    position: absolute;
    top: -18px;
    left: -25px;
    @media screen and (max-width: 1440px) {
      left: 0;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .titleImageRow__title_icon2 {
    position: absolute;
    right: 0;
    bottom: 0;
    @media screen and (max-width: 1440px) {
      right: 32px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .titleImageRow__row {
    margin-top: 40px;
    display: flex;
    column-gap: 80px;
    position: relative;
    justify-content: center;
    @media screen and (max-width: 1440px) {
      column-gap: 60px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  .titleImageRow__row:nth-child(odd) {
    flex-direction: row-reverse;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  .titleImageRow__image {
    width: 40%;
    img {
      width: 100%;
      min-height: 300px;
      display: block;
      object-fit: cover;
    }
    @media screen and (max-width: 1440px) {
      width: 43%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      img {
        min-height: 280px;
      }
    }
  }
  .titleImageRow__content {
    width: 40%;
    padding-top: 60px;
    @media screen and (max-width: 1440px) {
      width: 43%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding-top: 20px;
    }
    .titleImageRow__content-title {
      font-size: 20px;
      font-weight: bold;
    }
    .titleImageRow__content-desc {
      font-size: 16px;
      line-height: 1.4;
      margin-top: 20px;
      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }
    .titleImageRow__content-link {
      display: flex;
      justify-content: flex-end;
      margin-top: 60px;
      img {
        width: 50px;
        display: block;
      }
      @media screen and (max-width: 767px) {
        margin-top: 24px;
      }
    }
  }
}

.titleImageSingle {
  background-image: linear-gradient(to right, #fc5e08 15%, #fd8e14 51%);
  .titleImageSingle__container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 40px 2rem;
    @media screen and (max-width: 767px) {
      padding: 40px 15px;
    }
  }
  .titleImageSingle__title {
    font-size: 32px;
    color: #fff;
    text-align: center;
  }
  .titleImageSingle__row {
    display: flex;
    column-gap: 80px;
    margin-top: 40px;
    justify-content: center;
    @media screen and (max-width: 1440px) {
      column-gap: 60px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  .titleImageSingle__image {
    width: 40%;
    img,
    iframe {
      width: 100%;
      min-height: 300px;
      display: block;
      object-fit: contain;
    }
    @media screen and (max-width: 1440px) {
      width: 43%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      img,
      iframe {
        min-height: 240px;
      }
    }
  }
  .titleImageSingle__content {
    width: 40%;
    color: #fff;
    padding-top: 48px;
    @media screen and (max-width: 1440px) {
      width: 43%;
    }
    .titleImageSingle__content-title {
      span {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }
    }
    .titleImageSingle__content-desc {
      font-size: 16px;
      margin-top: 20px;
      line-height: 1.5em;
    }
    .titleImageSingle__content-link {
      display: flex;
      justify-content: flex-end;
      margin-top: 48px;
      img {
        width: 50px;
        display: block;
      }
      @media screen and (max-width: 767px) {
        margin-top: 24px;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding-top: 24px;
    }
  }
}
