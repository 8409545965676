@charset "utf-8";
$componentsTitle: '.page-success-story';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

.sec-page-story{
    max-width: 1920px;
    margin: 0 auto;
}

.story-filter{
    background-color: #242655;
    text-align: center;
}

.story-filter__title{
    @extend .title-lv5;
    font-weight: 400;
    display: inline-block;
    color: #fff;
    padding: 25px 60px;
    border-right: 1px solid rgba($color: #fff, $alpha: 0.2);
    @media screen and (max-width:767px) {
        display: block;
    }
}

.story-filter-condList{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    margin: 20px 0;
    cursor: pointer;
}

.story-filter-cat-container{
    display: inline-block;
    position: relative;
    @media screen and (max-width:767px) {
        display: block;
    }
    .filter-cat__title{
        // @extend .title-lv6;
        font-weight: 300;
        color: #fff;
        padding: 25px 120px 25px 40px;
        border-right: 1px solid rgba($color: #fff, $alpha: 0.2);
        @media screen and (max-width:767px) {
            font-size: 16px;
            padding: 25px;
        }
        &:after{
            content: '';
            display: inline-block;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #fff;
            margin-left: 10px;
        }
    }
}

.story-list{
    max-width: 1240px;
    margin: 0 auto;
    margin-top: 70px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    // -webkit-justify-content: center;
    // -ms-flex-pack: center;
    // justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    &.loading {
        margin-bottom: 100px;
    }
    .story-container{
        display: inline-block;
        $i:3;
        width: (100% / $i );
        padding: 0 20px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        line-height: 1.5em;
        @media screen and (max-width:767px) {
            width: 100%;
            text-align: center;
        }
    }
    .story-img{
        width: 100%;
        img {
            max-width: 360px;
            max-height: 180px;
        }
    }
    .story-title{
        @extend .title-lv5;
        margin-top: 15px;
    }
    .story-desc{
        width: 85%;
        margin-top: 6px;
        text-align: left;
        a {
            @extend .gb-fontsize-s;
            text-decoration: underline;
            color: $colorOrange;
            margin-left: 5px;
            font-weight: bold;
        }
        @media screen and (max-width:767px) {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .story-tags{
        margin-top: 30px;
        margin-bottom: 70px;
    }
    .story-tag{
        position: relative;
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 2px 5px;
        &:after{
            content: "\00b7";
            position: absolute;
            top: 3px;
            right: -5px;
        }
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            &:after{
                content: none;
            }
        }
    }
}


/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */