@charset "utf-8";
$componentsTitle: 'market-focus';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.market-focus {
    position: relative;
}

.market-focus__holder {
    @extend .basic-section;
    &.animated.fadeIn {
        @include animation(none);
        opacity: 1 !important;
    }
}

.market-focus__intro {
    text-align: center;
    margin-bottom: 45px;
}

.market-focus__intro-title {
    @extend .title-lv2;
    margin: 0 0 15px;
    font-weight: 300;
    color: $colorOrange;
    .market-focus__holder.animated & {
        opacity: 0;
    }
    .market-focus__holder.animated.fadeIn & {
        @include animation(mf-item-title .6s);
        @include keyframes(mf-item-title) {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
}

.market-focus__intro-desc {
    @extend .basic-desc;
    @extend .gb-fontsize-m;
    .market-focus__holder.animated & {
        opacity: 0;
    }
    .market-focus__holder.animated.fadeIn & {
        @include animation(mf-item-title .6s .3s);
        @include keyframes(mf-item-title) {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
}

.market-focus__items {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 90%;
        @include transform(scale(0, 0));
        @include transform-origin(right center);
        @include transition(1s .3s);
        right: 0;
        top: 25%;
        height: 50%;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fb4805+0,fd8811+100 */
        background: rgb(251, 72, 5);
        /* Old browsers */
        background: -moz-linear-gradient(left, rgba(251, 72, 5, 1) 0%, rgba(253, 136, 17, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(251, 72, 5, 1) 0%, rgba(253, 136, 17, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(251, 72, 5, 1) 0%, rgba(253, 136, 17, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb4805', endColorstr='#fd8811', GradientType=1);
        /* IE6-9 */
        .market-focus__holder.animated.fadeIn & {
            @include transform(scale(1, 1));
        }
    }
    >.content-wrapper {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        @media screen and (max-width: 480px) {
            width:100%;
        }
    }
}

$space:30px;
.market-focus__item {
    position: relative;
    overflow: hidden;
    width:calc(33.3333% - #{$space*2/3});
    margin-right: $space;
    border-radius: 5px; //margin-bottom: $space;
    //background:#000;
    $delay: .6s;
    $weight: .12s;
    opacity: 0;
    cursor: pointer;
    @media screen and (max-width: 480px) {
        background:#000;
        border-radius: 0;
    }
    .market-focus__holder.animated.fadeIn & {
        &:hover {
            opacity: 0;
        }
        @include animation(mf-item .6s);
        &:nth-child(2) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(3) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(4) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(5) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(6) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(7) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(8) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
    }
    @include keyframes(mf-item) {
        0% {
            @include transform(scale(1.2));
            opacity: 0;
        }
        100% {
            @include transform(scale(1));
            opacity: 1;
        }
    }
    color:#FFF;
    &:hover {
        opacity: 0;
        color: #FFF;
    }
    &:before {
        content: '';
        position: relative;
        padding-bottom: 100%;
        width: 100%;
        display: block;
    }
    &:nth-child(3n+3) {
        margin-right: 0;
    }
    &:nth-child(3n+2) {
        margin-top: 30px;
        &:before {
            padding-top: 30px;
        }
    }
    &:nth-child(2) {
        margin-top: 0;
        &:before {
            padding-top: 30px;
        }
    }
    @media screen and (max-width:767px) {
        width:calc(50% - #{$space*1/2});
        margin-right: $space;
        margin-bottom: $space;
        &:nth-child(3n+3) {
            margin-right: $space;
        }
        &:nth-child(2n+2) {
            margin-right: 0;
        }
        &:nth-child(3n+2) {
            margin-top: 0;
            &:before {
                padding-top: 0;
            }
        }
    }
    @media screen and (max-width:480px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;
        &:nth-child(3n+3),
        &:nth-child(2n+2) {
            margin-right: 0;
        }
        &:before {
            padding-bottom: 50%;
        }
    }
}

@media screen and (min-width:768px) {
    
    .market-focus__items8 .market-focus__item {
        
        margin-top: 30px;
        &:before {
            padding-top: 30px;
        }
        &:nth-child(3n+3) {
            margin-right: $space;
        }
        &:nth-child(1), &:nth-child(2) {
            width:calc(50% - #{$space*2/4});
            &:before {
                padding-bottom: 50%;
            }
        }
        &:nth-child(2), &:nth-child(5), &:nth-child(8) {
            margin-right: 0;
        }
    }
    
    .market-focus__items7 .market-focus__item {
        
        margin-top: 30px;
        &:before {
            padding-top: 30px;
        }
        &:nth-child(3n+3) {
            margin-right: $space;
        }
        &:nth-child(1), &:nth-child(2), &:nth-child(6), &:nth-child(7) {
            width:calc(50% - #{$space*2/4});
            &:before {
                padding-bottom: 50%;
            }
        }
        &:nth-child(2), &:nth-child(5), &:nth-child(7) {
            margin-right: 0;
        }
    }
}

.market-focus__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: no-repeat center center;
    background-size: cover;
    @media screen and (max-width: 480px) {
        opacity:0.8;
    }
    @include transform(scale(1, 1));
    @include transition(.4s);
    .market-focus__item:hover & {
        @include transform(scale(1.1, 1.1));
    }
    &:before{
        content:'';
        display:block;
        position:absolute;
        width:100%;
        height:45%;
        left:0;
        bottom:0;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
    }
}

.market-focus__info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 30px 15px;
}

.market-focus__title {
    display: block;
    width: 100%;
    @extend .title-lv4;
    font-weight: 300;
    line-height: 1.2em;
    margin: 0 0 10px;
    text-transform: uppercase;
    @media screen and (max-width: 991px) {
        @include fontSize(16);
        margin: 0 0 5px;
    }
}

.market-focus__arrow {
    &:before {
        $size: 30px;
        content: '';
        width: $size;
        height: $size;
        display: block;
        background: no-repeat center center;
        background-image: imgurl("common/icons/svg/white/icon-long-arrow.svg");
        background-size: 100%;
        @media screen and (max-width: 991px) {
            $size:24px;
            width: $size;
            height: $size;
        }
        .market-focus__item:hover & {
            @include animation(mf-arrow .6s infinite);
        }
    }
}

@include keyframes(mf-arrow) {
    0% {
        @include transform(translateX(0));
    }
    100% {
        @include transform(translateX(12px));
    }
}

.market-focus__content {
    position: relative;
}

.market-focus-open {
    section.market-focus {
        z-index: 1520;
    }
    #market-focus-overlay {
        background: rgba(255, 255, 255, 0.9);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1500;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */