@charset "utf-8";
$componentsTitle: '.page-item-slider';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

.item-sliders{
    position: relative;
    &.item-sliders--product-mags{
        width: 35%;
        height: 370px;
        position: absolute;
        left: 54%;
        top: -35px;
        bottom: 0;
    }
    @media screen and (max-width:1240px) {
        margin-top: 35px;
        &.item-sliders--product-mags{
            position: relative;
            left: 0;
            top: 0;
            width: 560px;
            margin-left: 36px;
        }
    }
    @media screen and (max-width:767px) { 
        text-align: initial;
        &.item-sliders--product-mags{
            margin-left: 0px;
            width: 100%;
            height: 340px;
        }
    }
    .item-sliders-container{
        width: 100%;
        height: 100%;
        // position: absolute;
        // left: 52%;
        // top: -240px;
        // bottom: 0;
        overflow: hidden;
        @media screen and (max-width:1240px) {
            position: static;
            left: 0;
            top: 0;
            width: 90%;
            margin: 0 auto;
            .item-slider-container{
                .item-slider-text{
                    max-width: initial;
                }
            }
        }
        @media screen and (max-width:767px) {
            width: 100%;
        }
    }
    .item-slider-container{
        position: relative;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        .item-slider__img{
            display: inline-block;
            margin-right: 10px;
            @media screen and (max-width:480px) {
                margin-right: 0px;
            }
        }
        @media screen and (max-width:480px) {
            text-align: center;
        }
        .item-slider-text{
            @each $name, $colorVal in $contentColor {
                .item-slider__title--#{$name} {
                    color: $colorVal;
                }
                .item-slider__desc--#{$name} {
                    color: $colorVal;
                }
                .item-slider__href--#{$name} {
                    color: $colorVal;
                }
            }
            margin-left: 0px;
            display: inline-block;
            max-width: 180px;
            margin-top: 40px;
            transform: translate(0%, -50%);
            .item-slider__title{
                @extend .title-lv5;
                margin-bottom: 20px;
                font-weight: 300;
            }
            .item-slider__desc{
                margin-bottom: 10px;
                @media screen and (max-width:480px) {
                    text-align: left;
                }
            }
            .item-slider__href{
                border-bottom: 1px solid #fff;
            }
            @media screen and (max-width:767px) { 
                margin-top: 15px;
            }
            @media screen and (max-width:480px) {
                position: initial;
                transform: translate(0%, 0%);
            }
        }
    }
    .item-slider__btn{
        width: 36px;
        height: 36px;
        border: 1px solid #fff;
        display: block;
        border-radius: 50%;
        position: absolute;
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        top: 35%;
        margin-top: -18px;
        background: none;
        cursor: pointer;
        @media screen and (max-width:1240px) {
            top: 40%;
        }
        &--prev{
            left: -60px;
            transform: rotate(225deg);
            &:after{
                content: '';
                position: absolute;
                display: block;
                width: 7.2px;
                height: 7.2px;
                border-top: 1px solid #fff;
                border-right: 1px solid #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -2.736px;
                margin-left: -5.4px;
            }
            @media screen and (max-width:1240px) {
                left: -25px;
            }
        }
        &--next{
            right: -60px;
            transform: rotate(45deg);
            &:after{
                content: '';
                position: absolute;
                display: block;
                width: 7.2px;
                height: 7.2px;
                border-top: 1px solid #fff;
                border-right: 1px solid #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -2.736px;
                margin-left: -5.4px;
            }
            @media screen and (max-width:1240px) {
                right: -25px;
            }
        }
    }
    .item-slider__pagination{
        text-align: center;
        position: absolute;
        bottom: 0px;
        .swiper-pagination-bullet{
            cursor: pointer;
            width: 20px;
            height: 3px;
            border: 0;
            border-radius: 0;
            margin: 0 3px;
            background: #fff;
            opacity: 1;
            vertical-align: top;
            &.swiper-pagination-bullet-active{
                background: #fb5004;
                height: 4px;
            }
        }
        @media screen and (max-width:480px) {    
            margin-top: 35px;
        }
    }
}


.item-sliders__slider-pause{
    display: inline-block;
    position: absolute;
    right: -10px;
    bottom: 10px;
    z-index: 10;
    @media screen and (max-width:480px) {
        right: -20px;
        bottom: -27px;
    }
    .pause-button{
        display: block;
        box-sizing: border-box;
        width: 0;
        height: 15px;
        cursor: pointer;
        border-color: transparent transparent transparent #fff;
        transition: 100ms all ease;
        cursor: pointer;
        // paused state
        border-style: double;
        border-width: 0px 0 0px 15px;
    }
    &.is-active{
        .pause-button {
            border-style: solid;
            border-width: 8px 0 8px 12px;
        }
    }
}
/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */