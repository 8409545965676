@charset "utf-8";
$componentsTitle: 'header-logo';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

.header-logo {
    /*
    position:relative;
    display:inline-block;
    vertical-align: middle;
    */
    z-index: 150;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    img {
        width: 105px;
    }    
    font-size: 0;
    @media screen and (max-width: 1580px) {
        img {
            width:90px;
        }
    }
    @media screen and (max-width: 991px) {
        margin-left: 18px;
        z-index: 1500;
        img {
            width: 65px;
        }
    }
    @media screen and (max-width: 767px) {
        img {
            width: 60px;
        }
    }
    @media screen and (max-width: 480px) {
        img {
            width: 55px;
        }
    }
}
.header-logo__tag {
    display: block;
    margin-top: 8px;
    @include fontSize(12);
    white-space: nowrap;
    padding: 0 8%;
    @include transform-origin(left center);
    @media screen and (max-width: 991px) and (min-width: 480px) {
        position:absolute;
        left: 100%;
        bottom: 5px;
        margin-left: 10px;
        padding-left: 10px;
        border-left: 3px solid #ee7700;
    }
    @media screen and (max-width: 540px) {
        margin-left: 5px;
        padding-left: 10px;
        @include transform(scale(0.8, 0.8));
    }
    @media screen and (max-width: 480px) {
        margin-left: 0;
        margin-top: 2px;
        margin-bottom: -5px;
        padding-left: 8%;
    }
    @media screen and (max-width: 480px) {
        @include transform(scale(0.6, 0.6));
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */