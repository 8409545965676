@charset "utf-8";
$componentsTitle: '.page-events-list';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */
.events-filter{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: $colorPurple;
    text-align: center;
    @media screen and (max-width:1062px) {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .events-filter__title{
        display: inline-block;
        @extend .title-lv5;
        font-weight: 400;
        display: inline-block;
        padding: 25px 60px;
        border-right: 1px solid rgba($color: #bebebe, $alpha: 0.2);
        color: white;
        @media screen and (max-width:767px) {
            width: 100%;
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 10px;
        }
    }
}

.events-filter-condList{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    margin: 20px 0;
}

.events-filter-cat-container{
    display: inline-block;
    position: relative;
    @media screen and (max-width:768px) {
        width: 100%;
    }
    .filter-cat__title{
        // @extend .title-lv6;
        font-weight: 300;
        padding: 25px 40px 25px 40px;
        border-right: 1px solid rgba($color: #bebebe, $alpha: 0.2);
        color: white;
        @media screen and (max-width:767px) {
            font-size: 16px;
            padding: 10px 25px;
        }
        &:after{
            content: '';
            display: inline-block;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #bebebe;
            margin-left: 10px;
        }
    }
}

.events-search__input-holder{
    position: relative;
    display: inline-block;
    padding: 25px 0 25px 40px;
    max-width: 300px;
    width: 100%;
    @media screen and (max-width:1240px) {
        max-width: 240px;
    }
    @media screen and (max-width:768px) {
        max-width: initial;
        width: 80%;
        padding-left: 0;
        padding-top: 10px;
    }
    input{
        color: white;
        @include placeholder-style() {
            color: white;
            font-weight: 300;
        }
        background-color: $colorPurple;
        border-top: none;
        border-right: none;
        border-bottom-color: rgba(112, 113, 148, 0.5);
        border-bottom-style: solid; /* PHR-542 - necessary in firefox */ 
        border-left: none;
        width: 100%;
        padding-right: 20px;
        &:focus {
            // outline: none;
        }
        &::-ms-clear {
            display: none;
        }
    }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
    .events-search__input-btn {
        position: absolute;
        right: 0;
        top: 25px;
        background: transparent;
        border: 0;
        cursor: pointer;
        @media screen and (max-width:768px) {
            top: 15%;
        }
        &:focus {
            // outline: none;
        }
        &:before {
            $size: 12px;
            content: '';
            width: $size;
            height: $size;
            background: imgurl('common/icons/svg/white/icon-search.svg') no-repeat center center;
            background-size: 100%;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.events-search__input-Error {
    color: red;
    font-size: 12px;
    padding: 5px 0;
    display: block;
    text-align: left;
}

.events-list-title{
    text-align: center;
    @extend .title-lv4;
    font-weight: 300;
    color: $colorOrange;
    margin-top: 55px;
}

.events-list-conatiner{
    width: 70%;
    margin: 40px auto;
    @media screen and (max-width:1240px) {
        width: 90%;
    }
    @media screen and (max-width:768px) {
        width: 95%;
    }
}

.events-list-header{
    border-bottom: 1px solid rgba(190, 190, 190, 0.2);
    .events-list-header__elem{
        display: inline-block;
        vertical-align: middle;
        padding: 20px 8px;
        &:first-child{
            width: 15%;
        }
        &:nth-child(2){
            width: 10%;
            padding-left: 12px;
            @media screen and (max-width:1240px) {
                width: 12%;
            }
        }
        &:nth-child(3){
            width: 25%;
            padding-left: 16px;
            @media screen and (max-width:1240px) {
                width: 18%;
            }
        }
        &:nth-child(n+4){
            width: 10%;
            text-align: center;
            @media screen and (max-width:1240px) {
                width: 11%;
                word-wrap: break-word;
            }
        }
    }
    @media screen and (max-width:768px) {
        display: none;
    }
}

.list-event-container{
    border-bottom: 1px solid rgba(190, 190, 190, 0.2);
    @media screen and (max-width:768px) {        
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        padding: 30px 0px;
    }
    .list-event__elem{
        display: inline-block;
        vertical-align: middle;
        padding: 10px;
    }
    .list-event__desktop-elem{
        @media screen and (max-width:768px) {
            display: none;
        }
    }
    .list-event__mobile-elem{
        display: none;
        @media screen and (max-width:768px) {
            display: initial;
        }
    }
    .list-event__elem--title{
        color: $colorOrange;
        cursor: default;
        a{
            color: $colorOrange;
        }
    }
    .list-event-left-container{
        display: inline-block;
        width: 15%;
        .list-event__elem--img{
            padding-left: 0;
            width: 100%;
            @media screen and (max-width:768px) {
                padding-top: 0;
            }
            img{
                width: 100%;
            }
        }
        @media screen and (max-width:768px) {
            width: 35%;
        }
    }
    .list-event-right-container{
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: 85%;
        @media screen and (max-width:768px) {
            width: 65%;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-items: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
        }
    }
    .list-event-header-container{
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 40%;
        @media screen and (max-width:1240px) {
            width: 35%;
        }
        @media screen and (max-width:768px) {
            -webkit-flex-direction: column-reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            -webkit-justify-content: flex-end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            width: 100%;
        }
        .list-event__elem--date{
            width: 30%;
            @media screen and (max-width:1240px) {
                width: 40%;
            }
            @media screen and (max-width:768px) {
                width: 100%;
            }
            .list-event__mobile-elem{
                @media screen and (max-width:768px) {
                    display: block;
                }
            }
        }
        .list-event__elem--title{
            width: 70%;
            @media screen and (max-width:1240px) {
                width: 60%;
            }
            @media screen and (max-width:768px) {
                width: 100%;
                font-weight: bold;    
                font-size: 18px;
                padding-top: 0;
                padding-bottom: 0;
            }
            .list-event__mobile-elem{
                @media screen and (max-width:768px) {
                    display: block;
                    color: #000;
                }
            }
        }
    }
    .list-event-detail-container{
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: 60%;
        @media screen and (max-width:1240px) {
            width: 65%;
        }
        @media screen and (max-width:768px) {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            width: 100%;
        }
        .list-event__elem{
            display: -ms-inline-flexbox;
            display: -webkit-inline-flex;
            display: inline-flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-align-content: flex-start;
            -ms-flex-line-pack: start;
            align-content: flex-start;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            width: 20%;
            img{
                margin-right: 5px;
            }
            @media screen and (max-width:768px) {
                width: 50%;
                img{
                    width: 16px;
                }
                .elem__desc{
                    width: calc(80% - 5px);
                }
            }
            &.list-event__elem--Download{
                @media screen and (max-width:560px) {
                    img{
                        width: 11px;
                    }
                }
            }
            &.list-event__elem--exhibit{
                @media screen and (max-width:560px) {
                    img{
                        width: 14px;
                    }
                }
            }
        }
        .list-event__empty-elem{
            @media screen and (max-width:768px) {
                display: none;
            }
        }
    }
}

.more-events-list-title{
    text-align: center;
    @extend .title-lv4;
    font-weight: 300;
    color: $colorOrange;
    margin-top: 55px;
}

.more-events-list-conatiner{
    width: 70%;
    margin: 40px auto;
    @media screen and (max-width:1240px) {
        width: 90%;
    }
    @media screen and (max-width:768px) {
        width: 95%;
    }
}

.more-events-list-header{
    border-bottom: 1px solid rgba(190, 190, 190, 0.2);
    .more-events-list-header__elem{
        display: inline-block;
        vertical-align: middle;
        padding: 20px 10px 20px 0;
        font-weight: 700;
        &:first-child{
            width: 10%;
            .list-events-container & {
                width: 14%;
            }
        }
        &:nth-child(2){
            width: 29%;
            .list-events-container & {
                width: 16.5%;
            }
        }
        &:nth-child(3){
            width: 16%;
            .list-events-container & {
                width: 28.5%;
            }
        }
        &:nth-child(4){
            width: 14.5%;
            text-align: left;
            .list-events-container & {
                width: 15.5%;
            }
        }
    }
    @media screen and (max-width:768px) {
        display: none;
    }
}

.more-event-list-container{    
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(190, 190, 190, 0.2);
    padding: 20px 0;
    @media screen and (min-width:769px) {
        .list-events-container & {
            padding: 0;
        }
    }    
    @media screen and (max-width:768px) {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .more-event-list__elem{
        display: inline-block;
        vertical-align: middle;
        padding: 10px 15px 10px 0;
    }
    .more-list-event__mobile-elem{
        display: none;
        @media screen and (max-width:768px) {
            display: block;
            font-weight: 700;
            &.elem__desc {
                font-weight: 400;
            }
        }
    }
    .more-event-list__desktop-elem {
        @media screen and (max-width:768px) {
            display: none;
        }
    }
    .more-event-list-left-container{
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: 85%;
        .list-events-container & {
            width: 89%;
        }
        .clearfix {
            display: none;
            @media screen and (max-width:768px) {
                @include clearfix();
            }
        }
        @media screen and (max-width:768px) {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
            width: 100%;
            .list-events-container & {
                width: 100%;
                display: block;
            }
        }
        .more-event-list__elem--img {
            width: 17%;
            a {
                padding-left: 0;
                width: 100%;
                @media screen and (max-width: 768px) {
                    padding-top: 0;
                }

                img {
                    width: 100%;
                }
            }
            @media screen and (max-width:768px) {
                width: 35%;
                float: left;
            }
            .list-events-container & {
                order: 0;
            }
        }        
        .more-event-list__elem--date{
            width: 20%;
            .list-events-container & {
                order: 1;
            }
            @media screen and (max-width:768px) {
                .list-events-container & {
                    width: 50%;
                    padding-top: 0;
                }
            }
        }
        .more-event-list__elem--title{
            color: $colorOrange;
            width: 34%;
            .list-events-container & {
                order: 2;
            }
            .more-list-event__mobile-elem{
                @media screen and (max-width:768px) {
                    color: #000;
                }
            }
            a {
                color: $colorOrange;
                @media screen and (max-width:768px) {
                    .list-events-container & {
                        font-weight: bold;
                    }
                }                
            }
        }
        .more-event-list__elem--details{
            width: 19%;
            .list-events-container & {
                order: 3;
            }
        }
        .more-event-list__elem--area{
            width: 17%;
            .list-events-container & {
                order: 4;
            }
        }
        .more-event-list__elem--organiser{
            width: 18%;
        }
        .more-event-list__elem{
            @media screen and (max-width:768px) {
                -webkit-order: 10;
                -ms-flex-order: 1;
                order: 1;
                width: 50%;
                .list-events-container & {
                    vertical-align: top;
                }
            }
            &.more-event-list__elem--title{
                @media screen and (max-width:768px) {
                    -webkit-order: 0;
                    -ms-flex-order: 0;
                    order: 0;
                    width: 100%;
                    .list-events-container & {
                        width: 50%;
                    }
                }
            }
        }
    }
    .more-event-list-right-container{
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 15%;
        .list-events-container & {
            width: 11%;
        }
        @media screen and (max-width:768px) {
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: flex-start;
            -ms-flex-line-pack: start;
            align-content: flex-start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
            margin-top: 3%;
            width: 100%;
            .list-events-container & {
                width: 100%;
            }
        }
        @media screen and (max-width:480px){
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
        .more-event-list__elem{    
            display: -ms-inline-flexbox;
            display: -webkit-inline-flex;
            display: inline-flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: flex-start;
            -ms-flex-line-pack: start;
            align-content: flex-start;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            padding-top: 0;
            padding-bottom: 3px;
            .elem-img{
                margin-right: 5px;
                width: 12%;
                .list-events-container & {
                    width: 20%;
                }
                @media screen and (max-width:480px) {
                    width: 20%;
                }
            }
            .elem__desc{
                width: calc(88% - 5px);
                @media screen and (max-width:480px) {
                    width: calc(80% - 5px);
                }
            }
            @media screen and (max-width:768px) {
                width: (100% / 3 );
            }
            @media screen and (max-width:480px) {
                width: initial;
                -webkit-align-items: flex-start;
                -ms-flex-align: start;
                align-items: flex-start;
            }
        }
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */