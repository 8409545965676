@charset "UTF-8";
/* ------------------------------------------ */
/* Style naming basically follows BEM: http://getbem.com/ */
/* ------------------------------------------ */
/* Preload Images */
/* ------------------------------------------ */
body:after {
  /*
    display:none;
    content:
        url(../images/common/icons/svg-icons/blue/icon-loc.svg)
        url(../images/common/icons/svg-icons/blue/icon-social-fb.svg)
        url(../images/common/icons/svg-icons/blue/icon-social-ig.svg)
        url(../images/common/icons/svg-icons/blue/icon-social-wb.svg)
        url(../images/common/icons/svg-icons/blue/icon-social-yt.svg)
        
        url(../images/common/icons/svg-icons/gold/icon-loc.svg)
        url(../images/common/icons/svg-icons/gold/icon-social-fb.svg)
        url(../images/common/icons/svg-icons/gold/icon-social-ig.svg)
        url(../images/common/icons/svg-icons/gold/icon-social-wb.svg)
        url(../images/common/icons/svg-icons/gold/icon-social-yt.svg)
        ;
    */ }

/* END Preload Images */
/* ------------------------------------------ */
/* BEGIN Light */
@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: light;
  font-style: normal; }

/* END Light */
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: normal; }

/* END Regular */
/* BEGIN Black */
@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("./assets/fonts/Roboto/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: normal; }

/* END Black */
/* Style commonly used  */
/* ------------------------------------------ */
body,
body * {
  max-height: 1000000px; }

html {
  font-size: 16px;
  /*
    @media screen and (max-width:1580px) {
        font-size:15px;
    }
    @media screen and (max-width:991px) {
        font-size:14px;
    }
    @media screen and (max-width:767px) {
        font-size:13px;
    }
    */ }

body {
  font-size: 16px;
  /*
    @media screen and (max-width:1580px) {
        font-size:15px;
    }
    @media screen and (max-width:991px) {
        font-size:14px;
    }
    @media screen and (max-width:767px) {
        font-size:13px;
    }
    */
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  margin: 0 !important;
  padding: 0;
  background: #FFF;
  color: #1a1a1a;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  min-width: 300px; }
  body:lang(zh-hk) {
    font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif; }
  body:lang(zh-cn) {
    font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif; }
  body.dt-only {
    min-width: 992px; }
  body.search-panel-open, body.mob-menu-open, body.scroll-locked {
    overflow: hidden; }
    body.search-panel-open #root, body.mob-menu-open #root, body.scroll-locked #root {
      overflow: scroll; }
  @media screen and (max-width: 991px) {
    body.mob-menu-open, body.search-panel-open {
      overflow: hidden;
      position: fixed; } }
  @media screen and (max-width: 991px) {
    body {
      overflow-x: auto; } }
  @media (max-height: 414px), screen and (orientation: landscape) {
    body.mob-menu-open {
      overflow: scroll;
      position: relative; } }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

a {
  color: #1a1a1a;
  text-decoration: none; }
  a:hover, a:active {
    color: #1a1a1a;
    opacity: 0.8;
    text-decoration: none; }
  a:focus {
    text-decoration: none; }

img {
  image-rendering: -webkit-optimize-contrast;
  max-width: 100%;
  height: auto; }

br {
  display: block;
  opacity: 0;
  font-family: "Arial", sans-serif !important;
  font-size: 0;
  color: transparent;
  line-height: 1em; }

p {
  font-size: 16px;
  /*
    @media screen and (max-width:1580px) {
        font-size:15px;
    }
    @media screen and (max-width:991px) {
        font-size:14px;
    }
    @media screen and (max-width:767px) {
        font-size:13px;
    }
    */
  margin-top: 0;
  margin-bottom: 20px; }
  p:last-child {
    margin-bottom: 0; }

ul,
ol {
  padding-left: 30px;
  margin-top: 0;
  margin-bottom: 20px; }
  ul:last-child,
  ol:last-child {
    margin-bottom: 0; }
  ul:last-child,
  ol:last-child {
    margin-bottom: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  margin-top: 0; }

.title-xxl {
  font-weight: bold;
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  line-height: 1em;
  font-size: 78px; }
  @media screen and (max-width: 1580px) {
    .title-xxl {
      font-size: 64px; } }
  @media screen and (max-width: 991px) {
    .title-xxl {
      font-size: 56px; } }
  @media screen and (max-width: 640px) {
    .title-xxl {
      font-size: 40px; } }

.title-xl, .main-banner__title {
  font-weight: bold;
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  line-height: 1em;
  font-size: 73px; }
  @media screen and (max-width: 1580px) {
    .title-xl, .main-banner__title {
      font-size: 64px; } }
  @media screen and (max-width: 991px) {
    .title-xl, .main-banner__title {
      font-size: 52px; } }
  @media screen and (max-width: 640px) {
    .title-xl, .main-banner__title {
      font-size: 36px; } }

.title-lv1, .header-search__title, .mf-detail__intro-title {
  font-weight: bold;
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-size: 60px; }
  @media screen and (max-width: 1580px) {
    .title-lv1, .header-search__title, .mf-detail__intro-title {
      font-size: 44px; } }
  @media screen and (max-width: 991px) {
    .title-lv1, .header-search__title, .mf-detail__intro-title {
      font-size: 42px; } }
  @media screen and (max-width: 767px) {
    .title-lv1, .header-search__title, .mf-detail__intro-title {
      font-size: 32px; } }
  @media screen and (max-width: 350px) {
    .title-lv1, .header-search__title, .mf-detail__intro-title {
      font-size: 28px; } }

.title-lv2, .app-sec__big-title, .market-focus__intro-title, .sec-intro__title, .side-tabs__title, .success-stories__title {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  font-size: 48px; }
  @media screen and (max-width: 1580px) {
    .title-lv2, .app-sec__big-title, .market-focus__intro-title, .sec-intro__title, .side-tabs__title, .success-stories__title {
      font-size: 42px; } }
  @media screen and (max-width: 991px) {
    .title-lv2, .app-sec__big-title, .market-focus__intro-title, .sec-intro__title, .side-tabs__title, .success-stories__title {
      font-size: 36px; } }
  @media screen and (max-width: 640px) {
    .title-lv2, .app-sec__big-title, .market-focus__intro-title, .sec-intro__title, .side-tabs__title, .success-stories__title {
      font-size: 32px; } }

.title-lv3, .hktdc-events__title, .mob-lang__headlink, .nav-link__subholder#mob-share .button-share__title, .item-content .item-content__title {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  font-size: 42px; }
  @media screen and (max-width: 1580px) {
    .title-lv3, .hktdc-events__title, .mob-lang__headlink, .nav-link__subholder#mob-share .button-share__title, .item-content .item-content__title {
      font-size: 32px; } }
  @media screen and (max-width: 991px) {
    .title-lv3, .hktdc-events__title, .mob-lang__headlink, .nav-link__subholder#mob-share .button-share__title, .item-content .item-content__title {
      font-size: 28px; } }
  @media screen and (max-width: 640px) {
    .title-lv3, .hktdc-events__title, .mob-lang__headlink, .nav-link__subholder#mob-share .button-share__title, .item-content .item-content__title {
      font-size: 24px; } }

.title-lv4, .app-sec__title, .footer-bar__title, .hktdc-events__tag, .hktdc-events__list-title, .market-focus__title, .sec-intro__detail-title, .side-tabs__item-title, .submenu__intro-title, .page-hktdc-events__title, .events-list-title, .more-events-list-title {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  font-size: 30px; }
  @media screen and (max-width: 1580px) {
    .title-lv4, .app-sec__title, .footer-bar__title, .hktdc-events__tag, .hktdc-events__list-title, .market-focus__title, .sec-intro__detail-title, .side-tabs__item-title, .submenu__intro-title, .page-hktdc-events__title, .events-list-title, .more-events-list-title {
      font-size: 24px; } }
  @media screen and (max-width: 991px) {
    .title-lv4, .app-sec__title, .footer-bar__title, .hktdc-events__tag, .hktdc-events__list-title, .market-focus__title, .sec-intro__detail-title, .side-tabs__item-title, .submenu__intro-title, .page-hktdc-events__title, .events-list-title, .more-events-list-title {
      font-size: 20px; } }
  @media screen and (max-width: 640px) {
    .title-lv4, .app-sec__title, .footer-bar__title, .hktdc-events__tag, .hktdc-events__list-title, .market-focus__title, .sec-intro__detail-title, .side-tabs__item-title, .submenu__intro-title, .page-hktdc-events__title, .events-list-title, .more-events-list-title {
      font-size: 20px; } }

.title-lv5, .app-intro__title, .feature-listing__item-big-title, .feature-listing__popcap-title, .mf-detail__quicklinks-title, .organic-listing__item-big-title, .organic-listing__popcap-title, .organic-listing__more, .search-nores__txt, .search-panel__filter__title, .sec-intro__detail-subtitle, .submenu__event-title, .item-content .item-tips .item-tips__title, .item-links .item-links__title, .child-item-container .child-item__title, .item-sliders .item-slider-container .item-slider-text .item-slider__title, .story-filter__title, .story-list .story-title, .events-filter .events-filter__title {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  font-size: 20px; }
  @media screen and (max-width: 1580px) {
    .title-lv5, .app-intro__title, .feature-listing__item-big-title, .feature-listing__popcap-title, .mf-detail__quicklinks-title, .organic-listing__item-big-title, .organic-listing__popcap-title, .organic-listing__more, .search-nores__txt, .search-panel__filter__title, .sec-intro__detail-subtitle, .submenu__event-title, .item-content .item-tips .item-tips__title, .item-links .item-links__title, .child-item-container .child-item__title, .item-sliders .item-slider-container .item-slider-text .item-slider__title, .story-filter__title, .story-list .story-title, .events-filter .events-filter__title {
      font-size: 18px; } }
  @media screen and (max-width: 991px) {
    .title-lv5, .app-intro__title, .feature-listing__item-big-title, .feature-listing__popcap-title, .mf-detail__quicklinks-title, .organic-listing__item-big-title, .organic-listing__popcap-title, .organic-listing__more, .search-nores__txt, .search-panel__filter__title, .sec-intro__detail-subtitle, .submenu__event-title, .item-content .item-tips .item-tips__title, .item-links .item-links__title, .child-item-container .child-item__title, .item-sliders .item-slider-container .item-slider-text .item-slider__title, .story-filter__title, .story-list .story-title, .events-filter .events-filter__title {
      font-size: 16px; } }
  @media screen and (max-width: 640px) {
    .title-lv5, .app-intro__title, .feature-listing__item-big-title, .feature-listing__popcap-title, .mf-detail__quicklinks-title, .organic-listing__item-big-title, .organic-listing__popcap-title, .organic-listing__more, .search-nores__txt, .search-panel__filter__title, .sec-intro__detail-subtitle, .submenu__event-title, .item-content .item-tips .item-tips__title, .item-links .item-links__title, .child-item-container .child-item__title, .item-sliders .item-slider-container .item-slider-text .item-slider__title, .story-filter__title, .story-list .story-title, .events-filter .events-filter__title {
      font-size: 16px; } }

.title-lv6 {
  font-family: "Roboto", 微軟雅黑體, "Microsoft JhengHei", 微軟正黑體, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px; }
  @media screen and (max-width: 1580px) {
    .title-lv6 {
      font-size: 12px; } }
  @media screen and (max-width: 991px) {
    .title-lv6 {
      font-size: 12px; } }
  @media screen and (max-width: 640px) {
    .title-lv6 {
      font-size: 12px; } }

.gb-fontsize-xl, .button-share__holder--big .button-share__title, .success-stories__info-title {
  font-size: 20px; }
  @media screen and (max-width: 640px) {
    .gb-fontsize-xl, .button-share__holder--big .button-share__title, .success-stories__info-title {
      font-size: 20px; } }

.gb-fontsize-l, .footer-bar__desc, .mf-detail__box-label {
  font-size: 18px; }
  @media screen and (max-width: 640px) {
    .gb-fontsize-l, .footer-bar__desc, .mf-detail__box-label {
      font-size: 16px; } }

.gb-fontsize-m, .accordion__content, .accordion__content p, .alert-template__msg p, .app-intro__intro, .app-intro__desc, .app-intro__btn, .hktdc-events__intro, .market-focus__intro-desc, .nav-link__link, .sec-intro__desc, .sec-intro__detail-desc, .side-tabs, .side-title, .sitemap__head, .success-stories__info-desc, .submenu__headlink, .submenu__head {
  font-size: 16px; }
  @media screen and (max-width: 640px) {
    .gb-fontsize-m, .accordion__content, .accordion__content p, .alert-template__msg p, .app-intro__intro, .app-intro__desc, .app-intro__btn, .hktdc-events__intro, .market-focus__intro-desc, .nav-link__link, .sec-intro__desc, .sec-intro__detail-desc, .side-tabs, .side-title, .sitemap__head, .success-stories__info-desc, .submenu__headlink, .submenu__head {
      font-size: 14px; } }

.gb-fontsize-s, .button-share__title, .hktdc-events__list, .hktdc-events__info, .hktdc-events__legend, .mf-detail__intro-desc, .mf-detail__list, .mf-detail__link, .mf-detail__box-date,
.mf-detail__box-desc, .sitemap__link, .sitemap__quicklink, .success-stories__info-desc a, .submenu__intro-desc, .submenu__event-desc, .submenu__event-label, .submenu__event-date, .submenu__link, .submenu__col-more, .submenu__more-link, .story-list .story-desc a {
  font-size: 14px; }
  @media screen and (max-width: 640px) {
    .gb-fontsize-s, .button-share__title, .hktdc-events__list, .hktdc-events__info, .hktdc-events__legend, .mf-detail__intro-desc, .mf-detail__list, .mf-detail__link, .mf-detail__box-date,
    .mf-detail__box-desc, .sitemap__link, .sitemap__quicklink, .success-stories__info-desc a, .submenu__intro-desc, .submenu__event-desc, .submenu__event-label, .submenu__event-date, .submenu__link, .submenu__col-more, .submenu__more-link, .story-list .story-desc a {
      font-size: 13px; } }

.gb-fontsize-xs, .alert-bar__title, .alert-bar__desc, .button, .feature-listing__btn, .feedback-panel__btn, .organic-listing__btn, .pagination__btn-prev,
.pagination__btn-next, .cookies-bar__title, .cookies-bar__desc, .footer-bottom__item, .footer-bottom__copyright {
  font-size: 13px; }
  @media screen and (max-width: 640px) {
    .gb-fontsize-xs, .alert-bar__title, .alert-bar__desc, .button, .feature-listing__btn, .feedback-panel__btn, .organic-listing__btn, .pagination__btn-prev,
    .pagination__btn-next, .cookies-bar__title, .cookies-bar__desc, .footer-bottom__item, .footer-bottom__copyright {
      font-size: 12px; } }

.gb-fontsize-xxs, .cookies-bar__control .button, .cookies-bar__control .feature-listing__btn, .cookies-bar__control .feedback-panel__btn, .cookies-bar__control .organic-listing__btn, .cookies-bar__control .pagination__btn-prev,
.cookies-bar__control .pagination__btn-next {
  font-size: 12px; }

main {
  background: #FFF;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  transition: 0.2s;
  transition: filter 0.4s; }
  @media screen and (max-width: 991px) {
    .mob-menu-open main {
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px); } }
  .search-panel-open main {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px); }

.access {
  position: absolute;
  left: 0px;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.clearfix {
  content: '';
  display: table;
  width: 100%;
  height: 0;
  clear: both; }

.common-wrapper, .content-wrapper, .app-sec__inner, .header__wrapper, .search-nores, .sec-intro__detail-wrapper, .submenu__wrapper {
  width: 94%; }
  @media screen and (max-width: 767px) {
    .common-wrapper, .content-wrapper, .app-sec__inner, .header__wrapper, .search-nores, .sec-intro__detail-wrapper, .submenu__wrapper {
      width: 90%; } }

.content-wrapper, .app-sec__inner, .header__wrapper, .search-nores, .sec-intro__detail-wrapper, .submenu__wrapper {
  margin: 0 auto;
  display: block;
  max-width: 1380px;
  position: relative; }

.content-paragraph {
  margin: 0 auto 15px;
  line-height: 1.5em; }
  .content-paragraph:last-child {
    margin-bottom: 0; }

.content-link:hover, .more-link:hover {
  opacity: 1;
  text-decoration: underline; }

.more-link {
  font-size: 20px; }
  @media screen and (max-width: 640px) {
    .more-link {
      font-size: 16px; } }

.basic-section, .market-focus__holder, .side-tabs {
  position: relative;
  padding: 100px 0; }
  @media screen and (max-width: 1580px) {
    .basic-section, .market-focus__holder, .side-tabs {
      padding: 80px 0; } }
  @media screen and (max-width: 991px) {
    .basic-section, .market-focus__holder, .side-tabs {
      padding: 60px 0; } }
  @media screen and (max-width: 767px) {
    .basic-section, .market-focus__holder, .side-tabs {
      padding: 40px 0; } }

.basic-desc, .market-focus__intro-desc {
  display: block;
  width: 100%;
  max-width: 640px;
  margin: 0 auto 15px;
  line-height: 1.5em; }
  .basic-desc:last-child, .market-focus__intro-desc:last-child {
    margin-bottom: 0; }

.force-oneword {
  display: inline-block;
  white-space: nowrap; }

.force-nobreak {
  white-space: nowrap; }

.force-break {
  word-break: break-all; }

.display--inline-block {
  display: inline-block; }

.display--block {
  display: inline-block; }

.display--inline {
  display: inline; }

.align--hc {
  text-align: center; }

.align--hl {
  text-align: left; }

.align--hr {
  text-align: right; }

.align--vt {
  vertical-align: top; }

.align--vb {
  vertical-align: bottom; }

.align--vm {
  vertical-align: middle; }

.align--hvc {
  text-align: center;
  vertical-align: middle; }

@media screen and (max-width: 991px) {
  .visible-dt {
    display: none !important; } }

@media screen and (min-width: 992px), screen and (max-width: 767px) {
  .visible-tb {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .visible-mb {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .hidden-dt {
    display: none !important; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .hidden-tb {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .hidden-mb {
    display: none !important; } }

.hidden-all {
  display: none !important; }

/* Loading Icon */
.loader {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0; }
  .loader.mf {
    display: block;
    position: relative; }
  .loader.ss {
    display: block;
    z-index: 600; }
    .swiper-slide-active .loader.ss {
      -webkit-animation: ss-fadeleft 0.6s 0.2s;
      animation: ss-fadeleft 0.6s 0.2s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }

@-webkit-keyframes ss-fadeleft {
  0% {
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(-10%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes ss-fadeleft {
  0% {
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(-10%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }
    .swiper-slide-next .loader.ss {
      -webkit-animation: ss-fadeleft 0.6s 0.2s;
      animation: ss-fadeleft 0.6s 0.2s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }
    .loader.ss .content {
      position: absolute; }
  .loader .content {
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    position: relative;
    text-align: center; }
    .loader .content .message:after {
      content: ' .';
      animation: dots 1s steps(5, end) infinite; }
    .loader .content .loading {
      margin: 0 auto 10px;
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      border-top: 5px solid #888888;
      border-right: 5px solid #888888;
      border-left: 5px solid #888888;
      width: 40px;
      height: 40px;
      -webkit-animation: spin 0.7s linear infinite;
      animation: spin 0.7s linear infinite; }

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  40% {
    color: #333333;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  60% {
    text-shadow: 0.25em 0 0 #333333, 0.5em 0 0 rgba(0, 0, 0, 0); }
  80%,
  100% {
    text-shadow: .25em 0 0 #333333, .5em 0 0 #333333; } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* END Loading Icon */
/* no selection highlight */
.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }

/* no selection highlight END */
/* back to top button */
#backtotop {
  margin: 50px 20px;
  background: url("./assets/images/common/icons/svg/white/icon-backtotop.svg") no-repeat center center;
  width: 45px;
  height: 45px;
  text-indent: -99999px;
  display: block;
  opacity: 0.9; }
  #backtotop:hover {
    opacity: 1; }
  @media screen and (max-width: 1024px) {
    #backtotop {
      display: none; } }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.align-left {
  text-align: left; }

.align-justify {
  text-align: justify; }

/* END Style commonly used  */
/* ------------------------------------------ */
/* Style for app */
/* ------------------------------------------ */
.app {
  position: relative;
  width: 100%; }

.app__top {
  position: relative;
  width: 100%; }

.app__main {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 130px; }
  @media screen and (max-width: 1580px) {
    .app__main {
      padding-top: 125px; } }
  @media screen and (max-width: 991px) {
    .app__main {
      padding-top: 70px; } }
  .inner-page .app__main {
    padding-top: 200px; }
    @media screen and (max-width: 1480px) {
      .inner-page .app__main {
        padding-top: 120px; } }
    @media screen and (max-width: 991px) {
      .inner-page .app__main {
        padding-top: 110px; } }
    @media screen and (max-width: 767px) {
      .inner-page .app__main {
        padding-top: 80px; } }

/* END Style app  */
/* ------------------------------------------ */
/* Style for access-elem */
/* ------------------------------------------ */
.access-elem {
  position: absolute;
  left: 0px;
  top: -5000px;
  width: 1px;
  height: 1px;
  overflow: hidden; }

/* END Style access-elem  */
/* ------------------------------------------ */
/* Style for accordion */
/* ------------------------------------------ */
.accordion {
  position: relative;
  display: block;
  margin-bottom: 15px; }
  @media screen and (max-width: 991px) {
    .accordion {
      border-bottom: 1px solid #777;
      padding: 30px; } }
  @media screen and (max-width: 380px) {
    .accordion {
      padding: 20px; } }
  .accordion:last-child {
    margin-bottom: 0; }

/*test*/
.accordion__btn {
  position: relative;
  font-weight: bold;
  cursor: pointer; }
  .accordion__btn:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
    .accordion.is-active .accordion__btn:after {
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg); }

.accordion__content {
  display: none;
  padding: 15px 0;
  font-weight: 300; }
  .accordion.is-active .accordion__content {
    display: block; }
  .accordion__content p {
    margin: 0 0 10px; }
    .accordion__content p:last-child {
      margin: 0; }
  .accordion__content a {
    color: #fb5004; }
    .accordion__content a:hover {
      text-decoration: underline; }

/* END Style accordion  */
/* ------------------------------------------ */
/* Style for alert-bar */
/* ------------------------------------------ */
.alert-bar {
  padding: 20px 0;
  background: #ededed;
  color: #1a1a1a;
  display: none;
  transition: filter 0.4s;
  /* PHR-757: Change color/font size of announcement bar */
  /* END PHR-757: Change color/font size of announcement bar */ }
  [data-page=home] .alert-bar,
  [data-page=service] .alert-bar {
    display: block; }
  .alert-bar.is-hidden {
    display: none !important; }
  @media screen and (max-width: 991px) {
    .mob-menu-open .alert-bar {
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px); } }
  .search-panel-open .alert-bar {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px); }
  @media screen and (max-width: 480px) {
    .alert-bar .content-wrapper, .alert-bar .app-sec__inner, .alert-bar .header__wrapper, .alert-bar .search-nores, .alert-bar .sec-intro__detail-wrapper, .alert-bar .submenu__wrapper {
      padding-right: 35px; } }
  .mob-menu-open .alert-bar, .search-panel-open .alert-bar {
    display: none; }
  .alert-bar.home, .alert-bar.source {
    background: #fb5004; }
    .alert-bar.home a, .alert-bar.home a:visited, .alert-bar.source a, .alert-bar.source a:visited {
      color: #fff;
      text-decoration: underline; }
    .alert-bar.home .alert-bar__desc, .alert-bar.source .alert-bar__desc {
      font-size: 17px;
      color: #fff; }
    .alert-bar.home .alert-bar__title, .alert-bar.source .alert-bar__title {
      font-size: 18px;
      color: #fff; }
    .alert-bar.home .alert-bar__close:before, .alert-bar.home .alert-bar__close:after, .alert-bar.source .alert-bar__close:before, .alert-bar.source .alert-bar__close:after {
      background: #fff; }
  .alert-bar.promotion, .alert-bar.join, .alert-bar.sourcePromotion {
    background: transparent;
    padding: 0;
    display: block; }
    .alert-bar.promotion .content-wrapper, .alert-bar.promotion .app-sec__inner, .alert-bar.promotion .header__wrapper, .alert-bar.promotion .search-nores, .alert-bar.promotion .sec-intro__detail-wrapper, .alert-bar.promotion .submenu__wrapper, .alert-bar.join .content-wrapper, .alert-bar.join .app-sec__inner, .alert-bar.join .header__wrapper, .alert-bar.join .search-nores, .alert-bar.join .sec-intro__detail-wrapper, .alert-bar.join .submenu__wrapper, .alert-bar.sourcePromotion .content-wrapper, .alert-bar.sourcePromotion .app-sec__inner, .alert-bar.sourcePromotion .header__wrapper, .alert-bar.sourcePromotion .search-nores, .alert-bar.sourcePromotion .sec-intro__detail-wrapper, .alert-bar.sourcePromotion .submenu__wrapper {
      max-width: 100%;
      width: 100%;
      padding: 0;
      margin: 0; }
    .alert-bar.promotion .alert-bar__holder, .alert-bar.join .alert-bar__holder, .alert-bar.sourcePromotion .alert-bar__holder {
      position: relative; }
    .alert-bar.promotion .alert-bar__control, .alert-bar.join .alert-bar__control, .alert-bar.sourcePromotion .alert-bar__control {
      position: absolute;
      right: 10px;
      top: 15px; }
    .alert-bar.promotion .alert-bar__desc, .alert-bar.join .alert-bar__desc, .alert-bar.sourcePromotion .alert-bar__desc {
      width: 100%; }
    .alert-bar.promotion .alert-bar__title, .alert-bar.join .alert-bar__title, .alert-bar.sourcePromotion .alert-bar__title {
      display: none; }
    .alert-bar.promotion .alert-bar__close:before, .alert-bar.promotion .alert-bar__close:after, .alert-bar.join .alert-bar__close:before, .alert-bar.join .alert-bar__close:after, .alert-bar.sourcePromotion .alert-bar__close:before, .alert-bar.sourcePromotion .alert-bar__close:after {
      background: #fff; }
    .alert-bar.promotion .video-onload, .alert-bar.join .video-onload, .alert-bar.sourcePromotion .video-onload {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -20%; }
    .alert-bar.promotion .loadingImg, .alert-bar.join .loadingImg, .alert-bar.sourcePromotion .loadingImg {
      z-index: 1;
      position: relative;
      margin-top: 20%; }
    .alert-bar.promotion .overlay-video-element, .alert-bar.join .overlay-video-element, .alert-bar.sourcePromotion .overlay-video-element {
      cursor: pointer; }
    .alert-bar.promotion .overlay-video-size, .alert-bar.join .overlay-video-size, .alert-bar.sourcePromotion .overlay-video-size {
      z-index: 2;
      position: absolute;
      top: 0;
      bottom: -60px;
      right: 0;
      left: 0; }
    .alert-bar.promotion .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_, .alert-bar.join .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_, .alert-bar.sourcePromotion .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_ {
      max-width: none !important;
      background-color: transparent !important;
      width: 70%;
      outline: none !important;
      top: -20%; }
    @media screen and (max-width: 900px) {
      .alert-bar.promotion .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_, .alert-bar.join .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_, .alert-bar.sourcePromotion .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_ {
        max-width: none !important;
        background-color: transparent !important;
        width: 90%;
        outline: none !important;
        top: -5%; } }
    .alert-bar.promotion .overlay-video-size, .alert-bar.join .overlay-video-size, .alert-bar.sourcePromotion .overlay-video-size {
      aspect-ratio: 1600 / 900 !important;
      width: 100% !important; }
    .alert-bar.promotion .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_ .styles_overlay-close__3e1ke, .alert-bar.join .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_ .styles_overlay-close__3e1ke, .alert-bar.sourcePromotion .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_ .styles_overlay-close__3e1ke {
      top: -5px !important;
      right: -10px !important; }
    .alert-bar.promotion svg, .alert-bar.join svg, .alert-bar.sourcePromotion svg {
      fill: white; }

.alert-bar__holder {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .alert-bar__holder {
      display: block; } }

.alert-bar__list {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.alert-bar__row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px; }
  .alert-bar__row:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 480px) {
    .alert-bar__row {
      display: block; } }

.alert-bar__title {
  margin: 0;
  padding-right: 30px;
  font-weight: bold;
  color: #1a1a1a;
  width: 20%; }
  @media screen and (max-width: 767px) {
    .alert-bar__title {
      width: 100%;
      padding-right: 20px; } }

.alert-bar__desc {
  color: #1a1a1a;
  width: 80%;
  /*&:active,
    &:focus,
    &:hover{
        opacity:1;
        color:#FFF;
        text-decoration: underline;
    }*/ }
  @media screen and (max-width: 480px) {
    .alert-bar__desc {
      width: 95%;
      margin-top: 2px;
      font-size: 12px; } }

.alert-bar__close {
  cursor: pointer;
  -webkit-appearance: none;
  position: relative;
  display: block;
  color: #1a1a1a;
  width: 20px;
  height: 20px;
  box-shadow: none;
  background: transparent;
  border: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }
  @media screen and (max-width: 480px) {
    .alert-bar__close {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -10px; } }
  .alert-bar__close:before, .alert-bar__close:after {
    content: '';
    background: #1a1a1a;
    position: absolute;
    display: block; }
  .alert-bar__close:before {
    width: 20px;
    height: 2px;
    left: 0;
    top: 10px;
    margin-top: -1px; }
  .alert-bar__close:after {
    height: 20px;
    width: 2px;
    top: 0;
    left: 10px;
    margin-left: -1px; }

/* END Style alert-bar  */
/* ------------------------------------------ */
/* Style for alert-template */
/* ------------------------------------------ */
.alert-template {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 35px 20px 20px;
  background: #dadada;
  color: #1a1a1a;
  z-index: 550;
  position: fixed;
  bottom: 40px;
  right: 45px;
  max-width: 350px;
  width: 90%; }
  @media screen and (max-width: 767px) {
    .alert-template {
      bottom: 25px;
      right: 5%;
      text-align: center;
      z-index: 451; } }
  [data-cookies-bar="show"] .alert-template {
    bottom: 85px; }
    @media screen and (max-width: 767px) {
      [data-cookies-bar="show"] .alert-template {
        bottom: 120px; } }
  .alert-template .alert-template__btn {
    padding: 10px 25px; }
    @media screen and (max-width: 767px) {
      .alert-template .alert-template__btn {
        padding: 8px 18px; } }
  .feedback-panel__alert.is-hidden .alert-template {
    display: none; }
  .alert-template a {
    text-decoration: underline; }
    .alert-template a:hover {
      opacity: 1;
      color: #fb5004; }
  .search-panel-open .alert-template {
    z-index: 2; }

.alert-template__msg {
  margin-bottom: 10px; }
  .alert-template__msg p {
    line-height: 1.5em;
    margin-bottom: 15px; }
    .alert-template__msg p:last-child {
      margin-bottom: 0; }

.alert-template__btns {
  white-space: nowrap; }

.alert-template__close {
  display: block;
  width: 16px;
  height: 16px;
  background: url("./assets/images/common/icons/svg/black/icon-close.svg") no-repeat center center;
  background-size: 100%;
  position: absolute;
  right: 12px;
  top: 12px;
  border: 0;
  box-shadow: none;
  cursor: pointer; }
  .alert-template__close:hover {
    background-image: url("./assets/images/common/icons/svg/orange/icon-close.svg"); }

/* END Style alert-template  */
/* ------------------------------------------ */
/* Style for app-intro */
/* ------------------------------------------ */
.app-intro {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0; }
  @media screen and (max-width: 380px) {
    .app-intro {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }

.app-intro__icon {
  position: relative;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  @media screen and (max-width: 380px) {
    .app-intro__icon {
      margin: 0 auto; } }
  .app-intro__icon img {
    display: block;
    width: 70px; }
    @media screen and (max-width: 1200px) {
      .app-intro__icon img {
        width: 55px; } }

.app-intro__intro {
  flex-basis: 100%;
  padding: 0 30px; }
  @media screen and (max-width: 1200px) {
    .app-intro__intro {
      padding: 0 15px; } }
  @media screen and (max-width: 380px) {
    .app-intro__intro {
      display: block;
      width: calc(100% - 55px);
      padding: 15px 0 0;
      text-align: center; } }

.app-intro__title {
  margin: 0 0 10px; }

.app-intro__desc {
  width: 100%; }

@media screen and (max-width: 380px) {
  .app-intro__btn {
    margin-top: 20px;
    width: 100%;
    text-align: center; } }

/* END Style app-intro  */
/* ------------------------------------------ */
/* Style for app-sec */
/* ------------------------------------------ */
.app-sec {
  position: relative;
  width: 100%; }

.app-sec__inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  padding: 90px 0; }
  @media screen and (max-width: 900px) {
    .app-sec__inner {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; } }
  @media screen and (max-width: 767px) {
    .app-sec__inner {
      padding: 45px 0; } }

.app-sec__item:nth-child(2n) .app-sec__inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .app-sec__item:nth-child(2n) .app-sec__inner .app-sec__detail {
    padding-left: 0;
    padding-right: 45px; }
    @media screen and (max-width: 900px) {
      .app-sec__item:nth-child(2n) .app-sec__inner .app-sec__detail {
        padding-right: 0; } }
  @media screen and (max-width: 900px) {
    .app-sec__item:nth-child(2n) .app-sec__inner {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; } }

.app-sec__item--white {
  color: #FFF; }

.app-sec__img {
  width: 55%;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  @media screen and (max-width: 900px) {
    .app-sec__img {
      width: 100%;
      margin-bottom: 15px;
      max-width: 800px; }
      .app-sec__img img {
        display: block; } }

.app-sec__detail {
  width: 45%;
  padding-left: 45px; }
  @media screen and (max-width: 900px) {
    .app-sec__detail {
      width: 100%;
      padding-left: 0; } }

.app-sec__icon {
  margin-bottom: 10px; }
  @media screen and (max-width: 1280px) {
    .app-sec__icon {
      width: 72px; } }

.app-sec__detail-item {
  margin-bottom: 20px; }

.app-sec__big-title {
  font-weight: 300;
  margin: 0 0 15px; }

.app-sec__title {
  margin-bottom: 10px; }

.app-sec__detail-title {
  margin-bottom: 10px;
  color: #fb5004; }
  .app-sec__item--white .app-sec__detail-title {
    color: #FFF; }

.app-sec__detail-desc {
  line-height: 1.5em;
  max-width: 480px; }
  @media screen and (max-width: 991px) {
    .app-sec__detail-desc {
      font-size: 14px; } }

.app-sec__link-icon {
  width: 20px; }

.app-sec__links-title {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-right: 10px; }
  @media screen and (max-width: 1280px) {
    .app-sec__links-title {
      font-size: 14px; } }

.app-sec__link {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  background: no-repeat center center;
  background-size: cover; }
  @media screen and (max-width: 1280px) {
    .app-sec__link {
      font-size: 14px; } }
  .app-sec__link:after {
    content: '';
    display: block;
    width: 1px;
    height: 18px;
    background: #1a1a1a;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -9px; }
    .app-sec__item--white .app-sec__link:after {
      background: #FFF; }
  .app-sec__link:last-child {
    padding-right: 0;
    margin-right: 0; }
    .app-sec__link:last-child:after {
      display: none; }
  .app-sec__link span,
  .app-sec__link .app-sec__link-icon {
    display: inline-block;
    vertical-align: middle; }
  .app-sec__link .app-sec__link-icon {
    margin-right: 5px;
    margin-top: -3px; }
  .app-sec__item--white .app-sec__link {
    color: #FFF; }

.app-sec__other-links {
  max-width: 480px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 12px;
  row-gap: 12px; }
  .app-sec__other-links-item img {
    display: block;
    width: 100%; }

/* END Style app-sec  */
/* ------------------------------------------ */
/* Style for button */
/* ------------------------------------------ */
.button, .feature-listing__btn, .feedback-panel__btn, .organic-listing__btn, .pagination__btn-prev,
.pagination__btn-next {
  display: inline-block;
  margin: 0;
  padding: 12px 40px;
  box-sizing: border-box;
  font-weight: bold;
  border-radius: 30px;
  background: transparent;
  color: #fb5004;
  border: 1px solid #fb5004;
  cursor: pointer; }
  .button + .button, .feature-listing__btn + .button, .feedback-panel__btn + .button, .organic-listing__btn + .button, .pagination__btn-prev + .button, .pagination__btn-next + .button, .button + .feature-listing__btn, .feature-listing__btn + .feature-listing__btn, .feedback-panel__btn + .feature-listing__btn, .organic-listing__btn + .feature-listing__btn, .pagination__btn-prev + .feature-listing__btn, .pagination__btn-next + .feature-listing__btn, .button + .feedback-panel__btn, .feature-listing__btn + .feedback-panel__btn, .feedback-panel__btn + .feedback-panel__btn, .organic-listing__btn + .feedback-panel__btn, .pagination__btn-prev + .feedback-panel__btn, .pagination__btn-next + .feedback-panel__btn, .button + .organic-listing__btn, .feature-listing__btn + .organic-listing__btn, .feedback-panel__btn + .organic-listing__btn, .organic-listing__btn + .organic-listing__btn, .pagination__btn-prev + .organic-listing__btn, .pagination__btn-next + .organic-listing__btn, .button + .pagination__btn-prev, .feature-listing__btn + .pagination__btn-prev, .feedback-panel__btn + .pagination__btn-prev, .organic-listing__btn + .pagination__btn-prev, .pagination__btn-prev + .pagination__btn-prev, .pagination__btn-next + .pagination__btn-prev,
  .button + .pagination__btn-next,
  .feature-listing__btn + .pagination__btn-next,
  .feedback-panel__btn + .pagination__btn-next,
  .organic-listing__btn + .pagination__btn-next,
  .pagination__btn-prev + .pagination__btn-next,
  .pagination__btn-next + .pagination__btn-next {
    margin-left: 5px; }
  .button > span, .feature-listing__btn > span, .feedback-panel__btn > span, .organic-listing__btn > span, .pagination__btn-prev > span, .pagination__btn-next > span {
    display: inline-block;
    vertical-align: middle; }
  .button:hover, .feature-listing__btn:hover, .feedback-panel__btn:hover, .organic-listing__btn:hover, .pagination__btn-prev:hover,
  .pagination__btn-next:hover {
    opacity: 1;
    background: #fb5004;
    color: #FFF; }
    .button:hover.button--right-arr:after, .feature-listing__btn:hover.button--right-arr:after, .feedback-panel__btn:hover.button--right-arr:after, .organic-listing__btn:hover.button--right-arr:after, .pagination__btn-prev:hover.button--right-arr:after,
    .pagination__btn-next:hover.button--right-arr:after {
      border-top: 2px solid #FFF;
      border-right: 2px solid #FFF; }
  .button.button--white, .button--white.feature-listing__btn, .button--white.feedback-panel__btn, .button--white.organic-listing__btn, .button--white.pagination__btn-prev,
  .button--white.pagination__btn-next {
    background: #FFF;
    color: #fb5004; }
    .button.button--white:hover, .button--white.feature-listing__btn:hover, .button--white.feedback-panel__btn:hover, .button--white.organic-listing__btn:hover, .button--white.pagination__btn-prev:hover,
    .button--white.pagination__btn-next:hover {
      background: #fb5004;
      color: #FFF; }
  .button.button--whiteOrange, .button--whiteOrange.feature-listing__btn, .button--whiteOrange.feedback-panel__btn, .button--whiteOrange.organic-listing__btn, .button--whiteOrange.pagination__btn-prev,
  .button--whiteOrange.pagination__btn-next {
    background: #FFF;
    color: #fb5004; }
    .button.button--whiteOrange:hover, .button--whiteOrange.feature-listing__btn:hover, .button--whiteOrange.feedback-panel__btn:hover, .button--whiteOrange.organic-listing__btn:hover, .button--whiteOrange.pagination__btn-prev:hover,
    .button--whiteOrange.pagination__btn-next:hover {
      background: transparent;
      color: #FFF;
      border: 1px solid #FFF; }
  .button.button--orange, .button--orange.feature-listing__btn, .button--orange.feedback-panel__btn, .button--orange.organic-listing__btn, .button--orange.pagination__btn-prev,
  .button--orange.pagination__btn-next {
    background: #fb5004;
    color: #FFF; }
    .button.button--orange:hover, .button--orange.feature-listing__btn:hover, .button--orange.feedback-panel__btn:hover, .button--orange.organic-listing__btn:hover, .button--orange.pagination__btn-prev:hover,
    .button--orange.pagination__btn-next:hover {
      background: #FFF;
      color: #fb5004; }
  .button.button--ghost-black, .button--ghost-black.feature-listing__btn, .button--ghost-black.feedback-panel__btn, .button--ghost-black.organic-listing__btn, .button--ghost-black.pagination__btn-prev,
  .button--ghost-black.pagination__btn-next {
    background: transparent;
    color: #1a1a1a;
    border-color: #1a1a1a; }
    .button.button--ghost-black:hover, .button--ghost-black.feature-listing__btn:hover, .button--ghost-black.feedback-panel__btn:hover, .button--ghost-black.organic-listing__btn:hover, .button--ghost-black.pagination__btn-prev:hover,
    .button--ghost-black.pagination__btn-next:hover {
      background: #fb5004;
      color: #FFF;
      border-color: #fb5004; }
  .button.button--ghost-white, .button--ghost-white.feature-listing__btn, .button--ghost-white.feedback-panel__btn, .button--ghost-white.organic-listing__btn, .button--ghost-white.pagination__btn-prev,
  .button--ghost-white.pagination__btn-next {
    background: transparent;
    color: #FFF;
    border-color: #FFF; }
    .button.button--ghost-white:hover, .button--ghost-white.feature-listing__btn:hover, .button--ghost-white.feedback-panel__btn:hover, .button--ghost-white.organic-listing__btn:hover, .button--ghost-white.pagination__btn-prev:hover,
    .button--ghost-white.pagination__btn-next:hover {
      background: #FFF;
      color: #fb5004;
      border-color: #FFF; }
  .button.button--ghost-orange, .button--ghost-orange.feature-listing__btn, .button--ghost-orange.feedback-panel__btn, .button--ghost-orange.organic-listing__btn, .button--ghost-orange.pagination__btn-prev,
  .button--ghost-orange.pagination__btn-next {
    background: transparent;
    color: #fb5004;
    border-color: #fb5004; }
    .button.button--ghost-orange:hover, .button--ghost-orange.feature-listing__btn:hover, .button--ghost-orange.feedback-panel__btn:hover, .button--ghost-orange.organic-listing__btn:hover, .button--ghost-orange.pagination__btn-prev:hover,
    .button--ghost-orange.pagination__btn-next:hover {
      background: #fb5004;
      color: #FFF;
      border-color: #fb5004; }
  .button.button--disable, .button--disable.feature-listing__btn, .button--disable.feedback-panel__btn, .button--disable.organic-listing__btn, .button--disable.pagination__btn-prev,
  .button--disable.pagination__btn-next {
    background: transparent;
    color: #888888;
    border-color: #888888; }
  .button.button--wide, .button--wide.feature-listing__btn, .button--wide.feedback-panel__btn, .button--wide.organic-listing__btn, .button--wide.pagination__btn-prev,
  .button--wide.pagination__btn-next {
    padding: 12px 45px; }
  .button.button--s, .button--s.feature-listing__btn, .button--s.feedback-panel__btn, .button--s.organic-listing__btn, .button--s.pagination__btn-prev,
  .button--s.pagination__btn-next {
    padding: 10px 30px; }
    @media screen and (max-width: 767px) {
      .button.button--s, .button--s.feature-listing__btn, .button--s.feedback-panel__btn, .button--s.organic-listing__btn, .button--s.pagination__btn-prev,
      .button--s.pagination__btn-next {
        padding: 8px 18px; } }
  .button.button--right-arr, .button--right-arr.feature-listing__btn, .button--right-arr.feedback-panel__btn, .button--right-arr.organic-listing__btn, .button--right-arr.pagination__btn-prev,
  .button--right-arr.pagination__btn-next {
    white-space: nowrap; }
    .button.button--right-arr span, .button--right-arr.feature-listing__btn span, .button--right-arr.feedback-panel__btn span, .button--right-arr.organic-listing__btn span, .button--right-arr.pagination__btn-prev span, .button--right-arr.pagination__btn-next span {
      display: inline-block;
      vertical-align: middle;
      white-space: all; }
    .button.button--right-arr:after, .button--right-arr.feature-listing__btn:after, .button--right-arr.feedback-panel__btn:after, .button--right-arr.organic-listing__btn:after, .button--right-arr.pagination__btn-prev:after,
    .button--right-arr.pagination__btn-next:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
      margin-left: 5px;
      width: 0.4em;
      height: 0.4em;
      border-top: 2px solid #fb5004;
      border-right: 2px solid #fb5004;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }

/* END Style button  */
/* ------------------------------------------ */
/* Style for button-share */
/* ------------------------------------------ */
.button-share__holder {
  position: relative;
  font-size: 0;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .footer-bottom .button-share__holder, .button-share__holder.button-share__holder--big {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    @media screen and (max-width: 480px) {
      .footer-bottom .button-share__holder, .button-share__holder.button-share__holder--big {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
  .footer-bottom .button-share__holder {
    margin-top: 15px; }
    @media screen and (max-width: 640px) {
      .footer-bottom .button-share__holder {
        text-align: left;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px; } }

.button-share__btn-holder {
  margin-top: -3px;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  @media screen and (max-width: 640px) {
    .footer-bottom .button-share__btn-holder {
      margin-top: 5px;
      text-align: left;
      width: 100%; } }

.button-share {
  cursor: pointer;
  border: 0;
  box-shadow: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 19px;
  margin-bottom: 20px;
  width: 19px;
  height: 19px;
  background: no-repeat center center;
  background-size: 100%; }
  @media screen and (max-width: 1580px) {
    .button-share {
      margin-right: 5px; } }
  @media screen and (max-width: 991px) {
    .button-share {
      margin-right: 15px; } }
  @media screen and (max-width: 767px) {
    .button-share {
      width: 15px;
      height: 15px;
      margin-right: 15px; } }
  @media screen and (max-width: 640px) {
    .footer-bottom .button-share {
      margin-bottom: 0; } }
  .button-share__holder--big .button-share {
    width: 35px;
    height: 35px;
    margin: 0;
    margin-right: 30px; }
    .button-share__holder--big .button-share:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1200px) {
      .button-share__holder--big .button-share {
        width: 28px;
        height: 28px;
        margin-right: 20px; } }
    @media screen and (max-width: 480px) {
      .button-share__holder--big .button-share {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        margin-bottom: 12px; } }
  .button-share.button-share--email {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-email.svg"); }
  .button-share.button-share--wa {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-wa.svg"); }
  .button-share.button-share--fb {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-fb.svg");
    background-size: 76%; }
  .button-share.button-share--tw {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-tw.svg");
    background-size: 76%; }
  .button-share.button-share--ln {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-ln.svg");
    background-size: 76%; }
  .button-share.button-share--wb {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-wb.svg");
    background-size: 100%; }
  .button-share.button-share--yt {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-yt.svg");
    background-size: 100%; }
  .button-share.button-share--sp {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-sp.svg"); }
  .button-share.button-share--wc {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-wc.svg");
    background-size: 95%; }
  .button-share.button-share--yk {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-yk.svg"); }
  .button-share.button-share--fbm {
    background-image: url("./assets/images/common/icons/svg/black/icon-share-fbmessenger.svg"); }
  .main-banner .button-share {
    margin-bottom: 15px; }
    @media screen and (max-width: 991px) {
      .main-banner .button-share.button-share--email {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-email.svg"); }
      .main-banner .button-share.button-share--wa {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-wa.svg"); }
      .main-banner .button-share.button-share--fb {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-fb.svg"); }
      .main-banner .button-share.button-share--tw {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-tw.svg"); }
      .main-banner .button-share.button-share--ln {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-ln.svg"); }
      .main-banner .button-share.button-share--wb {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-wb.svg"); }
      .main-banner .button-share.button-share--wc {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-wc.svg"); }
      .main-banner .button-share.button-share--yt {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-yt.svg"); }
      .main-banner .button-share.button-share--yk {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-yk.svg"); }
      .main-banner .button-share.button-share--fbm {
        background-image: url("./assets/images/common/icons/svg/white/icon-share-fbmessenger.svg"); } }

.button-share__title {
  margin-bottom: 0;
  margin-right: 15px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap; }
  @media screen and (max-width: 1580px) {
    .button-share__title {
      margin-right: 5px; } }
  @media screen and (max-width: 991px) {
    .button-share__title {
      margin-right: 15px; } }
  .footer-bottom .button-share__title {
    margin-bottom: 20px; }
    @media screen and (max-width: 640px) {
      .footer-bottom .button-share__title {
        margin-top: 4px;
        margin-bottom: 0;
        text-align: left; } }
    @media screen and (max-width: 480px) {
      .footer-bottom .button-share__title {
        width: 100%; } }
  .button-share__holder--big .button-share__title {
    margin: 0;
    margin-right: 30px;
    font-weight: 400;
    white-space: nowrap; }
    @media screen and (max-width: 480px) {
      .button-share__holder--big .button-share__title {
        margin: 0 auto 10px; } }

.button-share__wcqr {
  text-align: center;
  padding: 30px 0 0;
  max-width: 200px; }
  .button-share__wcqr + button {
    cursor: pointer; }

.button-share__wcqr-txt {
  text-align: center;
  margin-top: 15px;
  padding: 0 30px;
  font-size: 14px;
  line-height: 1.5em; }

/* END Style button-share  */
/* ------------------------------------------ */
/* Style for sample */
/* ------------------------------------------ */
.channel-bar {
  position: relative;
  font-size: 0;
  margin: 0 0 45px; }
  @media screen and (max-width: 991px) {
    .channel-bar {
      margin: 0 auto; } }
  .channel-bar > .content-wrapper, .channel-bar > .app-sec__inner, .channel-bar > .header__wrapper, .channel-bar > .search-nores, .channel-bar > .sec-intro__detail-wrapper, .channel-bar > .submenu__wrapper {
    display: table; }
    @media screen and (max-width: 991px) {
      .channel-bar > .content-wrapper, .channel-bar > .app-sec__inner, .channel-bar > .header__wrapper, .channel-bar > .search-nores, .channel-bar > .sec-intro__detail-wrapper, .channel-bar > .submenu__wrapper {
        display: block; } }

.channel-bar__item {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  border-right: 2px solid #e5e5e5;
  padding-right: 45px; }
  @media screen and (max-width: 991px) {
    .channel-bar__item {
      display: block;
      width: 100%; } }
  @media screen and (max-width: 991px) {
    .channel-bar__item {
      padding-right: 0;
      border-right: 0; } }
  .channel-bar > .content-wrapper.animated .channel-bar__item, .channel-bar > .animated.app-sec__inner .channel-bar__item, .channel-bar > .animated.header__wrapper .channel-bar__item, .channel-bar > .animated.search-nores .channel-bar__item, .channel-bar > .animated.sec-intro__detail-wrapper .channel-bar__item, .channel-bar > .animated.submenu__wrapper .channel-bar__item {
    transition: 0.6s; }
  .channel-bar > .content-wrapper.animated .channel-bar__item:first-child, .channel-bar > .animated.app-sec__inner .channel-bar__item:first-child, .channel-bar > .animated.header__wrapper .channel-bar__item:first-child, .channel-bar > .animated.search-nores .channel-bar__item:first-child, .channel-bar > .animated.sec-intro__detail-wrapper .channel-bar__item:first-child, .channel-bar > .animated.submenu__wrapper .channel-bar__item:first-child {
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px); }
  .channel-bar > .content-wrapper.animated.fadeIn .channel-bar__item:first-child, .channel-bar > .animated.fadeIn.app-sec__inner .channel-bar__item:first-child, .channel-bar > .animated.fadeIn.header__wrapper .channel-bar__item:first-child, .channel-bar > .animated.fadeIn.search-nores .channel-bar__item:first-child, .channel-bar > .animated.fadeIn.sec-intro__detail-wrapper .channel-bar__item:first-child, .channel-bar > .animated.fadeIn.submenu__wrapper .channel-bar__item:first-child {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  .channel-bar__item:last-child {
    padding-left: 45px;
    padding-right: 0;
    border-right: 0; }
    .channel-bar > .content-wrapper.animated .channel-bar__item:last-child, .channel-bar > .animated.app-sec__inner .channel-bar__item:last-child, .channel-bar > .animated.header__wrapper .channel-bar__item:last-child, .channel-bar > .animated.search-nores .channel-bar__item:last-child, .channel-bar > .animated.sec-intro__detail-wrapper .channel-bar__item:last-child, .channel-bar > .animated.submenu__wrapper .channel-bar__item:last-child {
      -webkit-transform: translateX(100px);
      -ms-transform: translateX(100px);
      transform: translateX(100px); }
    .channel-bar > .content-wrapper.animated.fadeIn .channel-bar__item:last-child, .channel-bar > .animated.fadeIn.app-sec__inner .channel-bar__item:last-child, .channel-bar > .animated.fadeIn.header__wrapper .channel-bar__item:last-child, .channel-bar > .animated.fadeIn.search-nores .channel-bar__item:last-child, .channel-bar > .animated.fadeIn.sec-intro__detail-wrapper .channel-bar__item:last-child, .channel-bar > .animated.fadeIn.submenu__wrapper .channel-bar__item:last-child {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0); }
    @media screen and (max-width: 991px) {
      .channel-bar__item:last-child {
        padding-left: 0;
        text-align: center;
        border-top: 2px solid #e5e5e5;
        padding: 45px 0; } }

/* END Style sample  */
/* ------------------------------------------ */
/* Style for comformance */
/* ------------------------------------------ */
.comformance {
  position: relative;
  text-align: center;
  margin: 0 0 15px; }
  @media screen and (max-width: 991px) {
    .comformance {
      display: none; } }

.comformance__title {
  font-size: 14px; }

.comformance__item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px; }
  @media screen and (max-width: 1180px) {
    .comformance__item {
      margin: 0 4px; } }

/* END Style comformance  */
/* ------------------------------------------ */
/* Style for cookies-bar */
/* ------------------------------------------ */
.cookies-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 0;
  background: rgba(0, 0, 0, 0.7);
  color: #FFF;
  z-index: 200;
  transition: filter 0.4s; }
  .cookies-bar.is-hidden {
    display: none; }
  @media screen and (max-width: 991px) {
    .mob-menu-open .cookies-bar {
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px); } }
  .search-panel-open .cookies-bar {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px); }
  @media screen and (max-width: 767px) {
    .cookies-bar {
      text-align: center; } }

.cookies-bar__holder {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .cookies-bar__holder {
      display: block; } }

.cookies-bar__title {
  margin: 0;
  padding-right: 30px;
  font-weight: bold;
  color: #FFF; }
  @media screen and (max-width: 767px) {
    .cookies-bar__title {
      padding-right: 20px; } }

.cookies-bar__desc {
  color: #FFF;
  width: 80%; }
  @media screen and (max-width: 767px) {
    .cookies-bar__desc {
      width: 100%; } }
  .cookies-bar__desc a {
    color: #fb5004; }
    .cookies-bar__desc a:hover {
      opacity: 1;
      color: #fb5004;
      text-decoration: underline; }

.cookies-bar__close {
  cursor: pointer;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  background: url("./assets/images/common/icons/svg/white/icon-close.svg") no-repeat center center transparent;
  background-size: 100%;
  box-shadow: none;
  border: 0; }

.cookies-bar__control {
  white-space: nowrap; }
  @media screen and (max-width: 480px) {
    .cookies-bar__control {
      margin-top: 10px; } }

/* END Style cookies-bar  */
/* ------------------------------------------ */
/* Style for sample */
/* ------------------------------------------ */
/* END Style sample  */
/* ------------------------------------------ */
/* Style for feature-listing */
/* ------------------------------------------ */
.feature-listing {
  position: relative; }
  .feature-listing em {
    font-style: normal;
    font-weight: bold;
    color: #fb5004; }

.feature-listing__head {
  position: relative;
  background: #fb5004;
  color: #FFF;
  font-size: 12px;
  padding: 10px 15px;
  padding-left: 35px; }
  .feature-listing__head h2,
  .feature-listing__head h3,
  .feature-listing__head h4,
  .feature-listing__head h5 {
    margin: 0; }
  .feature-listing__head:before {
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    left: 15px;
    margin-top: -7.5px;
    background: url("./assets/images/common/icons/svg/white/icon-bookmark.svg") no-repeat center center;
    background-size: 100%; }

.feature-listing__items {
  position: relative;
  background: #fff3ec;
  padding: 15px 5%; }

.feature-listing__item {
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid #dadada; }
  .feature-listing__item:last-child {
    border-bottom: 0; }

.feature-listing__item-big-title {
  margin: 0 0 15px; }
  @media screen and (min-width: 768px) {
    .feature-listing__item-big-title {
      margin-right: 110px; } }

.feature-listing__item-row {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .feature-listing__item-row {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  @media screen and (max-width: 767px) {
    .feature-listing__item-row.is1Badge {
      min-height: 40px; } }
  @media screen and (max-width: 767px) {
    .feature-listing__item-row.is2Badge {
      min-height: 80px; } }

.feature-listing__img {
  text-align: center;
  outline: 1px solid #e6e6e6;
  margin-right: 20px;
  background: white;
  position: relative; }
  @media screen and (max-width: 480px) {
    .feature-listing__img {
      margin-bottom: 10px; } }
  .feature-listing__img > a {
    width: 160px;
    height: 120px;
    line-height: 120px;
    display: block;
    padding-bottom: 1px; }

.feature-listing__thumb {
  max-height: 100%;
  max-width: 100%; }
  .feature-listing__thumb:last-child {
    margin-right: 0; }

@media screen and (min-width: 768px) {
  .feature-listing__img-holder:hover .feature-listing__pop {
    display: block; } }

.feature-listing__img-holder:hover:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 30px;
  left: 0;
  bottom: 100%;
  z-index: 500; }

@media screen and (min-width: 768px) {
  .feature-listing__img:hover .feature-listing__pop {
    display: block; } }

.feature-listing__pop {
  position: absolute;
  background: #FFF;
  font-size: 0;
  line-height: normal;
  width: 430px;
  z-index: 1000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  left: 0;
  bottom: 100%;
  display: none; }
  .feature-listing__pop img {
    height: 268px;
    display: block;
    margin: auto; }
  @media screen and (max-width: 991px) {
    .feature-listing__pop {
      display: none; } }

.feature-listing__popcap {
  background: #fb5004;
  color: #FFF;
  padding: 15px 30px; }
  @media screen and (max-width: 991px) {
    .feature-listing__popcap {
      padding: 13px 28px; } }

.feature-listing__popcap-title em, .feature-listing__popcap-title a, .feature-listing__popcap-title div, .feature-listing__popcap-title span {
  color: #FFF; }

.feature-listing__popcap-btns {
  margin-top: 10px; }
  .feature-listing__popcap-btns .feature-listing__btn {
    color: #FFF;
    border-color: #FFF;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px; }

.feature-listing__item-head {
  display: block;
  margin: 0 0 5px; }

.feature-listing__item-title {
  display: inline-block;
  margin: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: bold; }

.feature-listing__infos {
  position: relative;
  font-size: 0;
  margin: 0 0 5px; }

.feature-listing__info {
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
  padding-right: 10px;
  position: relative; }
  .feature-listing__info:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 16px;
    margin-top: -8px;
    width: 1px;
    background: #1a1a1a; }
  .feature-listing__info:last-child:after {
    display: none;
    margin-right: 0;
    padding-right: 0; }

.feature-listing__detail {
  width: 100%; }

.feature-listing__desc {
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 5px; }

.feature-listing__specs {
  position: relative;
  font-size: 0; }
  @media screen and (max-width: 640px) {
    .feature-listing__specs {
      display: block;
      margin: 10px 0; } }

.feature-listing__spec {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  padding-right: 10px;
  position: relative; }
  .feature-listing__spec:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 16px;
    margin-top: -8px;
    width: 1px;
    background: #1a1a1a; }
  .feature-listing__spec:last-child:after {
    display: none;
    margin-right: 0;
    padding-right: 0; }

.feature-listing__btn {
  margin: 10px 0 0;
  padding: 8px 20px;
  margin-right: 10px; }
  .feature-listing__btn + .feature-listing__btn {
    margin-left: 0; }

.feature-listing__note-icons {
  display: inline-block;
  vertical-align: middle; }
  @media screen and (max-width: 640px) {
    .feature-listing__note-icons {
      display: block;
      margin: 10px 0; } }

.feature-listing__icons {
  margin-top: 5px; }

.feature-listing__icon {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  height: 22px;
  margin-right: 10px;
  /*
    background:imgurl("common/icons/sribadge.svg") no-repeat;
    background-position-x: -14px;
    background-position-y:0;
    background-size: 440px auto;*/
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  /* SRI BADGE ICONS */ }
  .feature-listing__icon:last-child {
    margin-right: 0; }
  .feature-listing__icon--booth {
    width: 61px;
    height: 24px;
    background-image: url("./assets/images/common/sri-icon/meetusatourbooth.svg"); }
  .feature-listing__icon--small-order {
    width: 71px;
    height: 30px;
    background-image: url("./assets/images/common/sri-icon/acceptsmallorder.svg"); }
  .feature-listing__icon--CVS {
    background-image: url("./assets/images/common/sri-icon/certificates-en.svg"); }
    :lang(zh-hk) .feature-listing__icon--CVS {
      background-image: url("./assets/images/common/sri-icon/certificates-tc.svg"); }
    :lang(zh-cn) .feature-listing__icon--CVS {
      background-image: url("./assets/images/common/sri-icon/certificates-sc.svg"); }
  .feature-listing__icon--DNB {
    background-image: url("./assets/images/common/sri-icon/d&B.svg"); }
  .feature-listing__icon--EXH {
    background-image: url("./assets/images/common/sri-icon/hktdcexhibitor-en.svg"); }
    :lang(zh-hk) .feature-listing__icon--EXH {
      background-image: url("./assets/images/common/sri-icon/hktdcexhibitor-tc.svg"); }
    :lang(zh-cn) .feature-listing__icon--EXH {
      background-image: url("./assets/images/common/sri-icon/hktdcexhibitor-sc.svg"); }
  .feature-listing__icon--PREMIUM1 {
    background-image: url("./assets/images/common/sri-icon/1stpremadver-en.svg"); }
    :lang(zh-hk) .feature-listing__icon--PREMIUM1 {
      background-image: url("./assets/images/common/sri-icon/1stpremadver-tc.svg"); }
    :lang(zh-cn) .feature-listing__icon--PREMIUM1 {
      background-image: url("./assets/images/common/sri-icon/1stpremadver-sc.svg"); }
  .feature-listing__icon--PREMIUM2 {
    background-image: url("./assets/images/common/sri-icon/2ndpremadver-en.svg"); }
    :lang(zh-hk) .feature-listing__icon--PREMIUM2 {
      background-image: url("./assets/images/common/sri-icon/2ndpremadver-tc.svg"); }
    :lang(zh-cn) .feature-listing__icon--PREMIUM2 {
      background-image: url("./assets/images/common/sri-icon/2ndpremadver-sc.svg"); }
  .feature-listing__icon--PREMIUM3 {
    background-image: url("./assets/images/common/sri-icon/3rdpremadver-en.svg"); }
    :lang(zh-hk) .feature-listing__icon--PREMIUM3 {
      background-image: url("./assets/images/common/sri-icon/3rdpremadver-tc.svg"); }
    :lang(zh-cn) .feature-listing__icon--PREMIUM3 {
      background-image: url("./assets/images/common/sri-icon/3rdpremadver-sc.svg"); }
  .feature-listing__icon--PREMIUM4 {
    background-image: url("./assets/images/common/sri-icon/4thpremadver-en.svg"); }
    :lang(zh-hk) .feature-listing__icon--PREMIUM4 {
      background-image: url("./assets/images/common/sri-icon/4thpremadver-tc.svg"); }
    :lang(zh-cn) .feature-listing__icon--PREMIUM4 {
      background-image: url("./assets/images/common/sri-icon/4thpremadver-sc.svg"); }
  .feature-listing__icon--PREMIUM5 {
    background-image: url("./assets/images/common/sri-icon/5thpremadver-en.svg"); }
    :lang(zh-hk) .feature-listing__icon--PREMIUM5 {
      background-image: url("./assets/images/common/sri-icon/5thpremadver-tc.svg"); }
    :lang(zh-cn) .feature-listing__icon--PREMIUM5 {
      background-image: url("./assets/images/common/sri-icon/5thpremadver-sc.svg"); }
  .feature-listing__icon--PREMIUM5P {
    background-image: url("./assets/images/common/sri-icon/L5premadver-en.svg"); }
    :lang(zh-hk) .feature-listing__icon--PREMIUM5P {
      background-image: url("./assets/images/common/sri-icon/L5premadver-tc.svg"); }
    :lang(zh-cn) .feature-listing__icon--PREMIUM5P {
      background-image: url("./assets/images/common/sri-icon/L5premadver-sc.svg"); }
  .feature-listing__icon--SRI {
    background-image: url("./assets/images/common/sri-icon/supplierreference-en.svg"); }
    :lang(zh-hk) .feature-listing__icon--SRI {
      background-image: url("./assets/images/common/sri-icon/supplierreference-tc.svg"); }
    :lang(zh-cn) .feature-listing__icon--SRI {
      background-image: url("./assets/images/common/sri-icon/supplierreference-sc.svg"); }
  .feature-listing__icon--ENV_COMPLIANCE {
    background-image: url("./assets/images/common/sri-icon/complianceverified.svg"); }
  .feature-listing__icon--QUALITY_COMPLIANCE {
    background-image: url("./assets/images/common/sri-icon/complianceverified.svg"); }
  .feature-listing__icon--SECURITY_COMPLIANCE {
    background-image: url("./assets/images/common/sri-icon/complianceverified.svg"); }
  .feature-listing__icon--SOCIAL_COMPLIANCE {
    background-image: url("./assets/images/common/sri-icon/complianceverified.svg"); }
  .feature-listing__icon--FREELISTING {
    background-image: url("./assets/images/common/sri-icon/unverified-en.svg"); }
    :lang(zh-hk) .feature-listing__icon--FREELISTING {
      background-image: url("./assets/images/common/sri-icon/unverified-tc.svg"); }
    :lang(zh-cn) .feature-listing__icon--FREELISTING {
      background-image: url("./assets/images/common/sri-icon/unverified-sc.svg"); }

/* END Style feature-listing  */
/* ------------------------------------------ */
/* Style for feedback-panel */
/* ------------------------------------------ */
.feedback-panel__btn {
  cursor: pointer;
  display: block;
  border: 0;
  box-shadow: none;
  position: fixed;
  bottom: 45px;
  right: 45px;
  margin-right: 2px;
  padding: 8px 15px;
  padding-right: 20px;
  transition: 0.3s;
  background: #dadada;
  color: #1a1a1a;
  z-index: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  [data-cookies-bar="show"] .feedback-panel__btn {
    bottom: 90px; }
  .feedback-panel__btn:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: url("./assets/images/common/icons/svg/black/icon-star.svg") no-repeat center center;
    background-size: 100%;
    margin-right: 8px; }
  .feedback-panel__btn span {
    display: inline-block;
    vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .feedback-panel__btn {
      bottom: 30px;
      right: 5%;
      z-index: 450; } }
  @media screen and (max-width: 480px) {
    [data-cookies-bar="show"] .feedback-panel__btn {
      bottom: 130px; } }
  .feedback-panel__btn:hover {
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 3px 3px rgba(0, 0, 0, 0.22); }
    .feedback-panel__btn:hover:before {
      background-image: url("./assets/images/common/icons/svg/white/icon-star.svg"); }
  .search-panel-open .feedback-panel__btn {
    z-index: 1; }

/* END Style feedback-panel  */
/* ------------------------------------------ */
/* Style for footer */
/* ------------------------------------------ */
footer {
  position: relative;
  transition: filter 0.4s; }
  @media screen and (max-width: 991px) {
    .mob-menu-open footer {
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px); } }
  .search-panel-open footer {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px); }

.footer__body {
  position: relative;
  padding: 80px 0; }
  @media screen and (max-width: 991px) {
    .footer__body {
      padding: 60px 0; } }

/* END Style footer  */
/* ------------------------------------------ */
/* Style for footer-bar */
/* ------------------------------------------ */
.footer-bar {
  position: relative;
  width: 100%;
  background: #fb5004;
  padding: 30px 0;
  color: #FFF;
  text-align: center; }

.footer-bar__title {
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
  margin: 0;
  font-weight: bold; }

.footer-bar__desc {
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
  margin: 10px 60px;
  line-height: 1.5;
  font-weight: 300; }
  .footer-bar__desc:last-child {
    margin-bottom: 10px; }

@media screen and (max-width: 480px) {
  .footer-bar__button {
    margin-top: 20px; } }

.footer-bar__arrow {
  display: inline-block;
  vertical-align: middle; }
  .footer-bar__arrow:before {
    content: '';
    width: 45px;
    height: 45px;
    display: block;
    background: no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/white/icon-long-arrow.svg");
    background-size: 100%; }
    @media screen and (max-width: 991px) {
      .footer-bar__arrow:before {
        width: 24px;
        height: 24px; } }

/* END Style footer-bar  */
/* ------------------------------------------ */
/* Style for footer-bottom */
/* ------------------------------------------ */
.footer-bottom {
  text-align: center; }

.footer-bottom__logo {
  display: block;
  margin: 45px auto 20px; }
  @media screen and (max-width: 640px) {
    .footer-bottom__logo {
      margin-top: 0;
      text-align: left; } }

.footer-bottom__row {
  text-align: center;
  margin-bottom: 10px; }
  @media screen and (max-width: 640px) {
    .footer-bottom__row + .footer-bottom__row {
      margin-top: -10px; } }
  .footer-bottom__row:first-child {
    margin-top: 45px; }
    @media screen and (max-width: 991px) {
      .footer-bottom__row:first-child {
        margin-top: 0; } }
  @media screen and (max-width: 991px) {
    .footer-bottom__row.footer-bottom__row--lang {
      display: none; } }
  @media screen and (max-width: 640px) {
    .footer-bottom__row {
      text-align: left; } }

.footer-bottom__item {
  position: relative;
  display: inline-block;
  padding: 0 15px; }
  @media screen and (max-width: 1580px) {
    .footer-bottom__item {
      padding: 0 10px; } }
  @media screen and (max-width: 640px) {
    .footer-bottom__item {
      width: 50%;
      text-align: left;
      margin-bottom: 15px;
      vertical-align: top;
      padding: 0;
      font-size: 14px; } }
  .footer-bottom__item:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 14px;
    right: 0;
    top: 50%;
    margin-top: -7px;
    background: #1a1a1a; }
    @media screen and (max-width: 640px) {
      .footer-bottom__item:after {
        display: none; } }
  .footer-bottom__item:last-child:after {
    display: none; }
  .footer-bottom__item:nth-child(2n+1) {
    padding-right: 10px; }
  @media screen and (max-width: 640px) {
    .footer-bottom__item.footer-bottom__item--hidden-mb {
      display: none; } }

.footer-bottom__row--lang {
  /* PHR-420, FS suggest to use HTML, the "About HKTDC" & "Merchandise Trade Page" use HTML like SP portal too.
    .footer-bottom__link-German{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -12px center;
        background-size: 690px;
    }
    .footer-bottom__link-Czech{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -633px center;
        background-size: 690px;
    }
    .footer-bottom__link-Spanish{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -84px center;
        background-size: 690px;
    }
    .footer-bottom__link-French{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -155px center;
        background-size: 690px;
    }
    .footer-bottom__link-Italian{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -225px center;
        background-size: 690px;
    }
    .footer-bottom__link-Portuguese{
        width: 60px;
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -298px center;
        background-size: 690px;
    }
    .footer-bottom__link-Japanese{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -377px center;
        background-size: 690px;
    }
    .footer-bottom__link-Korean{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -441px center;
        background-size: 690px;
    }
    .footer-bottom__link-Russian{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -511px center;
        background-size: 690px;
    }
    .footer-bottom__link-Arabric{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -577px center;
        background-size: 690px;
    }
    .footer-bottom__link-Polish{
        background-image: imgurl("common/icons/img-lang-full.png");
        background-repeat: no-repeat;
        background-position: -335px center;
        background-size: 700px;
    }*/ }
  .footer-bottom__row--lang .footer-bottom__link {
    cursor: pointer;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    vertical-align: middle;
    width: 50px;
    height: 22px;
    background: no-repeat center center;
    background-size: 100%; }

.footer-bottom__copyright-title {
  display: inline-block;
  margin-right: 0.5em; }
  @media screen and (max-width: 640px) {
    .footer-bottom__copyright-title {
      display: block;
      margin-right: 0;
      margin-bottom: 5px; } }

@media screen and (max-width: 640px) {
  .footer-bottom__logo-img-dt {
    display: none; } }

.footer-bottom__logo-img-mb {
  max-width: 240px;
  width: auto;
  height: 55px; }
  @media screen and (min-width: 641px) {
    .footer-bottom__logo-img-mb {
      display: none; } }

/* END Style footer-bottom  */
/* ------------------------------------------ */
/* Style for header */
/* ------------------------------------------ */
header {
  background: transparent;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 500;
  color: #FFF;
  box-sizing: content-box;
  transition: height 0.3s;
  padding: 35px 0 30px;
  /*
    @media screen and (max-width:991px) {
        height: $headerHeightTb;
        .header--small & {
            height: $headerHeightTb;
            background: rgba(#FFF, .95);
        }
    }
    @media screen and (max-width:767px) {
        height: $headerHeightMb;
        .header--small & {
            height: $headerHeightTb;
            height: $headerHeightMb;
            background: rgba(#FFF, .95);
        }
    }
    */ }
  @media screen and (max-width: 991px) {
    header {
      padding: 35px 0; } }
  .header--small header {
    color: #1a1a1a;
    height: 75px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 3px 4px rgba(150, 150, 150, 0.2); }

.header__wrapper {
  max-width: 1440px;
  display: block; }
  @media screen and (max-width: 991px) {
    .header__wrapper {
      width: 100%; } }

.header__top-overlay {
  position: absolute;
  display: none;
  width: 100%;
  height: 70px;
  top: 0;
  left: 0;
  background: white;
  padding: 0;
  margin: 0;
  z-index: 600; }
  @media screen and (max-width: 767px) {
    .search-panel-open .header__top-overlay {
      /* mobile only */
      display: block; } }

.header__top {
  /*
    position: relative;
    text-align: right;
    padding: 10px 0 0;
    &+.header__main {
        padding-top: 0;
    }*/
  z-index: 1000;
  width: 100%;
  top: 0;
  padding: 10px 0 0;
  position: absolute;
  text-align: right; }
  .search-panel-open .header__top {
    pointer-events: none;
    opacity: 0.5;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px); }
  @media screen and (max-width: 991px) {
    .header__top {
      display: none; } }

.header__right {
  right: 0; }
  @media screen and (max-width: 991px) {
    .header__right {
      top: -20px;
      position: absolute;
      margin-right: 18px; } }
  @media screen and (max-width: 767px) {
    .header__right {
      top: -13px; } }

.header__right.close {
  z-index: 1050; }

.header__main {
  position: relative;
  padding: 10px 0; }
  @media screen and (max-width: 991px) {
    .header__main {
      padding: 0; } }
  .header__main .header__wrapper {
    margin: 0 auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    @media screen and (max-width: 991px) {
      .header__main .header__wrapper {
        margin: 0; } }

.header__item {
  display: inline-block;
  width: 100%;
  text-align: center; }

.header__mob-lang {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url("./assets/images/common/icons/svg/black/icon-lang.svg") no-repeat center center;
  background-size: 100%;
  margin-left: 15px; }
  @media screen and (max-width: 350px) {
    .header__mob-lang {
      margin-left: 12px; } }
  .search-panel-open .header__mob-lang {
    visibility: hidden; }

.header__mob-share {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url("./assets/images/common/icons/svg/black/icon-share-header.svg") no-repeat center center;
  background-size: 100%;
  margin-left: 15px; }
  @media screen and (max-width: 350px) {
    .header__mob-share {
      margin-left: 12px; } }
  .search-panel-open .header__mob-share {
    visibility: hidden; }

.header__mob-menu {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  margin-left: 15px;
  border-top: 2px solid #333; }
  @media screen and (max-width: 350px) {
    .header__mob-menu {
      margin-left: 12px; } }
  .header__mob-menu:before, .header__mob-menu:after {
    content: '';
    background: #333;
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    left: 0; }
  .header__mob-menu:before {
    top: 50%;
    margin-top: -2px; }
  .header__mob-menu:after {
    bottom: 0; }
  .search-panel-open .header__mob-menu {
    visibility: hidden; }

.header__submenu-back-container {
  text-align: left;
  height: 20px;
  padding: 25px 10px;
  display: none; }
  @media screen and (max-width: 991px) {
    .header__submenu-back-container {
      visibility: hidden;
      display: block; } }
  @media screen and (max-width: 991px) {
    .on-submenu .header__submenu-back-container {
      visibility: visible; } }

.header__submenu-back {
  position: relative;
  text-align: left;
  font-weight: bold;
  left: 5%;
  color: #1a1a1a;
  text-decoration: none;
  display: inline-block; }
  .header__submenu-back:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg); }

.header__mob-close {
  display: none;
  right: 5%;
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }
  @media screen and (max-width: 991px) {
    .mob-menu-open .header__mob-close {
      display: block;
      z-index: 1500; } }
  .header__mob-close:before, .header__mob-close:after {
    content: '';
    background: #333;
    position: absolute;
    display: block; }
  .header__mob-close:before {
    width: 100%;
    height: 2px;
    left: 0;
    top: 50%;
    margin-top: -1px; }
  .header__mob-close:after {
    height: 100%;
    width: 2px;
    top: 0;
    left: 50%;
    margin-left: -1px; }

@media screen and (min-width: 992px) {
  .header__mob-lang,
  .header__mob-share,
  .header__mob-menu,
  .header__mob-close {
    display: none; } }

/* END Style header  */
/* ------------------------------------------ */
/* Style for header-logo */
/* ------------------------------------------ */
.header-logo {
  /*
    position:relative;
    display:inline-block;
    vertical-align: middle;
    */
  z-index: 150;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0; }
  .header-logo img {
    width: 105px; }
  @media screen and (max-width: 1580px) {
    .header-logo img {
      width: 90px; } }
  @media screen and (max-width: 991px) {
    .header-logo {
      margin-left: 18px;
      z-index: 1500; }
      .header-logo img {
        width: 65px; } }
  @media screen and (max-width: 767px) {
    .header-logo img {
      width: 60px; } }
  @media screen and (max-width: 480px) {
    .header-logo img {
      width: 55px; } }

.header-logo__tag {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  white-space: nowrap;
  padding: 0 8%;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center; }
  @media screen and (max-width: 991px) and (min-width: 480px) {
    .header-logo__tag {
      position: absolute;
      left: 100%;
      bottom: 5px;
      margin-left: 10px;
      padding-left: 10px;
      border-left: 3px solid #ee7700; } }
  @media screen and (max-width: 540px) {
    .header-logo__tag {
      margin-left: 5px;
      padding-left: 10px;
      -webkit-transform: scale(0.8, 0.8);
      -ms-transform: scale(0.8, 0.8);
      transform: scale(0.8, 0.8); } }
  @media screen and (max-width: 480px) {
    .header-logo__tag {
      margin-left: 0;
      margin-top: 2px;
      margin-bottom: -5px;
      padding-left: 8%; } }
  @media screen and (max-width: 480px) {
    .header-logo__tag {
      -webkit-transform: scale(0.6, 0.6);
      -ms-transform: scale(0.6, 0.6);
      transform: scale(0.6, 0.6); } }

/* END Style header-logo  */
/* ------------------------------------------ */
/* Style for .header-search */
/* ------------------------------------------ */
.header-search {
  display: inline-block; }

.header-search__btn {
  cursor: pointer;
  position: relative;
  display: inline-block;
  color: #FFF;
  background: #fb5004;
  padding: 12px 35px;
  padding-left: 25px;
  border-radius: 30px;
  white-space: nowrap;
  z-index: 120; }
  .header-search__btn:hover {
    color: #FFF; }
  @media screen and (max-width: 991px) {
    .header-search__btn {
      padding: 12px 20px;
      padding-left: 15px; } }
  @media screen and (max-width: 767px) {
    .header-search__btn {
      padding: 8px 15px;
      padding-left: 12px;
      font-size: 14px;
      z-index: 601; } }
  @media screen and (max-width: 350px) {
    .header-search__btn {
      padding: 8px 12px;
      padding-left: 8px; } }
  .header-search__btn:before {
    content: '';
    width: 18px;
    height: 18px;
    background: url("./assets/images/common/icons/svg/white/icon-search.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px; }
    @media screen and (max-width: 991px) {
      .header-search__btn:before {
        width: 16px;
        height: 16px; } }
    @media screen and (max-width: 767px) {
      .header-search__btn:before {
        width: 14px;
        height: 14px; } }
    @media screen and (max-width: 350px) {
      .header-search__btn:before {
        margin-right: 5px; } }
  .search-panel-open .header-search__btn:before {
    background-image: url("./assets/images/common/icons/svg/white/icon-close.svg"); }

.header-search__input {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
  background: #fb5004;
  border: 0; }
  @media screen and (max-width: 991px) {
    .header-search__input {
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    .header-search__input {
      font-size: 12px; } }
  .header-search__input:focus {
    outline: 2px solid #bbb; }
  .header-search__input::-webkit-input-placeholder {
    color: #FFF; }
  .header-search__input:-moz-placeholder {
    /* Firefox 18- */
    color: #FFF; }
  .header-search__input::-moz-placeholder {
    /* Firefox 19+ */
    color: #FFF; }
  .header-search__input:-ms-input-placeholder {
    color: #FFF; }

.header-search__input-Error {
  color: red;
  font-size: 12px;
  padding: 10px 50px 0;
  display: block;
  text-align: left;
  line-height: 1.3; }

.header-search__panel {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 120;
  background: rgba(255, 255, 255, 0.9);
  z-index: 100;
  display: none;
  padding-top: 200px; }
  .search-panel-open .header-search__panel {
    display: block;
    overflow: scroll; }
  @media screen and (max-width: 767px) {
    .header-search__panel {
      padding: 0 20px 0;
      padding-top: 60px;
      /*body[data-alert-bar="show"] & {
            margin-top: 120px;
            
            html.smartbanner-show & {
                margin-top: 205px;
            }
        }  */ }
      .header-search__panel .header-search__wrapper {
        /*body[data-alert-bar="show"] & {
                padding-bottom: 170px;
                html.smartbanner-show & {
                    padding-bottom: 250px;
                }
            }*/ }
      html.smartbanner-show .header-search__panel {
        padding-top: 135px; } }

.header-search__wrapper {
  padding-top: 50px;
  text-align: center;
  padding-bottom: 50px; }

.header-search__title {
  color: #fb5004;
  display: block;
  margin: 0 0 30px;
  text-align: center;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase; }

.header-search__txt-close {
  display: none;
  vertical-align: middle; }
  .search-panel-open .header-search__txt-close {
    display: inline-block; }

.header-search__txt-open {
  display: inline-block;
  vertical-align: middle; }
  .search-panel-open .header-search__txt-open {
    display: none; }

.header-search__input-holder {
  display: block;
  position: relative;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  border: 0; }
  @media screen and (max-width: 767px) {
    .header-search__input-holder {
      border-radius: 25px; } }

.header-search__input-btn {
  position: absolute;
  width: 100px;
  height: 80px;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  cursor: pointer; }
  .header-search__input-btn:focus {
    outline: 2px solid #bbb; }
  .header-search__input-btn:before {
    content: '';
    width: 24px;
    height: 24px;
    background: url("./assets/images/common/icons/svg/orange/icon-search.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .header-search__input-btn {
      height: 50px;
      width: 60px; } }

.header-serach__input {
  background: #fff;
  position: relative;
  width: 100%;
  height: 80px;
  border: 0;
  font-weight: bold;
  border-radius: 40px;
  -webkit-box-shadow: 0px 0px 20px 1px rgba(219, 219, 219, 0.6);
  -moz-box-shadow: 0px 0px 20px 1px rgba(219, 219, 219, 0.6);
  box-shadow: 0px 0px 20px 1px rgba(219, 219, 219, 0.6);
  padding-left: 50px;
  padding-right: 160px; }
  .header-serach__input::-webkit-search-cancel-button {
    display: none; }
  .header-serach__input:focus {
    outline: 2px solid #bbb; }
  .header-serach__input + .header-search__autocomplete {
    display: none; }
  .header-serach__input:focus + .header-search__autocomplete,
  .header-serach__input + .header-search__autocomplete:focus,
  .header-serach__input + .header-search__autocomplete:hover {
    display: block; }
  .header-serach__input:focus + .search-panel__autocomplete.force-close,
  .header-serach__input + .search-panel__autocomplete.force-close:focus,
  .header-serach__input + .search-panel__autocomplete.force-close:hover {
    display: none; }
  .header-serach__input::-ms-clear {
    display: none; }
  @media screen and (max-width: 767px) {
    .header-serach__input {
      height: 50px;
      padding-right: 100px; } }

.header-search__filters {
  margin-bottom: 25px;
  font-size: 0; }

.header-search__filter {
  display: inline-block;
  margin-right: 45px; }
  .header-search__filter:last-child {
    margin-right: 0; }
  .header-search__filter input {
    display: none; }
    .header-search__filter input + label {
      position: relative;
      display: block;
      cursor: pointer;
      font-size: 14px;
      color: #1a1a1a; }
    .header-search__filter input:checked + label {
      font-weight: bold;
      padding: 5px 0; }
      .header-search__filter input:checked + label:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: #fb5004; }
  @media screen and (max-width: 767px) {
    .header-search__filter {
      margin-bottom: 10px;
      margin-right: 35px; } }

.header-search__topsearch {
  margin-top: 25px;
  font-size: 12px; }
  @media screen and (max-width: 767px) {
    .header-search__topsearch {
      font-size: 13px; } }

.header-search__topsearch-title {
  display: inline-block;
  vertical-align: middle;
  color: #fb5004;
  font-weight: bold;
  margin: 0;
  margin-right: 25px; }
  @media screen and (max-width: 767px) {
    .header-search__topsearch-title {
      margin-bottom: 10px; } }

.header-search__topsearch-item {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-right: 15px; }
  @media screen and (max-width: 767px) {
    .header-search__topsearch-item {
      margin-bottom: 10px; } }

.header-search__autocomplete {
  position: relative;
  width: 100%;
  background: #ececec;
  text-align: left;
  padding-bottom: 25px; }

.header-search__autocomplet-grp {
  padding: 0 50px; }
  .header-search__autocomplet-grp.acActive, .header-search__autocomplet-grp:hover {
    background-color: #fcf5f1; }
    .header-search__autocomplet-grp.acActive .header-search__autocomplet-item, .header-search__autocomplet-grp:hover .header-search__autocomplet-item {
      font-weight: bold;
      color: #000;
      text-decoration: none;
      opacity: 1; }

.header-search__autocomplet-item {
  display: block;
  cursor: pointer;
  font-size: 16px;
  /*margin: 0 0 14px;*/
  margin: 0;
  padding: 7px 0; }
  @media screen and (max-width: 767px) {
    .header-search__autocomplet-item {
      font-weight: normal; } }

.header-search__autocomplet-subitem {
  display: block;
  padding-left: 15px;
  font-size: 12px;
  margin: 0 0 14px; }

.header-search__clear-btn {
  position: absolute;
  width: 60px;
  height: 80px;
  right: 100px;
  top: 0;
  background: transparent;
  border: 0;
  cursor: pointer; }
  .header-search__clear-btn:focus {
    outline: 2px solid #bbb; }
  .header-search__clear-btn:before {
    content: '';
    width: 16px;
    height: 16px;
    background: url("./assets/images/common/icons/svg/black/icon-close.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .header-search__clear-btn {
      right: 40px;
      height: 50px; } }

/* END Style .header-search  */
/* ------------------------------------------ */
/* Style for hktdc-events */
/* ------------------------------------------ */
.hktdc-events {
  margin-top: 60px;
  margin-bottom: 60px; }
  @media screen and (max-width: 991px) {
    .hktdc-events {
      margin-top: 0; } }

.hktdc-events__row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .hktdc-events__row .button-row {
    margin: 50px auto; }
    .hktdc-events__row .button-row:last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 767px) {
      .hktdc-events__row .button-row {
        margin-top: 20px; } }

.hktdc-events__col {
  width: 45%;
  padding: 0 30px;
  opacity: 0; }
  @media screen and (max-width: 991px) {
    .hktdc-events__col:first-child {
      padding: 30px 0 0; } }
  .hktdc-events__col.animated.fadeIn {
    -webkit-animation: hktdc-events 0.3s;
    animation: hktdc-events 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
    .hktdc-events__col.animated.fadeIn:nth-child(2) {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s; }
    .hktdc-events__col.animated.fadeIn:nth-child(3) {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s; }

@-webkit-keyframes hktdc-events {
  0% {
    opacity: 0;
    -webkit-transform: translateY(45px);
    -ms-transform: translateY(45px);
    transform: translateY(45px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes hktdc-events {
  0% {
    opacity: 0;
    -webkit-transform: translateY(45px);
    -ms-transform: translateY(45px);
    transform: translateY(45px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
  @media screen and (max-width: 1280px) {
    .hktdc-events__col {
      padding: 0 15px; } }
  @media screen and (max-width: 767px) {
    .hktdc-events__col {
      width: 100%;
      padding: 0;
      margin-bottom: 20px; }
      .hktdc-events__col:first-child {
        padding: 20px 0 0; } }

.hktdc-events__col-full {
  width: 100%; }

@media screen and (min-width: 768px) {
  .hktdc-events__col-55 {
    width: 55%; } }

.hktdc-events__tag {
  position: relative;
  color: #fb5004;
  padding: 0 30px;
  text-transform: uppercase; }
  @media screen and (max-width: 991px) {
    .hktdc-events__tag {
      display: block;
      padding: 25px 0;
      text-align: center;
      color: #FFF;
      width: 100%; }
      .hktdc-events__tag:after {
        content: '';
        position: absolute;
        display: block;
        width: 100vw;
        left: -3.19148%;
        height: 100%;
        top: 0;
        z-index: 0;
        background: #fd8811;
        /* Old browsers */
        background: -moz-linear-gradient(left, #fd8811 0%, #fb4805 50%, #fd8811 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #fd8811 0%, #fb4805 50%, #fd8811 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #fd8811 0%, #fb4805 50%, #fd8811 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fd8811', endColorstr='#fd8811', GradientType=1);
        /* IE6-9 */ } }
    @media screen and (max-width: 991px) and (max-width: 991px) {
      .hktdc-events__tag:after {
        left: -5.55556%; } }
  @media screen and (max-width: 991px) {
      .hktdc-events__tag span {
        position: relative;
        z-index: 10; } }

.hktdc-events__title {
  color: #fb5004;
  font-weight: 300;
  margin: 0 0 15px; }

.hktdc-events__intro {
  line-height: 1.5em; }

.hktdc-events__filter {
  position: relative;
  display: inline-block;
  margin-right: 30px;
  padding: 5px 0;
  font-size: 18px; }
  @media screen and (max-width: 767px) {
    .hktdc-events__filter {
      margin-right: 15px;
      font-size: 16px; } }
  .hktdc-events__filter:last-child {
    margin-right: 0; }
  .hktdc-events__filter.is-active {
    font-weight: bold; }
    .hktdc-events__filter.is-active:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      display: block;
      background: #000; }

.home-hktdc-events__filters {
  width: 50%;
  margin: 20px auto 30px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: center; }
  @media screen and (max-width: 1240px) {
    .home-hktdc-events__filters {
      width: 55%; } }
  @media screen and (max-width: 768px) {
    .home-hktdc-events__filters {
      width: 70%; } }
  .home-hktdc-events__filters.desktop {
    display: none; }
    @media screen and (min-width: 768px) {
      .home-hktdc-events__filters.desktop {
        display: flex; } }
  .home-hktdc-events__filters.mobile {
    display: none; }
    @media screen and (max-width: 767px) {
      .home-hktdc-events__filters.mobile {
        margin: 10px auto 15px;
        display: flex; } }

.home-hktdc-events__filter {
  display: inline-block;
  width: 33.33333%;
  font-weight: 300;
  padding: 15px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .home-hktdc-events__filter.is-active {
    background-color: #fc5e08;
    color: #fff;
    border-top: none;
    border-bottom: none;
    position: relative; }
  .home-hktdc-events__filter:first-child {
    width: 25%;
    border-left: 1px solid #e5e5e5;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px; }
  .home-hktdc-events__filter:nth-child(3) {
    width: 40%; }
  .home-hktdc-events__filter:last-child {
    border-right: 1px solid #e5e5e5;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px; }

.home-hktdc-events__filters-cat-container {
  /* this only appears in mobile */
  display: inline-block;
  position: relative; }
  @media screen and (max-width: 768px) {
    .home-hktdc-events__filters-cat-container {
      width: 100%; } }
  .home-hktdc-events__filters-cat-container .home-hktdc-events__filters-cat__title {
    font-weight: bold;
    background-color: #fc5e08;
    margin: 0 auto;
    border-radius: 45px;
    padding: 20px 10px;
    max-width: 80%;
    font-size: 16px;
    border-right: 1px solid rgba(190, 190, 190, 0.2);
    color: white; }
    @media screen and (max-width: 767px) {
      .home-hktdc-events__filters-cat-container .home-hktdc-events__filters-cat__title {
        padding: 12px 10px; } }
    .home-hktdc-events__filters-cat-container .home-hktdc-events__filters-cat__title:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid white;
      margin-left: 10px; }
  .home-hktdc-events__filters-cat-container .home-hktdc-events__filters-condList {
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    opacity: 0;
    padding: 20px 0; }

.hktdc-events__item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  padding-right: 20px;
  padding-left: 0; }

.hktdc-events__list {
  position: relative;
  max-height: 240px;
  overflow: auto; }

.hktdc-events__list-title {
  font-weight: 300;
  margin: 0 0 15px; }
  @media screen and (max-width: 767px) {
    .hktdc-events__list-title {
      text-align: center; } }

.hktdc-events__date {
  width: 60px;
  height: 60px;
  color: #FFF;
  background: #fb5004;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px; }
  .hktdc-events__item--os .hktdc-events__date {
    background: #242655; }
  @media screen and (max-width: 1280px) {
    .hktdc-events__date {
      width: 55px;
      height: 55px; } }

.hktdc-events__details {
  width: 100%; }

.hktdc-events__info {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px; }

.hktdc-events__info-loc {
  font-weight: bold;
  color: #fb5004;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #1a1a1a; }
  .hktdc-events__item--os .hktdc-events__info-loc {
    color: #242655; }
  @media screen and (max-width: 1280px) {
    .hktdc-events__info-loc {
      padding-right: 7px;
      margin-right: 7px; } }

.hktdc-events__days {
  display: block; }

.hktdc-events__legends {
  position: relative;
  display: block;
  padding: 0 8%; }
  @media screen and (min-width: 769px) {
    .hktdc-events__legends {
      max-width: 450px;
      margin-left: auto;
      /* make self align right */ } }
  @media screen and (max-width: 767px) {
    .hktdc-events__legends {
      text-align: center; } }

.hktdc-events__legend {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  margin-right: 25px; }
  .hktdc-events__legend:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #fb5004;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%; }
  .hktdc-events__legend.hktdc-events__legend--hk:before {
    background: #fb5004; }
  .hktdc-events__legend.hktdc-events__legend--os:before {
    background: #242655; }

.hktdc-events .DayPicker-Month {
  margin-top: 0; }
  @media screen and (min-width: 769px) {
    .hktdc-events .DayPicker-Month {
      width: 100%; } }

.hktdc-events .DayPicker {
  display: block;
  margin: 0 auto; }
  @media screen and (min-width: 769px) {
    .hktdc-events .DayPicker {
      max-width: 450px;
      margin-left: auto;
      /* make self align right */ } }

.hktdc-events .DayPicker-NavButton {
  top: 0; }

.hktdc-events .DayPicker-NavButton--prev {
  right: 50%;
  margin: -1px 0 0;
  margin-right: 75px; }

.hktdc-events .DayPicker-NavButton--next {
  left: 50%;
  margin: -1px 0 0;
  margin-left: 75px; }

.hktdc-events .DayPicker-Caption {
  text-align: center; }

.hktdc-events .DayPicker-Day {
  padding: 0.7rem 1rem; }
  @media screen and (max-width: 1280px) {
    .hktdc-events .DayPicker-Day {
      padding: 0.5rem 0.8rem; } }

.hktdc-events .DayPicker-Day--hlhk,
.hktdc-events .DayPicker-Day--hlos {
  position: relative; }
  .hktdc-events .DayPicker-Day--hlhk:after,
  .hktdc-events .DayPicker-Day--hlos:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #fb5004;
    bottom: 1px;
    left: 50%;
    margin-left: -2.5px;
    position: absolute; }

.hktdc-events .DayPicker-Day--hlhk:after {
  background: #fb5004; }

.hktdc-events .DayPicker-Day--hlos:after {
  background: #242655; }

.hktdc-events .DayPicker-Day--hlhk.DayPicker-Day--hlos {
  position: relative; }
  .hktdc-events .DayPicker-Day--hlhk.DayPicker-Day--hlos:before, .hktdc-events .DayPicker-Day--hlhk.DayPicker-Day--hlos:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #fb5004;
    bottom: 1px;
    left: 50%;
    margin-left: -2.5px;
    position: absolute; }
  .hktdc-events .DayPicker-Day--hlhk.DayPicker-Day--hlos:before {
    left: calc(50% - 4px);
    background: #fb5004; }
  .hktdc-events .DayPicker-Day--hlhk.DayPicker-Day--hlos:after {
    left: calc(50% + 4px);
    background: #242655; }

.hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlhk, .hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlos {
  color: #FFF; }
  .hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlhk:before, .hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlos:before {
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    margin-top: -7.5px;
    margin-left: -7.5px; }

.hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlhk {
  background: #fb5004; }

.hktdc-events .DayPicker-Day--picked.DayPicker-Day--hlos {
  background: #242655; }

/* END Style hktdc-events  */
/* ------------------------------------------ */
/* Style for sample */
/* ------------------------------------------ */
/* END Style sample  */
/* ------------------------------------------ */
/* Style for market-focus */
/* ------------------------------------------ */
.market-focus {
  position: relative; }

.market-focus__holder.animated.fadeIn {
  -webkit-animation: none;
  animation: none;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform;
  opacity: 1 !important; }

.market-focus__intro {
  text-align: center;
  margin-bottom: 45px; }

.market-focus__intro-title {
  margin: 0 0 15px;
  font-weight: 300;
  color: #fb5004; }
  .market-focus__holder.animated .market-focus__intro-title {
    opacity: 0; }
  .market-focus__holder.animated.fadeIn .market-focus__intro-title {
    -webkit-animation: mf-item-title 0.6s;
    animation: mf-item-title 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes mf-item-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes mf-item-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.market-focus__holder.animated .market-focus__intro-desc {
  opacity: 0; }

.market-focus__holder.animated.fadeIn .market-focus__intro-desc {
  -webkit-animation: mf-item-title 0.6s 0.3s;
  animation: mf-item-title 0.6s 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform; }

@-webkit-keyframes mf-item-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes mf-item-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.market-focus__items {
  position: relative; }
  .market-focus__items:before {
    content: '';
    position: absolute;
    display: block;
    width: 90%;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
    transition: 1s 0.3s;
    right: 0;
    top: 25%;
    height: 50%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fb4805+0,fd8811+100 */
    background: #fb4805;
    /* Old browsers */
    background: -moz-linear-gradient(left, #fb4805 0%, #fd8811 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #fb4805 0%, #fd8811 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #fb4805 0%, #fd8811 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb4805', endColorstr='#fd8811', GradientType=1);
    /* IE6-9 */ }
    .market-focus__holder.animated.fadeIn .market-focus__items:before {
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      transform: scale(1, 1); }
  .market-focus__items > .content-wrapper, .market-focus__items > .app-sec__inner, .market-focus__items > .header__wrapper, .market-focus__items > .search-nores, .market-focus__items > .sec-intro__detail-wrapper, .market-focus__items > .submenu__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start; }
    @media screen and (max-width: 480px) {
      .market-focus__items > .content-wrapper, .market-focus__items > .app-sec__inner, .market-focus__items > .header__wrapper, .market-focus__items > .search-nores, .market-focus__items > .sec-intro__detail-wrapper, .market-focus__items > .submenu__wrapper {
        width: 100%; } }

.market-focus__item {
  position: relative;
  overflow: hidden;
  width: calc(33.3333% - 20px);
  margin-right: 30px;
  border-radius: 5px;
  opacity: 0;
  cursor: pointer;
  color: #FFF; }
  @media screen and (max-width: 480px) {
    .market-focus__item {
      background: #000;
      border-radius: 0; } }
  .market-focus__holder.animated.fadeIn .market-focus__item {
    -webkit-animation: mf-item 0.6s;
    animation: mf-item 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
    .market-focus__holder.animated.fadeIn .market-focus__item:hover {
      opacity: 0; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(2) {
      -webkit-animation-delay: 0.72s;
      animation-delay: 0.72s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(3) {
      -webkit-animation-delay: 0.84s;
      animation-delay: 0.84s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(4) {
      -webkit-animation-delay: 0.96s;
      animation-delay: 0.96s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(5) {
      -webkit-animation-delay: 1.08s;
      animation-delay: 1.08s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(6) {
      -webkit-animation-delay: 1.2s;
      animation-delay: 1.2s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(7) {
      -webkit-animation-delay: 1.32s;
      animation-delay: 1.32s; }
    .market-focus__holder.animated.fadeIn .market-focus__item:nth-child(8) {
      -webkit-animation-delay: 1.44s;
      animation-delay: 1.44s; }

@-webkit-keyframes mf-item {
  0% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes mf-item {
  0% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }
  .market-focus__item:hover {
    opacity: 0;
    color: #FFF; }
  .market-focus__item:before {
    content: '';
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    display: block; }
  .market-focus__item:nth-child(3n+3) {
    margin-right: 0; }
  .market-focus__item:nth-child(3n+2) {
    margin-top: 30px; }
    .market-focus__item:nth-child(3n+2):before {
      padding-top: 30px; }
  .market-focus__item:nth-child(2) {
    margin-top: 0; }
    .market-focus__item:nth-child(2):before {
      padding-top: 30px; }
  @media screen and (max-width: 767px) {
    .market-focus__item {
      width: calc(50% - 15px);
      margin-right: 30px;
      margin-bottom: 30px; }
      .market-focus__item:nth-child(3n+3) {
        margin-right: 30px; }
      .market-focus__item:nth-child(2n+2) {
        margin-right: 0; }
      .market-focus__item:nth-child(3n+2) {
        margin-top: 0; }
        .market-focus__item:nth-child(3n+2):before {
          padding-top: 0; } }
  @media screen and (max-width: 480px) {
    .market-focus__item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0; }
      .market-focus__item:nth-child(3n+3), .market-focus__item:nth-child(2n+2) {
        margin-right: 0; }
      .market-focus__item:before {
        padding-bottom: 50%; } }

@media screen and (min-width: 768px) {
  .market-focus__items8 .market-focus__item {
    margin-top: 30px; }
    .market-focus__items8 .market-focus__item:before {
      padding-top: 30px; }
    .market-focus__items8 .market-focus__item:nth-child(3n+3) {
      margin-right: 30px; }
    .market-focus__items8 .market-focus__item:nth-child(1), .market-focus__items8 .market-focus__item:nth-child(2) {
      width: calc(50% - 15px); }
      .market-focus__items8 .market-focus__item:nth-child(1):before, .market-focus__items8 .market-focus__item:nth-child(2):before {
        padding-bottom: 50%; }
    .market-focus__items8 .market-focus__item:nth-child(2), .market-focus__items8 .market-focus__item:nth-child(5), .market-focus__items8 .market-focus__item:nth-child(8) {
      margin-right: 0; }
  .market-focus__items7 .market-focus__item {
    margin-top: 30px; }
    .market-focus__items7 .market-focus__item:before {
      padding-top: 30px; }
    .market-focus__items7 .market-focus__item:nth-child(3n+3) {
      margin-right: 30px; }
    .market-focus__items7 .market-focus__item:nth-child(1), .market-focus__items7 .market-focus__item:nth-child(2), .market-focus__items7 .market-focus__item:nth-child(6), .market-focus__items7 .market-focus__item:nth-child(7) {
      width: calc(50% - 15px); }
      .market-focus__items7 .market-focus__item:nth-child(1):before, .market-focus__items7 .market-focus__item:nth-child(2):before, .market-focus__items7 .market-focus__item:nth-child(6):before, .market-focus__items7 .market-focus__item:nth-child(7):before {
        padding-bottom: 50%; }
    .market-focus__items7 .market-focus__item:nth-child(2), .market-focus__items7 .market-focus__item:nth-child(5), .market-focus__items7 .market-focus__item:nth-child(7) {
      margin-right: 0; } }

.market-focus__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: no-repeat center center;
  background-size: cover;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  transition: 0.4s; }
  @media screen and (max-width: 480px) {
    .market-focus__bg {
      opacity: 0.8; } }
  .market-focus__item:hover .market-focus__bg {
    -webkit-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  .market-focus__bg:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 45%;
    left: 0;
    bottom: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    /* IE6-9 */ }

.market-focus__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 30px 15px; }

.market-focus__title {
  display: block;
  width: 100%;
  font-weight: 300;
  line-height: 1.2em;
  margin: 0 0 10px;
  text-transform: uppercase; }
  @media screen and (max-width: 991px) {
    .market-focus__title {
      font-size: 16px;
      margin: 0 0 5px; } }

.market-focus__arrow:before {
  content: '';
  width: 30px;
  height: 30px;
  display: block;
  background: no-repeat center center;
  background-image: url("./assets/images/common/icons/svg/white/icon-long-arrow.svg");
  background-size: 100%; }
  @media screen and (max-width: 991px) {
    .market-focus__arrow:before {
      width: 24px;
      height: 24px; } }
  .market-focus__item:hover .market-focus__arrow:before {
    -webkit-animation: mf-arrow 0.6s infinite;
    animation: mf-arrow 0.6s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes mf-arrow {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px); } }

@keyframes mf-arrow {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px); } }

.market-focus__content {
  position: relative; }

.market-focus-open section.market-focus {
  z-index: 1520; }

.market-focus-open #market-focus-overlay {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1500; }

/* END Style market-focus  */
/* ------------------------------------------ */
/* Style for main-banner */
/* ------------------------------------------ */
.main-banner {
  position: relative; }

.main-banner__slider {
  max-width: 1600px;
  margin: 0 auto;
  padding: 15px 0 45px;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 991px) {
    .main-banner__slider {
      padding: 0; } }
  @media screen and (min-width: 992px) {
    .main-banner__slider .swiper-wrapper {
      height: 100% !important; } }

.main-banner__slide {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  @media screen and (min-width: 992px) {
    .main-banner__slide {
      background-image: none !important; } }
  @media screen and (max-width: 991px) {
    .main-banner__slide {
      display: block; } }

.main-banner__img, .main-banner__vid {
  display: block;
  position: relative;
  overflow: hidden;
  width: calc(100% - 400px);
  -webkit-animation: main-banner-img-line 0s linear 1s;
  animation: main-banner-img-line 0s linear 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform; }

@-webkit-keyframes main-banner-img-line {
  0% {
    border-bottom: 6px solid #FFF; }
  100% {
    border-bottom: 6px solid #fb5004; } }

@keyframes main-banner-img-line {
  0% {
    border-bottom: 6px solid #FFF; }
  100% {
    border-bottom: 6px solid #fb5004; } }
  @media screen and (min-width: 992px) {
    .main-banner__img, .main-banner__vid {
      background-color: transparent !important; } }
  @media screen and (max-width: 1580px) {
    .main-banner__img, .main-banner__vid {
      width: calc(100% - 350px); } }
  @media screen and (max-width: 1280px) {
    .main-banner__img, .main-banner__vid {
      width: calc(100% - 300px); } }
  @media screen and (max-width: 991px) {
    .main-banner__img, .main-banner__vid {
      display: none; } }
  .main-banner__img:hover, .main-banner__vid:hover {
    opacity: 1; }
  .main-banner__img img, .main-banner__vid img {
    display: block;
    width: 100%; }
    .is-ie .main-banner__img img, .is-ie .main-banner__vid img {
      display: inline; }
    .main-banner__img img.loading--close, .main-banner__vid img.loading--close {
      opacity: 0;
      display: none; }
    .main-banner__img img.loading--open, .main-banner__vid img.loading--open {
      opacity: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: auto;
      height: auto; }
    .main-banner__img img.image--loading, .main-banner__vid img.image--loading {
      opacity: 0;
      display: none; }
    .main-banner__img img.image--loaded, .main-banner__vid img.image--loaded {
      opacity: 1;
      display: block; }
    .swiper-slide-active .main-banner__img img, .swiper-slide-active .main-banner__vid img {
      -webkit-animation: main-banner-img 5.5s linear;
      animation: main-banner-img 5.5s linear;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }

@-webkit-keyframes main-banner-img {
  0% {
    -webkit-transform: scale(1.08, 1.08) rotate(0.02deg);
    -ms-transform: scale(1.08, 1.08) rotate(0.02deg);
    transform: scale(1.08, 1.08) rotate(0.02deg); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes main-banner-img {
  0% {
    -webkit-transform: scale(1.08, 1.08) rotate(0.02deg);
    -ms-transform: scale(1.08, 1.08) rotate(0.02deg);
    transform: scale(1.08, 1.08) rotate(0.02deg); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }
    .swiper-wrapper.pause-active .main-banner__img img, .swiper-wrapper.pause-active .main-banner__vid img {
      animation-play-state: paused; }
    .swiper-slide-duplicate .main-banner__img img:last-child, .swiper-slide-duplicate .main-banner__vid img:last-child {
      opacity: 1 !important; }
      @media screen and (min-width: 992px) {
        .swiper-slide-duplicate .main-banner__img img:last-child, .swiper-slide-duplicate .main-banner__vid img:last-child {
          display: block !important; } }
    @media screen and (max-width: 991px) {
      .main-banner__img img, .main-banner__vid img {
        display: none; } }

.main-banner__img_mobile {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-bottom: 0;
  -webkit-animation: main-banner-img-line 0s linear 1s;
  animation: main-banner-img-line 0s linear 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform; }

@-webkit-keyframes main-banner-img-line {
  0% {
    border-bottom: 6px solid #FFF; }
  100% {
    border-bottom: 6px solid #fb5004; } }

@keyframes main-banner-img-line {
  0% {
    border-bottom: 6px solid #FFF; }
  100% {
    border-bottom: 6px solid #fb5004; } }
  @media screen and (min-width: 992px) {
    .main-banner__img_mobile {
      display: none; } }
  @media screen and (max-width: 991px) {
    .main-banner__img_mobile {
      height: 500px; } }
  @media screen and (max-width: 767px) {
    .main-banner__img_mobile {
      height: 430px; } }
  @media screen and (max-width: 481px) {
    .main-banner__img_mobile {
      height: 280px; } }
  @media screen and (max-width: 391px) {
    .main-banner__img_mobile {
      height: 230px; } }
  .main-banner__img_mobile:hover {
    opacity: 1; }

.main-banner__vid {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%; }
  @media screen and (max-width: 991px) {
    .main-banner__vid {
      width: auto;
      height: 500px; } }
  @media screen and (max-width: 767px) {
    .main-banner__vid {
      height: 430px; } }
  @media screen and (max-width: 481px) {
    .main-banner__vid {
      height: 280px; } }
  @media screen and (max-width: 391px) {
    .main-banner__vid {
      height: 230px; } }
  .main-banner__vid:before {
    content: '';
    position: relative;
    width: 100%;
    padding-bottom: 50%;
    display: block;
    z-index: -1; }
  .main-banner__vid video {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .is-ie .main-banner__vid > div {
    position: static !important; }
  .is-ie .main-banner__vid video {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }

.main-banner__txt {
  padding: 0 40px;
  width: 400px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .main-banner__txt > * {
    width: 100%; }
  .main-banner__txt:before {
    content: '';
    display: block;
    width: 100%; }
  @media screen and (max-width: 1580px) {
    .main-banner__txt {
      width: 350px; } }
  @media screen and (max-width: 1280px) {
    .main-banner__txt {
      width: 300px;
      padding: 0 30px; } }
  @media screen and (max-width: 991px) {
    .main-banner__txt {
      background: #fc5e08;
      background: -moz-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
      background: -webkit-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
      background: linear-gradient(to right, #fc5e08 0%, #fd8e14 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc5e08', endColorstr='#fd8e14', GradientType=1);
      color: #FFF;
      position: relative;
      padding: 60px 5%;
      width: 100%;
      height: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; } }
  @media screen and (max-width: 767px) {
    .main-banner__txt {
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      padding: 50px 5%; } }
  @media screen and (max-width: 480px) {
    .main-banner__txt {
      padding: 30px 5%; } }

.main-banner__loading {
  position: absolute; }

.main-banner__title {
  margin: 45px 0 0;
  line-height: 1.2;
  width: calc(100% + 150px);
  -webkit-transform: translateX(-150px);
  -ms-transform: translateX(-150px);
  transform: translateX(-150px);
  opacity: 0;
  font-weight: 900;
  background: #fc5e08;
  background: -moz-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
  background: -webkit-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
  background: linear-gradient(to right, #fc5e08 0%, #fd8e14 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc5e08', endColorstr='#fd8e14', GradientType=1);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text; }
  .is-firefox .main-banner__title {
    background: none;
    color: #fc5e08;
    -webkit-animation: sec-intro-fadetop 1s;
    animation: sec-intro-fadetop 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
    @media screen and (max-width: 991px) {
      .is-firefox .main-banner__title {
        background: transparent;
        color: #FFF; } }
  .swiper-slide-active .main-banner__title {
    -webkit-animation: main-banner-title 0.9s;
    animation: main-banner-title 0.9s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform;
    width: calc(100% - -165px); }
    @media screen and (max-width: 1280px) {
      .swiper-slide-active .main-banner__title {
        width: calc(100% - -155px); } }
    @media screen and (max-width: 991px) {
      .swiper-slide-active .main-banner__title {
        -webkit-animation: main-banner-title-mb 0.4s;
        animation: main-banner-title-mb 0.4s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        will-change: transform;
        width: calc(100% - 0px); } }

@-webkit-keyframes main-banner-title {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
    -ms-transform: translateX(200px);
    transform: translateX(200px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(-125px);
    -ms-transform: translateX(-125px);
    transform: translateX(-125px); } }

@keyframes main-banner-title {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
    -ms-transform: translateX(200px);
    transform: translateX(200px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(-125px);
    -ms-transform: translateX(-125px);
    transform: translateX(-125px); } }

@-webkit-keyframes main-banner-title-mb {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px); } }

@keyframes main-banner-title-mb {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px); } }
  @media screen and (max-width: 1280px) {
    .main-banner__title {
      margin: 30px 0 0;
      font-size: 36px; } }
  @media screen and (max-width: 991px) {
    .main-banner__title {
      width: 100%;
      margin: 0;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0); } }
  @media screen and (max-width: 767px) {
    .main-banner__title {
      margin: 0 0 15px; } }
  @media screen and (max-width: 480px) {
    .main-banner__title {
      font-size: 28px; } }
  .is-ie .main-banner__title {
    background: transparent;
    color: #fb5004; }
    @media screen and (max-width: 991px) {
      .is-ie .main-banner__title {
        background: transparent;
        color: #FFF; } }
  @media screen and (max-width: 991px) {
    .main-banner__title {
      background: transparent;
      color: #FFF; } }

.main-banner__detail {
  position: relative;
  opacity: 0; }
  .swiper-slide-active .main-banner__detail {
    -webkit-animation: main-banner-detail 0.4s 0.2s;
    animation: main-banner-detail 0.4s 0.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes main-banner-detail {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes main-banner-detail {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
  @media screen and (max-width: 991px) {
    .main-banner__detail {
      color: #FFF; } }

.main-banner__detail-title {
  font-size: 20px;
  color: #fb5004;
  margin: 0 0 3px; }
  @media screen and (max-width: 991px) {
    .main-banner__detail-title {
      color: #FFF; } }
  @media screen and (max-width: 767px) {
    .main-banner__detail-title {
      font-size: 18px;
      margin: 0 0 3px; } }

.main-banner__detail-subHeading {
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 15px; }
  @media screen and (max-width: 991px) {
    .main-banner__detail-subHeading {
      color: #FFF; } }
  @media screen and (max-width: 767px) {
    .main-banner__detail-subHeading {
      font-size: 14px;
      margin: 0 0 10px; } }

.main-banner__detail-desc {
  font-size: 16px;
  margin: 0 0 15px; }
  @media screen and (max-width: 991px) {
    .main-banner__detail-desc {
      color: #FFF; } }
  @media screen and (max-width: 767px) {
    .main-banner__detail-desc {
      font-size: 14px;
      margin: 0 0 10px; } }

.main-banner__detail-date {
  color: #1a1a1a;
  font-weight: 400; }
  @media screen and (max-width: 991px) {
    .main-banner__detail-date {
      display: block;
      color: #FFF;
      margin-top: 15px; } }

.main-banner__scrolldown {
  position: absolute;
  display: block;
  bottom: 0;
  left: 10%;
  opacity: 0;
  -webkit-animation: main-banner-scrolldown 0.4s 2s;
  animation: main-banner-scrolldown 0.4s 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform; }
  @media screen and (max-width: 1800px) {
    .main-banner__scrolldown {
      left: 5%; } }
  @media screen and (max-width: 1600px) {
    .main-banner__scrolldown {
      left: 2%; } }

@-webkit-keyframes main-banner-scrolldown {
  0% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes main-banner-scrolldown {
  0% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }
  .main-banner__scrolldown:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: no-repeat center center;
    background-size: 100%;
    background-image: url("./assets/images/common/icons/svg/black/icon-mouse.svg");
    margin-bottom: 8px; }
  .main-banner__scrolldown:after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #1a1a1a transparent transparent transparent;
    -webkit-animation: 0.7s scrolldown infinite;
    animation: 0.7s scrolldown infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
  @media screen and (max-width: 991px) {
    .main-banner__scrolldown {
      display: none; } }

@-webkit-keyframes scrolldown {
  from {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0); }
  to {
    -webkit-transform: translate(0, 60%);
    -ms-transform: translate(0, 60%);
    transform: translate(0, 60%); } }

@keyframes scrolldown {
  from {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0); }
  to {
    -webkit-transform: translate(0, 60%);
    -ms-transform: translate(0, 60%);
    transform: translate(0, 60%); } }

.main-banner__thumbs {
  z-index: 50;
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 45px;
  pointer-events: none;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%); }
  @media screen and (max-width: 991px) {
    .main-banner__thumbs {
      top: 440px;
      bottom: auto; } }
  @media screen and (max-width: 767px) {
    .main-banner__thumbs {
      top: 370px; } }
  @media screen and (max-width: 481px) {
    .main-banner__thumbs {
      top: 220px; } }
  @media screen and (max-width: 391px) {
    .main-banner__thumbs {
      top: 170px; } }
  .main-banner__thumbs .content-wrapper, .main-banner__thumbs .app-sec__inner, .main-banner__thumbs .header__wrapper, .main-banner__thumbs .search-nores, .main-banner__thumbs .sec-intro__detail-wrapper, .main-banner__thumbs .submenu__wrapper {
    padding: 0 30px; }
    @media screen and (max-width: 991px) {
      .main-banner__thumbs .content-wrapper, .main-banner__thumbs .app-sec__inner, .main-banner__thumbs .header__wrapper, .main-banner__thumbs .search-nores, .main-banner__thumbs .sec-intro__detail-wrapper, .main-banner__thumbs .submenu__wrapper {
        padding: 0;
        width: 90%; } }

.main-banner__thumb {
  pointer-events: all;
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 110px;
  margin-right: 10px;
  background-size: cover;
  -webkit-animation: main-banner-thumb 0.4s;
  animation: main-banner-thumb 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform;
  opacity: 0; }
  .main-banner__thumb:nth-child(2) {
    -webkit-animation-delay: 0.12s;
    animation-delay: 0.12s; }
  .main-banner__thumb:nth-child(3) {
    -webkit-animation-delay: 0.24s;
    animation-delay: 0.24s; }
  .main-banner__thumb:nth-child(4) {
    -webkit-animation-delay: 0.36s;
    animation-delay: 0.36s; }
  .main-banner__thumb:nth-child(5) {
    -webkit-animation-delay: 0.48s;
    animation-delay: 0.48s; }
  .main-banner__thumb:nth-child(6) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s; }
  .main-banner__thumb:nth-child(7) {
    -webkit-animation-delay: 0.72s;
    animation-delay: 0.72s; }
  .main-banner__thumb:nth-child(8) {
    -webkit-animation-delay: 0.84s;
    animation-delay: 0.84s; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .main-banner__thumb {
      opacity: 1;
      animation: none; } }

@-webkit-keyframes main-banner-thumb {
  0% {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes main-banner-thumb {
  0% {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }
  @media screen and (max-width: 1600px) {
    .main-banner__thumb {
      width: 85px; } }
  @media screen and (max-width: 1180px) {
    .main-banner__thumb {
      width: 65px;
      margin-right: 8px; } }
  @media screen and (max-width: 991px) {
    .main-banner__thumb {
      background: transparent !important;
      width: 30px; } }
  @media screen and (max-width: 767px) {
    .main-banner__thumb {
      width: 20px; } }
  .main-banner__thumb:last-child {
    margin-right: 0; }
  .main-banner__thumb:before {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; }
    @media screen and (max-width: 991px) {
      .main-banner__thumb:before {
        padding: 0;
        height: 4px; } }
    @media screen and (max-width: 767px) {
      .main-banner__thumb:before {
        height: 3px; } }
  .main-banner__thumb:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
    transition: 0.3s; }
    @media screen and (max-width: 991px) {
      .main-banner__thumb:after {
        background: rgba(255, 255, 255, 0.5); } }
  .main-banner__thumb.is-active, .main-banner__thumb:hover {
    opacity: 1; }
    .main-banner__thumb.is-active:after, .main-banner__thumb:hover:after {
      box-sizing: border-box;
      border: 5px solid #fb5004; }
      @media screen and (max-width: 1280px) {
        .main-banner__thumb.is-active:after, .main-banner__thumb:hover:after {
          border: 3px solid #fb5004; } }
      @media screen and (max-width: 991px) {
        .main-banner__thumb.is-active:after, .main-banner__thumb:hover:after {
          border: 0; } }
  @media screen and (max-width: 991px) {
    .main-banner__thumb.is-active:after {
      background: #fb5004; } }

.main-banner__slider-pause {
  display: inline-block;
  position: absolute;
  right: 400px;
  bottom: 20px;
  z-index: 2; }
  @media screen and (max-width: 1580px) {
    .main-banner__slider-pause {
      right: 350px; } }
  @media screen and (max-width: 1280px) {
    .main-banner__slider-pause {
      right: 300px; } }
  @media screen and (max-width: 991px) {
    .main-banner__slider-pause {
      right: 10px;
      bottom: calc(100% - 530px); } }
  @media screen and (max-width: 768px) {
    .main-banner__slider-pause {
      bottom: calc(100% - 450px); } }
  @media screen and (max-width: 480px) {
    .main-banner__slider-pause {
      bottom: calc(100% - 390px); } }
  .main-banner__slider-pause .pause-button {
    display: block;
    box-sizing: border-box;
    width: 0;
    height: 15px;
    cursor: pointer;
    border-color: transparent transparent transparent #1a1a1a;
    transition: 100ms all ease;
    cursor: pointer;
    border-style: double;
    border-width: 0px 0 0px 15px; }
    @media screen and (max-width: 991px) {
      .main-banner__slider-pause .pause-button {
        border-color: transparent transparent transparent #fff; } }
  .main-banner__slider-pause.is-active .pause-button {
    border-style: solid;
    border-width: 8px 0 8px 12px; }

/* END Style main-banner  */
/* ------------------------------------------ */
/* Style for mob-lang */
/* ------------------------------------------ */
.mob-lang__headlink {
  display: block;
  margin: 0 0 20px; }

.mob-lang__link {
  display: block;
  margin: 0 auto 15px; }

/* END Style mob-lang  */
/* ------------------------------------------ */
/* Style for mf-detail */
/* ------------------------------------------ */
.mf-detail {
  color: #FFF;
  top: 0;
  width: 100%;
  position: absolute;
  opacity: 0;
  z-index: -1; }
  .mf-detail.is-active {
    position: relative;
    display: block;
    opacity: 1;
    z-index: 10; }

.mf-detail__top {
  position: relative;
  overflow: hidden;
  height: 680px;
  padding: 250px 0;
  background: #000 no-repeat 55% center; }
  @media screen and (max-width: 1200px) {
    .mf-detail__top {
      padding: 200px 0;
      background-size: contain;
      height: 500px; } }
  @media screen and (max-width: 767px) {
    .mf-detail__top {
      padding: 60px 0;
      background-size: cover;
      height: auto; }
      .mf-detail__top:before {
        content: '';
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 0; } }
  .mf-detail__top .content-wrapper, .mf-detail__top .app-sec__inner, .mf-detail__top .header__wrapper, .mf-detail__top .search-nores, .mf-detail__top .sec-intro__detail-wrapper, .mf-detail__top .submenu__wrapper {
    position: relative;
    z-index: 10;
    height: 100%;
    padding: 0 60px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }

@-webkit-keyframes mf-item-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes mf-item-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.mf-detail__top-bg {
  z-index: 0;
  position: relative;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000 no-repeat 55% center;
  transition: 0.5s 0.1s;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  opacity: 0; }
  .mf-detail.is-active .mf-detail__top-bg {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1; }
  .mf-detail__top-bg:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1; }
    @media screen and (max-width: 767px) {
      .mf-detail__top-bg:after {
        background: rgba(0, 0, 0, 0.4); } }

.mf-detail__intro {
  display: inline-block;
  max-width: 45%;
  opacity: 0; }
  .mf-detail.is-active .mf-detail__intro {
    -webkit-animation: mf-item-fadein 0.6s;
    animation: mf-item-fadein 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
  @media screen and (max-width: 767px) {
    .mf-detail__intro {
      width: 100%;
      max-width: 100%;
      margin: 0 0 30px; } }

.mf-detail__intro-title {
  margin: 0 0 15px;
  font-weight: 200;
  text-transform: uppercase; }

.mf-detail__intro-desc {
  line-height: 1.5; }

.mf-detail__quicklinks {
  display: inline-block;
  width: 28%; }
  @media screen and (max-width: 767px) {
    .mf-detail__quicklinks {
      width: 100%;
      max-width: 100%; } }

.mf-detail__quicklinks-title {
  margin: 0 0 10px;
  font-weight: 300;
  color: #fb5004;
  opacity: 0; }
  .mf-detail.is-active .mf-detail__quicklinks-title {
    -webkit-animation: mf-item-quicklinks 0.6s;
    animation: mf-item-quicklinks 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes mf-item-quicklinks {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes mf-item-quicklinks {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.mf-detail__list {
  line-height: 1.5;
  padding-left: 15px; }
  .mf-detail__list li {
    padding: 5px 0; }
    .mf-detail.is-active .mf-detail__list li .mf-detail__link {
      display: block;
      -webkit-animation: mf-item-quicklink 0.6s;
      animation: mf-item-quicklink 0.6s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }

@-webkit-keyframes mf-item-quicklink {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes mf-item-quicklink {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
    .mf-detail.is-active .mf-detail__list li:nth-child(2) .mf-detail__link {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(3) .mf-detail__link {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(4) .mf-detail__link {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(5) .mf-detail__link {
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(6) .mf-detail__link {
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(7) .mf-detail__link {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(8) .mf-detail__link {
      -webkit-animation-delay: 0.7s;
      animation-delay: 0.7s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(9) .mf-detail__link {
      -webkit-animation-delay: 0.8s;
      animation-delay: 0.8s; }
    .mf-detail.is-active .mf-detail__list li:nth-child(10) .mf-detail__link {
      -webkit-animation-delay: 0.9s;
      animation-delay: 0.9s; }

.mf-detail__link {
  color: #FFF;
  opacity: 0; }
  .mf-detail__link:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #FFF;
    border-right: 2px solid #FFF;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .mf-detail__link:hover {
    color: #FFF; }

.mf-detail__control {
  position: absolute;
  top: 50%;
  height: 0;
  width: 100%;
  left: 0; }

.mf-detail__top-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 15; }
  .mf-detail__top-control .content-wrapper, .mf-detail__top-control .app-sec__inner, .mf-detail__top-control .header__wrapper, .mf-detail__top-control .search-nores, .mf-detail__top-control .sec-intro__detail-wrapper, .mf-detail__top-control .submenu__wrapper {
    width: 100%; }
    .mf-detail__top-control .content-wrapper *, .mf-detail__top-control .app-sec__inner *, .mf-detail__top-control .header__wrapper *, .mf-detail__top-control .search-nores *, .mf-detail__top-control .sec-intro__detail-wrapper *, .mf-detail__top-control .submenu__wrapper * {
      pointer-events: all; }

.mf-detail__btn-prev,
.mf-detail__btn-next {
  width: 36px;
  height: 36px;
  border: 1px solid #FFF;
  display: block;
  border-radius: 50%;
  position: absolute;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  top: 50%;
  margin-top: -18px;
  cursor: pointer; }
  .mf-detail__btn-prev:after,
  .mf-detail__btn-next:after {
    content: '';
    position: absolute;
    display: block;
    width: 7.2px;
    height: 7.2px;
    border-top: 1px solid #FFF;
    border-right: 1px solid #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.736px;
    margin-left: -5.4px; }

.mf-detail__btn-prev {
  left: 0px;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg); }

.mf-detail__btn-next {
  right: 0px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.mf-detail__btn-close {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  overflow: hidden;
  top: 30px;
  right: 30px;
  cursor: pointer; }
  .mf-detail__btn-close:before, .mf-detail__btn-close:after {
    content: '';
    position: absolute;
    display: block;
    background: #FFF;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .mf-detail__btn-close:before {
    width: 100%;
    height: 3px;
    left: 0;
    top: 50%;
    margin-top: -1.5px; }
  .mf-detail__btn-close:after {
    height: 100%;
    width: 3px;
    left: 50%;
    margin-left: -1.5px;
    top: 0; }

.mf-detail__boxes {
  /*
    position:absolute;
    width:100%;
    left:0;
    top:100%;
    */
  margin-top: -60px; }
  @media screen and (max-width: 767px) {
    .mf-detail__boxes {
      margin-top: 0; } }
  .mf-detail__boxes .content-wrapper, .mf-detail__boxes .app-sec__inner, .mf-detail__boxes .header__wrapper, .mf-detail__boxes .search-nores, .mf-detail__boxes .sec-intro__detail-wrapper, .mf-detail__boxes .submenu__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.06); }
    @media screen and (max-width: 767px) {
      .mf-detail__boxes .content-wrapper, .mf-detail__boxes .app-sec__inner, .mf-detail__boxes .header__wrapper, .mf-detail__boxes .search-nores, .mf-detail__boxes .sec-intro__detail-wrapper, .mf-detail__boxes .submenu__wrapper {
        width: 100%;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch; } }

.mf-detail__box {
  position: relative;
  display: inline-block;
  width: 33.3333%;
  padding: 80px 60px 45px;
  opacity: 0; }
  .mf-detail.is-active .mf-detail__box {
    -webkit-animation: mf-item-box 0.6s;
    animation: mf-item-box 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes mf-item-box {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes mf-item-box {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
  .mf-detail__box.mf-detail__box--1 {
    width: 36%;
    background: #1e1e1e; }
  .mf-detail__box.mf-detail__box--2 {
    width: 36%;
    color: #1a1a1a;
    background: #FFF; }
    .mf-detail.is-active .mf-detail__box.mf-detail__box--2 {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s; }
  .mf-detail__box.mf-detail__box--3 {
    width: 28%;
    background: #fb5004; }
    .mf-detail.is-active .mf-detail__box.mf-detail__box--3 {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s; }
  @media screen and (max-width: 767px) {
    .mf-detail__box {
      width: 100%;
      padding: 45px; }
      .mf-detail__box.mf-detail__box--1, .mf-detail__box.mf-detail__box--2, .mf-detail__box.mf-detail__box--3 {
        width: 100%; } }

.mf-detail__box-img {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 100%;
  left: 0; }
  @media screen and (max-width: 767px) {
    .mf-detail__box-img {
      position: relative;
      bottom: auto;
      left: auto;
      margin: 0 auto 30px; } }
  .mf-detail__box-img img, .mf-detail__box-img iframe {
    position: relative;
    padding: 0 60px;
    margin: 0 auto -60px;
    display: block;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .mf-detail__box-img img, .mf-detail__box-img iframe {
        margin: 0 auto;
        padding: 0; } }

.mf-detail__btn-arrow {
  display: inline-block; }
  .mf-detail__btn-arrow:before {
    content: '';
    width: 30px;
    height: 30px;
    display: block;
    background: no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/orange/icon-long-arrow.svg");
    background-size: 100%; }

.mf-detail__box-label {
  font-weight: 400;
  margin: 0 0 10px; }
  .mf-detail__box--1 .mf-detail__box-label {
    color: #fb5004; }

.mf-detail__box-title {
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 10px;
  line-height: 1.5;
  color: #FFF; }

.mf-detail__box-date,
.mf-detail__box-desc {
  margin: 0 0 10px;
  line-height: 1.5; }

/* END Style mf-detail  */
/* ------------------------------------------ */
/* Style for nav-link */
/* ------------------------------------------ */
.nav-link__holder {
  padding-left: 90px; }
  @media screen and (max-width: 991px) {
    .nav-link__holder {
      padding-left: 0;
      position: relative;
      top: -35px;
      left: 0;
      z-index: 1000;
      background: rgba(255, 255, 255, 0.85);
      width: 100vw;
      height: 2000px;
      padding-top: 70px;
      display: none; }
      .mob-menu-open .nav-link__holder {
        display: block;
        overflow: auto; } }

.nav-link__subholder {
  display: none; }
  @media screen and (min-width: 992px) {
    .nav-link__subholder {
      display: none; }
      .nav-link__subholder#mob-menu {
        display: block; } }
  @media screen and (max-width: 991px) {
    .nav-link__subholder {
      display: none; }
      .nav-link__subholder.is-active {
        display: block;
        height: 100%;
        position: fixed;
        overflow: auto;
        padding-bottom: 160px;
        left: 0;
        right: 0;
        margin: auto; } }
  .nav-link__subholder#mob-share {
    width: 90%;
    margin: 0 auto;
    color: #1a1a1a;
    text-align: center; }
    .nav-link__subholder#mob-share .button-share__holder {
      display: block; }
    .nav-link__subholder#mob-share .button-share__title {
      display: block;
      margin: 0 0 20px; }
    .nav-link__subholder#mob-share .button-share {
      margin: 0 10px 20px; }
  .nav-link__subholder#mob-lang {
    width: 90%;
    margin: 0 auto;
    color: #1a1a1a;
    text-align: center; }

.nav-link {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #1a1a1a;
  padding: 0 20px; }
  @media screen and (min-width: 992px) and (max-width: 1280px) {
    .nav-link {
      padding: 0 15px; } }
  @media screen and (max-width: 991px) {
    .nav-link {
      position: relative;
      display: block;
      margin-bottom: 30px; }
      .on-submenu .nav-link {
        display: none; }
        .on-submenu .nav-link.is-open {
          display: block; } }

.nav-link__mob-toggle {
  display: none; }
  @media screen and (max-width: 991px) {
    .nav-link__mob-toggle {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 30; }
      .nav-link.is-open .nav-link__mob-toggle {
        display: none; } }

.nav-link__link {
  z-index: 10;
  position: relative;
  display: block;
  color: #1a1a1a;
  /*
    @include fontSize(14);
    @media screen and (max-width: 1580px) {
        @include fontSize(12);
    }
    */ }
  .nav-link__link:hover {
    opacity: 1;
    color: #fb5004; }
  .nav-link.is-hover .nav-link__link {
    z-index: 15; }
  .nav-link__link.is-active {
    color: #fb5004; }
  @media screen and (min-width: 992px) and (max-width: 1280px) {
    .nav-link__link {
      font-size: 12px; } }
  .nav-link__link strong {
    text-transform: uppercase;
    display: block;
    margin: 0 auto 5px;
    font-size: 22px; }
    @media screen and (max-width: 1580px) {
      .nav-link__link strong {
        font-size: 18px; } }
    [lang="zh-hk"] .nav-link__link strong,
    [lang="zh-cn"] .nav-link__link strong {
      display: inline; }
  [lang="zh-hk"] .nav-link__link,
  [lang="zh-cn"] .nav-link__link {
    font-size: 22px;
    font-weight: bold; }
    @media screen and (max-width: 1580px) {
      [lang="zh-hk"] .nav-link__link,
      [lang="zh-cn"] .nav-link__link {
        font-size: 18px; } }
  .nav-link__link:before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 5px;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
    .nav-link.is-hover .nav-link__link:before {
      width: 300%; }
    [lang="zh-hk"] .nav-link__link:before,
    [lang="zh-cn"] .nav-link__link:before {
      height: 10px; }
  .nav-link.is-hover .nav-link__link:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background: #FFF;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    top: 100%;
    left: 50%;
    margin-top: 25px;
    margin-left: -10px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
    @media screen and (max-width: 991px) {
      .nav-link.is-hover .nav-link__link:after {
        display: none; } }
    [lang="zh-hk"] .nav-link.is-hover .nav-link__link:after,
    [lang="zh-cn"] .nav-link.is-hover .nav-link__link:after {
      margin-top: 34px; }

/* END Style nav-link  */
/* ------------------------------------------ */
/* Style for organic-listing */
/* ------------------------------------------ */
.organic-listing {
  position: relative; }
  .organic-listing em {
    font-style: normal;
    font-weight: bold;
    color: #fb5004; }

.organic-listing__items {
  position: relative;
  background: #FFF;
  padding: 15px 2%; }

.organic-listing__item {
  padding: 25px 0;
  width: 100%;
  border-bottom: 1px solid #dadada;
  position: relative; }
  .organic-listing__item:last-child {
    border-bottom: 0; }

.organic-listing__item-big-title {
  display: inline-block;
  margin: 0 0 15px;
  margin-right: 10px; }
  .organic-listing__item-big-title + .organic-listing__note-icons {
    margin: 5px 0; }
  @media screen and (min-width: 768px) {
    .organic-listing__item-big-title {
      margin-right: 110px; } }

.organic-listing__item-row {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 991px) {
    .organic-listing__item-row.organic-listing__item-row--multiimg {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  @media screen and (max-width: 767px) {
    .organic-listing__item-row {
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; } }
  @media screen and (max-width: 480px) {
    .organic-listing__item-row {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  @media screen and (max-width: 767px) {
    .organic-listing__item-row.is1Badge {
      min-height: 40px; } }
  @media screen and (max-width: 767px) {
    .organic-listing__item-row.is2Badge {
      min-height: 80px; } }
  .organic-listing__item-row.is1Badge .organic-listing__detail, .organic-listing__item-row.is2Badge .organic-listing__detail {
    padding-right: 100px; }

.organic-listing__img {
  position: relative;
  padding-right: 20px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  @media screen and (max-width: 480px) {
    .organic-listing__img {
      width: 100%;
      margin-bottom: 10px;
      padding-right: 0px; } }
  @media screen and (max-width: 991px) {
    .organic-listing__item-row--multiimg .organic-listing__img {
      width: 100%;
      margin-bottom: 10px; } }

.organic-listing__thumb {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  margin-bottom: 5px; }

.organic-listing__img-holder {
  float: left;
  outline: 1px solid #e6e6e6;
  width: 160px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  margin-right: 10px;
  /* 
    Sam: PHR-454, disable hover effect when OL no longer has popup. As if element has hover effect, IOS treats as click. It causes hyperlink need tap twice on IOS.
    ====================================================
    &:hover {
        .organic-listing__pop {
            display: block;
        }
        &:after{
            content:'';
            position: absolute;
            display:block;
            width:100%;
            height:30px;
            left:0;
            bottom:100%;
            z-index: 500;
        }
    }
    ======================= */ }
  @media screen and (max-width: 991px) {
    .organic-listing__img-holder {
      width: 120px;
      height: 68px;
      line-height: 68px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 640px) {
    .organic-listing__img-holder {
      width: 100px;
      height: 57px;
      line-height: 57px;
      margin-bottom: 10px; } }
  .organic-listing__img-holder:last-child {
    margin-right: 0; }

.organic-listing__pop {
  position: absolute;
  background: #FFF;
  font-size: 0;
  width: 480px;
  z-index: 1000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  left: 0;
  bottom: 100%;
  margin-bottom: 30px;
  display: none; }
  .organic-listing__pop:hover {
    display: block; }

.organic-listing__popcap {
  background: #fb5004;
  color: #FFF;
  padding: 15px 30px; }

.organic-listing__popcap-title em {
  color: #FFF; }

.organic-listing__popcap-btns {
  margin-top: 10px; }
  .organic-listing__popcap-btns .organic-listing__btn {
    color: #FFF;
    border-color: #FFF;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px; }

.organic-listing__item-head {
  display: block;
  margin: 0 0 5px; }

.organic-listing__item-title {
  display: inline-block;
  margin: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: bold; }

.organic-listing__infos {
  position: relative;
  font-size: 0;
  margin: 0 0 5px; }

.organic-listing__info {
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
  padding-right: 10px;
  position: relative; }
  .organic-listing__info:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 16px;
    margin-top: -8px;
    width: 1px;
    background: #1a1a1a; }
  .organic-listing__info:last-child:after {
    display: none;
    margin-right: 0;
    padding-right: 0; }

.organic-listing__specs {
  position: relative;
  font-size: 0;
  margin: 0 0 5px; }

.organic-listing__spec {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  padding-right: 10px;
  position: relative; }
  .organic-listing__spec:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 16px;
    margin-top: -8px;
    width: 1px;
    background: #1a1a1a; }
  .organic-listing__spec:last-child:after {
    display: none;
    margin-right: 0;
    padding-right: 0; }

.organic-listing__desc {
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 5px; }

.organic-listing__btn {
  margin: 0;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 20px; }
  @media screen and (max-width: 480px) {
    .organic-listing__btn {
      margin-left: 0; }
      .organic-listing__btn + .organic-listing__btn {
        margin-left: 0; } }

.organic-listing__note-icons {
  display: inline-block;
  vertical-align: middle; }

.organic-listing__icons {
  margin: 0 0 10px; }

.organic-listing__icon {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  height: 22px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  /* SRI BADGE ICONS */ }
  .organic-listing__icon:last-child {
    margin-right: 0; }
  .organic-listing__icon--booth {
    width: 61px;
    height: 24px;
    background-image: url("./assets/images/common/sri-icon/meetusatourbooth.svg"); }
  .organic-listing__icon--small-order {
    width: 71px;
    height: 30px;
    background-image: url("./assets/images/common/sri-icon/acceptsmallorder.svg"); }
  .organic-listing__icon--CVS {
    background-image: url("./assets/images/common/sri-icon/certificates-en.svg"); }
    :lang(zh-hk) .organic-listing__icon--CVS {
      background-image: url("./assets/images/common/sri-icon/certificates-tc.svg"); }
    :lang(zh-cn) .organic-listing__icon--CVS {
      background-image: url("./assets/images/common/sri-icon/certificates-sc.svg"); }
  .organic-listing__icon--DNB {
    background-image: url("./assets/images/common/sri-icon/d&B.svg"); }
  .organic-listing__icon--EXH {
    background-image: url("./assets/images/common/sri-icon/hktdcexhibitor-en.svg"); }
    :lang(zh-hk) .organic-listing__icon--EXH {
      background-image: url("./assets/images/common/sri-icon/hktdcexhibitor-tc.svg"); }
    :lang(zh-cn) .organic-listing__icon--EXH {
      background-image: url("./assets/images/common/sri-icon/hktdcexhibitor-sc.svg"); }
  .organic-listing__icon--PREMIUM1 {
    background-image: url("./assets/images/common/sri-icon/1stpremadver-en.svg"); }
    :lang(zh-hk) .organic-listing__icon--PREMIUM1 {
      background-image: url("./assets/images/common/sri-icon/1stpremadver-tc.svg"); }
    :lang(zh-cn) .organic-listing__icon--PREMIUM1 {
      background-image: url("./assets/images/common/sri-icon/1stpremadver-sc.svg"); }
  .organic-listing__icon--PREMIUM2 {
    background-image: url("./assets/images/common/sri-icon/2ndpremadver-en.svg"); }
    :lang(zh-hk) .organic-listing__icon--PREMIUM2 {
      background-image: url("./assets/images/common/sri-icon/2ndpremadver-tc.svg"); }
    :lang(zh-cn) .organic-listing__icon--PREMIUM2 {
      background-image: url("./assets/images/common/sri-icon/2ndpremadver-sc.svg"); }
  .organic-listing__icon--PREMIUM3 {
    background-image: url("./assets/images/common/sri-icon/3rdpremadver-en.svg"); }
    :lang(zh-hk) .organic-listing__icon--PREMIUM3 {
      background-image: url("./assets/images/common/sri-icon/3rdpremadver-tc.svg"); }
    :lang(zh-cn) .organic-listing__icon--PREMIUM3 {
      background-image: url("./assets/images/common/sri-icon/3rdpremadver-sc.svg"); }
  .organic-listing__icon--PREMIUM4 {
    background-image: url("./assets/images/common/sri-icon/4thpremadver-en.svg"); }
    :lang(zh-hk) .organic-listing__icon--PREMIUM4 {
      background-image: url("./assets/images/common/sri-icon/4thpremadver-tc.svg"); }
    :lang(zh-cn) .organic-listing__icon--PREMIUM4 {
      background-image: url("./assets/images/common/sri-icon/4thpremadver-sc.svg"); }
  .organic-listing__icon--PREMIUM5 {
    background-image: url("./assets/images/common/sri-icon/5thpremadver-en.svg"); }
    :lang(zh-hk) .organic-listing__icon--PREMIUM5 {
      background-image: url("./assets/images/common/sri-icon/5thpremadver-tc.svg"); }
    :lang(zh-cn) .organic-listing__icon--PREMIUM5 {
      background-image: url("./assets/images/common/sri-icon/5thpremadver-sc.svg"); }
  .organic-listing__icon--PREMIUM5P {
    background-image: url("./assets/images/common/sri-icon/L5premadver-en.svg"); }
    :lang(zh-hk) .organic-listing__icon--PREMIUM5P {
      background-image: url("./assets/images/common/sri-icon/L5premadver-tc.svg"); }
    :lang(zh-cn) .organic-listing__icon--PREMIUM5P {
      background-image: url("./assets/images/common/sri-icon/L5premadver-sc.svg"); }
  .organic-listing__icon--SRI {
    background-image: url("./assets/images/common/sri-icon/supplierreference-en.svg"); }
    :lang(zh-hk) .organic-listing__icon--SRI {
      background-image: url("./assets/images/common/sri-icon/supplierreference-tc.svg"); }
    :lang(zh-cn) .organic-listing__icon--SRI {
      background-image: url("./assets/images/common/sri-icon/supplierreference-sc.svg"); }
  .organic-listing__icon--ENV_COMPLIANCE {
    background-image: url("./assets/images/common/sri-icon/complianceverified.svg"); }
  .organic-listing__icon--QUALITY_COMPLIANCE {
    background-image: url("./assets/images/common/sri-icon/complianceverified.svg"); }
  .organic-listing__icon--SECURITY_COMPLIANCE {
    background-image: url("./assets/images/common/sri-icon/complianceverified.svg"); }
  .organic-listing__icon--SOCIAL_COMPLIANCE {
    background-image: url("./assets/images/common/sri-icon/complianceverified.svg"); }
  .organic-listing__icon--FREELISTING {
    background-image: url("./assets/images/common/sri-icon/unverified-en.svg"); }
    :lang(zh-hk) .organic-listing__icon--FREELISTING {
      background-image: url("./assets/images/common/sri-icon/unverified-tc.svg"); }
    :lang(zh-cn) .organic-listing__icon--FREELISTING {
      background-image: url("./assets/images/common/sri-icon/unverified-sc.svg"); }

.organic-listing__more {
  display: inline-block;
  position: relative;
  color: #fb5004;
  padding: 30px 0; }

.organic-listing__more-link {
  padding-right: 50px; }
  .organic-listing__more-link:after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -20px;
    background: url("./assets/images/common/icons/svg/orange/icon-long-arrow.svg") no-repeat center center;
    background-size: 100%; }

/* END Style organic-listing  */
/* ------------------------------------------ */
/* Style for pagination */
/* ------------------------------------------ */
.pagination {
  padding: 60px 0;
  color: #1a1a1a;
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .pagination {
      padding: 30px 0; } }
  .pagination.mobile {
    display: none; }
    @media screen and (max-width: 480px) {
      .pagination.mobile {
        display: block; } }
  @media screen and (max-width: 480px) {
    .pagination.desktop {
      display: none; } }
  .pagination > .content-wrapper, .pagination > .app-sec__inner, .pagination > .header__wrapper, .pagination > .search-nores, .pagination > .sec-intro__detail-wrapper, .pagination > .submenu__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }

.pagination__btn-prev,
.pagination__btn-next {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle; }
  @media screen and (max-width: 640px) {
    .pagination__btn-prev,
    .pagination__btn-next {
      font-size: 14px; } }
  @media screen and (max-width: 480px) {
    .pagination__btn-prev,
    .pagination__btn-next {
      position: relative;
      font-size: 0px;
      width: 30px;
      height: 30px;
      padding: 0;
      border-radius: 50%; }
      .pagination__btn-prev:before,
      .pagination__btn-next:before {
        content: '';
        position: absolute;
        display: block;
        width: 7.2px;
        height: 7.2px;
        border-top: 1px solid #fb5004;
        border-right: 1px solid #fb5004;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -4px; }
      .pagination__btn-prev:hover:before,
      .pagination__btn-next:hover:before {
        border-top: 1px solid #FFF;
        border-right: 1px solid #FFF; } }
  .pagination__btn-prev:hover,
  .pagination__btn-next:hover {
    opacity: 1; }

@media screen and (max-width: 480px) {
  .pagination__btn-prev:before {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    margin-left: -2px; } }

@media screen and (max-width: 480px) {
  .pagination__btn-next:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: -6px; } }

.pagination__pages {
  vertical-align: middle;
  font-weight: bold;
  margin: 0 20px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .pagination__pages {
      margin: 0 10px; } }
  @media screen and (max-width: 480px) {
    .pagination__pages {
      margin: 6px; } }
  .pagination__pages > span,
  .pagination__pages > div {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle; }
    @media screen and (max-width: 640px) {
      .pagination__pages > span,
      .pagination__pages > div {
        font-size: 14px; } }
    @media screen and (max-width: 480px) {
      .pagination__pages > span,
      .pagination__pages > div {
        font-size: 12px; } }

.pagination__current {
  margin: 0 10px; }
  .pagination__current .pagination__current-pg {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    padding: 0;
    text-align: center;
    color: #1a1a1a;
    border: 1px solid #1a1a1a;
    border-radius: 50%;
    font-weight: bold; }
    @media screen and (max-width: 480px) {
      .pagination__current .pagination__current-pg {
        font-size: 12px; } }
    .pagination__current .pagination__current-pg:focus {
      border-color: #fb5004; }

.pagination__total {
  display: inline-block;
  margin-left: 10px; }

.pagination__page {
  margin: 0 5px; }
  @media screen and (max-width: 767px) {
    .pagination__page {
      margin: 0 4px; } }
  .pagination__page.is-active {
    color: #fb5004; }

/* END Style pagination  */
/* ------------------------------------------ */
/* Style for search-nores */
/* ------------------------------------------ */
.search-nores {
  position: relative; }

.search-nores__txt {
  font-weight: 300;
  position: relative;
  padding: 60px 5%;
  line-height: 1.8em; }

.search-nores__suggestion {
  position: relative;
  display: inline-block;
  margin-right: 10px; }
  .search-nores__suggestion:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 2px;
    background: #1a1a1a; }

/* END Style search-nores  */
/* ------------------------------------------ */
/* Style for .search-panel */
/* ------------------------------------------ */
.search-panel {
  position: relative;
  display: block; }

.search-panel__btn {
  position: relative;
  display: inline-block;
  color: #FFF;
  background: #fb5004;
  padding: 12px 35px;
  padding-left: 25px;
  border-radius: 30px;
  white-space: nowrap;
  z-index: 120; }
  .search-panel__btn:hover {
    color: #FFF; }
  @media screen and (max-width: 991px) {
    .search-panel__btn {
      padding: 12px 20px;
      padding-left: 15px; } }
  @media screen and (max-width: 767px) {
    .search-panel__btn {
      padding: 8px 15px;
      padding-left: 12px; } }
  @media screen and (max-width: 350px) {
    .search-panel__btn {
      padding: 8px 12px;
      padding-left: 8px; } }
  .search-panel__btn:before {
    content: '';
    width: 18px;
    height: 18px;
    background: url("./assets/images/common/icons/svg/white/icon-search.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px; }
    @media screen and (max-width: 991px) {
      .search-panel__btn:before {
        width: 16px;
        height: 16px; } }
    @media screen and (max-width: 767px) {
      .search-panel__btn:before {
        width: 14px;
        height: 14px; } }
    @media screen and (max-width: 350px) {
      .search-panel__btn:before {
        margin-right: 5px; } }
  .search-panel-open .search-panel__btn:before {
    background-image: url("./assets/images/common/icons/svg/white/icon-close.svg"); }

.search-panel__input {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background: #fb5004;
  border: 0;
  font-weight: bold; }
  .search-panel__input:focus {
    outline: 2px solid #bbb; }
  .search-panel__input::-webkit-input-placeholder {
    color: #FFF; }
  .search-panel__input:-moz-placeholder {
    /* Firefox 18- */
    color: #FFF; }
  .search-panel__input::-moz-placeholder {
    /* Firefox 19+ */
    color: #FFF; }
  .search-panel__input:-ms-input-placeholder {
    color: #FFF; }

.search-panel__panel {
  padding: 0 0 15px;
  position: relative; }

.search-panel__wrapper {
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .search-panel__wrapper {
      display: block; } }

.search-panel__txt-close {
  display: none;
  vertical-align: middle; }
  .search-panel-open .search-panel__txt-close {
    display: inline-block; }

.search-panel__txt-open {
  display: inline-block;
  vertical-align: middle; }
  .search-panel-open .search-panel__txt-open {
    display: none; }

.search-panel__input-holder {
  display: block;
  position: relative;
  width: 100%;
  max-width: 480px;
  margin: 0 30px; }
  @media screen and (max-width: 480px) {
    .search-panel__input-holder {
      margin: 10px auto 0; } }

.search-panel__input-btn {
  position: absolute;
  width: 55px;
  padding-right: 15px;
  height: 43px;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  z-index: 50;
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .search-panel__input-btn {
      height: 32px; } }
  .search-panel__input-btn:focus {
    outline: 2px solid #bbb; }
  .search-panel__input-btn:before {
    content: '';
    width: 20px;
    height: 20px;
    background: url("./assets/images/common/icons/svg/orange/icon-search.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle; }

.search-panel__input {
  position: relative;
  width: 100%;
  height: 43px;
  border-radius: 21.5px;
  border: 0;
  background: #ececec;
  z-index: 15;
  font-weight: bold;
  padding-left: 50px;
  padding-right: 100px; }
  @media screen and (max-width: 767px) {
    .search-panel__input {
      height: 32px;
      border-radius: 16px; } }
  .search-panel__input::-webkit-input-placeholder {
    color: #777; }
  .search-panel__input:-moz-placeholder {
    /* Firefox 18- */
    color: #777; }
  .search-panel__input::-moz-placeholder {
    /* Firefox 19+ */
    color: #777; }
  .search-panel__input:-ms-input-placeholder {
    color: #777; }
  .search-panel__input::-webkit-search-cancel-button {
    display: none; }
  .search-panel__input::-ms-clear {
    display: none; }
  .search-panel__input:focus {
    outline: 2px solid #bbb; }
  .search-panel__input + .search-panel__autocomplete {
    display: none; }
  .search-panel__input:focus + .search-panel__autocomplete,
  .search-panel__input + .search-panel__autocomplete:focus,
  .search-panel__input + .search-panel__autocomplete:hover {
    display: block; }
  .search-panel__input:focus + .search-panel__autocomplete.force-close,
  .search-panel__input + .search-panel__autocomplete.force-close:focus,
  .search-panel__input + .search-panel__autocomplete.force-close:hover {
    display: none; }

.search-panel__filters {
  padding: 15px 0;
  background: #242655;
  font-size: 0;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .search-panel__filters {
      width: 100%;
      height: 70px;
      overflow: hidden;
      position: relative; }
      .search-panel__filters:after {
        content: "";
        display: block;
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(36, 38, 85, 0)), color-stop(100%, #242655));
        height: 70px;
        position: absolute;
        pointer-events: none;
        right: 0;
        top: 0;
        width: calc(5% + 30px);
        z-index: 2; }
      .search-panel__filters .content-wrapper, .search-panel__filters .app-sec__inner, .search-panel__filters .header__wrapper, .search-panel__filters .search-nores, .search-panel__filters .sec-intro__detail-wrapper, .search-panel__filters .submenu__wrapper {
        height: 80px;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hide;
        -webkit-overflow-scrolling: touch; } }

.search-panel__filter {
  display: inline-block;
  margin-right: 45px; }
  @media screen and (max-width: 767px) {
    .search-panel__filter {
      margin-right: 30px; } }
  @media screen and (max-width: 767px) {
    .search-panel__filter:first-child {
      margin-left: 10px; } }
  .search-panel__filter:last-child {
    margin-right: 0; }
    @media screen and (max-width: 767px) {
      .search-panel__filter:last-child {
        margin-right: 30px; } }
  .search-panel__filter__title {
    color: #fff;
    display: block;
    position: relative;
    font-weight: 400; }
  .search-panel__filter input {
    display: none; }
    .search-panel__filter input + label {
      position: relative;
      display: block;
      cursor: pointer;
      font-size: 16px;
      line-height: 2em;
      color: #FFF; }
    .search-panel__filter input:checked + label {
      font-weight: bold; }
      .search-panel__filter input:checked + label:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: #fb5004; }

.search-panel__topsearch {
  margin-top: 25px;
  font-size: 12px;
  line-height: 1.5em; }

.search-panel__topsearch-title {
  display: inline-block;
  vertical-align: middle;
  color: #fb5004;
  font-weight: bold;
  margin: 0;
  margin-right: 25px; }

.search-panel__topsearch-item {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-right: 15px; }

.search-panel__autocomplete {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background: #ececec;
  text-align: left;
  padding-bottom: 25px;
  margin-top: -21.5px;
  padding-top: 21.5px;
  border-radius: 0 0 21.5px 21.5px;
  z-index: 5; }

.search-panel__autocomplet-grp {
  padding: 0 50px; }
  .search-panel__autocomplet-grp.acActive, .search-panel__autocomplet-grp:hover {
    background-color: #fcf5f1; }
    .search-panel__autocomplet-grp.acActive .header-search__autocomplet-item, .search-panel__autocomplet-grp:hover .header-search__autocomplet-item {
      font-weight: bold;
      color: #000;
      text-decoration: none;
      opacity: 1; }

.search-panel__autocomplet-item {
  display: block;
  font-size: 16px;
  margin: 0 0 14px; }

.search-panel__autocomplet-subitem {
  display: block;
  padding-left: 15px;
  font-size: 12px;
  margin: 0 0 14px; }

.search-panel__keywords {
  position: relative;
  padding: 5px 0; }

.search-panel__keywords-title {
  font-size: 16px;
  line-height: 16px;
  font-weight: 900;
  display: inline-block;
  vertical-align: baseline;
  font-weight: bold;
  margin: 0;
  margin-right: 10px; }
  @media screen and (max-width: 767px) {
    .search-panel__keywords-title {
      font-size: 14px; } }

.search-panel__keywords-holder {
  display: inline-block;
  vertical-align: baseline;
  font-size: 14px;
  line-height: 16px; }
  @media screen and (max-width: 767px) {
    .search-panel__keywords-holder {
      font-size: 12px; } }

.search-panel__keywords-words {
  display: inline-block;
  vertical-align: baseline;
  font-weight: 900;
  color: #fb5004;
  line-height: 16px;
  margin: 0;
  margin-right: 5px; }

.search-panel__result-num {
  display: inline-block;
  vertical-align: baseline;
  line-height: 16px;
  font-size: 100%; }

.search-panel__list-control {
  position: relative;
  padding: 15px 0; }

.search-panel__list-control-holder {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 5%; }
  @media screen and (max-width: 640px) {
    .search-panel__list-control-holder {
      display: block; } }

.search-panel__sorting {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.search-panel__sorting-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  display: inline-block;
  margin: 0;
  margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .search-panel__sorting-title {
      font-size: 12px;
      margin-right: 10px; } }

.search-panel__sorting-items {
  position: relative; }

.search-panel__sorting-item {
  position: relative;
  border: 0;
  box-shadow: none;
  background: transparent;
  padding: 0;
  font-size: 14px;
  margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .search-panel__sorting-item {
      margin-right: 10px;
      font-size: 12px; } }
  .search-panel__sorting-item:last-child {
    margin-right: 0; }
  .search-panel__sorting-item.is-active {
    color: #fb5004;
    font-weight: bold;
    padding: 5px 0; }
    .search-panel__sorting-item.is-active::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background: #fb5004; }

.search-panel__clear-btn {
  position: absolute;
  width: 43px;
  height: 43px;
  right: 55px;
  top: 0;
  background: transparent;
  border: 0;
  z-index: 50;
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .search-panel__clear-btn {
      height: 32px; } }
  .search-panel__clear-btn:focus {
    outline: 2px solid #bbb; }
  .search-panel__clear-btn:before {
    content: '';
    width: 12px;
    height: 12px;
    background: url("./assets/images/common/icons/svg/black/icon-close.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle; }

/* END Style .search-panel  */
/* ------------------------------------------ */
/* Style for sec-break */
/* ------------------------------------------ */
.sec-break {
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: 2px;
  background: #e5e5e5; }
  .sec-break.animated {
    opacity: 0;
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center; }
    .sec-break.animated.fadeIn {
      -webkit-animation: sec-break 0.4s;
      animation: sec-break 0.4s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }

@-webkit-keyframes sec-break {
  0% {
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1; } }

@keyframes sec-break {
  0% {
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1; } }

/* END Style sec-break  */
/* ------------------------------------------ */
/* Style for sec-intro */
/* ------------------------------------------ */
.sec-intro {
  text-align: center;
  padding: 45px 0; }
  .sec-intro .button-share__title {
    opacity: 0;
    -webkit-animation: sec-intro-faderight 0.6s;
    animation: sec-intro-faderight 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
  .sec-intro .button-share {
    opacity: 0;
    -webkit-animation: sec-intro-fadeleft 0.6s;
    animation: sec-intro-fadeleft 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform;
    opacity: 0; }
    .sec-intro .button-share:nth-child(2) {
      -webkit-animation-delay: 0.12s;
      animation-delay: 0.12s; }
    .sec-intro .button-share:nth-child(3) {
      -webkit-animation-delay: 0.24s;
      animation-delay: 0.24s; }
    .sec-intro .button-share:nth-child(4) {
      -webkit-animation-delay: 0.36s;
      animation-delay: 0.36s; }
    .sec-intro .button-share:nth-child(5) {
      -webkit-animation-delay: 0.48s;
      animation-delay: 0.48s; }
    .sec-intro .button-share:nth-child(6) {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s; }
    .sec-intro .button-share:nth-child(7) {
      -webkit-animation-delay: 0.72s;
      animation-delay: 0.72s; }
    .sec-intro .button-share:nth-child(8) {
      -webkit-animation-delay: 0.84s;
      animation-delay: 0.84s; }
    .sec-intro .button-share:nth-child(9) {
      -webkit-animation-delay: 0.96s;
      animation-delay: 0.96s; }
    .sec-intro .button-share:nth-child(10) {
      -webkit-animation-delay: 1.08s;
      animation-delay: 1.08s; }

main .sec-intro:first-child {
  background: url("./assets/images/common/bg-sec-intro.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 175px;
  margin-top: -130px; }
  @media screen and (max-width: 1580px) {
    main .sec-intro:first-child {
      padding-top: 170px; } }
  @media screen and (max-width: 991px) {
    main .sec-intro:first-child {
      padding-top: 115px; } }
  @media screen and (max-width: 1580px) {
    main .sec-intro:first-child {
      margin-top: -125px; } }
  @media screen and (max-width: 991px) {
    main .sec-intro:first-child {
      margin-top: -70px; } }

.sec-intro__title {
  opacity: 0;
  -webkit-animation: sec-intro-fadetop 0.6s;
  animation: sec-intro-fadetop 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform;
  display: block;
  max-width: 800px;
  font-weight: 400;
  margin: 0 auto 30px;
  background: #fc5e08;
  background: -moz-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
  background: -webkit-linear-gradient(left, #fc5e08 0%, #fd8e14 100%);
  background: linear-gradient(to right, #fc5e08 0%, #fd8e14 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc5e08', endColorstr='#fd8e14', GradientType=1);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase; }
  .is-firefox .sec-intro__title {
    background: none;
    color: #fc5e08;
    -webkit-animation: sec-intro-fadetop 1s;
    animation: sec-intro-fadetop 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
  .is-ie .sec-intro__title {
    background: transparent;
    color: #fb5004; }

.sec-intro__desc {
  max-width: 768px;
  display: block;
  line-height: 1.5em;
  margin: 0 auto 30px;
  opacity: 0;
  -webkit-animation: sec-intro-fadetop 0.6s 0.3s;
  animation: sec-intro-fadetop 0.6s 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  will-change: transform; }

.sec-intro__detail {
  display: block;
  margin: 0 auto;
  padding: 100px 0;
  background: #f5f5f5; }
  @media screen and (max-width: 991px) {
    .sec-intro__detail {
      padding: 80px 0; } }
  @media screen and (max-width: 640px) {
    .sec-intro__detail {
      padding: 60px 0; } }

.sec-intro__detail-wrapper {
  max-width: 960px; }
  .sec-intro__detail-wrapper iframe {
    max-width: 960px; }
    @media screen and (max-width: 767px) {
      .sec-intro__detail-wrapper iframe {
        width: 100%; } }

.sec-intro__detail-item {
  display: block;
  margin: 0 auto 30px; }
  .sec-intro__detail-item:last-child {
    margin-bottom: 0; }

.sec-intro__detail-title {
  font-weight: bold;
  margin: 0 auto 20px; }
  .sec-intro__detail-desc + .sec-intro__detail-title {
    margin-top: 30px; }

.sec-intro__detail-subtitle {
  text-decoration: underline;
  font-weight: normal;
  margin: 0 auto 10px; }
  .sec-intro__detail-desc + .sec-intro__detail-subtitle {
    margin-top: 30px; }

.sec-intro__detail-desc {
  line-height: 1.5em; }

@-webkit-keyframes sec-intro-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes sec-intro-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes sec-intro-fadetop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes sec-intro-fadetop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes sec-intro-fadeleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes sec-intro-fadeleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes sec-intro-faderight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes sec-intro-faderight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

/* END Style sec-intro  */
/* ------------------------------------------ */
/* Style for side-tabs */
/* ------------------------------------------ */
.side-tabs {
  padding-bottom: 30px; }

.side-tabs__title {
  position: relative;
  color: #fb5004;
  text-align: center;
  font-weight: 300;
  margin: 0 0 30px; }

.side-tabs__items {
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 991px) {
    .side-tabs__items {
      height: auto !important; } }

.side-tabs__item .side-tabs__content {
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  display: block; }
  @media screen and (max-width: 991px) {
    .side-tabs__item .side-tabs__content {
      display: none; } }

.side-tabs__item.is-active .side-tabs__content {
  opacity: 1;
  z-index: 10;
  pointer-events: all; }
  @media screen and (max-width: 991px) {
    .side-tabs__item.is-active .side-tabs__content {
      display: block; } }

.side-tabs .content-wrapper.animated .side-tabs__item .side-tabs__tab, .side-tabs .animated.app-sec__inner .side-tabs__item .side-tabs__tab, .side-tabs .animated.header__wrapper .side-tabs__item .side-tabs__tab, .side-tabs .animated.search-nores .side-tabs__item .side-tabs__tab, .side-tabs .animated.sec-intro__detail-wrapper .side-tabs__item .side-tabs__tab, .side-tabs .animated.submenu__wrapper .side-tabs__item .side-tabs__tab {
  opacity: 0; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item .side-tabs__tab {
  -webkit-animation: st-fadein 0.6s;
  animation: st-fadein 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(2) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(2) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(2) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(2) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(2) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(2) .side-tabs__tab {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(3) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(3) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(3) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(3) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(3) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(3) .side-tabs__tab {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(4) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(4) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(4) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(4) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(4) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(4) .side-tabs__tab {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(5) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(5) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(5) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(5) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(5) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(5) .side-tabs__tab {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(6) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(6) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(6) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(6) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(6) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(6) .side-tabs__tab {
  -webkit-animation-delay: 1s;
  animation-delay: 1s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(7) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(7) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(7) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(7) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(7) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(7) .side-tabs__tab {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(8) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(8) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(8) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(8) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(8) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(8) .side-tabs__tab {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(9) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(9) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(9) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(9) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(9) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(9) .side-tabs__tab {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s; }

.side-tabs .content-wrapper.animated.fadeIn .side-tabs__item:nth-child(10) .side-tabs__tab, .side-tabs .animated.fadeIn.app-sec__inner .side-tabs__item:nth-child(10) .side-tabs__tab, .side-tabs .animated.fadeIn.header__wrapper .side-tabs__item:nth-child(10) .side-tabs__tab, .side-tabs .animated.fadeIn.search-nores .side-tabs__item:nth-child(10) .side-tabs__tab, .side-tabs .animated.fadeIn.sec-intro__detail-wrapper .side-tabs__item:nth-child(10) .side-tabs__tab, .side-tabs .animated.fadeIn.submenu__wrapper .side-tabs__item:nth-child(10) .side-tabs__tab {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s; }

@-webkit-keyframes st-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes st-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.side-tabs__item-title {
  position: relative;
  color: #fb5004;
  font-weight: 300;
  margin: 0 0 15px; }
  @media screen and (max-width: 991px) {
    .side-tabs__item-title {
      display: none; } }

.side-tabs__tab {
  cursor: pointer;
  display: block;
  background: #242655;
  color: #FFF;
  padding: 30px 45px;
  border-bottom: 1px solid #FFF;
  width: 32%; }
  @media screen and (max-width: 1580px) {
    .side-tabs__tab {
      width: 35%; } }
  @media screen and (max-width: 1280px) {
    .side-tabs__tab {
      width: 40%; } }
  @media screen and (max-width: 991px) {
    .side-tabs__tab {
      width: 100%;
      padding: 30px; } }
  @media screen and (max-width: 380px) {
    .side-tabs__tab {
      padding: 20px; } }
  .side-tabs__tab:last-child {
    border-bottom: 0; }
  .side-tabs__tab:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .side-tabs__tab:hover {
    background: #fb5004;
    color: #FFF;
    opacity: 1; }
    .side-tabs__tab:hover:after {
      border-top: 2px solid #FFF;
      border-right: 2px solid #FFF; }
  .is-active .side-tabs__tab {
    background: #fb5004;
    color: #FFF;
    opacity: 1; }
    .is-active .side-tabs__tab:after {
      border-top: 2px solid #FFF;
      border-right: 2px solid #FFF; }
      @media screen and (min-width: 992px) {
        .is-active .side-tabs__tab:after {
          border-top: 2px solid #fb5004;
          border-right: 2px solid #fb5004; } }
    @media screen and (min-width: 992px) {
      .is-active .side-tabs__tab {
        background: #f5f5f5;
        color: #1a1a1a;
        font-weight: bold; } }

.side-tabs__content {
  position: absolute;
  display: block;
  width: 68%;
  left: 32%;
  top: 0;
  padding: 60px;
  background: #f5f5f5;
  -webkit-transform-origin: center top;
  -ms-transform-origin: center top;
  transform-origin: center top;
  transition: 0.3s; }
  @media screen and (min-width: 992px) {
    .side-tabs__content:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 10000px;
      left: 0;
      top: 0;
      z-index: 0;
      background: #f5f5f5; } }
  .side-tabs__content .side-tabs__item-title,
  .side-tabs__content .accordion {
    position: relative;
    z-index: 10; }
  @media screen and (max-width: 1580px) {
    .side-tabs__content {
      width: 65%;
      left: 35%; } }
  @media screen and (max-width: 1280px) {
    .side-tabs__content {
      width: 60%;
      left: 40%; } }
  @media screen and (max-width: 991px) {
    .side-tabs__content {
      position: relative;
      width: 100%;
      left: auto;
      top: auto;
      padding: 0; } }

/* END Style side-tabs  */
/* ------------------------------------------ */
/* Style for side-title */
/* ------------------------------------------ */
.side-title {
  position: absolute;
  left: 105%;
  bottom: 100%;
  padding: 4px 0;
  white-space: nowrap;
  text-indent: -9999px;
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: 0.4s;
  font-weight: 500; }
  .animated .side-title {
    -webkit-transform: translateY(-60px) rotate(90deg);
    -ms-transform: translateY(-60px) rotate(90deg);
    transform: translateY(-60px) rotate(90deg);
    opacity: 0; }
  .animated.fadeIn .side-title {
    -webkit-transform: translateX(0) rotate(90deg);
    -ms-transform: translateX(0) rotate(90deg);
    transform: translateX(0) rotate(90deg);
    opacity: 1; }
  .side-title:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #000;
    transition: 1s; }
    .animated .side-title:after {
      opacity: 0;
      -webkit-transform: scale(0, 1);
      -ms-transform: scale(0, 1);
      transform: scale(0, 1); }
    .animated.fadeIn .side-title:after {
      opacity: 1;
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      transform: scale(1, 1); }
  @media screen and (max-width: 991px) {
    .side-title {
      display: none; } }

/* END Style side-title  */
/* ------------------------------------------ */
/* Style for sitemap */
/* ------------------------------------------ */
.sitemap {
  position: relative; }
  @media screen and (max-width: 991px) {
    .sitemap {
      display: none; } }

.sitemap__row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.sitemap__col {
  display: inline-block;
  width: 20%;
  padding: 0 10px 0 30px; }
  @media screen and (max-width: 1200px) {
    .sitemap__col {
      padding: 0 15px; } }

.sitemap__head {
  display: block;
  margin-bottom: 15px;
  line-height: 1.3;
  font-weight: 900;
  /* PHR-53 */ }
  .sitemap__head strong {
    font-weight: 900;
    display: block; }
  .sitemap__head:hover {
    color: #fb5004; }
  :lang(zh-hk) .sitemap__head, :lang(zh-cn) .sitemap__head {
    font-size: 18px; }
    :lang(zh-hk) .sitemap__head strong, :lang(zh-cn) .sitemap__head strong {
      display: inline-block; }

.sitemap__link {
  display: block;
  margin-bottom: 8px; }
  .sitemap__link:hover {
    color: #fb5004; }

.sitemap__quicklinks {
  position: relative; }

.sitemap__quicklink {
  display: block;
  margin-bottom: 8px; }
  .sitemap__quicklink:hover {
    color: #fb5004; }

/* END Style sitemap  */
/* ------------------------------------------ */
/* Style for sitemappage */
/* ------------------------------------------ */
.sitemappage {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap; }
  .sitemappage__col {
    flex: 1 1 25%;
    align-self: flex-start;
    padding: 0 30px 30px 0;
    margin: 0; }
    @media screen and (max-width: 991px) {
      .sitemappage__col {
        flex-basis: 50%; } }
    @media screen and (max-width: 767px) {
      .sitemappage__col {
        flex-basis: 100%; } }
    .sitemappage__col-row {
      list-style: none;
      line-height: 1.5; }
      @media screen and (max-width: 1580px) {
        .sitemappage__col-row {
          font-size: 15px; } }
    .sitemappage__col-title,
    .sitemappage__col-title a {
      color: #fb5004;
      font-weight: bold;
      line-height: 1.15;
      font-size: 20px;
      margin-bottom: 10px; }
      @media screen and (max-width: 1580px) {
        .sitemappage__col-title,
        .sitemappage__col-title a {
          font-size: 18px; } }

/* END Style sitemappage  */
/* ------------------------------------------ */
/* Style for success-stories */
/* ------------------------------------------ */
.success-stories {
  position: relative;
  padding: 90px 0;
  background: url("./assets/images//common/img-home-successstory-bg.jpg") no-repeat center center;
  background-size: cover; }
  @media screen and (max-width: 768px) {
    .success-stories {
      padding: 45px 0; } }
  .success-stories .content-wrapper.animated:before, .success-stories .animated.app-sec__inner:before, .success-stories .animated.header__wrapper:before, .success-stories .animated.search-nores:before, .success-stories .animated.sec-intro__detail-wrapper:before, .success-stories .animated.submenu__wrapper:before, .success-stories .content-wrapper.animated:after, .success-stories .animated.app-sec__inner:after, .success-stories .animated.header__wrapper:after, .success-stories .animated.search-nores:after, .success-stories .animated.sec-intro__detail-wrapper:after, .success-stories .animated.submenu__wrapper:after {
    opacity: 0; }
  .success-stories .content-wrapper.animated.fadeIn, .success-stories .animated.fadeIn.app-sec__inner, .success-stories .animated.fadeIn.header__wrapper, .success-stories .animated.fadeIn.search-nores, .success-stories .animated.fadeIn.sec-intro__detail-wrapper, .success-stories .animated.fadeIn.submenu__wrapper {
    opacity: 1; }
    .success-stories .content-wrapper.animated.fadeIn:before, .success-stories .animated.fadeIn.app-sec__inner:before, .success-stories .animated.fadeIn.header__wrapper:before, .success-stories .animated.fadeIn.search-nores:before, .success-stories .animated.fadeIn.sec-intro__detail-wrapper:before, .success-stories .animated.fadeIn.submenu__wrapper:before {
      -webkit-animation: ss-quote-open 0.6s;
      animation: ss-quote-open 0.6s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }
    .success-stories .content-wrapper.animated.fadeIn:after, .success-stories .animated.fadeIn.app-sec__inner:after, .success-stories .animated.fadeIn.header__wrapper:after, .success-stories .animated.fadeIn.search-nores:after, .success-stories .animated.fadeIn.sec-intro__detail-wrapper:after, .success-stories .animated.fadeIn.submenu__wrapper:after {
      -webkit-animation: ss-quote-close 0.6s;
      animation: ss-quote-close 0.6s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      will-change: transform; }

@-webkit-keyframes ss-quote-open {
  0% {
    -webkit-transform: rotate(180deg) translate(45px, 45px);
    -ms-transform: rotate(180deg) translate(45px, 45px);
    transform: rotate(180deg) translate(45px, 45px);
    opacity: 0; }
  100% {
    -webkit-transform: rotate(180deg) translate(0, 0);
    -ms-transform: rotate(180deg) translate(0, 0);
    transform: rotate(180deg) translate(0, 0);
    opacity: 1; } }

@keyframes ss-quote-open {
  0% {
    -webkit-transform: rotate(180deg) translate(45px, 45px);
    -ms-transform: rotate(180deg) translate(45px, 45px);
    transform: rotate(180deg) translate(45px, 45px);
    opacity: 0; }
  100% {
    -webkit-transform: rotate(180deg) translate(0, 0);
    -ms-transform: rotate(180deg) translate(0, 0);
    transform: rotate(180deg) translate(0, 0);
    opacity: 1; } }

@-webkit-keyframes ss-quote-close {
  0% {
    -webkit-transform: translate(45px, 45px);
    -ms-transform: translate(45px, 45px);
    transform: translate(45px, 45px);
    opacity: 0; }
  100% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1; } }

@keyframes ss-quote-close {
  0% {
    -webkit-transform: translate(45px, 45px);
    -ms-transform: translate(45px, 45px);
    transform: translate(45px, 45px);
    opacity: 0; }
  100% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1; } }

.success-stories__title {
  position: relative;
  color: #fb5004;
  text-align: center;
  font-weight: 300;
  margin: 0 0 30px; }

.success-stories__slider {
  position: relative;
  overflow: hidden; }
  .success-stories .content-wrapper.animated .success-stories__slider, .success-stories .animated.app-sec__inner .success-stories__slider, .success-stories .animated.header__wrapper .success-stories__slider, .success-stories .animated.search-nores .success-stories__slider, .success-stories .animated.sec-intro__detail-wrapper .success-stories__slider, .success-stories .animated.submenu__wrapper .success-stories__slider {
    opacity: 0; }
  .success-stories .content-wrapper.animated.fadeIn .success-stories__slider, .success-stories .animated.fadeIn.app-sec__inner .success-stories__slider, .success-stories .animated.fadeIn.header__wrapper .success-stories__slider, .success-stories .animated.fadeIn.search-nores .success-stories__slider, .success-stories .animated.fadeIn.sec-intro__detail-wrapper .success-stories__slider, .success-stories .animated.fadeIn.submenu__wrapper .success-stories__slider {
    -webkit-animation: ss-fadein 0.6s 0.3s;
    animation: ss-fadein 0.6s 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes ss-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes ss-fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.success-stories__slide {
  font-size: 0; }

.success-stories__img {
  display: block;
  vertical-align: middle;
  width: 100%;
  margin: 0 auto; }
  .success-stories__img img {
    width: 100%; }
  @media screen and (max-width: 640px) {
    .success-stories__img {
      width: 100%;
      margin-bottom: 30px; } }

.success-stories__info {
  display: block;
  vertical-align: middle;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  opacity: 0; }
  @media screen and (max-width: 640px) {
    .success-stories__info {
      width: 100%;
      padding: 0; } }
  .swiper-slide-active .success-stories__info {
    -webkit-animation: ss-fadeleft 0.6s 0.2s;
    animation: ss-fadeleft 0.6s 0.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@-webkit-keyframes ss-fadeleft {
  0% {
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(-10%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes ss-fadeleft {
  0% {
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(-10%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }
  .swiper-slide-next .success-stories__info {
    -webkit-animation: ss-fadeleft 0.6s 0.2s;
    animation: ss-fadeleft 0.6s 0.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
  .swiper-slide-visible .success-stories__info {
    -webkit-animation: ss-fadeleft 0.6s 0.2s;
    animation: ss-fadeleft 0.6s 0.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

@media screen and (max-width: 640px) {
  .success-stories__more {
    margin: 30px auto; } }

.success-stories__info-title {
  font-weight: bold;
  margin: 0 0 10px; }

.success-stories__info-desc {
  line-height: 1.5em;
  margin: 0; }
  .success-stories__info-desc a {
    text-decoration: underline;
    color: #fb5004;
    margin-left: 5px;
    font-weight: bold; }
  @media screen and (max-width: 768px) {
    .success-stories__info-desc span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      max-height: 6em;
      -webkit-box-orient: vertical; } }

.success-stories__top {
  position: relative;
  padding: 0 60px; }

.success-stories__btn-prev,
.success-stories__btn-next {
  width: 36px;
  height: 36px;
  border: 1px solid #777;
  display: block;
  border-radius: 50%;
  position: absolute;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  top: 50%;
  margin-top: -18px;
  background: none;
  cursor: pointer; }
  .success-stories__btn-prev:after,
  .success-stories__btn-next:after {
    content: '';
    position: absolute;
    display: block;
    width: 7.2px;
    height: 7.2px;
    border-top: 1px solid #777;
    border-right: 1px solid #777;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.736px;
    margin-left: -5.4px; }

.success-stories__btn-prev {
  left: 0;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  font-size: 0;
  line-height: 0; }

.success-stories__btn-next {
  right: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 0;
  line-height: 0; }

.success-stories__bottom {
  position: relative; }

.success-stories__pagination {
  padding: 15px 0;
  text-align: center; }
  .success-stories .content-wrapper.animated .success-stories__pagination, .success-stories .animated.app-sec__inner .success-stories__pagination, .success-stories .animated.header__wrapper .success-stories__pagination, .success-stories .animated.search-nores .success-stories__pagination, .success-stories .animated.sec-intro__detail-wrapper .success-stories__pagination, .success-stories .animated.submenu__wrapper .success-stories__pagination {
    opacity: 0; }
  .success-stories .content-wrapper.animated.fadeIn .success-stories__pagination, .success-stories .animated.fadeIn.app-sec__inner .success-stories__pagination, .success-stories .animated.fadeIn.header__wrapper .success-stories__pagination, .success-stories .animated.fadeIn.search-nores .success-stories__pagination, .success-stories .animated.fadeIn.sec-intro__detail-wrapper .success-stories__pagination, .success-stories .animated.fadeIn.submenu__wrapper .success-stories__pagination {
    -webkit-animation: ss-fadein 0.6s 0.4s;
    animation: ss-fadein 0.6s 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }
  .success-stories__pagination .swiper-pagination-bullet {
    cursor: pointer;
    width: 15px;
    height: 3px;
    border: 0;
    border-radius: 0;
    margin: 0 3px; }
    .success-stories__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #fb5004; }

.success-stories__btn-row {
  margin-top: 30px;
  text-align: center; }
  .success-stories .content-wrapper.animated .success-stories__btn-row, .success-stories .animated.app-sec__inner .success-stories__btn-row, .success-stories .animated.header__wrapper .success-stories__btn-row, .success-stories .animated.search-nores .success-stories__btn-row, .success-stories .animated.sec-intro__detail-wrapper .success-stories__btn-row, .success-stories .animated.submenu__wrapper .success-stories__btn-row {
    opacity: 0; }
  .success-stories .content-wrapper.animated.fadeIn .success-stories__btn-row, .success-stories .animated.fadeIn.app-sec__inner .success-stories__btn-row, .success-stories .animated.fadeIn.header__wrapper .success-stories__btn-row, .success-stories .animated.fadeIn.search-nores .success-stories__btn-row, .success-stories .animated.fadeIn.sec-intro__detail-wrapper .success-stories__btn-row, .success-stories .animated.fadeIn.submenu__wrapper .success-stories__btn-row {
    -webkit-animation: ss-fadein 0.6s 0.6s;
    animation: ss-fadein 0.6s 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    will-change: transform; }

.success-stories__slider-pause {
  display: inline-block;
  position: absolute;
  right: 35px;
  bottom: 30px;
  z-index: 10; }
  .success-stories__slider-pause .pause-button {
    display: block;
    box-sizing: border-box;
    width: 0;
    height: 15px;
    cursor: pointer;
    border-color: transparent transparent transparent #1a1a1a;
    transition: 100ms all ease;
    cursor: pointer;
    border-style: double;
    border-width: 0px 0 0px 15px; }
  .success-stories__slider-pause.is-active .pause-button {
    border-style: solid;
    border-width: 8px 0 8px 12px; }

/* END Style success-stories  */
/* ------------------------------------------ */
/* Style for submenu */
/* ------------------------------------------ */
.submenu {
  display: none;
  position: absolute;
  overflow: hidden;
  width: 100vw;
  top: 100%;
  /*margin-top: 35px;*/
  left: calc(720px - 50vw);
  background: #FFF; }
  @media screen and (min-width: 992px) {
    .submenu {
      display: block;
      max-height: 0;
      pointer-events: none;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.08); }
      .nav-link.is-hover .submenu {
        pointer-events: all;
        max-height: 640px;
        transition: max-height 0.8s; } }
  @media screen and (max-width: 1529px) {
    .submenu {
      left: -3.1948%; } }
  @media screen and (max-width: 991px) {
    .submenu {
      position: relative;
      background: transparent;
      top: auto;
      left: auto;
      width: 100%; }
      .nav-link.is-open .submenu {
        display: block; } }

.submenu__wrapper {
  padding: 30px 45px;
  max-width: 1440px;
  border-top: 1px solid #e5e5e5;
  margin-top: 35px; }
  @media screen and (max-width: 991px) {
    .submenu__wrapper {
      border-top: 0;
      margin-top: 15px;
      padding: 15px 0; }
      .submenu__wrapper:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 50%;
        display: block;
        width: 60px;
        height: 1px;
        margin-left: -30px;
        background: #e5e5e5; } }

.submenu__body {
  position: relative;
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.submenu__intro {
  width: 33.3333%;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-size: 0;
  padding-right: 30px;
  -webkit-transform: translateX(-60px);
  -ms-transform: translateX(-60px);
  transform: translateX(-60px);
  transition: 0.4s 0.3s; }
  @media screen and (max-width: 991px) {
    .submenu__intro {
      display: none; } }
  @media screen and (min-width: 992px) {
    .submenu__intro {
      opacity: 0; }
      .nav-link.is-hover .submenu__intro {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0); } }

.submenu__intro-title {
  margin: 0 0 15px;
  font-weight: 900; }
  :lang(en) .submenu__intro-title {
    font-weight: 300; }
  .submenu__intro-title strong {
    font-weight: 900; }
    :lang(en) .submenu__intro-title strong {
      display: block; }
  .submenu__intro-title:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.submenu__intro-desc {
  line-height: 1.5; }

.submenu__intro-img {
  position: relative;
  width: 100%; }

.submenu__intro-btns .button, .submenu__intro-btns .feature-listing__btn, .submenu__intro-btns .feedback-panel__btn, .submenu__intro-btns .organic-listing__btn, .submenu__intro-btns .pagination__btn-prev,
.submenu__intro-btns .pagination__btn-next {
  margin-top: 15px; }

.submenu__intro-links a:first-child:before {
  content: '';
  display: block;
  width: 60px;
  height: 1px;
  margin-bottom: 30px;
  background: #fb5004; }

.submenu__items {
  width: 66.6666%;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  @media screen and (max-width: 991px) {
    .submenu__items {
      width: 100%; } }
  @media screen and (min-width: 992px) {
    .submenu__items {
      opacity: 0;
      -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
      transform: translateY(30px);
      transition: 0.4s 0.3s; }
      .nav-link.is-hover .submenu__items {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0); } }
  .submenu__items.submenu__items--4col {
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
    .submenu__items.submenu__items--4col .submenu__col {
      width: 25%;
      padding: 0 18px; }
      @media screen and (max-width: 1280px) {
        .submenu__items.submenu__items--4col .submenu__col {
          width: calc(100% / 3);
          padding: 0 30px; } }
      @media screen and (max-width: 991px) {
        .submenu__items.submenu__items--4col .submenu__col {
          width: 100%; } }

.submenu__col {
  width: 33.3333%;
  display: inline-block;
  vertical-align: middle;
  padding: 0 30px;
  margin: 20px 0; }
  @media screen and (max-width: 991px) {
    .submenu__col {
      padding: 0;
      width: 100%;
      text-align: center;
      margin: 5px 0; } }

.submenu__event {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  padding: 0 30px;
  margin: 20px 0; }
  @media screen and (max-width: 991px) {
    .submenu__event {
      display: none; } }
  @media screen and (max-width: 767px) {
    .submenu__event {
      padding: 0 15px; } }
  @media screen and (max-width: 480px) {
    .submenu__event {
      max-width: 420px;
      width: 100%;
      padding: 0; } }

.submenu__event-img {
  position: relative; }
  .submenu__event:hover .submenu__event-img {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }

.submenu__event-title {
  margin: 10px 0; }

.submenu__event-desc {
  line-height: 1.5; }

.submenu__event-info {
  position: relative;
  font-size: 0; }

.submenu__event-break {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  width: 1px;
  background: #1a1a1a;
  margin: 0 8px; }

.submenu__event-label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
  color: #fb5004;
  font-weight: bold;
  margin: 0; }

.submenu__event-date {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
  margin: 0; }

.submenu__event-mob {
  display: none; }
  @media screen and (max-width: 991px) {
    .submenu__event-mob {
      display: block; } }

.submenu__headlink {
  display: block;
  margin-bottom: 10px;
  font-weight: bold; }
  .submenu__headlink + .submenu__headlink {
    margin-top: 20px; }
  .submenu__headlink:hover {
    color: #fb5004; }

.submenu__head {
  display: block;
  margin-bottom: 10px;
  font-weight: bold; }

.submenu__link {
  display: block;
  margin-bottom: 10px; }
  .submenu__link:hover {
    color: #fb5004; }
  @media screen and (max-width: 991px) {
    .submenu__link {
      display: none; } }

.submenu__col-more {
  display: block;
  font-weight: bold; }
  @media screen and (max-width: 991px) {
    .submenu__col-more {
      display: none; } }
  .submenu__col-more:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.submenu__bar {
  padding: 0;
  text-align: right; }
  @media screen and (max-width: 991px) {
    .submenu__bar {
      text-align: center; } }

.submenu__more-link {
  font-weight: bold; }
  .submenu__more-link:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
    width: 0.4em;
    height: 0.4em;
    border-top: 2px solid #fb5004;
    border-right: 2px solid #fb5004;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

/* END Style submenu  */
/* ------------------------------------------ */
/* Style for top-bar */
/* ------------------------------------------ */
.top-bar {
  position: relative;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-align: right;
  font-size: 14px;
  z-index: 30; }
  .top-bar.top-bar--mob {
    width: 90%;
    margin: 60px auto 15px;
    text-align: center;
    display: none; }
    @media screen and (max-width: 991px) {
      .top-bar.top-bar--mob {
        display: block;
        white-space: normal; } }

.top-bar__holder {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 14px; }
  .top-bar__holder:after {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 15px;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #777; }
  .top-bar__holder:last-child {
    padding-right: 0; }
    .top-bar__holder:last-child:after {
      display: none; }

.top-bar__subholder {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 15px 20px;
  background: #FFF;
  box-shadow: 0px 3px 4px rgba(150, 150, 150, 0.5);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }
  .top-bar__holder:hover .top-bar__subholder {
    display: block; }
  @media screen and (max-width: 1480px) {
    .top-bar__subholder {
      right: -30px;
      left: auto;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0); } }
  .top-bar__subholder .button-share__title {
    display: none; }
  .top-bar__subholder .button-share {
    margin-right: 12px;
    margin-bottom: 0; }
    .top-bar__subholder .button-share:last-child {
      margin-right: 0; }

.top-bar__link {
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
  color: #777;
  font-size: 13px; }
  .top-bar__link:last-child {
    margin-right: 0; }
    @media screen and (max-width: 991px) {
      .top-bar__link:last-child {
        margin-top: 10px;
        margin-bottom: 10px; } }
  .top-bar__link span {
    display: inline-block;
    vertical-align: middle; }

.top-bar__txt {
  color: #777;
  display: inline-block;
  cursor: pointer; }
  .top-bar__txt:hover {
    color: #1a1a1a;
    opacity: 0.8; }

.top-bar__icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 17px;
  height: 17px;
  background: url("") no-repeat center center;
  background-size: 90%;
  opacity: .6; }
  .top-bar__icon:last-child {
    margin-right: 0; }
  .top-bar__icon.top-bar__icon--login {
    background-image: url("./assets/images/common/icons/svg/black/icon-profile.svg"); }
  .top-bar__icon.top-bar__icon--share {
    background-image: url("./assets/images/common/icons/svg/black/icon-share.svg"); }

.top-bar__lang {
  text-transform: uppercase; }

/* END Style top-bar  */
/* ------------------------------------------ */
/* Style for skip-link */
/* ------------------------------------------ */
.skip-link {
  position: relative;
  z-index: 1001; }

.skip-link__link {
  display: -moz-inline-stack;
  display: inline-block;
  margin: 0;
  max-height: 0;
  color: #FFF;
  position: absolute;
  top: -5000px;
  left: -5000px; }
  .skip-link__link:focus {
    max-height: 1920px;
    display: inline-block;
    padding: 15px;
    margin: 5px;
    outline: 3px solid #FFF;
    text-decoration: underline;
    left: 20px;
    top: 20px;
    background-color: #fb5004; }

/* END Style skip-link  */
/* ------------------------------------------ */
.previewDateInput_wrapper {
  z-index: 30000;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }
  .previewDateInput_wrapper input {
    width: auto; }
  .previewDateInput_wrapper button {
    cursor: pointer; }

/* Style for search-listing */
/* ------------------------------------------ */
.search-listing__icons {
  right: 0px;
  text-align: right;
  position: absolute; }
  @media screen and (max-width: 767px) {
    .search-listing__icons {
      bottom: 20px; } }

.search-listing__bookmarkRibbon {
  display: inline-block;
  height: 0;
  border-bottom: 20px solid #fb5004;
  border-top: 20px solid #fb5004;
  border-left: 20px solid transparent;
  padding-left: 20px; }
  .search-listing__bookmarkRibbon span {
    color: white;
    position: relative;
    text-align: right;
    right: 10px;
    font-size: 12px;
    top: -14px;
    line-height: 15px; }

.search-listing__sriLabel-gold, .search-listing__sriLabel-silver, .search-listing__sriLabel-bronze {
  background-repeat: no-repeat;
  width: 110px;
  height: 35px;
  right: 0;
  background-position: center right; }

.search-listing__sriLabel-gold {
  background-image: url("./assets/images/common/sri-icon/verified_l_gold.svg"); }
  :lang(zh-hk) .search-listing__sriLabel-gold {
    background-image: url("./assets/images/common/sri-icon/verified_l_gold_tc.svg"); }
  :lang(zh-cn) .search-listing__sriLabel-gold {
    background-image: url("./assets/images/common/sri-icon/verified_l_gold_sc.svg"); }

.search-listing__sriLabel-bronze {
  background-image: url("./assets/images/common/sri-icon/verified_l_bronze.svg"); }
  :lang(zh-hk) .search-listing__sriLabel-bronze {
    background-image: url("./assets/images/common/sri-icon/verified_l_bronze_tc.svg"); }
  :lang(zh-cn) .search-listing__sriLabel-bronze {
    background-image: url("./assets/images/common/sri-icon/verified_l_bronze_sc.svg"); }

.search-listing__sriLabel-silver {
  background-image: url("./assets/images/common/sri-icon/verified_l_silver.svg"); }
  :lang(zh-hk) .search-listing__sriLabel-silver {
    background-image: url("./assets/images/common/sri-icon/verified_l_silver_tc.svg"); }
  :lang(zh-cn) .search-listing__sriLabel-silver {
    background-image: url("./assets/images/common/sri-icon/verified_l_silver_sc.svg"); }

/* Style for page-items */
/* ------------------------------------------ */
.items-container {
  max-width: 1920px;
  margin: 0 auto 50px auto; }
  @media screen and (max-width: 1240px) {
    .items-container {
      width: 100%; } }

.items-row:last-of-type {
  border-bottom: 1px solid #d2d2d2; }

.items-row .row-container {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  .items-row .row-container.row-container--slider .item-container {
    padding: 120px 0; }
    @media screen and (max-width: 1240px) {
      .items-row .row-container.row-container--slider .item-container {
        padding: 80px 0; } }
    @media screen and (max-width: 767px) {
      .items-row .row-container.row-container--slider .item-container {
        padding: 45px 0; } }
  @media screen and (max-width: 1240px) {
    .items-row .row-container.row-container--slider {
      height: auto; } }
  @media screen and (max-width: 767px) {
    .items-row .row-container {
      height: auto; } }
  .items-row .row-container > .item-container {
    padding: 80px 0;
    float: inherit;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .items-row .row-container > .item-container.item-container--bg {
      top: 0;
      left: 0;
      background: no-repeat center center;
      background-size: cover; }
      .items-row .row-container > .item-container.item-container--bg.item-container--bg--x-100 {
        background-position-x: 100%; }
      .items-row .row-container > .item-container.item-container--bg.item-container--bg--y-100 {
        background-position-y: 100%; }
      .items-row .row-container > .item-container.item-container--bg.item-container--bg--x-0 {
        background-position-x: 0%; }
      .items-row .row-container > .item-container.item-container--bg.item-container--bg--y-0 {
        background-position-y: 0%; }
    @media screen and (max-width: 767px) {
      .items-row .row-container > .item-container {
        padding: 45px 0; }
        .items-row .row-container > .item-container:nth-of-type(n) .item-content {
          left: initial;
          text-align: center; } }

@media screen and (max-width: 767px) {
  .item-content {
    position: relative; } }

.item-content .side-title--white {
  color: #ffffff; }
  .item-content .side-title--white:after {
    background: #ffffff; }

.item-content .side-title--orange {
  color: #fb5004; }
  .item-content .side-title--orange:after {
    background: #fb5004; }

.item-content .item-icon {
  margin-bottom: 10px; }
  .item-content .item-icon img {
    max-width: 240px; }

.item-content .item-content__title--white {
  color: #ffffff; }

.item-content .item-content__title--orange {
  color: #fb5004; }

.item-content .item-content__title {
  margin-bottom: 10px;
  font-weight: 200; }

.item-content .item-content__desc--white {
  color: #ffffff; }

.item-content .item-content__desc--orange {
  color: #fb5004; }

.item-content .item-content__desc {
  line-height: 25px;
  text-align: left;
  margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    .item-content .item-content__desc {
      margin-bottom: 10px; } }

.item-content .item-tips .item-tips__title--white {
  color: #ffffff; }

.item-content .item-tips .item-tips__title--orange {
  color: #fb5004; }

.item-content .item-tips .item-tips__title {
  font-weight: 300;
  padding-bottom: 5px; }
  .item-content .item-tips .item-tips__title:after {
    content: ":"; }

.item-content .item-tips .item-tips__desc {
  white-space: pre-line;
  line-height: 25px;
  text-align: left; }

.item-links .item-links__title--white {
  color: #ffffff; }

.item-links .item-links__title--orange {
  color: #fb5004; }

.item-links .item-links__title {
  margin-bottom: 8px;
  font-weight: 600; }

.item-links .item-links-content--col {
  left: 0;
  padding-top: 20px;
  column-count: 1;
  column-fill: balance;
  overflow: hidden;
  column-rule: 0.5px solid;
  column-rule-color: transparent;
  z-index: inherit;
  list-style-type: none;
  padding-top: 0;
  padding-left: 0;
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .item-links .item-links-content--col {
      column-count: 1; } }

.item-links .item-links-content--cols {
  left: 0;
  padding-top: 20px;
  column-count: 2;
  column-fill: balance;
  overflow: hidden;
  column-rule: 0.5px solid;
  column-rule-color: transparent;
  z-index: inherit;
  list-style-type: none;
  padding-top: 0;
  padding-left: 0;
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .item-links .item-links-content--cols {
      column-count: 1; } }

.item-links .item-links-content li {
  margin-bottom: 5px;
  text-align: left; }
  .item-links .item-links-content li a:after {
    content: ">";
    padding-left: 5px; }

.item-links__more {
  text-align: left; }
  .item-links__more.item-links__more--white a {
    color: #ffffff; }
  .item-links__more.item-links__more--orange a {
    color: #fb5004; }

.item-arrow {
  margin-top: 15px; }
  @media screen and (max-width: 767px) {
    .item-arrow {
      margin-top: 5px; } }
  .item-arrow .item-arrow__link--white:before {
    content: '';
    width: 50px;
    height: 50px;
    display: block;
    background: no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/white/icon-long-arrow.svg");
    background-size: 100%; }
    @media screen and (max-width: 767px) {
      .item-arrow .item-arrow__link--white:before {
        height: 30px; } }
  .item-arrow .item-arrow__link--orange:before {
    content: '';
    width: 50px;
    height: 50px;
    display: block;
    background: no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/orange/icon-long-arrow.svg");
    background-size: 100%; }
    @media screen and (max-width: 767px) {
      .item-arrow .item-arrow__link--orange:before {
        height: 30px; } }
  .item-arrow a {
    display: block; }

.item-more {
  display: inline-block;
  margin-top: 25px; }
  @media screen and (max-width: 767px) {
    .item-more {
      margin-top: 10px; } }

.items-row--full .item-content {
  width: 86%;
  position: relative;
  max-width: 1440px; }
  .items-row--full .item-content .side-title {
    left: 98%;
    margin-bottom: 30px;
    text-indent: 0; }
    @media screen and (max-width: 1240px) {
      .items-row--full .item-content .side-title {
        margin-bottom: 50px; } }
  .items-row--full .item-content.item--full__links--col .item-icon {
    width: 30%; }
    @media screen and (max-width: 1240px) {
      .items-row--full .item-content.item--full__links--col .item-icon {
        width: 45%; } }
    @media screen and (max-width: 768px) {
      .items-row--full .item-content.item--full__links--col .item-icon {
        width: initial; } }
  .items-row--full .item-content.item--full__links--col .item-content__title {
    width: 30%; }
    @media screen and (max-width: 1240px) {
      .items-row--full .item-content.item--full__links--col .item-content__title {
        width: 45%; } }
    @media screen and (max-width: 768px) {
      .items-row--full .item-content.item--full__links--col .item-content__title {
        width: initial; } }
  .items-row--full .item-content.item--full__child--cols .item-icon {
    width: initial; }
  .items-row--full .item-content.item--full__child--cols .item-content__title {
    width: initial; }
  .items-row--full .item-content.item--full__links--cols .item-content__desc {
    width: 45%; }
    @media screen and (max-width: 767px) {
      .items-row--full .item-content.item--full__links--cols .item-content__desc {
        width: 100%; } }
  .items-row--full .item-content.item--full__slider--cols .side-title {
    margin-bottom: 60px; }
  .items-row--full .item-content.item--full__slider--cols .item-content__desc {
    width: 45%; }
  @media screen and (max-width: 1240px) {
    .items-row--full .item-content.item--full__slider--cols {
      position: relative;
      transform: translate(4%, 0%);
      left: initial; }
      .items-row--full .item-content.item--full__slider--cols .side-title {
        margin-bottom: -25px; }
      .items-row--full .item-content.item--full__slider--cols .item-content__desc {
        width: 95%; } }
  .items-row--full .item-content.item--search-bar .item-links {
    top: 80%; }

@media screen and (max-width: 767px) {
  .items-row--full .item-content__desc {
    width: 100%; } }

.items-row--full .business-item .item-content__left, .items-row--full .insights-item .item-content__left {
  width: 55%; }
  @media screen and (max-width: 767px) {
    .items-row--full .business-item .item-content__left, .items-row--full .insights-item .item-content__left {
      width: 100%; } }

.items-row--full .business-item .item-content__right, .items-row--full .insights-item .item-content__right {
  width: 40%; }
  @media screen and (max-width: 767px) {
    .items-row--full .business-item .item-content__right, .items-row--full .insights-item .item-content__right {
      width: 100%; } }

.items-row--full .bnr-item .item-content__left {
  width: 60%;
  margin-right: 5%; }
  @media screen and (max-width: 1240px) {
    .items-row--full .bnr-item .item-content__left {
      width: 50%; } }
  @media screen and (max-width: 767px) {
    .items-row--full .bnr-item .item-content__left {
      width: 100%; } }
  .items-row--full .bnr-item .item-content__left .item-content__sub1 {
    width: 60%; }
    @media screen and (max-width: 1240px) {
      .items-row--full .bnr-item .item-content__left .item-content__sub1 {
        width: 100%; } }
  .items-row--full .bnr-item .item-content__left .item-content__sub2 {
    width: 40%;
    padding: 100px 5% 0px 5%;
    vertical-align: top; }
    @media screen and (max-width: 1240px) {
      .items-row--full .bnr-item .item-content__left .item-content__sub2 {
        width: 100%;
        padding: 10px 0 0 0; } }
  .items-row--full .bnr-item .item-content__left .item-content__sub1, .items-row--full .bnr-item .item-content__left .item-content__sub2 {
    display: inline-block; }
    @media screen and (max-width: 1240px) {
      .items-row--full .bnr-item .item-content__left .item-content__sub1, .items-row--full .bnr-item .item-content__left .item-content__sub2 {
        display: block; } }

.items-row--full .bnr-item .item-content__right {
  width: 30%; }
  @media screen and (max-width: 1240px) {
    .items-row--full .bnr-item .item-content__right {
      width: 45%; } }
  @media screen and (max-width: 767px) {
    .items-row--full .bnr-item .item-content__right {
      width: 100%; } }

@media screen and (max-width: 767px) {
  .items-row--full .item-content {
    width: 70%; } }

.items-row--full .item-content__left, .items-row--full .item-content__right {
  display: inline-block;
  vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .items-row--full .item-content__left, .items-row--full .item-content__right {
      display: block; } }

.items-row--full .item-content__left {
  margin-right: 5%;
  width: 45%; }
  @media screen and (max-width: 767px) {
    .items-row--full .item-content__left {
      width: 100%; } }

.items-row--full .item-content__right {
  width: 50%; }
  @media screen and (max-width: 767px) {
    .items-row--full .item-content__right {
      margin: 0px;
      width: 100%; } }
  .items-row--full .item-content__right-item-links-row .item-links {
    display: inline-block;
    width: 50%;
    vertical-align: top; }
    @media screen and (max-width: 767px) {
      .items-row--full .item-content__right-item-links-row .item-links {
        display: block;
        width: 100%; } }
  .items-row--full .item-content__right-item-links-row .item-links-content--col {
    -webkit-column-break-inside: avoid;
    /* Chrome, Safari */
    page-break-inside: avoid;
    /* Theoretically FF 20+ */
    break-inside: avoid-column;
    /* IE 11 */ }
  @media screen and (max-width: 767px) {
    .items-row--full .item-content__right-item-links-row .upcoming {
      text-align: center; }
      .items-row--full .item-content__right-item-links-row .upcoming .item-links__link {
        display: block; } }
  .items-row--full .item-content__right-item-links-row .upcoming img {
    display: block;
    margin-bottom: 5px;
    max-width: 160px; }
    @media screen and (max-width: 767px) {
      .items-row--full .item-content__right-item-links-row .upcoming img {
        margin: 0 auto 5px; } }
  .items-row--full .item-content__right-item-links-row .item-links__mobileonly {
    display: none; }
    @media screen and (max-width: 767px) {
      .items-row--full .item-content__right-item-links-row .item-links__mobileonly {
        display: block; } }
  .items-row--full .item-content__right-item-links-row .item-links__desktopOnly {
    display: block; }
    @media screen and (max-width: 767px) {
      .items-row--full .item-content__right-item-links-row .item-links__desktopOnly {
        display: none; } }

@media screen and (max-width: 767px) {
  .items-row--cols .row-container {
    display: block; } }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(1) {
  width: 100%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(2),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(2) ~ div {
  width: 50%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(3),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(3) ~ div {
  width: 33.33333%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(4),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(4) ~ div {
  width: 25%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(5),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(5) ~ div {
  width: 20%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(6),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(6) ~ div {
  width: 16.66667%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(7),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(7) ~ div {
  width: 14.28571%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(8),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(8) ~ div {
  width: 12.5%; }

.items-row--cols .row-container > .item-container:first-child:nth-last-child(9),
.items-row--cols .row-container > .item-container:first-child:nth-last-child(9) ~ div {
  width: 11.11111%; }

@media screen and (max-width: 767px) {
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(2),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(2) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(3),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(3) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(4),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(4) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(5),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(5) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(6),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(6) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(7),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(7) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(8),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(8) ~ div {
    width: 100%; }
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(9),
  .items-row--cols .row-container > .item-container:first-child:nth-last-child(9) ~ div {
    width: 100%; } }

@media screen and (max-width: 767px) {
  .items-row--cols .row-container > .item-container:nth-of-type(n) .item-content {
    left: initial;
    text-align: center; } }

.items-row--cols .row-container > .item-container .item-content {
  width: 71.5%;
  height: auto;
  overflow: hidden;
  top: 50%;
  margin: 0px auto; }

@media screen and (min-width: 1680px) {
  .items-row--cols .row-container > .item-container:first-child .item-content {
    margin-left: calc(100% - 720px);
    padding-right: 72px; }
  .items-row--cols .row-container > .item-container:last-child .item-content {
    margin-right: calc(100% - 720px);
    padding-left: 72px; } }

/* END Style page-items  */
/* ------------------------------------------ */
/* Style for page-ads */
/* ------------------------------------------ */
.sec-page-ads .ads-container {
  width: 80%;
  margin: 50px auto; }
  .sec-page-ads .ads-container .ad-container {
    text-align: center; }

.sec-page-ads .ad-container a:hover {
  opacity: 1; }

/* END Style page-ads  */
/* ------------------------------------------ */
/* Style for .page-item-search */
/* ------------------------------------------ */
.item-search__input-holder {
  display: block;
  position: relative;
  width: 100%;
  max-width: 720px;
  margin: -30px auto 50px;
  /*border-radius: $fieldSize/2;*/
  border: 0; }
  @media screen and (max-width: 767px) {
    .item-search__input-holder {
      margin-top: 0; } }

.item-search__input-Error {
  color: black;
  font-size: 12px;
  padding: 10px 25px;
  display: block;
  font-weight: bold;
  text-align: left; }
  @media screen and (max-width: 767px) {
    .item-search__input-Error {
      padding-left: 15px;
      padding-right: 15px; } }

.item-search__input {
  position: relative;
  width: 100%;
  height: 65px;
  border: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  font-weight: bold;
  color: #000;
  padding-left: 25px;
  padding-right: 60px; }
  .item-search__input::placeholder {
    color: #fb5004;
    opacity: 1; }
  .item-search__input:-ms-input-placeholder {
    color: #fb5004 !important;
    /* IE11 needs the !important flag */ }
  .item-search__input::-webkit-search-cancel-button {
    display: none; }
  .item-search__input:focus {
    outline: 2px solid #bbb; }
  .item-search__input + .item-search__autocomplete {
    display: none; }
  .item-search__input:focus + .item-search__autocomplete,
  .item-search__input + .item-search__autocomplete:focus,
  .item-search__input + .item-search__autocomplete:hover {
    display: block; }
  @media screen and (max-width: 767px) {
    .item-search__input {
      height: 50px;
      padding-left: 15px;
      padding-right: 50px; } }
  @media screen and (max-width: 400px) {
    .item-search__input {
      font-size: 15px; } }

.item-search__input-btn {
  position: absolute;
  width: 100px;
  height: 65px;
  right: -25px;
  bottom: 0;
  background: transparent;
  border: 0;
  cursor: pointer; }
  .item-search__input-btn:before {
    content: '';
    width: 24px;
    height: 24px;
    background: url("./assets/images/common/icons/svg/orange/icon-search.svg") no-repeat center center;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .item-search__input-btn {
      height: 50px; } }

.item-search__topsearch {
  margin-top: 5px;
  margin-bottom: 50px;
  font-size: 12px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .item-search__topsearch {
      margin-top: 10px; } }

.item-search__topsearch-title {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-weight: 700;
  margin: 0;
  margin-right: 25px; }

.item-search__topsearch-item {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-right: 15px;
  color: #fff; }

.item-search__panel {
  width: 92%; }
  @media screen and (max-width: 1023px) {
    .item-search__panel {
      width: 100%; } }

.item-search__filters {
  margin-bottom: 15px;
  font-size: 0;
  text-align: center; }

.item-search__filter {
  display: inline-block;
  margin-right: 45px; }
  .item-search__filter:last-child {
    margin-right: 0; }
  .item-search__filter input {
    display: none; }
    .item-search__filter input + label {
      position: relative;
      display: block;
      cursor: pointer;
      font-size: 17px;
      color: #fff; }
    .item-search__filter input:checked + label {
      font-weight: bold;
      padding: 3px 0; }
      .item-search__filter input:checked + label:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #fff; }
  @media screen and (max-width: 767px) {
    .item-search__filter {
      margin-bottom: 10px;
      margin-right: 35px; } }

/* END Style .page-item-search  */
/* ------------------------------------------ */
/* Style for .page-item-search */
/* ------------------------------------------ */
.item-content.item--full__child--col > .item-content__desc {
  width: 30%; }
  @media screen and (max-width: 1240px) {
    .item-content.item--full__child--col > .item-content__desc {
      width: 45%; } }
  @media screen and (max-width: 767px) {
    .item-content.item--full__child--col > .item-content__desc {
      width: 100%; } }

.item-content.item--full__child--col > .item-links {
  left: 40%;
  transform: translate(0%, -50%); }
  @media screen and (max-width: 1240px) {
    .item-content.item--full__child--col > .item-links {
      position: initial;
      right: initial;
      top: initial;
      transform: translate(0%, 0%); } }

@media screen and (max-width: 767px) {
  .item-content.item--full__child--col .item-arrow .item-arrow__link {
    display: inline-block; } }

.item-content.item--full__child--cols .item-icon {
  text-align: center; }

.item-content.item--full__child--cols .item-content__title {
  text-align: center; }

.item-content.item--full__child--cols .item-content__desc {
  width: 100%;
  text-align: center; }

.child-item-container {
  width: 360px; }
  @media screen and (max-width: 1580px) {
    .child-item-container {
      width: 320px; } }
  @media screen and (max-width: 1240px) {
    .child-item-container {
      width: 260px; } }
  @media screen and (max-width: 991px) {
    .child-item-container {
      width: 200px; } }
  @media screen and (max-width: 767px) {
    .child-item-container {
      width: 360px; } }
  @media screen and (max-width: 480px) {
    .child-item-container {
      width: 240px; } }
  .child-item-container .child-item__img {
    overflow: hidden;
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 1580px) {
      .child-item-container .child-item__img {
        height: 160px; } }
    @media screen and (max-width: 1240px) {
      .child-item-container .child-item__img {
        height: 130px; } }
    @media screen and (max-width: 991px) {
      .child-item-container .child-item__img {
        height: 100px; } }
    @media screen and (max-width: 767px) {
      .child-item-container .child-item__img {
        height: 180px; } }
    @media screen and (max-width: 480px) {
      .child-item-container .child-item__img {
        height: 120px; } }
    .child-item-container .child-item__img > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%; }
      .child-item-container .child-item__img > a > img {
        max-width: 100%;
        max-height: 100%; }
    .child-item-container .child-item__img > img {
      max-width: 100%;
      max-height: 100%; }
    .item-child-item--cols .child-item-container .child-item-container .child-item__img > img {
      max-width: 100%;
      max-height: 100%; }
    .child-item-container .child-item__img a:hover {
      opacity: 1; }
    .child-item-container .child-item__img.fillImg a {
      height: 180px;
      width: 360px;
      overflow: hidden;
      position: relative; }
      @media screen and (max-width: 1580px) {
        .child-item-container .child-item__img.fillImg a {
          width: 320px; } }
      @media screen and (max-width: 1240px) {
        .child-item-container .child-item__img.fillImg a {
          width: 260px; } }
      @media screen and (max-width: 991px) {
        .child-item-container .child-item__img.fillImg a {
          width: 200px; } }
      @media screen and (max-width: 767px) {
        .child-item-container .child-item__img.fillImg a {
          width: 360px; } }
      @media screen and (max-width: 480px) {
        .child-item-container .child-item__img.fillImg a {
          width: 240px; } }
      @media screen and (max-width: 1580px) {
        .child-item-container .child-item__img.fillImg a {
          height: 160px; } }
      @media screen and (max-width: 1240px) {
        .child-item-container .child-item__img.fillImg a {
          height: 130px; } }
      @media screen and (max-width: 991px) {
        .child-item-container .child-item__img.fillImg a {
          height: 100px; } }
      @media screen and (max-width: 767px) {
        .child-item-container .child-item__img.fillImg a {
          height: 180px; } }
      @media screen and (max-width: 480px) {
        .child-item-container .child-item__img.fillImg a {
          height: 120px; } }
      .child-item-container .child-item__img.fillImg a img {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        max-width: none;
        max-height: none;
        left: 0; }
        .child-item-container .child-item__img.fillImg a img.landscape {
          width: auto;
          height: 100%;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%); }
  .child-item-container .child-item__title {
    font-weight: 300;
    margin: 10px 0;
    line-height: 1.5em; }
    .child-item-container .child-item__title a:hover {
      opacity: 1; }
  .child-item-container .child-item__title--white {
    color: #ffffff; }
  .child-item-container .child-item__title--orange {
    color: #fb5004; }

.child-item-content .child-item__desc--white {
  color: #ffffff; }

.child-item-content .child-item__desc--orange {
  color: #fb5004; }

.child-item-content .child-item__link-container {
  text-align: left; }
  @media screen and (max-width: 767px) {
    .child-item-content .child-item__link-container {
      text-align: center; } }

.child-item-content * {
  display: inline; }

.child-item-arrow {
  margin-top: 15px; }
  .child-item-arrow .child-item-arrow__link--white:before {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    background: no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/white/icon-long-arrow.svg");
    background-size: 100%; }
    @media screen and (max-width: 767px) {
      .child-item-arrow .child-item-arrow__link--white:before {
        margin: 0 auto; } }
  .child-item-arrow .child-item-arrow__link--orange:before {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    background: no-repeat center center;
    background-image: url("./assets/images/common/icons/svg/orange/icon-long-arrow.svg");
    background-size: 100%; }
    @media screen and (max-width: 767px) {
      .child-item-arrow .child-item-arrow__link--orange:before {
        margin: 0 auto; } }

.child-item__link {
  text-decoration: underline;
  margin-left: 5px;
  font-weight: bold;
  font-size: 14px; }
  .child-item__link--orange {
    color: #fb5004; }
  .child-item__link--white {
    color: #FFF; }

.child-more-item {
  text-align: center; }
  .child-more-item .child-more-item-text__link--white {
    color: #ffffff;
    border: 1px solid #ffffff;
    padding: 12px 35px;
    border-radius: 20px; }
  .child-more-item .child-more-item-text__link--orange {
    color: #fb5004;
    border: 1px solid #fb5004;
    padding: 12px 35px;
    border-radius: 20px; }

.item-child-item--col {
  position: relative; }
  .item-child-item--col .child-item-container:before {
    content: '';
    position: absolute;
    left: -50px;
    width: 1px;
    height: 100%;
    background: #efefef;
    margin-right: 200px; }
  @media screen and (max-width: 767px) {
    .item-child-item--col .child-item-container {
      width: 100%; } }
  @media screen and (max-width: 1240px) {
    .item-child-item--col .child-item-container:before {
      content: none; } }
  .item-child-item--col .child-item-content .child-item__link {
    margin-top: 10px; }

.item-child-item--cols .child-items-container {
  margin: 35px auto 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 1240px) {
    .item-child-item--cols .child-items-container {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .item-child-item--cols .child-items-container {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; } }

.item-child-item--cols .child-item-container {
  margin: 0 30px;
  padding-bottom: 30px;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch; }
  @media screen and (max-width: 991px) {
    .item-child-item--cols .child-item-container {
      margin: 0 15px; } }
  .item-child-item--cols .child-item-container:first-child {
    margin: 0; }
  .item-child-item--cols .child-item-container:last-child {
    margin: 0; }
  @media screen and (max-width: 767px) {
    .item-child-item--cols .child-item-container {
      margin: 0 auto; } }
  @media screen and (max-width: 767px) {
    .item-child-item--cols .child-item-container {
      margin: 0; }
      .item-child-item--cols .child-item-container:first-child:nth-last-child(2),
      .item-child-item--cols .child-item-container:first-child:nth-last-child(2) ~ div {
        width: 100%;
        margin-bottom: 10px; }
      .item-child-item--cols .child-item-container:first-child:nth-last-child(3),
      .item-child-item--cols .child-item-container:first-child:nth-last-child(3) ~ div {
        width: 100%;
        margin-bottom: 10px; }
      .item-child-item--cols .child-item-container:first-child:nth-last-child(4),
      .item-child-item--cols .child-item-container:first-child:nth-last-child(4) ~ div {
        width: 100%;
        margin-bottom: 10px; }
      .item-child-item--cols .child-item-container:first-child:nth-last-child(5),
      .item-child-item--cols .child-item-container:first-child:nth-last-child(5) ~ div {
        width: 100%;
        margin-bottom: 10px; }
      .item-child-item--cols .child-item-container:first-child:nth-last-child(6),
      .item-child-item--cols .child-item-container:first-child:nth-last-child(6) ~ div {
        width: 100%;
        margin-bottom: 10px; }
      .item-child-item--cols .child-item-container:first-child:nth-last-child(7),
      .item-child-item--cols .child-item-container:first-child:nth-last-child(7) ~ div {
        width: 100%;
        margin-bottom: 10px; }
      .item-child-item--cols .child-item-container:first-child:nth-last-child(8),
      .item-child-item--cols .child-item-container:first-child:nth-last-child(8) ~ div {
        width: 100%;
        margin-bottom: 10px; }
      .item-child-item--cols .child-item-container:first-child:nth-last-child(9),
      .item-child-item--cols .child-item-container:first-child:nth-last-child(9) ~ div {
        width: 100%;
        margin-bottom: 10px; } }
  .item-child-item--cols .child-item-container .child-item-arrow {
    position: absolute;
    bottom: 0; }
    @media screen and (max-width: 767px) {
      .item-child-item--cols .child-item-container .child-item-arrow {
        left: 46%; } }

/* END Style .page-item-search  */
/* ------------------------------------------ */
/* Style for .page-item-slider */
/* ------------------------------------------ */
.item-sliders {
  position: relative; }
  .item-sliders.item-sliders--product-mags {
    width: 35%;
    height: 370px;
    position: absolute;
    left: 54%;
    top: -35px;
    bottom: 0; }
  @media screen and (max-width: 1240px) {
    .item-sliders {
      margin-top: 35px; }
      .item-sliders.item-sliders--product-mags {
        position: relative;
        left: 0;
        top: 0;
        width: 560px;
        margin-left: 36px; } }
  @media screen and (max-width: 767px) {
    .item-sliders {
      text-align: initial; }
      .item-sliders.item-sliders--product-mags {
        margin-left: 0px;
        width: 100%;
        height: 340px; } }
  .item-sliders .item-sliders-container {
    width: 100%;
    height: 100%;
    overflow: hidden; }
    @media screen and (max-width: 1240px) {
      .item-sliders .item-sliders-container {
        position: static;
        left: 0;
        top: 0;
        width: 90%;
        margin: 0 auto; }
        .item-sliders .item-sliders-container .item-slider-container .item-slider-text {
          max-width: initial; } }
    @media screen and (max-width: 767px) {
      .item-sliders .item-sliders-container {
        width: 100%; } }
  .item-sliders .item-slider-container {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .item-sliders .item-slider-container .item-slider__img {
      display: inline-block;
      margin-right: 10px; }
      @media screen and (max-width: 480px) {
        .item-sliders .item-slider-container .item-slider__img {
          margin-right: 0px; } }
    @media screen and (max-width: 480px) {
      .item-sliders .item-slider-container {
        text-align: center; } }
    .item-sliders .item-slider-container .item-slider-text {
      margin-left: 0px;
      display: inline-block;
      max-width: 180px;
      margin-top: 40px;
      transform: translate(0%, -50%); }
      .item-sliders .item-slider-container .item-slider-text .item-slider__title--white {
        color: #ffffff; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__desc--white {
        color: #ffffff; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__href--white {
        color: #ffffff; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__title--orange {
        color: #fb5004; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__desc--orange {
        color: #fb5004; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__href--orange {
        color: #fb5004; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__title {
        margin-bottom: 20px;
        font-weight: 300; }
      .item-sliders .item-slider-container .item-slider-text .item-slider__desc {
        margin-bottom: 10px; }
        @media screen and (max-width: 480px) {
          .item-sliders .item-slider-container .item-slider-text .item-slider__desc {
            text-align: left; } }
      .item-sliders .item-slider-container .item-slider-text .item-slider__href {
        border-bottom: 1px solid #fff; }
      @media screen and (max-width: 767px) {
        .item-sliders .item-slider-container .item-slider-text {
          margin-top: 15px; } }
      @media screen and (max-width: 480px) {
        .item-sliders .item-slider-container .item-slider-text {
          position: initial;
          transform: translate(0%, 0%); } }
  .item-sliders .item-slider__btn {
    width: 36px;
    height: 36px;
    border: 1px solid #fff;
    display: block;
    border-radius: 50%;
    position: absolute;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    top: 35%;
    margin-top: -18px;
    background: none;
    cursor: pointer; }
    @media screen and (max-width: 1240px) {
      .item-sliders .item-slider__btn {
        top: 40%; } }
    .item-sliders .item-slider__btn--prev {
      left: -60px;
      transform: rotate(225deg); }
      .item-sliders .item-slider__btn--prev:after {
        content: '';
        position: absolute;
        display: block;
        width: 7.2px;
        height: 7.2px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -2.736px;
        margin-left: -5.4px; }
      @media screen and (max-width: 1240px) {
        .item-sliders .item-slider__btn--prev {
          left: -25px; } }
    .item-sliders .item-slider__btn--next {
      right: -60px;
      transform: rotate(45deg); }
      .item-sliders .item-slider__btn--next:after {
        content: '';
        position: absolute;
        display: block;
        width: 7.2px;
        height: 7.2px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -2.736px;
        margin-left: -5.4px; }
      @media screen and (max-width: 1240px) {
        .item-sliders .item-slider__btn--next {
          right: -25px; } }
  .item-sliders .item-slider__pagination {
    text-align: center;
    position: absolute;
    bottom: 0px; }
    .item-sliders .item-slider__pagination .swiper-pagination-bullet {
      cursor: pointer;
      width: 20px;
      height: 3px;
      border: 0;
      border-radius: 0;
      margin: 0 3px;
      background: #fff;
      opacity: 1;
      vertical-align: top; }
      .item-sliders .item-slider__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #fb5004;
        height: 4px; }
    @media screen and (max-width: 480px) {
      .item-sliders .item-slider__pagination {
        margin-top: 35px; } }

.item-sliders__slider-pause {
  display: inline-block;
  position: absolute;
  right: -10px;
  bottom: 10px;
  z-index: 10; }
  @media screen and (max-width: 480px) {
    .item-sliders__slider-pause {
      right: -20px;
      bottom: -27px; } }
  .item-sliders__slider-pause .pause-button {
    display: block;
    box-sizing: border-box;
    width: 0;
    height: 15px;
    cursor: pointer;
    border-color: transparent transparent transparent #fff;
    transition: 100ms all ease;
    cursor: pointer;
    border-style: double;
    border-width: 0px 0 0px 15px; }
  .item-sliders__slider-pause.is-active .pause-button {
    border-style: solid;
    border-width: 8px 0 8px 12px; }

/* END Style .page-item-slider  */
/* ------------------------------------------ */
/* Style for .page-success-story */
/* ------------------------------------------ */
.sec-page-story {
  max-width: 1920px;
  margin: 0 auto; }

.story-filter {
  background-color: #242655;
  text-align: center; }

.story-filter__title {
  font-weight: 400;
  display: inline-block;
  color: #fff;
  padding: 25px 60px;
  border-right: 1px solid rgba(255, 255, 255, 0.2); }
  @media screen and (max-width: 767px) {
    .story-filter__title {
      display: block; } }

.story-filter-condList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  margin: 20px 0;
  cursor: pointer; }

.story-filter-cat-container {
  display: inline-block;
  position: relative; }
  @media screen and (max-width: 767px) {
    .story-filter-cat-container {
      display: block; } }
  .story-filter-cat-container .filter-cat__title {
    font-weight: 300;
    color: #fff;
    padding: 25px 120px 25px 40px;
    border-right: 1px solid rgba(255, 255, 255, 0.2); }
    @media screen and (max-width: 767px) {
      .story-filter-cat-container .filter-cat__title {
        font-size: 16px;
        padding: 25px; } }
    .story-filter-cat-container .filter-cat__title:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
      margin-left: 10px; }

.story-list {
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 70px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .story-list.loading {
    margin-bottom: 100px; }
  .story-list .story-container {
    display: inline-block;
    width: 33.33333%;
    padding: 0 20px;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    line-height: 1.5em; }
    @media screen and (max-width: 767px) {
      .story-list .story-container {
        width: 100%;
        text-align: center; } }
  .story-list .story-img {
    width: 100%; }
    .story-list .story-img img {
      max-width: 360px;
      max-height: 180px; }
  .story-list .story-title {
    margin-top: 15px; }
  .story-list .story-desc {
    width: 85%;
    margin-top: 6px;
    text-align: left; }
    .story-list .story-desc a {
      text-decoration: underline;
      color: #fb5004;
      margin-left: 5px;
      font-weight: bold; }
    @media screen and (max-width: 767px) {
      .story-list .story-desc {
        margin-left: auto;
        margin-right: auto; } }
  .story-list .story-tags {
    margin-top: 30px;
    margin-bottom: 70px; }
  .story-list .story-tag {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 2px 5px; }
    .story-list .story-tag:after {
      content: "\00b7";
      position: absolute;
      top: 3px;
      right: -5px; }
    .story-list .story-tag:first-child {
      padding-left: 0; }
    .story-list .story-tag:last-child:after {
      content: none; }

/* END Style .page-success-story  */
/* ------------------------------------------ */
/* Style for .page-hktdc-events */
/* ------------------------------------------ */
.page-hktdc-events {
  max-width: 1920px;
  margin: 0 auto; }
  .page-hktdc-events .items-row--cols {
    border-bottom: none; }
    .page-hktdc-events .items-row--cols .item-container {
      padding: 60px 0; }
      .page-hktdc-events .items-row--cols .item-container:last-of-type {
        border-left: 1px solid #fff; }
    .page-hktdc-events .items-row--cols .item-arrow {
      width: 10%;
      cursor: pointer; }
      .page-hktdc-events .items-row--cols .item-arrow a {
        display: block; }

.item-arrow.item-arrow--al-center {
  text-align: center; }
  .item-arrow.item-arrow--al-center a {
    display: inline-block; }

.page-hktdc-events__heading {
  font-size: 32px;
  margin-top: 40px;
  text-align: center; }

.page-hktdc-events__title {
  font-weight: 300;
  color: #fc5e08;
  text-align: center; }

.page-hktdc-events__filters {
  width: 50%;
  margin: 40px auto 60px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: center; }
  @media screen and (max-width: 1240px) {
    .page-hktdc-events__filters {
      width: 80%; } }
  @media screen and (max-width: 768px) {
    .page-hktdc-events__filters {
      width: calc(100% - 10px); } }
  .page-hktdc-events__filters.desktop {
    display: none; }
    @media screen and (min-width: 768px) {
      .page-hktdc-events__filters.desktop {
        display: flex; } }
  .page-hktdc-events__filters.mobile {
    display: none; }
    @media screen and (max-width: 767px) {
      .page-hktdc-events__filters.mobile {
        display: flex; } }

.page-hktdc-events__filters-cat-container {
  /* this only appears in mobile */
  display: inline-block;
  position: relative; }
  @media screen and (max-width: 768px) {
    .page-hktdc-events__filters-cat-container {
      width: 100%; } }
  .page-hktdc-events__filters-cat-container .page-hktdc-events__filters-cat__title {
    font-weight: bold;
    background-color: #fc5e08;
    margin: 0 auto;
    border-radius: 45px;
    padding: 20px 10px;
    max-width: 80%;
    font-size: 16px;
    border-right: 1px solid rgba(190, 190, 190, 0.2);
    color: white; }
    .page-hktdc-events__filters-cat-container .page-hktdc-events__filters-cat__title:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid white;
      margin-left: 10px; }
  .page-hktdc-events__filters-cat-container .page-hktdc-events__filters-condList {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    margin: 20px 0; }

.page-hktdc-events__filter {
  display: inline-block;
  width: 33.33333%;
  font-weight: 300;
  padding: 25px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media screen and (max-width: 566px) {
    .page-hktdc-events__filter {
      padding: 15px 0; }
      .page-hktdc-events__filter:first-child {
        width: calc((100% - 26%)/2); }
      .page-hktdc-events__filter:nth-child(2) {
        width: calc((100% - 26%)/2); }
      .page-hktdc-events__filter:last-child {
        width: 26%; } }
  @media screen and (max-width: 407px) {
    .page-hktdc-events__filter {
      padding: 15px 0; }
      .page-hktdc-events__filter:first-child {
        width: 38%; }
      .page-hktdc-events__filter:nth-child(2) {
        width: 26%; }
      .page-hktdc-events__filter:last-child {
        width: 36%; } }
  .page-hktdc-events__filter.is-active {
    background-color: #fc5e08;
    color: #fff;
    border-top: none;
    border-bottom: none;
    position: relative; }
    .page-hktdc-events__filter.is-active:after {
      content: '';
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fc5e08;
      border-right: 1px solid #fc5e08;
      border-bottom: 1px solid #fc5e08;
      top: 50%;
      left: 50%;
      margin-top: 25px;
      margin-left: -10px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
      @media screen and (max-width: 566px) {
        .page-hktdc-events__filter.is-active:after {
          top: 35px; } }
  .page-hktdc-events__filter:first-child {
    border-left: 1px solid #e5e5e5;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px; }
  .page-hktdc-events__filter:last-child {
    border-right: 1px solid #e5e5e5;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px; }

/* END Style .page-hktdc-events  */
/* ------------------------------------------ */
/* Style for .page-item-multi-slider */
/* ------------------------------------------ */
.page-hktdc-events-slider {
  position: relative; }
  .page-hktdc-events-slider .item-sliders {
    position: relative;
    max-width: 1340px;
    width: 70%;
    margin: 0 auto; }
    @media screen and (max-width: 1240px) {
      .page-hktdc-events-slider .item-sliders {
        width: 80%; } }

.multi-sliders-container {
  width: 100%;
  overflow: hidden;
  padding: 0 30px; }
  .multi-sliders-container .item-slider__btn {
    top: 45%;
    cursor: pointer;
    background: none;
    border-color: #777; }
    .multi-sliders-container .item-slider__btn:after {
      border-color: #777; }
  .multi-sliders-container .item-slider__btn--prev {
    left: -30px; }
    .multi-sliders-container .item-slider__btn--prev:after {
      border-color: #777; }
  .multi-sliders-container .item-slider__btn--next {
    right: -30px; }
    .multi-sliders-container .item-slider__btn--next:after {
      border-color: #777; }
  .multi-sliders-container .item-slider__pagination {
    position: absolute;
    bottom: 10px; }
    .multi-sliders-container .item-slider__pagination .swiper-pagination-bullet {
      background: #000;
      opacity: 0.2; }
      .multi-sliders-container .item-slider__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #fb5004;
        opacity: 1; }

.multi-slider-container {
  margin-bottom: 60px;
  opacity: 0;
  -webkit-transform: translateX(60px);
  -ms-transform: translateX(60px);
  transform: translateX(60px);
  transition: 0.3s; }
  .multi-slider-container.swiper-slide-active,
  .multi-slider-container.swiper-slide-active + .multi-slider-container,
  .multi-slider-container.swiper-slide-active + .multi-slider-container + .multi-slider-container {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  .multi-slider-container.swiper-slide-active {
    transition-delay: 0.8s; }
  .multi-slider-container.swiper-slide-active + .multi-slider-container {
    transition-delay: 1s; }
  .multi-slider-container.swiper-slide-active + .multi-slider-container + .multi-slider-container {
    transition-delay: 1.2s; }
  .multi-slider-container .slider__img a:hover {
    opacity: 1; }
  .multi-slider-container .slider__img img {
    width: 100%; }
  .multi-slider-container .slider__title {
    margin-top: 15px;
    width: 75%;
    font-weight: bold;
    font-size: 18px; }
    .multi-slider-container .slider__title a:hover {
      opacity: 1; }
  .multi-slider-container .slider__desc {
    margin-top: 5px;
    width: 100%;
    font-size: 15px; }
  .multi-slider-container .slider-location-date {
    margin-top: 10px; }
  .multi-slider-container .slider__location {
    display: inline-block;
    color: #fc5e08;
    padding-right: 15px; }
  .multi-slider-container .slider__date {
    display: inline-block;
    border-left: 1px solid #1a1a1a;
    padding-left: 15px; }

.multi-sliders__slider-pause {
  display: inline-block;
  position: absolute;
  right: 35px;
  bottom: 30px;
  z-index: 10; }
  .multi-sliders__slider-pause .pause-button {
    display: block;
    box-sizing: border-box;
    width: 0;
    height: 15px;
    cursor: pointer;
    border-color: transparent transparent transparent #1a1a1a;
    transition: 100ms all ease;
    cursor: pointer;
    border-style: double;
    border-width: 0px 0 0px 15px; }
  .multi-sliders__slider-pause.is-active .pause-button {
    border-style: solid;
    border-width: 8px 0 8px 12px; }

/* END Style .page-item-multi-slider  */
/* ------------------------------------------ */
/* Style for .page-events-list */
/* ------------------------------------------ */
.events-filter {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #242655;
  text-align: center; }
  @media screen and (max-width: 1062px) {
    .events-filter {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  .events-filter .events-filter__title {
    display: inline-block;
    font-weight: 400;
    display: inline-block;
    padding: 25px 60px;
    border-right: 1px solid rgba(190, 190, 190, 0.2);
    color: white; }
    @media screen and (max-width: 767px) {
      .events-filter .events-filter__title {
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        padding-bottom: 10px; } }

.events-filter-condList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  margin: 20px 0; }

.events-filter-cat-container {
  display: inline-block;
  position: relative; }
  @media screen and (max-width: 768px) {
    .events-filter-cat-container {
      width: 100%; } }
  .events-filter-cat-container .filter-cat__title {
    font-weight: 300;
    padding: 25px 40px 25px 40px;
    border-right: 1px solid rgba(190, 190, 190, 0.2);
    color: white; }
    @media screen and (max-width: 767px) {
      .events-filter-cat-container .filter-cat__title {
        font-size: 16px;
        padding: 10px 25px; } }
    .events-filter-cat-container .filter-cat__title:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #bebebe;
      margin-left: 10px; }

.events-search__input-holder {
  position: relative;
  display: inline-block;
  padding: 25px 0 25px 40px;
  max-width: 300px;
  width: 100%; }
  @media screen and (max-width: 1240px) {
    .events-search__input-holder {
      max-width: 240px; } }
  @media screen and (max-width: 768px) {
    .events-search__input-holder {
      max-width: initial;
      width: 80%;
      padding-left: 0;
      padding-top: 10px; } }
  .events-search__input-holder input {
    color: white;
    background-color: #242655;
    border-top: none;
    border-right: none;
    border-bottom-color: rgba(112, 113, 148, 0.5);
    border-bottom-style: solid;
    /* PHR-542 - necessary in firefox */
    border-left: none;
    width: 100%;
    padding-right: 20px; }
    .events-search__input-holder input::-webkit-input-placeholder {
      color: white;
      font-weight: 300; }
    .events-search__input-holder input:-moz-placeholder {
      /* Firefox 18- */
      color: white;
      font-weight: 300; }
    .events-search__input-holder input::-moz-placeholder {
      /* Firefox 19+ */
      color: white;
      font-weight: 300; }
    .events-search__input-holder input:-ms-input-placeholder {
      color: white;
      font-weight: 300; }
    .events-search__input-holder input::-ms-clear {
      display: none; }
  .events-search__input-holder input[type="search"]::-webkit-search-decoration,
  .events-search__input-holder input[type="search"]::-webkit-search-cancel-button,
  .events-search__input-holder input[type="search"]::-webkit-search-results-button,
  .events-search__input-holder input[type="search"]::-webkit-search-results-decoration {
    display: none; }
  .events-search__input-holder .events-search__input-btn {
    position: absolute;
    right: 0;
    top: 25px;
    background: transparent;
    border: 0;
    cursor: pointer; }
    @media screen and (max-width: 768px) {
      .events-search__input-holder .events-search__input-btn {
        top: 15%; } }
    .events-search__input-holder .events-search__input-btn:before {
      content: '';
      width: 12px;
      height: 12px;
      background: url("./assets/images/common/icons/svg/white/icon-search.svg") no-repeat center center;
      background-size: 100%;
      display: inline-block;
      vertical-align: middle; }

.events-search__input-Error {
  color: red;
  font-size: 12px;
  padding: 5px 0;
  display: block;
  text-align: left; }

.events-list-title {
  text-align: center;
  font-weight: 300;
  color: #fb5004;
  margin-top: 55px; }

.events-list-conatiner {
  width: 70%;
  margin: 40px auto; }
  @media screen and (max-width: 1240px) {
    .events-list-conatiner {
      width: 90%; } }
  @media screen and (max-width: 768px) {
    .events-list-conatiner {
      width: 95%; } }

.events-list-header {
  border-bottom: 1px solid rgba(190, 190, 190, 0.2); }
  .events-list-header .events-list-header__elem {
    display: inline-block;
    vertical-align: middle;
    padding: 20px 8px; }
    .events-list-header .events-list-header__elem:first-child {
      width: 15%; }
    .events-list-header .events-list-header__elem:nth-child(2) {
      width: 10%;
      padding-left: 12px; }
      @media screen and (max-width: 1240px) {
        .events-list-header .events-list-header__elem:nth-child(2) {
          width: 12%; } }
    .events-list-header .events-list-header__elem:nth-child(3) {
      width: 25%;
      padding-left: 16px; }
      @media screen and (max-width: 1240px) {
        .events-list-header .events-list-header__elem:nth-child(3) {
          width: 18%; } }
    .events-list-header .events-list-header__elem:nth-child(n+4) {
      width: 10%;
      text-align: center; }
      @media screen and (max-width: 1240px) {
        .events-list-header .events-list-header__elem:nth-child(n+4) {
          width: 11%;
          word-wrap: break-word; } }
  @media screen and (max-width: 768px) {
    .events-list-header {
      display: none; } }

.list-event-container {
  border-bottom: 1px solid rgba(190, 190, 190, 0.2); }
  @media screen and (max-width: 768px) {
    .list-event-container {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      padding: 30px 0px; } }
  .list-event-container .list-event__elem {
    display: inline-block;
    vertical-align: middle;
    padding: 10px; }
  @media screen and (max-width: 768px) {
    .list-event-container .list-event__desktop-elem {
      display: none; } }
  .list-event-container .list-event__mobile-elem {
    display: none; }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event__mobile-elem {
        display: initial; } }
  .list-event-container .list-event__elem--title {
    color: #fb5004;
    cursor: default; }
    .list-event-container .list-event__elem--title a {
      color: #fb5004; }
  .list-event-container .list-event-left-container {
    display: inline-block;
    width: 15%; }
    .list-event-container .list-event-left-container .list-event__elem--img {
      padding-left: 0;
      width: 100%; }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-left-container .list-event__elem--img {
          padding-top: 0; } }
      .list-event-container .list-event-left-container .list-event__elem--img img {
        width: 100%; }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event-left-container {
        width: 35%; } }
  .list-event-container .list-event-right-container {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 85%; }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event-right-container {
        width: 65%;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch; } }
  .list-event-container .list-event-header-container {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 40%; }
    @media screen and (max-width: 1240px) {
      .list-event-container .list-event-header-container {
        width: 35%; } }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event-header-container {
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        width: 100%; } }
    .list-event-container .list-event-header-container .list-event__elem--date {
      width: 30%; }
      @media screen and (max-width: 1240px) {
        .list-event-container .list-event-header-container .list-event__elem--date {
          width: 40%; } }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-header-container .list-event__elem--date {
          width: 100%; } }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-header-container .list-event__elem--date .list-event__mobile-elem {
          display: block; } }
    .list-event-container .list-event-header-container .list-event__elem--title {
      width: 70%; }
      @media screen and (max-width: 1240px) {
        .list-event-container .list-event-header-container .list-event__elem--title {
          width: 60%; } }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-header-container .list-event__elem--title {
          width: 100%;
          font-weight: bold;
          font-size: 18px;
          padding-top: 0;
          padding-bottom: 0; } }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-header-container .list-event__elem--title .list-event__mobile-elem {
          display: block;
          color: #000; } }
  .list-event-container .list-event-detail-container {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 60%; }
    @media screen and (max-width: 1240px) {
      .list-event-container .list-event-detail-container {
        width: 65%; } }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event-detail-container {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        width: 100%; } }
    .list-event-container .list-event-detail-container .list-event__elem {
      display: -ms-inline-flexbox;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: 20%; }
      .list-event-container .list-event-detail-container .list-event__elem img {
        margin-right: 5px; }
      @media screen and (max-width: 768px) {
        .list-event-container .list-event-detail-container .list-event__elem {
          width: 50%; }
          .list-event-container .list-event-detail-container .list-event__elem img {
            width: 16px; }
          .list-event-container .list-event-detail-container .list-event__elem .elem__desc {
            width: calc(80% - 5px); } }
      @media screen and (max-width: 560px) {
        .list-event-container .list-event-detail-container .list-event__elem.list-event__elem--Download img {
          width: 11px; } }
      @media screen and (max-width: 560px) {
        .list-event-container .list-event-detail-container .list-event__elem.list-event__elem--exhibit img {
          width: 14px; } }
    @media screen and (max-width: 768px) {
      .list-event-container .list-event-detail-container .list-event__empty-elem {
        display: none; } }

.more-events-list-title {
  text-align: center;
  font-weight: 300;
  color: #fb5004;
  margin-top: 55px; }

.more-events-list-conatiner {
  width: 70%;
  margin: 40px auto; }
  @media screen and (max-width: 1240px) {
    .more-events-list-conatiner {
      width: 90%; } }
  @media screen and (max-width: 768px) {
    .more-events-list-conatiner {
      width: 95%; } }

.more-events-list-header {
  border-bottom: 1px solid rgba(190, 190, 190, 0.2); }
  .more-events-list-header .more-events-list-header__elem {
    display: inline-block;
    vertical-align: middle;
    padding: 20px 10px 20px 0;
    font-weight: 700; }
    .more-events-list-header .more-events-list-header__elem:first-child {
      width: 10%; }
      .list-events-container .more-events-list-header .more-events-list-header__elem:first-child {
        width: 14%; }
    .more-events-list-header .more-events-list-header__elem:nth-child(2) {
      width: 29%; }
      .list-events-container .more-events-list-header .more-events-list-header__elem:nth-child(2) {
        width: 16.5%; }
    .more-events-list-header .more-events-list-header__elem:nth-child(3) {
      width: 16%; }
      .list-events-container .more-events-list-header .more-events-list-header__elem:nth-child(3) {
        width: 28.5%; }
    .more-events-list-header .more-events-list-header__elem:nth-child(4) {
      width: 14.5%;
      text-align: left; }
      .list-events-container .more-events-list-header .more-events-list-header__elem:nth-child(4) {
        width: 15.5%; }
  @media screen and (max-width: 768px) {
    .more-events-list-header {
      display: none; } }

.more-event-list-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(190, 190, 190, 0.2);
  padding: 20px 0; }
  @media screen and (min-width: 769px) {
    .list-events-container .more-event-list-container {
      padding: 0; } }
  @media screen and (max-width: 768px) {
    .more-event-list-container {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; } }
  .more-event-list-container .more-event-list__elem {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 15px 10px 0; }
  .more-event-list-container .more-list-event__mobile-elem {
    display: none; }
    @media screen and (max-width: 768px) {
      .more-event-list-container .more-list-event__mobile-elem {
        display: block;
        font-weight: 700; }
        .more-event-list-container .more-list-event__mobile-elem.elem__desc {
          font-weight: 400; } }
  @media screen and (max-width: 768px) {
    .more-event-list-container .more-event-list__desktop-elem {
      display: none; } }
  .more-event-list-container .more-event-list-left-container {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 85%; }
    .list-events-container .more-event-list-container .more-event-list-left-container {
      width: 89%; }
    .more-event-list-container .more-event-list-left-container .clearfix {
      display: none; }
      @media screen and (max-width: 768px) {
        .more-event-list-container .more-event-list-left-container .clearfix {
          content: '';
          display: table;
          width: 100%;
          height: 0;
          clear: both; } }
    @media screen and (max-width: 768px) {
      .more-event-list-container .more-event-list-left-container {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 100%; }
        .list-events-container .more-event-list-container .more-event-list-left-container {
          width: 100%;
          display: block; } }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--img {
      width: 17%; }
      .more-event-list-container .more-event-list-left-container .more-event-list__elem--img a {
        padding-left: 0;
        width: 100%; }
        @media screen and (max-width: 768px) {
          .more-event-list-container .more-event-list-left-container .more-event-list__elem--img a {
            padding-top: 0; } }
        .more-event-list-container .more-event-list-left-container .more-event-list__elem--img a img {
          width: 100%; }
      @media screen and (max-width: 768px) {
        .more-event-list-container .more-event-list-left-container .more-event-list__elem--img {
          width: 35%;
          float: left; } }
      .list-events-container .more-event-list-container .more-event-list-left-container .more-event-list__elem--img {
        order: 0; }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--date {
      width: 20%; }
      .list-events-container .more-event-list-container .more-event-list-left-container .more-event-list__elem--date {
        order: 1; }
      @media screen and (max-width: 768px) {
        .list-events-container .more-event-list-container .more-event-list-left-container .more-event-list__elem--date {
          width: 50%;
          padding-top: 0; } }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--title {
      color: #fb5004;
      width: 34%; }
      .list-events-container .more-event-list-container .more-event-list-left-container .more-event-list__elem--title {
        order: 2; }
      @media screen and (max-width: 768px) {
        .more-event-list-container .more-event-list-left-container .more-event-list__elem--title .more-list-event__mobile-elem {
          color: #000; } }
      .more-event-list-container .more-event-list-left-container .more-event-list__elem--title a {
        color: #fb5004; }
        @media screen and (max-width: 768px) {
          .list-events-container .more-event-list-container .more-event-list-left-container .more-event-list__elem--title a {
            font-weight: bold; } }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--details {
      width: 19%; }
      .list-events-container .more-event-list-container .more-event-list-left-container .more-event-list__elem--details {
        order: 3; }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--area {
      width: 17%; }
      .list-events-container .more-event-list-container .more-event-list-left-container .more-event-list__elem--area {
        order: 4; }
    .more-event-list-container .more-event-list-left-container .more-event-list__elem--organiser {
      width: 18%; }
    @media screen and (max-width: 768px) {
      .more-event-list-container .more-event-list-left-container .more-event-list__elem {
        -webkit-order: 10;
        -ms-flex-order: 1;
        order: 1;
        width: 50%; }
        .list-events-container .more-event-list-container .more-event-list-left-container .more-event-list__elem {
          vertical-align: top; } }
    @media screen and (max-width: 768px) {
      .more-event-list-container .more-event-list-left-container .more-event-list__elem.more-event-list__elem--title {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        width: 100%; }
        .list-events-container .more-event-list-container .more-event-list-left-container .more-event-list__elem.more-event-list__elem--title {
          width: 50%; } }
  .more-event-list-container .more-event-list-right-container {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 15%; }
    .list-events-container .more-event-list-container .more-event-list-right-container {
      width: 11%; }
    @media screen and (max-width: 768px) {
      .more-event-list-container .more-event-list-right-container {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin-top: 3%;
        width: 100%; }
        .list-events-container .more-event-list-container .more-event-list-right-container {
          width: 100%; } }
    @media screen and (max-width: 480px) {
      .more-event-list-container .more-event-list-right-container {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
    .more-event-list-container .more-event-list-right-container .more-event-list__elem {
      display: -ms-inline-flexbox;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      padding-top: 0;
      padding-bottom: 3px; }
      .more-event-list-container .more-event-list-right-container .more-event-list__elem .elem-img {
        margin-right: 5px;
        width: 12%; }
        .list-events-container .more-event-list-container .more-event-list-right-container .more-event-list__elem .elem-img {
          width: 20%; }
        @media screen and (max-width: 480px) {
          .more-event-list-container .more-event-list-right-container .more-event-list__elem .elem-img {
            width: 20%; } }
      .more-event-list-container .more-event-list-right-container .more-event-list__elem .elem__desc {
        width: calc(88% - 5px); }
        @media screen and (max-width: 480px) {
          .more-event-list-container .more-event-list-right-container .more-event-list__elem .elem__desc {
            width: calc(80% - 5px); } }
      @media screen and (max-width: 768px) {
        .more-event-list-container .more-event-list-right-container .more-event-list__elem {
          width: 33.33333%; } }
      @media screen and (max-width: 480px) {
        .more-event-list-container .more-event-list-right-container .more-event-list__elem {
          width: initial;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start; } }

/* END Style .page-events-list  */
/* ------------------------------------------ */
.factsheetTable {
  font-size: 16px;
  border-collapse: collapse;
  width: 100%; }

.factsheetTable td,
.factsheetTable th {
  border: 1px solid #aaa;
  padding: 10px; }

.text-center {
  text-align: center; }

.factsheetTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #fb5004;
  color: white; }

.greyrow {
  background-color: #eaecee;
  width: 25%; }

.whiterow {
  background-color: #ffffff;
  width: 75%;
  word-break: normal;
  overflow-wrap: anywhere; }

.scrollableTable {
  margin-bottom: 30px;
  overflow-x: auto; }

.factsheetButtons {
  margin: 20px 0 30px; }
  .factsheetButtons button {
    margin-top: 10px;
    margin-right: 20px; }
    .factsheetButtons button + .button, .factsheetButtons button + .feature-listing__btn, .factsheetButtons button + .feedback-panel__btn, .factsheetButtons button + .organic-listing__btn, .factsheetButtons button + .pagination__btn-prev,
    .factsheetButtons button + .pagination__btn-next {
      margin-left: 0; }

.page-multimedia__table {
  border: 0;
  width: 100%; }
  .page-multimedia__table td {
    vertical-align: top; }
    .page-multimedia__table td:first-child {
      width: 100px; }

.page-multimedia__speaker {
  color: #777; }

.page-multimedia__video {
  width: 100%;
  height: auto;
  max-width: 640px; }

.page-multimedia__video_lang_button_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0 30px 0; }

.page-multimedia__icon--video-play {
  display: block;
  background: url("./assets/images/common/icons/svg/black/icon-play-video.svg") no-repeat center center;
  width: 40px;
  height: 40px;
  margin-right: 30px; }

.page-multimedia__video_lang_button_bar {
  text-align: right; }
  .page-multimedia__video_lang_button_bar button {
    font-weight: 700;
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0;
    margin-top: 5px;
    margin-right: 30px; }
    .page-multimedia__video_lang_button_bar button + .button, .page-multimedia__video_lang_button_bar button + .feature-listing__btn, .page-multimedia__video_lang_button_bar button + .feedback-panel__btn, .page-multimedia__video_lang_button_bar button + .organic-listing__btn, .page-multimedia__video_lang_button_bar button + .pagination__btn-prev,
    .page-multimedia__video_lang_button_bar button + .pagination__btn-next {
      margin-left: 0; }
    .page-multimedia__video_lang_button_bar button:last-child {
      margin-right: 0; }

.page-multimedia__active_button {
  cursor: pointer;
  border-bottom: none;
  color: #1a1a1a; }
  .page-multimedia__active_button:hover {
    color: #fb5004; }

.page-multimedia__disabled_button {
  cursor: default;
  border-bottom: solid;
  color: #fb5004; }

/* ------------------------------------------ */
/* Override Style   */
/* ------------------------------------------ */
/* Style for Smart banner*/
.smartbanner-show {
  margin-top: 0; }

.smartbanner-icon {
  background-image: url("./assets/images/common/icon.png") !important; }

.smartbanner {
  position: relative; }

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

/* ------------------------------------------ */
/* END Override Style  */
/* ------------------------------------------ */
