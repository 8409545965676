@charset "utf-8";
$componentsTitle: 'footer-bottom';

/* Style for #{$componentsTitle} */


/* ------------------------------------------ */

// TODO: Simplify
.footer-bottom {
    text-align: center;
}
.footer-bottom__logo{
    display:block;
    margin: 45px auto 20px;
    @media screen and (max-width: 640px) {
        margin-top:0;
        text-align: left;
    }
}
.footer-bottom__row {
    text-align: center;
    margin-bottom: 10px;
    @media screen and (max-width: 640px) {
        // display: inline;
        &+.footer-bottom__row {
            margin-top: -10px;
        }
    }
    &:first-child {
        margin-top: 45px; 
        @media screen and (max-width: 991px) {
            margin-top: 0;
        }
    }
    &.footer-bottom__row--lang {
        @media screen and (max-width: 991px) {
            display:none;
        }
    }
    @media screen and (max-width: 640px) {
        text-align: left;
    }
}

.footer-bottom__item {
    position: relative;
    display: inline-block;
    padding: 0 15px;
    @extend .gb-fontsize-xs;
    @media screen and (max-width: 1580px) {
        padding:0 10px;
    }
    @media screen and (max-width: 640px) {
       width:50%;
       text-align: left;
       margin-bottom:15px;
       vertical-align: top;
       padding:0;
       font-size:14px;
    }
    &:after {
        $lineHeight: 14px;
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: $lineHeight;
        right: 0;
        top: 50%;
        margin-top: -$lineHeight/2;
        background: $colorTxt;
        @media screen and (max-width: 640px) {
            display:none;
        }
    }
    &:last-child:after {
        display: none;
    }
    &:nth-child(2n+1){
        padding-right:10px;
    }
    &.footer-bottom__item--hidden-mb{
        @media screen and (max-width: 640px) {
            display:none;
        }
    }
}

.footer-bottom__row--lang{
    .footer-bottom__link{
        cursor: pointer;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        // display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 22px;
        background: no-repeat center center;
        background-size: 100%;
    }
    /* PHR-420, FS suggest to use HTML, the "About HKTDC" & "Merchandise Trade Page" use HTML like SP portal too.
    .footer-bottom__link-German{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -12px center;
        background-size: 690px;
    }
    .footer-bottom__link-Czech{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -633px center;
        background-size: 690px;
    }
    .footer-bottom__link-Spanish{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -84px center;
        background-size: 690px;
    }
    .footer-bottom__link-French{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -155px center;
        background-size: 690px;
    }
    .footer-bottom__link-Italian{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -225px center;
        background-size: 690px;
    }
    .footer-bottom__link-Portuguese{
        width: 60px;
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -298px center;
        background-size: 690px;
    }
    .footer-bottom__link-Japanese{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -377px center;
        background-size: 690px;
    }
    .footer-bottom__link-Korean{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -441px center;
        background-size: 690px;
    }
    .footer-bottom__link-Russian{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -511px center;
        background-size: 690px;
    }
    .footer-bottom__link-Arabric{
        background-image: imgurl("common/icons/img-footer-lang.gif");
        background-repeat: no-repeat;
        background-position: -577px center;
        background-size: 690px;
    }
    .footer-bottom__link-Polish{
        background-image: imgurl("common/icons/img-lang-full.png");
        background-repeat: no-repeat;
        background-position: -335px center;
        background-size: 700px;
    }*/
}

.footer-bottom__copyright {
    @extend .gb-fontsize-xs;
}
.footer-bottom__copyright-title{
    display:inline-block;
    margin-right: 0.5em;
    @media screen and (max-width: 640px) {
        display:block;
        margin-right:0;
        margin-bottom:5px;
    }
}
.footer-bottom__logo-img-dt{
    @media screen and (max-width: 640px) {
        display:none
    }
}
.footer-bottom__logo-img-mb{
    max-width: 240px;
    width: auto;
    height: 55px;
    @media screen and (min-width: 641px) {
        display:none
    }
}
/* END Style #{$componentsTitle}  */


/* ------------------------------------------ */