@charset "utf-8";
$componentsTitle: 'skip-link';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify

.skip-link {
    position: relative;
    z-index: 1001;
}

.skip-link__link {
    display: -moz-inline-stack;
    display: inline-block;
    margin: 0;
    max-height: 0;
    color: #FFF;
    position: absolute;
    top: -5000px;
    left: -5000px;
    &:focus {
        max-height: 1920px;
        display: inline-block;
        padding: 15px;
        margin: 5px;
        outline: 3px solid #FFF;
        text-decoration: underline;
        left: 20px;
        top: 20px;
        background-color: #fb5004;
    }
}


/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */