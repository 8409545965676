@charset "utf-8";
$componentsTitle: 'search-nores';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify
.search-nores{
    @extend .content-wrapper;
    position:relative;
}
.search-nores__txt{
    @extend .title-lv5;
    font-weight:300;
    position:relative;
    padding: 60px 5%;
    line-height:1.8em;
}

.search-nores__suggestion{
    position:relative;
    display:inline-block;
    margin-right:10px;
    //text-decoration: underline;
    &:after{
        content:'';
        position:absolute;
        width:100%;
        height:1px;
        left:0;
        bottom:2px;
        background:$colorTxt;
    }
}

/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */