@charset "utf-8";
$componentsTitle: 'sitemappage';

/* Style for #{$componentsTitle} */


/* ------------------------------------------ */

.sitemappage {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;

  &__col {
    flex: 1 1 25%;
    align-self: flex-start;
    padding: 0 30px 30px 0;
    margin: 0;
    @media screen and (max-width: 991px) {
      flex-basis: 50%;
    }
    @media screen and (max-width: 767px) {
      flex-basis: 100%;
    }

    &-row {
      list-style: none;
      line-height: 1.5;

      @media screen and (max-width: 1580px) {
        font-size: 15px;
      }
    }
    &-title,
    &-title a {
      color: $colorOrange;
      font-weight: bold;
      line-height: 1.15;
      font-size: 20px;
      margin-bottom: 10px;
      @media screen and (max-width: 1580px) {
        font-size: 18px;
      }
    }
  }
}

/* END Style #{$componentsTitle}  */


/* ------------------------------------------ */