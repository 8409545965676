@charset "utf-8";
$componentsTitle: 'mf-detail';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.mf-detail {
    color: #FFF;
    top: 0; //height:100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    z-index: -1;
    &.is-active {
        position: relative;
        display: block;
        opacity: 1;
        z-index: 10;
    }
}

.mf-detail__top {
    position: relative; //height:100%;
    overflow: hidden;
    height: 680px;
    padding: 250px 0;
    background: #000 no-repeat 55% center;
    @media screen and (max-width: 1200px) {
        padding:200px 0;
        background-size: contain;
        height: 500px;
    }
    @media screen and (max-width:767px) {
        padding: 60px 0;
        background-size: cover;
        height: auto;
        &:before {
            content: '';
            @include overlayStyle(0.5,
            0);
        }
    }
    .content-wrapper {
        position: relative;
        z-index: 10;
        height: 100%;
        padding: 0 60px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@include keyframes(mf-item-fadein) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.mf-detail__top-bg {
    z-index: 0;
    position:relative;
    @include fullyCover();
    background: #000 no-repeat 55% center;
    @include transition(.5s .1s);
    @include transform-origin(center center);
    @include transform(scale(1.1,1.1));
    opacity: 0;
    .mf-detail.is-active & {
        @include transform(scale(1,
        1));
        opacity: 1;
    }
    &:after {
        content: '';
        position:absolute;
        display:block;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.2);
        z-index:1;
        @media screen and (max-width: 767px) {
            background:rgba(0,0,0,0.4);
        }
    }
}

.mf-detail__intro {
    display: inline-block;
    max-width: 45%;
    opacity: 0;
    .mf-detail.is-active & {
        @include animation(mf-item-fadein .6s);
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
        margin: 0 0 30px;
    }
}

.mf-detail__intro-title {
    @extend .title-lv1;
    margin: 0 0 15px;
    font-weight: 200;
    text-transform: uppercase;
}

.mf-detail__intro-desc {
    //@include fontSize(14);
    @extend .gb-fontsize-s;
    line-height: 1.5;
}

.mf-detail__quicklinks {
    display: inline-block;
    width: 28%;
    @media screen and (max-width: 767px) {
        width:100%;
        max-width: 100%;
    }
}

.mf-detail__quicklinks-title {
    @extend .title-lv5;
    margin: 0 0 10px;
    font-weight: 300;
    color: $colorOrange;
    opacity: 0;
    .mf-detail.is-active & {
        @include animation(mf-item-quicklinks .6s);
    }
    @include keyframes(mf-item-quicklinks) {
        0% {
            opacity: 0;
            @include transform(translateX(30px));
        }
        100% {
            opacity: 1;
            @include transform(translateX(0));
        }
    }
}

.mf-detail__list {
    @extend .gb-fontsize-s;
    line-height: 1.5;
    padding-left: 15px;
    li {
        padding: 5px 0;
        .mf-detail.is-active & {
            .mf-detail__link {
                display: block;
                @include animation(mf-item-quicklink .6s);
            }
            @include keyframes(mf-item-quicklink) {
                0% {
                    opacity: 0;
                    @include transform(translateX(30px));
                }
                100% {
                    opacity: 1;
                    @include transform(translateX(0));
                }
            }
            $delay:0;
            $weight:.1s;
            &:nth-child(2) .mf-detail__link {
                $delay: $delay+$weight;
                @include animation-delay($delay);
            }
            &:nth-child(3) .mf-detail__link {
                $delay: $delay+$weight;
                @include animation-delay($delay);
            }
            &:nth-child(4) .mf-detail__link {
                $delay: $delay+$weight;
                @include animation-delay($delay);
            }
            &:nth-child(5) .mf-detail__link {
                $delay: $delay+$weight;
                @include animation-delay($delay);
            }
            &:nth-child(6) .mf-detail__link {
                $delay: $delay+$weight;
                @include animation-delay($delay);
            }
            &:nth-child(7) .mf-detail__link {
                $delay: $delay+$weight;
                @include animation-delay($delay);
            }
            &:nth-child(8) .mf-detail__link {
                $delay: $delay+$weight;
                @include animation-delay($delay);
            }
            &:nth-child(9) .mf-detail__link {
                $delay: $delay+$weight;
                @include animation-delay($delay);
            }
            &:nth-child(10) .mf-detail__link {
                $delay: $delay+$weight;
                @include animation-delay($delay);
            }
        }
    }
}

.mf-detail__link {
    color: #FFF;
    @extend .gb-fontsize-s;
    opacity: 0;
    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 5px;
        width: 0.4em;
        height: 0.4em;
        border-top: 2px solid #FFF;
        border-right: 2px solid #FFF;
        @include transform(rotate(45deg));
    }
    &:hover {
        color: #FFF;
    }
}

$btnSize:36px;
$arrowSize:$btnSize*0.2;
.mf-detail__control {
    position: absolute;
    top: 50%;
    height: 0;
    width: 100%;
    left: 0;
}

.mf-detail__top-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 15;
    .content-wrapper {
        width: 100%;
        * {
            pointer-events: all;
        }
    }
}

.mf-detail__btn-prev,
.mf-detail__btn-next {
    width: $btnSize;
    height: $btnSize;
    border: 1px solid #FFF;
    display: block;
    border-radius: 50%;
    position: absolute;
    @include transform-origin(center center);
    top: 50%;
    margin-top: -$btnSize/2;
    cursor: pointer;
    &:after {
        content: '';
        position: absolute;
        display: block;
        width: $arrowSize;
        height: $arrowSize;
        border-top: 1px solid #FFF;
        border-right: 1px solid #FFF;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -$arrowSize*0.38;
        margin-left: -$arrowSize*0.75;
    }
}

.mf-detail__btn-prev {
    left: 0px;
    @include transform(rotate(225deg));
}

.mf-detail__btn-next {
    right: 0px; //left:0;
    @include transform(rotate(45deg));
}

.mf-detail__btn-close {
    $size: 40px;
    $thick: 3px;
    display: block;
    width: $size;
    height: $size;
    position: absolute;
    overflow: hidden;
    top: 30px;
    right: 30px;
    cursor: pointer;
    &:before,
    &:after {
        content: '';
        position: absolute;
        display: block;
        background: #FFF;
        @include transform(rotate(45deg));
    }
    &:before {
        width: 100%;
        height: $thick;
        left: 0;
        top: 50%;
        margin-top: -$thick/2;
    }
    &:after {
        height: 100%;
        width: $thick;
        left: 50%;
        margin-left: -$thick/2;
        top: 0;
    }
}

.mf-detail__boxes {
    /*
    position:absolute;
    width:100%;
    left:0;
    top:100%;
    */
    margin-top: -60px;
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
    .content-wrapper {
        //background:#FFF;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.10), 0 8px 8px rgba(0, 0, 0, 0.06);
        @media screen and (max-width: 767px) {
            width:100%;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: stretch;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
            -webkit-align-items: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
        }
    }
}

.mf-detail__box {
    position: relative;
    display: inline-block;
    width: 33.3333%;
    padding: 80px 60px 45px;
    opacity: 0;
    .mf-detail.is-active & {
        @include animation(mf-item-box .6s);
    }
    @include keyframes(mf-item-box) {
        0% {
            opacity: 0;
            @include transform(translateY(30px));
        }
        100% {
            opacity: 1;
            @include transform(translateY(0));
        }
    }
    $delay:0;
    $weight:.1s;
    &.mf-detail__box--1 {
        width: 36%;
        background: #1e1e1e;
    }
    &.mf-detail__box--2 {
        width: 36%;
        color: $colorTxt;
        background: #FFF;
        $delay: $delay+$weight;
        .mf-detail.is-active & {
            @include animation-delay($delay);
        }
    }
    &.mf-detail__box--3 {
        width: 28%;
        background: $colorOrange;
        $delay: $delay+$weight;
        .mf-detail.is-active & {
            @include animation-delay($delay);
        }
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 45px;
        &.mf-detail__box--1,
        &.mf-detail__box--2,
        &.mf-detail__box--3 {
            width: 100%;
        }
    }
}

.mf-detail__box-img {
    display: block;
    width: 100%; //margin-top:-160px;
    //margin-bottom:15px;
    position: absolute;
    bottom: 100%;
    left: 0;
    @media screen and (max-width: 767px) {
        position: relative;
        bottom: auto;
        left: auto;
        margin: 0 auto 30px;
    }
    img, iframe {
        position: relative;
        padding: 0 60px;
        margin: 0 auto -60px;
        display: block;
        width: 100%;
        @media screen and (max-width: 767px) {
            margin: 0 auto;
            padding: 0;
        }
    }
}

.mf-detail__btn-arrow {
    display: inline-block;
    &:before {
        $size: 30px;
        content: '';
        width: $size;
        height: $size;
        display: block;
        background: no-repeat center center;
        background-image: imgurl("common/icons/svg/orange/icon-long-arrow.svg");
        background-size: 100%;
    }
}

.mf-detail__box-label {
    @extend .gb-fontsize-l;
    font-weight: 400;
    margin: 0 0 10px;
    .mf-detail__box--1 & {
        color: $colorOrange;
    }
}

.mf-detail__box-title {
    @include fontSize(20);
    font-weight: 300;
    margin: 0 0 10px;
    line-height: 1.5;
    color: #FFF;
}

.mf-detail__box-date,
.mf-detail__box-desc {
    @extend .gb-fontsize-s;
    margin: 0 0 10px;
    line-height: 1.5;
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */