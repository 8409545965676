@charset "utf-8";
$componentsTitle: 'page-ads';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify

.sec-page-ads{
    .ads-container{
        width: 80%;
        margin: 50px auto;
        .ad-container{
            text-align: center;
        }
    }
    .ad-container{
        a{
            &:hover{
                opacity: 1;
            }
        }
    }
}

/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */