@charset "utf-8";
$componentsTitle: 'app-sec';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.app-sec {
    position: relative;
    width: 100%;
}

.app-sec__inner {
    @extend .content-wrapper;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    padding: 90px 0;
    @media screen and (max-width: 900px) {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    @media screen and (max-width: 767px) {
        padding: 45px 0;
    }
}

.app-sec__item {
    &:nth-child(2n) {
        .app-sec__inner {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row-reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-content: stretch;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
            .app-sec__detail {
                padding-left: 0;
                padding-right: 45px;
                @media screen and (max-width: 900px) {
                    padding-right: 0;
                }
            }
            @media screen and (max-width: 900px) {
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-justify-content: flex-start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-align-content: stretch;
                -ms-flex-line-pack: stretch;
                align-content: stretch;
                -webkit-align-items: flex-start;
                -ms-flex-align: start;
                align-items: flex-start;
            }
        }
    }
    &--white {
        color: #FFF;
    }
}

.app-sec__img {
    width: 55%;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    @media screen and (max-width: 900px) {
        width: 100%;
        margin-bottom:15px;
        max-width:800px;
        img{
            display:block;
        }
    }
}

.app-sec__detail {
    width: 45%;
    padding-left: 45px;
    @media screen and (max-width: 900px) {
        width: 100%;
        padding-left: 0;
    }
}
.app-sec__icon{
    margin-bottom:10px;
    @media screen and (max-width: 1280px) {
        width: 72px;
    }
}
.app-sec__detail-item{
    margin-bottom:20px;
}

.app-sec__big-title {
    font-weight: 300;
    @extend .title-lv2;
    margin: 0 0 15px;
}

.app-sec__title {
    @extend .title-lv4;
    margin-bottom:10px;
}
.app-sec__detail-title{
    margin-bottom:10px;
    color:$colorOrange;
    .app-sec__item--white & {
        color: #FFF;
    }
}
.app-sec__detail-desc{
    line-height:1.5em;
    max-width:480px;
    @media screen and (max-width: 991px) {
        @include fontSize(14);
    }

}
.app-sec__link-icon {
    width: 20px;
}
.app-sec__links-title{
    display:inline-block;
    vertical-align: middle;
    margin:0;
    margin-right:10px;
    @media screen and (max-width: 1280px) {
        font-size:14px;
    }
}

.app-sec__link {
    position:relative;
    padding-right:15px;
    margin-right:15px;
    @media screen and (max-width: 1280px) {
        font-size:14px;
    }
    background:no-repeat center center;
    background-size:cover;
    &:after{
        content:'';
        display:block;
        width:1px;
        height:18px;
        background:$colorTxt;
        position:absolute;
        right:0;
        top:50%;
        margin-top:-9px;
        .app-sec__item--white & {
            background: #FFF;
        }
    }
    &:last-child{
        padding-right:0;
        margin-right:0;
        &:after{
            display:none;
        }
    }
    span,
    .app-sec__link-icon{
        display:inline-block;
        vertical-align: middle;
    }
    .app-sec__link-icon{
        margin-right:5px;
        margin-top:-3px;
    }
    .app-sec__item--white & {
        color: #FFF;
    }
}

.app-sec__other-links {
    max-width: 480px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 12px;
    row-gap: 12px;
    &-item {
        img {
            display: block;
            width: 100%;
        }
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */