@charset "utf-8";
$componentsTitle: 'feedback-panel';
/* Style for #{$componentsTitle} */
// TODO: deprecated

/* ------------------------------------------ */

// TODO: Simplify
.feedback-panel {}

.feedback-panel__btn {
    cursor: pointer;
    display: block;
    border: 0;
    box-shadow: none;
    position: fixed;
    bottom: 45px;
    right: 45px;
    margin-right: 2px;
    padding: 8px 15px;
    padding-right:20px;
    [data-cookies-bar="show"] & {
        bottom: 90px;
    }
    @include transition(.3s);
    @extend .button;
    background: #dadada;
    color:$colorTxt;
    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        $size: 16px;
        width: $size;
        height: $size;
        background: imgurl('common/icons/svg/black/icon-star.svg') no-repeat center center;
        background-size: 100%;
        margin-right: 8px;
    }
    span {
        display: inline-block;
        vertical-align: middle;
    } //background: imgurl('common/icons/svg/white/icon-feedback.svg') no-repeat center center $colorOrange;
    //background-size:50%;
    z-index:500;
    @media screen and (max-width: 767px) {
        bottom: 30px;
        right: 5%;
        z-index:450;
    }
    @media screen and (max-width: 480px) {
        [data-cookies-bar="show"] & {
            bottom: 130px;
        }
    }
    box-shadow: 0 1px 3px rgba(0,
    0,
    0,
    0.12),
    0 1px 2px rgba(0,
    0,
    0,
    0.24);
    &:hover {
        box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 3px 3px rgba(0, 0, 0, 0.22);
        &:before {
            background-image: imgurl('common/icons/svg/white/icon-star.svg');
        }
    }
    .search-panel-open & {
        z-index: 1;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */