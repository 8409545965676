.factsheetTable {
  font-size: 16px;
  border-collapse: collapse;
  width: 100%;
}

.factsheetTable td,
.factsheetTable th {
  border: 1px solid #aaa;
  padding: 10px;
}

.text-center {
  text-align: center;
}

.factsheetTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #fb5004;
  color: white;
}

.greyrow {
  background-color: #eaecee;
  width: 25%;
}

.whiterow {
  background-color: #ffffff;
  width: 75%;
  word-break: normal;
  overflow-wrap: anywhere;
}

.scrollableTable {
  margin-bottom: 30px;
  overflow-x: auto;
}

.factsheetButtons {
  margin: 20px 0 30px;
  button {
    margin-top: 10px;
    margin-right: 20px;
    +.button {
      margin-left: 0;
    }
  }
}