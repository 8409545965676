@charset "utf-8";
$componentsTitle: 'sample';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.channel-bar {
    position: relative;
    font-size: 0;
    margin: 0 0 45px;
    @media screen and (max-width: 991px) {
        margin: 0 auto;
    }
    >.content-wrapper {
        display: table;
        @media screen and (max-width: 991px) {
            display:block;
        }
    }
}

.channel-bar__item {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    @media screen and (max-width: 991px) {
        display:block;
        width: 100%;
    } //width:50%;
    border-right:2px solid $colorBorderGrey;
    padding-right:45px;
    @media screen and (max-width:991px) {
        padding-right: 0;
        border-right: 0;
    }
    .channel-bar>.content-wrapper.animated & {
        @include transition(.6s);
    }
    &:first-child {
        .channel-bar>.content-wrapper.animated & {
            @include transform(translateX(-100px));
        }
        .channel-bar>.content-wrapper.animated.fadeIn & {
            @include transform(translateX(0));
        }
    }
    &:last-child {
        .channel-bar>.content-wrapper.animated & {
            @include transform(translateX(100px));
        }
        .channel-bar>.content-wrapper.animated.fadeIn & {
            @include transform(translateX(0));
        }
        padding-left: 45px;
        padding-right: 0;
        border-right: 0;
        @media screen and (max-width: 991px) {
            padding-left:0;
            text-align: center;
            border-top: 2px solid $colorBorderGrey;
            padding: 45px 0;
        }
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */