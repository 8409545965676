@charset "utf-8";
$componentsTitle: 'search-listing';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

.search-listing {

  &__icons {
    right: 0px;
    text-align: right;
    position: absolute;

    @media screen and (max-width: $max-w-mb) {
      bottom: 20px;
    }

    // div:not(:first-child){
    //   margin-top: 5px;
    // }
  }

  &__bookmarkRibbon {
    display: inline-block;
    height: 0;
    border-bottom: 20px solid $colorOrange;
    border-top: 20px solid $colorOrange;
    border-left: 20px solid transparent;
    padding-left: 20px;

    span {
      color: white;
      position: relative;
      text-align: right;
      right: 10px;
      font-size: 12px;
      top: -14px;
      line-height: 15px;
    }
  }

  &__sriLabel {
    &-gold, &-silver, &-bronze {
      background-repeat: no-repeat;
      width: 110px;
      height: 35px;
      // background-size: 100% 100%;
      right: 0;
      background-position: center right;
    }
    &-gold {
      background-image: imgurl("common/sri-icon/verified_l_gold.svg");

      :lang(zh-hk) & {
        background-image: imgurl("common/sri-icon/verified_l_gold_tc.svg");
      }

      :lang(zh-cn) & {
        background-image: imgurl("common/sri-icon/verified_l_gold_sc.svg");
      }
    }

    &-bronze {
      background-image: imgurl("common/sri-icon/verified_l_bronze.svg");

      :lang(zh-hk) & {
        background-image: imgurl("common/sri-icon/verified_l_bronze_tc.svg");
      }

      :lang(zh-cn) & {
        background-image: imgurl("common/sri-icon/verified_l_bronze_sc.svg");
      }
    }

    &-silver {
      background-image: imgurl("common/sri-icon/verified_l_silver.svg");

      :lang(zh-hk) & {
        background-image: imgurl("common/sri-icon/verified_l_silver_tc.svg");
      }

      :lang(zh-cn) & {
        background-image: imgurl("common/sri-icon/verified_l_silver_sc.svg");
      }
    }
  }
}