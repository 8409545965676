@charset "utf-8";
/* ------------------------------------------ */

/* Override Style   */

/* ------------------------------------------ */

/* Style for Smart banner*/
.smartbanner-show {
    margin-top: 0;
}

.smartbanner-icon {
    background-image: imgurl("common/icon.png") !important;
}

.smartbanner {
    position: relative;
}

.visually-hidden{    
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

/* ------------------------------------------ */

/* END Override Style  */

/* ------------------------------------------ */