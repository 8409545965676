@charset "utf-8";
$componentsTitle: 'feature-listing';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.feature-listing {
    position: relative;
    em {
        font-style: normal;
        font-weight: bold;
        color: $colorOrange;
    }
}

.feature-listing__head {
    position: relative;
    background: $colorOrange;
    color: #FFF;
    @include fontSize(12);
    padding: 10px 15px;
    padding-left: 35px;
    h2,
    h3,
    h4,
    h5 {
        margin: 0;
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        $size: 15px;
        width: $size;
        height: $size;
        top: 50%;
        left: 15px;
        margin-top: -$size/2;
        background: imgurl("common/icons/svg/white/icon-bookmark.svg") no-repeat center center;
        background-size: 100%;
    }
}

.feature-listing__items {
    position: relative;
    background: $colorBgLtOrange;
    padding: 15px 5%;
}

.feature-listing__item {
    padding: 20px 0;
    width: 100%;
    border-bottom:1px solid $colorRowBorderBottom;
    &:last-child{
        border-bottom:0;
    }
}

.feature-listing__item-big-title {
    @extend .title-lv5;
    margin: 0 0 15px;
    @media screen and (min-width: $mim-w-tb) {
        margin-right: 110px;
    }
}

.feature-listing__item-row {
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 480px) {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    &.is1Badge {
        @media screen and (max-width: $max-w-mb) {
            min-height: 40px;
        }
    }
    &.is2Badge {
        @media screen and (max-width: $max-w-mb) {
            min-height: 80px;
        }
    }
}

.feature-listing__img {
    // width: 160px;
    // height: 120px; 
    // line-height: 120px;
    text-align: center;
    outline: 1px solid $colorPhotoBorderGrey;
    margin-right: 20px;
    background: white;
    position: relative;
    @media screen and (max-width: 480px) {
        margin-bottom:10px;
    }
    & > a {
        width: 160px;
        height: 120px;
        line-height: 120px;
        display: block;
        padding-bottom: 1px;
    }

}

.feature-listing__thumb {
    max-height: 100%;
    max-width: 100%;
    &:last-child {
        margin-right: 0;
    }
}

.feature-listing__img-holder {
    &:hover {
        .feature-listing__pop {
            @media screen and (min-width: 768px) {
                display: block;
            }
        }
        &:after{
            content:'';
            position: absolute;
            display:block;
            width:100%;
            height:30px;
            left:0;
            bottom:100%;
            z-index: 500;
        }
    }
}

.feature-listing__img:hover .feature-listing__pop {
    @media screen and (min-width: 768px) {
        display: block;
    }
}

.feature-listing__pop {
    position: absolute;
    background: #FFF;
    font-size: 0;
    line-height: normal;
    width: 430px;
    z-index: 1000;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    left:0;
    bottom:100%;
    display: none;

    img {
        // restrict the image size of the pop up 
        height:268px;
        display:block;
        margin:auto;
    }
    
    @media screen and (max-width: $max-w-tb) {
        display:none;
    }
}

.feature-listing__popcap {
    background: $colorOrange;
    color: #FFF;
    padding: 15px 30px;
    
    @media screen and (max-width: $max-w-tb) {   
        padding: 13px 28px;
    }
}

.feature-listing__popcap-title {
    @extend .title-lv5;
    em, a, div, span {
        color: #FFF;
    }
}

.feature-listing__popcap-btns {
    margin-top: 10px;
    .feature-listing__btn {
        color: #FFF;
        border-color: #FFF;
        margin: 0;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.feature-listing__item-head {
    display: block;
    margin: 0 0 5px;
}

.feature-listing__item-title {
    display: inline-block;
    margin: 0;
    margin-right:10px;
    @include fontSize(15);
    font-weight: bold;
}

.feature-listing__infos {
    position: relative;
    font-size: 0;
    margin: 0 0 5px;
}


.feature-listing__info {
    display: inline-block;
    @include fontSize(12);
    margin-right: 10px;
    padding-right: 10px;
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        $lineSize: 16px;
        height: $lineSize;
        margin-top: -$lineSize/2;
        width: 1px;
        background: $colorTxt;
    }
    &:last-child:after {
        display: none;
        margin-right: 0;
        padding-right: 0;
    }
}

.feature-listing__detail {
    width: 100%;
}

.feature-listing__desc{
    @include fontSize(14);
    line-height: 1.5;
    margin: 0 0 5px;
}

.feature-listing__specs {
    position: relative;
    font-size: 0;
    @media screen and (max-width: 640px) {
        display: block;
        margin: 10px 0;
    }
}

.feature-listing__spec {
    display: inline-block;
    @include fontSize(14);
    margin-right: 10px;
    padding-right: 10px;
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        $lineSize: 16px;
        height: $lineSize;
        margin-top: -$lineSize/2;
        width: 1px;
        background: $colorTxt;
    }
    &:last-child:after {
        display: none;
        margin-right: 0;
        padding-right: 0;
    }
}

.feature-listing__btns {}

.feature-listing__btn {
    margin: 10px 0 0;
    @extend .button;
    padding:8px 20px;
    margin-right: 10px;
    +.feature-listing__btn {
        margin-left: 0;
    }
}
.feature-listing__note-icons {
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 640px) {
        display: block;
        margin: 10px 0;
    }
}
.feature-listing__icons{
    margin-top:5px;
}
.feature-listing__icon {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    height: 22px;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
    /*
    background:imgurl("common/icons/sribadge.svg") no-repeat;
    background-position-x: -14px;
    background-position-y:0;
    background-size: 440px auto;*/
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;

    &--booth{
        width: 61px;
        height: 24px;
        background-image:imgurl("common/sri-icon/meetusatourbooth.svg");
    }
    &--small-order{
        width: 71px;
        height: 30px;
        background-image:imgurl("common/sri-icon/acceptsmallorder.svg");
    }
    /* SRI BADGE ICONS */
    &--CVS {
        background-image: imgurl("common/sri-icon/certificates-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/certificates-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/certificates-sc.svg");
        }
    }
    &--DNB {
        background-image: imgurl("common/sri-icon/d&B.svg");
    }
    &--EXH {
        background-image: imgurl("common/sri-icon/hktdcexhibitor-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/hktdcexhibitor-tc.svg");
        }

        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/hktdcexhibitor-sc.svg");
        }
    }
    &--PREMIUM {
        
    }
    &--PREMIUM1 {
        background-image: imgurl("common/sri-icon/1stpremadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/1stpremadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/1stpremadver-sc.svg");
        }
    }
    &--PREMIUM2 {
        background-image: imgurl("common/sri-icon/2ndpremadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/2ndpremadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/2ndpremadver-sc.svg");
        }
    }
    &--PREMIUM3 {
        background-image: imgurl("common/sri-icon/3rdpremadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/3rdpremadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/3rdpremadver-sc.svg");
        }
    }
    &--PREMIUM4 {
        background-image: imgurl("common/sri-icon/4thpremadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/4thpremadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/4thpremadver-sc.svg");
        }
    }
    &--PREMIUM5 {
        background-image: imgurl("common/sri-icon/5thpremadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/5thpremadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/5thpremadver-sc.svg");
        }
    }
    &--PREMIUM5P {
        background-image: imgurl("common/sri-icon/L5premadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/L5premadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/L5premadver-sc.svg");
        }
    }
    &--SRI {
        background-image: imgurl("common/sri-icon/supplierreference-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/supplierreference-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/supplierreference-sc.svg");
        }
    }
    &--ENV_COMPLIANCE {
        background-image: imgurl("common/sri-icon/complianceverified.svg");
    }
    &--QUALITY_COMPLIANCE {
        background-image: imgurl("common/sri-icon/complianceverified.svg");
    }
    &--SECURITY_COMPLIANCE {
        background-image: imgurl("common/sri-icon/complianceverified.svg");
    }
    &--SOCIAL_COMPLIANCE {
        background-image: imgurl("common/sri-icon/complianceverified.svg");
    }
    &--FREELISTING {
        background-image: imgurl("common/sri-icon/unverified-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/unverified-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/unverified-sc.svg");
        }
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */