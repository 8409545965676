@charset "utf-8";
$componentsTitle: '.header-search';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

.header-search {
    display: inline-block;
}

.header-search__btn {
    cursor: pointer;
    position: relative;
    display: inline-block;
    color: #FFF;
    background: $colorOrange;
    padding: 12px 35px;
    padding-left: 25px; //padding-left: 50px;
    border-radius: 30px;
    white-space: nowrap;
    z-index: 120;
    &:hover {
        color: #FFF;
    }
    @media screen and (max-width: 991px) {
        padding: 12px 20px;
        padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
        padding: 8px 15px;
        padding-left: 12px;
        @include fontSize(14);
        z-index: 601;
    }
    @media screen and (max-width: 350px) {
        padding: 8px 12px;
        padding-left: 8px;
    }
    &:before {
        $size: 18px;
        content: '';
        width: $size;
        height: $size;
        background: imgurl('common/icons/svg/white/icon-search.svg') no-repeat center center;
        background-size: 100%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        @media screen and (max-width: 991px) {
            $size: 16px;
            width: $size;
            height: $size;
        }
        @media screen and (max-width: 767px) {
            $size: 14px;
            width: $size;
            height: $size;
        }
        @media screen and (max-width: 350px) {
            margin-right: 5px;
        }
    }
    .search-panel-open & {
        $lineWidth: 2px;
        &:before {
            background-image: imgurl('common/icons/svg/white/icon-close.svg');
        }
    }
}

.header-search__input {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #FFF;
    background: $colorOrange;
    border: 0;
    @media screen and (max-width: 991px) {
        @include fontSize(14);
    }
    @media screen and (max-width: 767px) {
        @include fontSize(12);
    }
    &:focus {
        outline: 2px solid #bbb;
    }
    &::-webkit-input-placeholder {
        color: #FFF;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: #FFF;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: #FFF;
    }
    &:-ms-input-placeholder {
        color: #FFF;
    }
}

.header-search__input-Error {
    color: red;
    font-size: 12px;
    padding: 10px 50px 0;
    display: block;
    text-align: left;
    line-height: 1.3;
}

.header-search__panel {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 120;
    background: rgba(255, 255, 255, 0.9);
    z-index: 100;
    display: none;
    padding-top: 200px;
    .search-panel-open & {
        // display: -ms-flexbox;
        // display: -webkit-flex;
        // display: flex;
        display: block;
        overflow:scroll;
    }
    @media screen and (max-width: 767px) {
        padding: 0 20px 0;
        // align-items: center;
        // justify-content: center;
        padding-top: 60px;
        .header-search__wrapper {
            // padding-bottom: 100px;
            html.smartbanner-show & {
                // padding-bottom: 190px;
            }
            /*body[data-alert-bar="show"] & {
                padding-bottom: 170px;
                html.smartbanner-show & {
                    padding-bottom: 250px;
                }
            }*/
        }
        html.smartbanner-show & {
            padding-top: 135px;            
        }
        /*body[data-alert-bar="show"] & {
            margin-top: 120px;
            
            html.smartbanner-show & {
                margin-top: 205px;
            }
        }  */
    }
}

.header-search__wrapper {
    // margin-top: 50px;
    padding-top: 50px;
    text-align: center;
    padding-bottom: 50px;
}

.header-search__title {
    color: $colorOrange;
    display: block;
    margin: 0 0 30px;
    text-align: center;
    @extend .title-lv1;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.header-search__txt-close {
    display: none;
    vertical-align: middle;
    .search-panel-open & {
        display: inline-block;
    }
}

.header-search__txt-open {
    display: inline-block;
    vertical-align: middle;
    .search-panel-open & {
        display: none;
    }
}

$fieldSize: 80px;
$fieldSize_m: 50px;
.header-search__input-holder {
    display: block;
    position: relative;
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    // overflow: hidden;
    border: 0;
    //box-shadow: 0 2px 20px rgba(0, 0, 0, 0.04), 0 1px 10px rgba(0, 0, 0, 0.06);    //updated 20180628
    @media screen and (max-width: 767px) {
        border-radius: $fieldSize_m/2;
    }
}

.header-search__input-btn {
    position: absolute;
    width: 100px;
    height: $fieldSize;
    right: 0;
    top: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    &:focus {
        outline: 2px solid #bbb;
    }
    &:before {
        $size: 24px;
        content: '';
        width: $size;
        height: $size;
        background: imgurl('common/icons/svg/orange/icon-search.svg') no-repeat center center;
        background-size: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    @media screen and (max-width: 767px) {
        height: $fieldSize_m;
        width: 60px;
    }
    
}

.header-serach__input {
    background: #fff;    //updated 20180628
    position: relative;
    width: 100%;
    height: $fieldSize;
    border: 0;
    font-weight: bold;
    border-radius: $fieldSize/2;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(219, 219, 219, 0.6);
    -moz-box-shadow: 0px 0px 20px 1px rgba(219, 219, 219, 0.6);
    box-shadow: 0px 0px 20px 1px rgba(219, 219, 219, 0.6);
    &::-webkit-search-cancel-button {
        display: none;
    }
    &:focus {
        outline: 2px solid #bbb;
    }
    padding-left:50px;
    padding-right:160px;
    &+.header-search__autocomplete {
        display: none;
    }
    &:focus+.header-search__autocomplete,
    &+.header-search__autocomplete:focus,
    &+.header-search__autocomplete:hover {
        display: block;
    }
    &:focus+.search-panel__autocomplete.force-close,
    &+.search-panel__autocomplete.force-close:focus,
    &+.search-panel__autocomplete.force-close:hover {
        display: none;
    }
    &::-ms-clear {
        display: none;
    }
    @media screen and (max-width: 767px) {
        height: $fieldSize_m;
        padding-right: 100px;
    }
}

.header-search__filters {
    margin-bottom: 25px;
    font-size: 0;
}

.header-search__filter {
    display: inline-block;
    margin-right: 45px;
    &:last-child {
        margin-right: 0;
    }
    input {
        display: none;
        +label {
            position: relative;
            display: block;
            cursor: pointer;
            @include fontSize(14);
            color: $colorTxt;
        }
        &:checked+label {
            font-weight: bold;
            padding:5px 0;
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 3px;
                background: $colorOrange;
            }
        }
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 10px;
        margin-right: 35px;
    }
}

.header-search__topsearch {
    margin-top: 25px;
    @include fontSize(12);
    @media screen and (max-width: 767px) {
        @include fontSize(13);
    }
}

.header-search__topsearch-title {
    display: inline-block;
    vertical-align: middle;
    color: $colorOrange;
    font-weight: bold;
    margin: 0;
    margin-right: 25px;
    @media screen and (max-width: 767px) {
        margin-bottom: 10px;
    }
}

.header-search__topsearch-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    margin-right: 15px;
    @media screen and (max-width: 767px) {
        margin-bottom: 10px;
    }
}

.header-search__autocomplete {
    position: relative;
    width: 100%;
    background: #ececec;
    text-align: left;
    padding-bottom: 25px; //display:none;
}

.header-search__autocomplet-grp {
    padding: 0 50px;

    &.acActive, &:hover {
        background-color: #fcf5f1;
        .header-search__autocomplet-item {
            font-weight: bold;
            color: #000;
            text-decoration: none;
            opacity: 1
        }        
    }
}

.header-search__autocomplet-item {
    display: block;
    cursor: pointer;
    // font-weight: bold;
    @include fontSize(16);
    /*margin: 0 0 14px;*/
    // PHR-202: autocomplete add highlight style
    margin: 0;
    padding: 7px 0;
    @media screen and (max-width: 767px) {
        font-weight: normal;
        // margin-bottom: 12px;
    }
}

.header-search__autocomplet-subitem {
    display: block;
    padding-left: 15px;
    @include fontSize(12);
    margin: 0 0 14px;
}


.header-search__clear-btn {
    position: absolute;
    width:  60px;
    height: $fieldSize;
    right: 100px;
    top: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    &:focus {
        outline: 2px solid #bbb;
    }
    &:before {
        $size: 16px;
        content: '';
        width: $size;
        height: $size;
        background: imgurl('common/icons/svg/black/icon-close.svg') no-repeat center center;
        background-size: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    @media screen and (max-width: 767px) {
        right: 40px;
        height: $fieldSize_m;
    }
    
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */