@charset "utf-8";
$componentsTitle: 'footer-bar';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify

.footer-bar{
    position:relative;
    width:100%;
    background:$colorOrange;
    padding:30px 0;
    color:#FFF;
    text-align:center;
}
.footer-bar__title{
    @extend .title-lv4;
    display:inline-block;
    vertical-align: middle;
    color:#FFF;
    margin:0;
    font-weight:bold;
}
.footer-bar__desc{
    display:inline-block;
    vertical-align: middle;
    color:#FFF;
    margin:10px 60px;
    @extend .gb-fontsize-l;
    line-height:1.5;
    font-weight:300;
    &:last-child{
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 480px) {
    .footer-bar__button {
        margin-top: 20px;
    }
}

.footer-bar__arrow{
    display:inline-block;
    vertical-align: middle;
    &:before{
        $size:45px;
        content:'';
        width:$size;
        height:$size;
        display:block;
        background:no-repeat center center;
        background-image:imgurl("common/icons/svg/white/icon-long-arrow.svg");
        background-size:100%;
        @media screen and (max-width:991px) {
            $size:24px;
            width:$size;
            height:$size;
        }
    }

}
/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */