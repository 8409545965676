@charset "utf-8";
$componentsTitle: '.search-panel';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

.search-panel {
    position: relative;
    display: block;
}

.search-panel__btn {
    position: relative;
    display: inline-block;
    color: #FFF;
    background: $colorOrange;
    padding: 12px 35px;
    padding-left: 25px; //padding-left: 50px;
    border-radius: 30px;
    white-space: nowrap;
    z-index: 120;
    &:hover {
        color: #FFF;
    }
    @media screen and (max-width: 991px) {
        padding: 12px 20px;
        padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
        padding: 8px 15px;
        padding-left: 12px;
    }
    @media screen and (max-width: 350px) {
        padding: 8px 12px;
        padding-left: 8px;
    }
    &:before {
        $size: 18px;
        content: '';
        width: $size;
        height: $size;
        background: imgurl('common/icons/svg/white/icon-search.svg') no-repeat center center;
        background-size: 100%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        @media screen and (max-width: 991px) {
            $size: 16px;
            width: $size;
            height: $size;
        }
        @media screen and (max-width: 767px) {
            $size: 14px;
            width: $size;
            height: $size;
        }
        @media screen and (max-width: 350px) {
            margin-right: 5px;
        }
    }
    .search-panel-open & {
        $lineWidth: 2px;
        &:before {
            background-image: imgurl('common/icons/svg/white/icon-close.svg');
        }
    }
}

.search-panel__input {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    // color: #FFF;
    background: $colorOrange;
    border: 0;
    font-weight: bold;
    &:focus {
        outline: 2px solid #bbb;
    }
    &::-webkit-input-placeholder {
        color: #FFF;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: #FFF;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: #FFF;
    }
    &:-ms-input-placeholder {
        color: #FFF;
    }
}

.search-panel__panel {
    padding: 0 0 15px;
    position: relative;
}

.search-panel__wrapper {
    text-align: center;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 480px) {
        display:block;
    }
}

.search-panel__txt-close {
    display: none;
    vertical-align: middle;
    .search-panel-open & {
        display: inline-block;
    }
}

.search-panel__txt-open {
    display: inline-block;
    vertical-align: middle;
    .search-panel-open & {
        display: none;
    }
}

$fieldSize: 43px;
$fieldSizeM: 32px;
.search-panel__input-holder {
    display: block;
    position: relative;
    width: 100%;
    max-width: 480px;
    //margin: 0 auto; 
    margin: 0 30px;
    @media screen and (max-width: 480px) {
        margin: 10px auto 0;
    }
    //overflow: hidden;
    //box-shadow: 0 2px 20px rgba(0, 0, 0, 0.04), 0 1px 10px rgba(0, 0, 0, 0.06);
}

.search-panel__input-btn {
    position: absolute;
    width: 55px;
    padding-right: 15px;
    height: $fieldSize;
    right: 0;
    top: 0;
    background: transparent;
    border: 0;
    z-index: 50;
    cursor: pointer;
    @media screen and (max-width: 767px) {
        height: $fieldSizeM;
    }
    &:focus {
        outline: 2px solid #bbb;
    }
    &:before {
        content: '';
        $size: 20px;
        width: $size;
        height: $size;
        background: imgurl('common/icons/svg/orange/icon-search.svg') no-repeat center center;
        background-size: 100%;
        display: inline-block;
        vertical-align: middle;
    }
}

.search-panel__input {
    position: relative;
    width: 100%;
    height: $fieldSize;
    border-radius: $fieldSize/2;
    border: 0;
    background: $colorBgLtGrey;
    z-index: 15;
    // color: $colorGrey;
    font-weight: bold;
    @media screen and (max-width: 767px) {
        height: $fieldSizeM;
        border-radius: $fieldSizeM/2;
    }
    @include placeholder-style() {
        color: $colorGrey;
    }
    &::-webkit-search-cancel-button {
        display: none;
    }
    &::-ms-clear {
        display: none;
    }
    &:focus {
        outline: 2px solid #bbb;
    }
    padding-left:50px;
    padding-right:100px;
    &+.search-panel__autocomplete {
        display: none;
    }
    &:focus+.search-panel__autocomplete,
    &+.search-panel__autocomplete:focus,
    &+.search-panel__autocomplete:hover {
        display: block;
    }
    &:focus+.search-panel__autocomplete.force-close,
    &+.search-panel__autocomplete.force-close:focus,
    &+.search-panel__autocomplete.force-close:hover {
        display: none;
    }
}

.search-panel__filters {
    padding: 15px 0;
    background: $colorPurple;
    font-size: 0;
    text-align: center;
    @media screen and (max-width: 767px) {
        width: 100%;
        height: 70px;
        overflow: hidden;
        position: relative;
        &:after{
            content: "";
            display: block;
            background: -webkit-gradient(linear,left top,right top,color-stop(0%,rgba(36,38,85,0)),color-stop(100%,rgba(36,38,85,1)));
            height: 70px;
            position: absolute;
            pointer-events: none;
            right: 0;
            top: 0;
            width: calc(5% + 30px);
            z-index: 2;
        }
        .content-wrapper{
            height: 80px;
            white-space:nowrap;
            overflow-x: scroll;
            overflow-y: hide;
            -webkit-overflow-scrolling:touch;
        }
    }
}

.search-panel__filter {
    display: inline-block;
    margin-right: 45px;
    @media screen and (max-width: 767px) {
        margin-right: 30px;
    }
    &:first-child{
        @media screen and (max-width: 767px) {
            margin-left: 10px;
        }
    }
    &:last-child {
        margin-right: 0;
        @media screen and (max-width: 767px) {
            margin-right: 30px;
        }
    }
    &__title {
        @extend .title-lv5;
        color: #fff;
        display: block;
        position: relative;
        font-weight: 400;
    }
    input {
        display: none;
        +label {
            position: relative;
            display: block;
            cursor: pointer;
            @include fontSize(16);
            line-height:2em;
            color: #FFF;
        }
        &:checked+label {
            font-weight: bold;
            // padding: 5px 0;
            // color: $colorOrange;
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 3px;
                background: $colorOrange;
            }
        }
    }
}

.search-panel__topsearch {
    margin-top: 25px;
    @include fontSize(12);
    line-height:1.5em;
}

.search-panel__topsearch-title {
    display: inline-block;
    vertical-align: middle;
    color: $colorOrange;
    font-weight: bold;
    margin: 0;
    margin-right: 25px;
}

.search-panel__topsearch-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    margin-right: 15px;
}

.search-panel__autocomplete {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background: $colorBgLtGrey;
    text-align: left;
    padding-bottom: 25px; //display:none;
    margin-top: -$fieldSize/2;
    padding-top: $fieldSize/2;
    border-radius: 0 0 $fieldSize/2 $fieldSize/2;
    z-index: 5;
}

.search-panel__autocomplet-grp {
    padding: 0 50px;

    &.acActive,
    &:hover {
        // opacity: 0.8;
        background-color: #fcf5f1;

        .header-search__autocomplet-item {
            font-weight: bold;
            color: #000;
            text-decoration: none;
            opacity: 1;
        }
    }
}

.search-panel__autocomplet-item {
    display: block;
    // font-weight: bold;
    @include fontSize(16);
    margin: 0 0 14px;
}

.search-panel__autocomplet-subitem {
    display: block;
    padding-left: 15px;
    @include fontSize(12);
    margin: 0 0 14px;
}

.search-panel__keywords {
    position: relative;
    padding: 5px 0;
}

.search-panel__keywords-title {
    @include fontSize(16);
    line-height: 16px;
    font-weight: 900;
    display: inline-block;
    vertical-align: baseline;
    font-weight: bold;
    margin: 0;
    margin-right: 10px;
    @media screen and (max-width: 767px) {
        @include fontSize(14);
    }
}

.search-panel__keywords-holder {
    display: inline-block;
    vertical-align: baseline;
    @include fontSize(14);
    line-height: 16px;
    @media screen and (max-width: 767px) {
        @include fontSize(12);
    }
}

.search-panel__keywords-words {
    display: inline-block;
    vertical-align: baseline;
    font-weight: 900;
    color: $colorOrange;
    line-height: 16px;
    margin: 0;
    margin-right: 5px;
}

.search-panel__result-num {
    display: inline-block;
    vertical-align: baseline;
    line-height: 16px;
    font-size:100%;
}

.search-panel__list-control {
    position: relative;
    padding: 15px 0;
}

.search-panel__list-control-holder {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 5%;
    @media screen and (max-width: 640px) {
        display:block;
    }

}

.search-panel__sorting {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.search-panel__sorting-title {
    @include fontSize(14);
    line-height: 16px;
    font-weight: 900;
    display: inline-block;
    margin: 0;
    margin-right: 20px;
    @media screen and (max-width: 767px) {
        @include fontSize(12);
        margin-right: 10px;
    }
}

.search-panel__sorting-items {
    position: relative;
}

.search-panel__sorting-item {
    position: relative;
    border: 0;
    box-shadow: none;
    background: transparent;
    padding: 0;
    @include fontSize(14);
    margin-right: 20px;
    @media screen and (max-width: 767px) {
        margin-right: 10px;
        @include fontSize(12);
    }
    &:last-child {
        margin-right: 0;
    }
    &.is-active {
        color: $colorOrange;
        font-weight: bold;
        padding: 5px 0;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            background: $colorOrange;
        }
    }
}

.search-panel__clear-btn {
    position: absolute;
    width: $fieldSize;
    height: $fieldSize;
    right: 55px;
    top: 0;
    background: transparent;
    border: 0;
    z-index: 50;
    cursor: pointer;
    @media screen and (max-width: 767px) {
        height: $fieldSizeM;
    }
    &:focus {
        outline: 2px solid #bbb;
    }
    &:before {
        content: '';
        $size: 12px;
        width: $size;
        height: $size;
        background: imgurl('common/icons/svg/black/icon-close.svg') no-repeat center center;
        background-size: 100%;
        display: inline-block;
        vertical-align: middle;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */