@charset "utf-8";
$componentsTitle: 'side-title';

/* Style for #{$componentsTitle} */


/* ------------------------------------------ */

// TODO: Simplify
.side-title {
    position: absolute;
    left: 105%;
    bottom: 100%;
    padding: 4px 0;
    white-space:nowrap;
    text-indent: -9999px;
    //@include fontSize(14);
    @include transform-origin(left bottom);
    @include transform(rotate(90deg));
    @include transition(.4s);
    @extend .gb-fontsize-m;
    font-weight:500;
    .animated &{
        @include transform(translateY(-60px) rotate(90deg));
        opacity:0;
    }
    .animated.fadeIn &{
        @include transform(translateX(0) rotate(90deg));
        opacity:1;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: #000;
        @include transition(1s);
        .animated &{
            opacity:0;
            @include transform(scale(0, 1));
        }
        .animated.fadeIn &{
            opacity:1;
            @include transform(scale(1, 1));
        }
    }
    @media screen and (max-width: 991px) {
        display:none;
    }
}


/* END Style #{$componentsTitle}  */


/* ------------------------------------------ */