@charset "utf-8";
$componentsTitle: 'accordion';

/* Style for #{$componentsTitle} */


/* ------------------------------------------ */

// TODO: Simplify
.accordion {
    position: relative;
    display: block; 
    margin-bottom: 15px;
    @media screen and (max-width: 991px) {
        border-bottom:1px solid $colorGrey;
        padding:30px;
    }
    @media screen and (max-width: 380px) {
        padding: 20px;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
/*test*/
.accordion__btn{
    position:relative;
    font-weight:bold;
    cursor: pointer;
    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 5px;
        width: 0.4em;
        height: 0.4em;
        border-top: 2px solid $colorOrange;
        border-right: 2px solid $colorOrange;
        @include transform(rotate(45deg));
        .accordion.is-active &{
            @include transform(rotate(135deg));
        }
    }
}
.accordion__content {
    display: none;
    .accordion.is-active &{
        display:block;
    }
    padding:15px 0;
    @extend .gb-fontsize-m;
    font-weight:300;
    p{
        @extend .gb-fontsize-m;
        margin:0 0 10px;
        &:last-child{
            margin:0;
        }
    }
    a{
        // font-weight:bold;
        color:$colorOrange;
        // text-decoration: underline;
        &:hover{
            text-decoration: underline;
        }
    }
}


/* END Style #{$componentsTitle}  */


/* ------------------------------------------ */