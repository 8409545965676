@charset "utf-8";
$componentsTitle: 'nav-link';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

.nav-link__holder {
    padding-left: 90px;
    @media screen and (max-width: 991px) {
        padding-left:0;
        position: relative;
        top: -35px;
        left: 0;
        z-index: 1000;
        background: rgba(#FFF, 0.85);
        width: 100vw;
        height: 2000px;
        padding-top: 70px; //@include transition(transform .6s);
        //@include transform(translateY(-100%));
        [data-alert-bar="show"] & {
            // padding-top: 200px;
        }
        display: none;
        .mob-menu-open & {
            //@include transform(translateY(0));
            display: block;
            overflow: auto;
        }
    }
}

.nav-link__subholder {
    display: none;
    // &#mob-menu {
    //     .search-panel-open & {
    //         $blurVal: 2px;
    //         @include blurFilter($blurVal);
    //     }
    // }
    @media screen and (min-width: 992px) {
        display: none;
        &#mob-menu {
            display: block;
        }
    }
    @media screen and (max-width: 991px) {
        display: none;
        &.is-active {
            display: block;
            height: 100%;
            position: fixed;
            overflow: auto;
            padding-bottom: 160px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    &#mob-share {
        width: 90%;
        margin: 0 auto;
        color: $colorTxt;
        text-align: center;
        .button-share__holder {
            display: block;
        }
        .button-share__title {
            display: block;
            @extend .title-lv3;
            margin: 0 0 20px;
        }
        .button-share {
            margin: 0 10px 20px;
        }
    }
    &#mob-lang {
        width: 90%;
        margin: 0 auto;
        color: $colorTxt;
        text-align: center;
    }
}

.nav-link {
    //position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: $colorTxt;
    padding: 0 20px;
    @media screen and (min-width: 992px) and (max-width: 1280px) {
        padding: 0 15px;
    }
    @media screen and (max-width: 991px) {
        position: relative;
        display: block;
        margin-bottom: 30px;
        .on-submenu & {
            display: none;
            &.is-open {
                display: block;
            }
        }
    }
}

.nav-link__mob-toggle {
    display: none;
    @media screen and (max-width: 991px) {
        position:absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 30;
        .nav-link.is-open & {
            display: none;
        }
    }
}

.nav-link__link {
    z-index: 10;
    position: relative;
    display: block;
    color: $colorTxt;
    &:hover{
        opacity:1;
        color:$colorOrange;
    }
    .nav-link.is-hover & {
        z-index: 15;
    }
    &.is-active {
        color: $colorOrange;
    }
    /*
    @include fontSize(14);
    @media screen and (max-width: 1580px) {
        @include fontSize(12);
    }
    */
    @extend .gb-fontsize-m;
    @media screen and (min-width: 992px) and (max-width: 1280px) {
        @include fontSize(12);
    }
    strong {
        text-transform: uppercase;
        display: block;
        margin: 0 auto 5px;
        @include fontSize(22);
        @media screen and (max-width: 1580px) {
            @include fontSize(18);
        }
        [lang="zh-hk"] &,
        [lang="zh-cn"] & {
            display: inline;
        }
    }
    [lang="zh-hk"] &,
    [lang="zh-cn"] & {
        @include fontSize(22);
        @media screen and (max-width: 1580px) {
            @include fontSize(18);
        }
        font-weight:bold;
    }
    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 120px;
        height: 5px;
        top: 100%;
        @include centerX();
        .nav-link.is-hover & {
            width: 300%;
        }
        [lang="zh-hk"] &,
        [lang="zh-cn"] & {
            height: 10px;
        }
    }
    .nav-link.is-hover & {
        &:after {
            content: '';
            display: block;
            position: absolute;
            $size: 20px;
            width: $size;
            height: $size;
            background: #FFF;
            border-right: 1px solid $colorBorderGrey;
            border-bottom: 1px solid $colorBorderGrey;
            top: 100%;
            left: 50%;
            margin-top: 25px;
            margin-left: -$size/2;
            @include transform(rotate(45deg));
            @media screen and (max-width: 991px) {
                display: none;
            }
            [lang="zh-hk"] &,
            [lang="zh-cn"] & {
                margin-top: 34px;
            }
        }
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */