@charset "utf-8";
/* Body adv_body1*/
.body1_bgDiv {
  height: 500px;
  @media screen and (max-width: 767px) {
    height: 300px;
  }
}
.body1_bgpic {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}
.body1_bgpic_div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.body1_bgpic_maxWidth {
  width: 100%;
  max-width: 1440px;
  position: relative;
}
.body1_box {
  background-color: rgba(255, 255, 255, 0.95);
  width: 70%;
  padding: 30px;
  margin-top: -105px;
  margin-left: 80px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  @media screen and (max-width: 767px) {
    position: relative;
    width: 100%;
    margin-top: -0;
    margin-left: 0;
    box-shadow: none;
    background-color: white;
    padding: 15px;
  }
}
.body1_box_title {
  color: #fd8e14;
  font-size: 32px;
  margin-bottom: 20px;
}
.body1_box_desc {
  font-size: 16px !important;
}
.adv_body1a {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
/* Body adv_body2*/
.adv_body2 {
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
.adv_body2.mobile {
  display: none;
}
.body2_maxWidth {
  max-width: 1440px;
  width: 100%;
  padding: 24px 2rem;
}
.body2_box {
  width: 100%;
  min-height: 300px;
  margin-top: 30px;
  display: flex;
}
.body2_img {
  flex-shrink: 0;
  img {
    width: 24px;
    height: 24px;
    transform: rotate(6deg);
    display: block;
  }
  .icon-blue {
    display: none;
  }
}
.body2_left_item {
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid;
  @media screen and (min-width: 992px) {
    width: 380px;
  }
}
.body2_left_item.active,
.body2_left_item:hover {
  cursor: pointer;
  color: #130485;
  .body2_img {
    .icon-blue {
      display: block;
    }
    .icon-black {
      display: none;
    }
  }
}

.body2_left_item.active {
  @media screen and (max-width: 767px) {
    .body2_img {
      .icon-blue {
        transform: rotate(96deg);
      }
    }
  }
}

.body2_left_item:hover .body2_img path {
  fill: #130485;
}
.body2_left_item_title {
  font-size: 18px;
  font-weight: bold;
  padding-right: 12px;
}
.body2_right {
  width: 100%;
  margin-left: 30px;
  position: relative;
  padding: 30px 80px;
  @media screen and (min-width: 992px) {
    margin-left: 50px;
  }
}
.body2_right::before,
.body2_right::after {
  content: "";
  position: absolute;
  background-color: transparent;
}
.body2_right::before {
  height: 100%;
  width: 40%;
  right: 0;
  bottom: 0;
  border-bottom: 2px solid #fc5e08;
  border-right: 2px solid #fc5e08;
  border-top: 2px solid #fc5e08;
  border-end-end-radius: 40px;
  border-start-end-radius: 40px;
}
.body2_right::after {
  border-left: 2px solid #fc5e08;
  border-bottom: 2px solid #fc5e08;
  height: 30%;
  bottom: 0;
  left: 0;
  width: 90%;
  border-bottom-left-radius: 34px;
}
.body2_right_learnMoreDiv {
  position: absolute;
  bottom: 30px;
  right: 30px;
  span {
    color: #fc5e08;
    font-size: 18px;
    font-weight: bold;
  }
}
.body2_right_item {
  height: 0;
  opacity: 0;
  transition: opacity 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
}
.body2_right_item.active {
  height: auto;
  opacity: 1;
}
.body2_right_item {
  ul {
    list-style: none;
    padding: 0;
  }
  ul li {
    display: flex;
    padding-left: 3px;
  }
  ul li::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: black;
    margin-right: 16px;
    transform: rotate(45deg);
    flex-shrink: 0;
    margin-top: 11px;
  }
}
@media screen and (max-width: 767px) {
  .adv_body2.desktop {
    display: none;
  }
  .adv_body2.mobile {
    display: flex;
  }
  .body2_maxWidth {
    padding: 0 15px;
  }
  .body2_box {
    margin-top: 0;
    flex-direction: column;
  }
  .body2_left_item {
    width: 100%;
  }
  .body2_img svg {
    width: 16px;
    height: 16px;
  }
  .body2_right_item {
    font-size: 16px !important;
    p {
      margin-top: 10px;
    }
    ul {
      li::before {
        margin-top: 9px;
      }
    }
  }
}
/* Body adv_body3*/
.adv_body3 {
  min-height: 500px;
  background-color: white;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 767px) {
    display: block;
  }
}
.body3_maxWidth {
  max-width: 1440px;
}
.body3_title {
  color: #fc5e08;
  font-size: 32px;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 40px 0px;
  @media screen and (max-width: 767px) {
    padding: 40px 15px;
  }
}
.adv_body3_desc {
  padding: 0 2rem;
  font-size: 16px !important;
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
}
.body3_box {
  padding-top: 30px;
  width: 100%;
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 24px;
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 767px) {
    display: block;
    padding-right: 15px;
    padding-left: 15px;
    gap: 0;
  }
}
.body3_item {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 2px solid lightgray;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  min-height: 350px;
  @media screen and (max-width: 991px) {
    width: calc((100% - 48px)/ 3);
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 15px;
    min-height: 300px;
  }
}
.body3_item_title {
  color: #130485;
  font-size: 20px;
  font-weight: bold;
  padding: 20px 0;
  span {
    color: #130485;
  }
}
.arrow {
  position: absolute;
  bottom: 0;
  right: 20px;
  img {
    width: 45px;
  }
}
.body3_img {
  img {
    height: 90px;
    width: 90px;
    object-fit: contain;
    object-position: center;
    @media screen and (max-width: 767px) {
      width: 60px;
      height: 60px;
    }
  }
}
/* .body3_item:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
} */
/* Body adv_body4*/
.adv_body4 {
  height: auto;
  background-color: white;
  position: relative;
}
.adv_body4_title {
  color: #130485;
  font-size: 32px;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;
}
.body4_content {
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.body4_maxWidth {
  display: flex;
  margin-top: 40px;
  width: 100%;
  max-width: 1440px;
  padding: 0 2rem;
  gap: 16px;
  z-index: 2;
  position: relative;
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    flex-direction: column;
    gap: 0;
  }
}
.body4_item {
  height: 500px;
  width: 25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  @media screen and (max-width: 991px) {
    width: calc((100% - 48px) /4);
  }
  @media screen and (max-width: 767px) {
    box-shadow: none;
    width: 100%;
    height: 100%;
    min-height: 150px;
    border-top: 1px solid white;
    margin-bottom: 0;
  }
}
.body4_bg_item1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  @media screen and (max-width: 767px) {
    background-image: none !important;
    background-color: #242655;
  }
}

.body4_bg_item {
  @media screen and (max-width: 767px) {
    background-image: none !important;
    background-color: #242655;
  }
}

.body4_item:hover .body4_bg_item1,
.body4_item:hover .body4_bg_item2,
.body4_item:hover .body4_bg_item3,
.body4_item:hover .body4_bg_item4 {
  @media screen and (max-width: 767px) {
    /* background-color: #fc5e08; */
  }
}

.body4_item_bg2 {
  z-index: 1;
  position: absolute;
  background-color: gray;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.body4_item_descBox {
  position: relative;
  z-index: 2;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.body4_item_title {
  padding: 80px 20px 0;
  font-size: 20px !important;
  font-weight: bold;
  @media screen and (max-width: 767px) {
    padding: 34px 20px 0;
  }
}
.body4_item_alinkBox {
  margin-top: auto;
  padding: 24px 20px;
  font-size: 16px;
}
.body4_item_alinkBox a {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    .orange_icon {
      color: #fb5004 !important;
    }
  }
  span {
    color: white;
    font-size: 16px;
  }
}
.body4_bg_item2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 23% 0;
  @media screen and (max-width: 767px) {
    background-image: none !important;
    background-color: #242655;
  }
}
.body4_bg_item3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 46% 0;
  @media screen and (max-width: 767px) {
    background-image: none !important;
    background-color: #242655;
  }
}
.body4_bg_item4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 69% 0;
  @media screen and (max-width: 767px) {
    background-image: none !important;
    background-color: #242655;
  }
}
.body4_bg_item5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 69% 0;
  @media screen and (max-width: 767px) {
    background-image: none !important;
    background-color: #242655;
  }
}
.adv_body5 {
  position: absolute;
  top: 100px;
  width: 100%;
  left: 0;
  bottom: 0;
  height: auto;
  background-image: linear-gradient(to right, #fc5e08 15%, #fd8e14 51%);
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 767px) {
    align-items: flex-start;
    padding: 0 2rem;
  }
}

.body5_content a {
  color: white;
  border-radius: 100px;
  border: white 1px solid;
  padding: 12px 30px;
  font-size: 16px;
  &:hover {
    background-color: white;
    opacity: 1;
    color: #fc5e08 !important;
  }
  @media screen and (max-width: 767px) {
    margin-top: 20px;
    width: 50%;
    text-align: center;
    min-width: 231px;
  }
}

.body5_content {
  display: flex;
  width: 100%;
  justify-content: center;
  column-gap: 12px;
  margin: 30px 0;
  position: relative;
  z-index: 2;
  @media screen and (max-width: 767px) {
    margin-bottom: 15px;
    margin-top: 0;
    flex-wrap: wrap;
  }
}
