@charset "utf-8";
$componentsTitle: '.page-hktdc-events';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

.page-hktdc-events{
    max-width: 1920px;
    margin: 0 auto;
    .items-row--cols{
        border-bottom: none;
        .item-container {
            padding: 60px 0;
            &:last-of-type{
                border-left: 1px solid #fff;
            }
        }
        .item-arrow{
            width: 10%;
            cursor: pointer;
            a{
                display: block;
            }
        }
    }
}

.item-arrow{
    &.item-arrow--al-center{
        text-align: center;
        a{
            display:inline-block;
        }
    }
}

.page-hktdc-events__heading{
    font-size: 32px;
    margin-top: 40px;
    text-align: center;
}

.page-hktdc-events__title{
    @extend .title-lv4;
    font-weight: 300;
    color: #fc5e08;
    text-align: center;
}

.page-hktdc-events__filters{
    width: 50%;
    margin: 40px auto 60px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    text-align: center;
    @media screen and (max-width:1240px) {
        width: 80%;
    }
    @media screen and (max-width:768px) {
        width: calc(100% - 10px);
    }
    &.desktop {
        display: none;
        @media screen and (min-width: 768px) {
            display: flex;
        }
    }
    &.mobile {
        display: none;
        @media screen and (max-width: 767px) {
            display: flex;
        }
    }
    
}
.page-hktdc-events__filters-cat-container {
    /* this only appears in mobile */
    display: inline-block;
    position: relative;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .page-hktdc-events__filters-cat__title {
        // @extend .title-lv6;
        font-weight: bold;
        background-color: #fc5e08;
        margin: 0 auto;
        border-radius: 45px;
        padding: 20px 10px;
        max-width: 80%;
        font-size: 16px;
        border-right: 1px solid rgba($color: #bebebe, $alpha: 0.2);
        color: white;

        &:after {
            content: '';
            display: inline-block;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid white;
            margin-left: 10px;
        }
    }
    .page-hktdc-events__filters-condList {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        margin: 20px 0;
    }
}

$fieldSize: 90px;
.page-hktdc-events__filter{
    display: inline-block;
    $i:3;
    width: (100% / $i );
    font-weight: 300;
    padding: 25px 0;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width:566px) {
        padding: 15px 0;
        &:first-child{
            width: calc((100% - 26%)/2);
        }
        &:nth-child(2){
            width: calc((100% - 26%)/2);
        }
        &:last-child{
            width: 26%;
        }
    }
    @media screen and (max-width:407px) {
        padding: 15px 0;
        &:first-child{
            width: 38%;
        }
        &:nth-child(2){
            width: 26%;
        }
        &:last-child{
            width: 36%;
        }
    }
    &.is-active{
        background-color: #fc5e08;
        color: #fff;
        border-top: none;
        border-bottom: none;
        position: relative;
        &:after{
            content: '';
            display: block;
            position: absolute;
            width: 15px;
            height: 15px;
            background: #fc5e08;
            border-right: 1px solid #fc5e08;
            border-bottom: 1px solid #fc5e08;
            top: 50%;
            left: 50%;
            margin-top: 25px;
            margin-left: -10px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            @media screen and (max-width:566px) {
                top: 35px;
            }
        }
    }
    &:first-child{
        border-left: 1px solid #e5e5e5;
        border-top-left-radius: $fieldSize/2;
        border-bottom-left-radius: $fieldSize/2;
    }
    &:last-child{
        border-right: 1px solid #e5e5e5;
        border-top-right-radius: $fieldSize/2;
        border-bottom-right-radius: $fieldSize/2;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */