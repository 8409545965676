@charset "utf-8";
$componentsTitle: 'pagination';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.pagination {
    padding: 60px 0;
    color: $colorTxt;
    text-align: center;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 480px) {
        padding: 30px 0;
    }
    &.mobile {
        display: none;
        @media screen and (max-width: 480px) {
            display: block;
        }
    }
    &.desktop {
        @media screen and (max-width: 480px) {
            display: none;
        }
    }
    >.content-wrapper {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.pagination__btn-prev,
.pagination__btn-next {
    @include fontSize(16);
    @extend .button;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle; //border-color: $colorTxt;
    //color: $colorTxt;
    @media screen and (max-width: 640px) {
        @include fontSize(14);
    }
    @media screen and (max-width:480px) {
        //margin-top: 15px;
        position: relative;
        @include fontSize(0);
        $size: 30px;
        width: $size;
        height: $size;
        padding: 0;
        border-radius: 50%;
        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 7.2px;
            height: 7.2px;
            border-top: 1px solid $colorOrange;
            border-right: 1px solid $colorOrange;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -4px;
        }
        &:hover:before {
            border-top: 1px solid #FFF;
            border-right: 1px solid #FFF;
        }
    }
    &:hover {
        opacity: 1;
    }
}

.pagination__btn-prev:before {
    @media screen and (max-width: 480px) {
        @include transform(rotate(225deg));
        margin-left: -2px;
    }
}

.pagination__btn-next:before {
    @media screen and (max-width: 480px) {
        @include transform(rotate(45deg));
        margin-left: -6px;
    }
}

.pagination__pages {
    vertical-align: middle;
    font-weight: bold;
    margin: 0 20px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        margin: 0 10px;
    }
    @media screen and (max-width: 480px) {
        margin: 6px;
    }
    >span,
    >div {
        @include fontSize(16);
        display: inline-block;
        vertical-align: middle;
        @media screen and (max-width: 640px) {
            @include fontSize(14);
        }
        @media screen and (max-width:480px) {
            @include fontSize(12);
        }
    }
}

.pagination__current {
    margin: 0 10px;
    .pagination__current-pg {
        display: inline-block;
        $size: 40px;
        width: $size;
        height: $size;
        line-height: $size - 2;
        padding: 0;
        text-align: center;
        color: $colorTxt;
        border: 1px solid $colorTxt;
        border-radius: 50%;
        font-weight: bold;
        @media screen and (max-width: 480px) {
            @include fontSize(12);
        }
        &:focus {
            // outline: none;
            border-color: $colorOrange;
        }
    }
}

.pagination__total {
    display: inline-block;
    margin-left: 10px;
}

.pagination__page {
    margin: 0 5px;
    @media screen and (max-width: 767px) {
        margin: 0 4px;
    }
    &.is-active {
        color: $colorOrange;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */