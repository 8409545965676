@charset "utf-8";
$componentsTitle: 'header';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

header {
    //height: $headerHeight;
    background: transparent; //background: red;
    //position: fixed;
    //position: relative;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 500;
    color: #FFF;
    box-sizing: content-box;
    @include transition(height .3s); //height:120px;
    padding: 35px 0 30px;
    @media screen and (max-width: 991px) {
        padding:35px 0;
    }
    .header--small & {
        color: $colorTxt;
        height: $headerHeightSmall;
        background: rgba(#FFF, .95);
        box-shadow: 0px 3px 4px rgba(150, 150, 150, 0.2);
    }
    /*
    @media screen and (max-width:991px) {
        height: $headerHeightTb;
        .header--small & {
            height: $headerHeightTb;
            background: rgba(#FFF, .95);
        }
    }
    @media screen and (max-width:767px) {
        height: $headerHeightMb;
        .header--small & {
            height: $headerHeightTb;
            height: $headerHeightMb;
            background: rgba(#FFF, .95);
        }
    }
    */
}

.header__wrapper {
    @extend .content-wrapper;
    max-width: 1440px;
    display: block;
    @media screen and (max-width: 991px) {
        width: $headerGridPercentS;
    }
}

.header__top-overlay {
    position: absolute;
    display: none;
    width: 100%;
    height: 70px;
    top: 0;
    left: 0;
    background: white;
    padding: 0;
    margin: 0;
    z-index: 600;
    .search-panel-open & {
        @media screen and (max-width: 767px) {
            /* mobile only */
            display: block;
        }
    }
}

.header__top {
    /*
    position: relative;
    text-align: right;
    padding: 10px 0 0;
    &+.header__main {
        padding-top: 0;
    }*/
    .search-panel-open & {
        pointer-events: none;
        opacity: 0.5;
        $blurVal: 2px;
        @include blurFilter($blurVal);
    }
    z-index:1000;
    width:100%;
    top:0;
    padding: 10px 0 0;
    position:absolute;
    text-align: right;
    @media screen and (max-width: 991px) {
        display: none;
    }
}

.header__right {
    right: 0;
    @media screen and (max-width: 991px) {
        top: -20px;
        // @include transform(translateY(-50%));
        position: absolute;
        margin-right: 18px;
    }
    @media screen and (max-width: 767px) {
        top: -13px;
    }
}
.header__right.close {
    z-index: 1050;
}

.header__main {
    position: relative;
    padding: 10px 0;
    @media screen and (max-width: 991px) {
        padding:0;
    }
    .header__wrapper {
        margin: 0 auto;        
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        
        @media screen and (max-width: 991px) {
            margin: 0;
        }
    }
}

.header__item {
    //position:relative;
    display: inline-block;
    width: 100%;
    text-align: center;
}

.header__mob-lang {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    $size: 20px;
    width: $size;
    height: $size;
    background: imgurl('common/icons/svg/black/icon-lang.svg') no-repeat center center;
    background-size: 100%;
    margin-left: 15px;
    @media screen and (max-width: 350px) {
        margin-left: 12px;
    }
    .search-panel-open & {
        visibility: hidden;
    }
}

.header__mob-share {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    $size: 20px;
    width: $size;
    height: $size;
    background: imgurl('common/icons/svg/black/icon-share-header.svg') no-repeat center center;
    background-size: 100%;
    margin-left: 15px;
    @media screen and (max-width: 350px) {
        margin-left: 12px;
    }
    .search-panel-open & {
        visibility: hidden;
    }
}

.header__mob-menu {
    $color: #333;
    $size: 20px;
    $lineWidth: 2px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: $size;
    height: $size*0.8;
    margin-left: 15px;
    @media screen and (max-width: 350px) {
        margin-left: 12px;
    }
    border-top: $lineWidth solid $color;
    &:before,
    &:after {
        content: '';
        background: $color;
        position: absolute;
        display: block;
        width: 100%;
        height: $lineWidth;
        left: 0;
    }
    &:before {
        top: 50%;
        margin-top: -$lineWidth;
    }
    &:after {
        bottom: 0;
    }
    .search-panel-open & {
        visibility: hidden;
    }
}
.header__submenu-back-container {
    
    text-align: left;
    height: 20px;
    padding: 25px 10px;

        
    display: none;
    @media screen and (max-width: 991px) {
        visibility: hidden;
        display: block;
    }
    .on-submenu & {
        @media screen and (max-width: 991px) {
            visibility: visible;
        }
    }
}
.header__submenu-back {
    position: relative;
    // [data-alert-bar="show"] & {
    //     top: 168px;
    // }
    text-align: left;
    font-weight: bold;
    left: 5%;
    color: #1a1a1a;
    text-decoration: none;
    display: inline-block;
    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-top: -4px;
        margin-right: 5px;
        width: 0.4em;
        height: 0.4em;
        border-top: 2px solid $colorOrange;
        border-right: 2px solid $colorOrange;
        @include transform(rotate(225deg));
    }


}

.header__mob-close {
    $color: #333;
    $size: 20px;
    $lineWidth: 2px;
    display: none; //top: 25px;
    [data-alert-bar="show"] & {
        //top: 100px;
    }
    right: 5%;
    width: $size;
    height: $size;
    @include transform-origin(center center);
    @include transform(rotate(45deg));
    @media screen and (max-width: 991px) {
        .mob-menu-open & {
            display: block;
            z-index:1500;
        }
    }
    &:before,
    &:after {
        content: '';
        background: $color;
        position: absolute;
        display: block;
    }
    &:before {
        width: 100%;
        height: $lineWidth;
        left: 0;
        top: 50%;
        margin-top: -$lineWidth/2;
    }
    &:after {
        height: 100%;
        width: $lineWidth;
        top: 0;
        left: 50%;
        margin-left: -$lineWidth/2;
    }
}

.header__mob-lang,
.header__mob-share,
.header__mob-menu,
.header__mob-close {
    @media screen and (min-width: 992px) {
        display: none;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */