@charset "utf-8";
$componentsTitle: 'sec-break';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.sec-break {
    position: relative;
    width: 90%;
    margin: 0 auto;
    height: 2px;
    background: $colorBorderGrey;
    &.animated {
        opacity: 0;
        @include transform-origin(right center);
        &.fadeIn {
            @include animation(sec-break .4s);
        }
    }
}

@include keyframes(sec-break) {
    0% {
        @include transform(scale(0, 1));
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        @include transform(scale(1, 1));
        opacity: 1;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */