@charset "utf-8";
$componentsTitle: 'organic-listing';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.organic-listing {
    position: relative;
    em {
        font-style: normal;
        font-weight: bold;
        color: $colorOrange;
    }
}

.organic-listing__items {
    position: relative;
    background: #FFF;
    padding: 15px 2%;
}

.organic-listing__item {
    padding: 25px 0;
    width: 100%;
    border-bottom: 1px solid $colorRowBorderBottom;
    position: relative;
    &:last-child {
        border-bottom: 0;
    }
}

.organic-listing__item-big-title {
    @extend .title-lv5;
    display: inline-block;
    margin: 0 0 15px;
    margin-right: 10px;
    &+.organic-listing__note-icons {
        margin: 5px 0;
    }
    @media screen and (min-width: $mim-w-tb) {
        margin-right: 110px;
    }
}

.organic-listing__item-row {
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 991px) {
        &.organic-listing__item-row--multiimg {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
    }
    @media screen and (max-width: 767px) {
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    @media screen and (max-width: 480px) {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    &.is1Badge {
        @media screen and (max-width: $max-w-mb) {
            min-height: 40px;
        }
    }
    &.is2Badge {
        @media screen and (max-width: $max-w-mb) {
            min-height: 80px;
        }
    }
    &.is1Badge,
    &.is2Badge {
        .organic-listing__detail {
            padding-right: 100px;
        }
    }
}

.organic-listing__img {
    position: relative;
    padding-right: 20px;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    @media screen and (max-width: 480px) {
        width: 100%;
        margin-bottom: 10px;
        padding-right: 0px;
    }
    .organic-listing__item-row--multiimg & {
        @media screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.organic-listing__thumb {
    // padding: 1px; //PHR-464
    max-width:100%;
    max-height:100%;
	vertical-align: middle;
	// 5px is fixing vertical-align: middle
    margin-bottom: 5px;
}

.organic-listing__img-holder {
    float: left;
    outline: 1px solid $colorPhotoBorderGrey;
    
    width: 160px;
    height: 90px; //PHR-400, from 100px to 90px
    line-height: 90px; //PHR-400, from 100px to 90px
    text-align: center;

    @media screen and (max-width: 991px) {
        width: 120px;
        height: 68px; //PHR-400, downsize 0.6 - from 76 to 68px
        line-height: 68px; //PHR-400, downsize 0.6 - from 76 to 68px
        margin-bottom: 10px;
    }
    @media screen and (max-width: 640px) {
        width: 100px;
        height: 57px; //PHR-400, downsize 0.6 - from 63 to 57px
        line-height: 57px; //PHR-400, downsize 0.6 - from 63 to 57px
        margin-bottom: 10px;
    }

    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
    /* 
    Sam: PHR-454, disable hover effect when OL no longer has popup. As if element has hover effect, IOS treats as click. It causes hyperlink need tap twice on IOS.
    ====================================================
    &:hover {
        .organic-listing__pop {
            display: block;
        }
        &:after{
            content:'';
            position: absolute;
            display:block;
            width:100%;
            height:30px;
            left:0;
            bottom:100%;
            z-index: 500;
        }
    }
    ======================= */
}

.organic-listing__pop {
    position: absolute;
    background: #FFF;
    font-size: 0;
    width: 480px;
    z-index: 1000;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    left:0;
    bottom:100%;
    margin-bottom:30px;
    display: none;
    &:hover {
        display: block;
    }
}

.organic-listing__popcap {
    background: $colorOrange;
    color: #FFF;
    padding: 15px 30px;
}

.organic-listing__popcap-title {
    @extend .title-lv5;
    em {
        color: #FFF;
    }
}

.organic-listing__popcap-btns {
    margin-top: 10px;
    .organic-listing__btn {
        color: #FFF;
        border-color: #FFF;
        margin: 0;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.organic-listing__item-head {
    display: block;
    margin: 0 0 5px;
}

.organic-listing__item-title {
    display: inline-block;
    margin: 0;
    margin-right: 10px;
    @include fontSize(15);
    font-weight: bold;
}

.organic-listing__infos {
    position: relative;
    font-size: 0;
    margin: 0 0 5px;
}

.organic-listing__info {
    display: inline-block;
    @include fontSize(12);
    margin-right: 10px;
    padding-right: 10px;
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        $lineSize: 16px;
        height: $lineSize;
        margin-top: -$lineSize/2;
        width: 1px;
        background: $colorTxt;
    }
    &:last-child:after {
        display: none;
        margin-right: 0;
        padding-right: 0;
    }
}

.organic-listing__specs {
    position: relative;
    font-size: 0;
    margin: 0 0 5px;
}

.organic-listing__spec {
    display: inline-block;
    @include fontSize(14);
    margin-right: 10px;
    padding-right: 10px;
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        $lineSize: 16px;
        height: $lineSize;
        margin-top: -$lineSize/2;
        width: 1px;
        background: $colorTxt;
    }
    &:last-child:after {
        display: none;
        margin-right: 0;
        padding-right: 0;
    }
}

.organic-listing__desc {
    @include fontSize(14);
    line-height: 1.5;
    margin: 0 0 5px;
}

.organic-listing__btn {
    margin: 0;
    margin-right: 5px;
    margin-bottom: 5px;
    @extend .button;
    padding: 8px 20px;
    @media screen and (max-width: 480px) {
        margin-left: 0;
        &+.organic-listing__btn {
            margin-left: 0;
        }
    }
}

.organic-listing__note-icons {
    display: inline-block;
    vertical-align: middle;
}

.organic-listing__icons {
    margin: 0 0 10px;
}

.organic-listing__icon {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    height: 22px;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    &--booth {
        width: 61px;
        height: 24px;
        background-image: imgurl("common/sri-icon/meetusatourbooth.svg");
    }
    &--small-order {
        width: 71px;
        height: 30px;
        background-image: imgurl("common/sri-icon/acceptsmallorder.svg");
    }
    /* SRI BADGE ICONS */
    &--CVS {
        background-image: imgurl("common/sri-icon/certificates-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/certificates-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/certificates-sc.svg");
        }
    }
    &--DNB {
        background-image: imgurl("common/sri-icon/d&B.svg");
    }
    &--EXH {
        background-image: imgurl("common/sri-icon/hktdcexhibitor-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/hktdcexhibitor-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/hktdcexhibitor-sc.svg");
        }
    }
    &--PREMIUM {}
    &--PREMIUM1 {
        background-image: imgurl("common/sri-icon/1stpremadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/1stpremadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/1stpremadver-sc.svg");
        }
    }
    &--PREMIUM2 {
        background-image: imgurl("common/sri-icon/2ndpremadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/2ndpremadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/2ndpremadver-sc.svg");
        }
    }
    &--PREMIUM3 {
        background-image: imgurl("common/sri-icon/3rdpremadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/3rdpremadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/3rdpremadver-sc.svg");
        }
    }
    &--PREMIUM4 {
        background-image: imgurl("common/sri-icon/4thpremadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/4thpremadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/4thpremadver-sc.svg");
        }
    }
    &--PREMIUM5 {
        background-image: imgurl("common/sri-icon/5thpremadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/5thpremadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/5thpremadver-sc.svg");
        }
    }
    &--PREMIUM5P {
        background-image: imgurl("common/sri-icon/L5premadver-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/L5premadver-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/L5premadver-sc.svg");
        }
    }
    &--SRI {
        background-image: imgurl("common/sri-icon/supplierreference-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/supplierreference-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/supplierreference-sc.svg");
        }
    }
    &--ENV_COMPLIANCE {
        background-image: imgurl("common/sri-icon/complianceverified.svg");
    }
    &--QUALITY_COMPLIANCE {
        background-image: imgurl("common/sri-icon/complianceverified.svg");
    }
    &--SECURITY_COMPLIANCE {
        background-image: imgurl("common/sri-icon/complianceverified.svg");
    }
    &--SOCIAL_COMPLIANCE {
        background-image: imgurl("common/sri-icon/complianceverified.svg");
    }
    &--FREELISTING {
        background-image: imgurl("common/sri-icon/unverified-en.svg");
        :lang(zh-hk) & {
            background-image: imgurl("common/sri-icon/unverified-tc.svg");
        }
        :lang(zh-cn) & {
            background-image: imgurl("common/sri-icon/unverified-sc.svg");
        }
    }
}

.organic-listing__more {
    display: inline-block;
    position: relative;
    @extend .title-lv5;
    color: $colorOrange;
    padding: 30px 0;
}

.organic-listing__more-link {
    padding-right: 50px;
    &:after {
        content: '';
        display: block;
        $size: 40px;
        width: $size;
        height: $size;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -$size/2;
        background: imgurl("common/icons/svg/orange/icon-long-arrow.svg") no-repeat center center;
        background-size: 100%;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */