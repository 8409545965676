@charset "utf-8";
$componentsTitle: 'alert-template';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.alert-template {
    //width:300px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 35px 20px 20px;
    background: #dadada;
    color: $colorTxt;
    z-index: 550;
    position: fixed;
    bottom: 40px;
    right: 45px;
    max-width: 350px;
    width: 90%;
    @media screen and (max-width: 767px) {
        bottom: 25px;
        right: 5%;
        text-align: center;
        z-index: 451;
    }
    [data-cookies-bar="show"] & {
        bottom: 85px;
        @media screen and (max-width: 767px) {
            bottom: 120px;
        }
    }
    .alert-template__btn {
        padding: 10px 25px;
        @media screen and (max-width: 767px) {
            padding: 8px 18px;
        }
    }
    .feedback-panel__alert.is-hidden & {
        display: none;
    }
    a{
        text-decoration: underline;
        &:hover{
            opacity:1;
            color:$colorOrange;
        }
    }
    .search-panel-open & {
        z-index: 2;
    }
}

.alert-template__msg {
    margin-bottom: 10px;
    p {
        @extend .gb-fontsize-m;
        line-height: 1.5em;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.alert-template__btns {
    white-space: nowrap;
}

.alert-template__close {
    display: block;
    $size: 16px;
    width: $size;
    height: $size;
    background: imgurl('common/icons/svg/black/icon-close.svg') no-repeat center center;
    background-size: 100%;
    position: absolute;
    right: 12px;
    top: 12px;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    &:hover {
        background-image: imgurl('common/icons/svg/orange/icon-close.svg');
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */