@charset "utf-8";
$componentsTitle: 'comformance';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify
.comformance{
    position:relative;
    text-align: center;
    margin:0 0 15px;
    @media screen and (max-width: 991px) {
        display:none;
    }
}
.comformance__title {
    font-size: 14px;
}
.comformance__item{
    display:inline-block;
    vertical-align: middle;
    margin:0 10px;
    @media screen and (max-width: 1180px) {
        margin:0 4px;
    }
}

/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */