@charset "utf-8";
$componentsTitle: 'success-stories';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.success-stories {
    position: relative;
    padding: 90px 0;
    background: imgurl('/common/img-home-successstory-bg.jpg') no-repeat center center;
    background-size: cover;
    @media screen and (max-width: 768px) {
        padding: 45px 0;
    }
    .content-wrapper {
        // &:before {
        //     content: '';
        //     position: absolute;
        //     display: block;
        //     $size: 90px;
        //     width: $size;
        //     height: $size;
        //     background: imgurl('/common/icons/icon-quote-null.png') no-repeat center center;
        //     background-size: 100%;
        //     left: 0;
        //     top: -30px;
        //     @include transform(rotate(180deg));
        //     @media screen and (max-width: 991px) {
        //         $size:60px;
        //         width: $size;
        //         height: $size;
        //     }
        //     @media screen and (max-width:767px) {
        //         $size: 45px;
        //         width: $size;
        //         height: $size;
        //         top: -45px;
        //     }
        // }
        // &:after {
        //     z-index: 10;
        //     $size: 180px;
        //     content: '';
        //     position: absolute;
        //     display: block;
        //     width: $size;
        //     height: $size;
        //     background: imgurl('/common/icons/icon-quote-null.png') no-repeat center center;
        //     background-size: 100%;
        //     right: 30px;
        //     bottom: -30px;
        //     @media screen and (max-width: 991px) {
        //         $size:140px;
        //         width: $size;
        //         height: $size;
        //     }
        //     @media screen and (max-width:767px) {
        //         $size: 70px;
        //         width: $size;
        //         height: $size;
        //         right: 0;
        //     }
        // }
        $aniTime:.6s;
        $aniScale:45px;
        &.animated {
            &:before,
            &:after {
                opacity: 0;
            }
            &.fadeIn {
                opacity: 1;
                &:before {
                    @include animation(ss-quote-open $aniTime);
                }
                &:after {
                    @include animation(ss-quote-close $aniTime);
                }
            }
        }
        @include keyframes(ss-quote-open) {
            0% {
                @include transform(rotate(180deg) translate($aniScale,
                $aniScale));
                opacity: 0;
            }
            100% {
                @include transform(rotate(180deg) translate(0,
                0));
                opacity: 1;
            }
        }
        @include keyframes(ss-quote-close) {
            0% {
                @include transform(translate($aniScale,
                $aniScale));
                opacity: 0;
            }
            100% {
                @include transform(translate(0,
                0));
                opacity: 1;
            }
        }
    }
}

.success-stories__title {
    position: relative;
    @extend .title-lv2;
    color: $colorOrange;
    text-align: center;
    font-weight: 300;
    margin: 0 0 30px;
}

.success-stories__slider {
    position: relative;
    overflow: hidden;
    .success-stories .content-wrapper.animated & {
        opacity: 0;
    }
    .success-stories .content-wrapper.animated.fadeIn & {
        @include animation(ss-fadein .6s .3s);
    }
    @include keyframes(ss-fadein) {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

.success-stories__slide {
    font-size: 0;
}

.success-stories__img {
    display: block;
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
    img {
        width: 100%;
    }
    @media screen and (max-width:640px) {
        width: 100%;
        margin-bottom: 30px;
    }
}

.success-stories__info {
    display: block;
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    @media screen and (max-width: 640px) {
        width:100%;
        padding: 0;
    }
        opacity: 0;
    .swiper-slide-active & {
        @include animation(ss-fadeleft .6s .2s);
    }
    @include keyframes(ss-fadeleft) {
        0% {
            @include transform(translateX(-10%));
            opacity: 0;
        }
        100% {
            @include transform(translateX(0));
            opacity: 1;
        }
    }
    .swiper-slide-next & {
        @include animation(ss-fadeleft .6s .2s);
    }
    .swiper-slide-visible & {
        @include animation(ss-fadeleft .6s .2s);
    }
}

.success-stories__more {
    @media screen and (max-width: 640px) {
        margin: 30px auto;
    }
}

.success-stories__info-title {
    @extend .gb-fontsize-xl;
    font-weight: bold;
    margin: 0 0 10px;
}

.success-stories__info-desc {
    @extend .gb-fontsize-m;
    line-height: 1.5em;
    margin: 0;
    a {
        @extend .gb-fontsize-s;
        text-decoration: underline;
        color: $colorOrange;
        margin-left: 5px;
        font-weight: bold;
    }
    @media screen and (max-width:768px) {
        span{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            max-height: 6em; 
            -webkit-box-orient: vertical;
        }
    }
}

.success-stories__top {
    position: relative;
    padding: 0 60px;
}

.success-stories__control {
    // position: absolute;
    // top: 50%;
    // left: 0;
    // width: 100%;
    // height: 0;
}

.success-stories__btn-prev,
.success-stories__btn-next {
    width: $btnSize;
    height: $btnSize;
    border: 1px solid $colorGrey;
    display: block;
    border-radius: 50%;
    position: absolute;
    @include transform-origin(center center);
    top: 50%;
    margin-top: -$btnSize/2;
    background: none;
    cursor: pointer;
    &:after {
        content: '';
        position: absolute;
        display: block;
        width: $arrowSize;
        height: $arrowSize;
        border-top: 1px solid $colorGrey;
        border-right: 1px solid $colorGrey;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -$arrowSize*0.38;
        margin-left: -$arrowSize*0.75;
    }
}

.success-stories__btn-prev {
    left: 0;
    @include transform(rotate(225deg));
    font-size: 0;
    line-height: 0;
}

.success-stories__btn-next {
    right: 0; //left:0;
    @include transform(rotate(45deg));
    font-size: 0;
    line-height: 0;
}

.success-stories__bottom {
    position: relative;
}

.success-stories__pagination {
    padding: 15px 0;
    text-align: center;
    .success-stories .content-wrapper.animated & {
        opacity: 0;
    }
    .success-stories .content-wrapper.animated.fadeIn & {
        @include animation(ss-fadein .6s .4s);
    }
    .swiper-pagination-bullet {
        cursor: pointer;
        width: 15px;
        height: 3px;
        border: 0;
        border-radius: 0;
        margin: 0 3px;
        &.swiper-pagination-bullet-active {
            background: $colorOrange;
        }
    }
}

.success-stories__btn-row {
    margin-top: 30px;
    text-align: center;
    .success-stories .content-wrapper.animated & {
        opacity: 0;
    }
    .success-stories .content-wrapper.animated.fadeIn & {
        @include animation(ss-fadein .6s .6s);
    }
}

.success-stories__slider-pause{
    display: inline-block;
    position: absolute;
    right: 35px;
    bottom: 30px;
    z-index: 10;
    .pause-button{
        display: block;
        box-sizing: border-box;
        width: 0;
        height: 15px;
        cursor: pointer;
        border-color: transparent transparent transparent #1a1a1a;
        transition: 100ms all ease;
        cursor: pointer;
        // paused state
        border-style: double;
        border-width: 0px 0 0px 15px;
    }
    &.is-active{
        .pause-button {
            border-style: solid;
            border-width: 8px 0 8px 12px;
        }
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */