/* Loading Icon */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  .content {
    display: flex;
    flex-direction: column;
    .message {
      color: #888888;
      &:after {
        content: ' .';
        animation: dots 1s steps(5, end) infinite;
      }
    }
    .loading {
      margin: 0 auto 10px;
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      border-top: 5px solid #888888;
      border-right: 5px solid #888888;
      border-left: 5px solid #888888;
      width: 40px;
      height: 40px;
      -webkit-animation: spin 0.7s linear infinite;
      animation: spin 0.7s linear infinite;
    }
  }
}

@keyframes dots {
  0%,
  20% {
    color: transparent;
    text-shadow: 0.25em 0 0 transparent, 0.5em 0 0 transparent;
  }
  40% {
    color: #333333;
    text-shadow: 0.25em 0 0 transparent, 0.5em 0 0 transparent;
  }
  60% {
    text-shadow: 0.25em 0 0 #333333, 0.5em 0 0 transparent;
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #333333, 0.5em 0 0 #333333;
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* END Loading Icon */
