    @charset "utf-8";
    $componentsTitle: 'hktdc-events';
    /* Style for #{$componentsTitle} */

    /* ------------------------------------------ */

    // TODO: Simplify
    .hktdc-events {
        margin-top: 60px;
        margin-bottom: 60px;
        @media screen and (max-width: 991px) {
            margin-top: 0;
        }
    }

    .hktdc-events__row {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;

        .button-row {

            margin: 50px auto;

            &:last-child {
                margin-bottom: 0;
            }
            @media screen and (max-width: 767px) {
                margin-top: 20px;
            }
        }
    }

    .hktdc-events__col {
        width: 45%;
        padding: 0 30px;
        opacity:0;
        @media screen and (max-width: 991px) {
            &:first-child {
                padding: 30px 0 0;
            }
        }
        &.animated.fadeIn{
            @include animation(hktdc-events .3s);
            &:nth-child(2) {
                @include animation-delay(.3s);
            }
            &:nth-child(3) {
                @include animation-delay(.6s);
            }
            @include keyframes(hktdc-events) {
                0%   {
                    opacity:0;
                    @include transform(translateY(45px));
                }
                100%   {
                    opacity:1; 
                    @include transform(translateY(0));
                }
            }
        }
        @media screen and (max-width: 1280px) {
            padding: 0 15px;
        }
        // @media screen and (max-width: 991px) {
        //     width: 50%;
        //     &:first-child {
        //         width: 100%;
        //         text-align: center;
        //         padding: 30px 0;
        //     }
        // }
        @media screen and (max-width: 767px) {
            width: 100%;
            padding: 0;
            margin-bottom: 20px;
            &:first-child {
                padding: 20px 0 0;
            }
        }
    }

    .hktdc-events__col-full {
        width: 100%;
    }
    .hktdc-events__col-55 {
        @media screen and (min-width: 768px) {
            width: 55%;
        }        
    }

    .hktdc-events__tag {
        position: relative;
        @extend .title-lv4;
        color: $colorOrange;
        padding: 0 30px;
        text-transform: uppercase;
        @media screen and (max-width: 991px) {
            display:block;
            padding: 25px 0;
            text-align: center;
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100vw;
                left: -3.19148%;
                height: 100%;
                top: 0;
                z-index: 0; //background: $colorOrange;
                background: rgb(253, 136, 17);
                /* Old browsers */
                background: -moz-linear-gradient(left, rgba(253, 136, 17, 1) 0%, rgba(251, 72, 5, 1) 50%, rgba(253, 136, 17, 1) 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(left, rgba(253, 136, 17, 1) 0%, rgba(251, 72, 5, 1) 50%, rgba(253, 136, 17, 1) 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, rgba(253, 136, 17, 1) 0%, rgba(251, 72, 5, 1) 50%, rgba(253, 136, 17, 1) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fd8811', endColorstr='#fd8811', GradientType=1);
                /* IE6-9 */
                @media screen and (max-width: 991px) {
                    left: -5.55556%;
                }
            }
            span {
                position: relative;
                z-index: 10;
            }
            color: #FFF;
            width:100%;
        }
    }

    .hktdc-events__title {
        @extend .title-lv3;
        color: $colorOrange;
        font-weight: 300;
        margin: 0 0 15px;
    }

    .hktdc-events__intro {
        line-height: 1.5em;
        @extend .gb-fontsize-m;
    }

    .hktdc-events__filter {
        position: relative;
        display: inline-block;
        margin-right: 30px;
        padding: 5px 0;
        @include fontSize(18);
        @media screen and (max-width: 767px) {
            margin-right: 15px;
            @include fontSize(16);
        }
        &:last-child {
            margin-right: 0;
        }
        &.is-active {
            font-weight: bold;
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                display: block;
                background: #000;
            }
        }
    }
    

    .home-hktdc-events__filters{
        width: 50%;
        margin: 20px auto 30px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        text-align: center;
        @media screen and (max-width:1240px) {
            width: 55%;
        }
        @media screen and (max-width:768px) {
            width: 70%;
        }
        &.desktop {
            display: none;
            @media screen and (min-width: 768px) {
                display: flex;
            }
        }
        &.mobile {
            display: none;
            @media screen and (max-width: 767px) {
                margin: 10px auto 15px;
                display: flex;
            }
        }
        
    }
    $fieldSize: 90px;
    .home-hktdc-events__filter{
        display: inline-block;
        $i:3;
        width: (100% / $i );
        font-weight: 300;
        padding: 15px 0;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        cursor: pointer;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        &.is-active{
            background-color: #fc5e08;
            color: #fff;
            border-top: none;
            border-bottom: none;
            position: relative;
        }
        &:first-child {
            width: 25%;
            border-left: 1px solid #e5e5e5;
            border-top-left-radius: $fieldSize/2;
            border-bottom-left-radius: $fieldSize/2;
        }
        &:nth-child(3) {
            width: 40%;
        }
        &:last-child {
            border-right: 1px solid #e5e5e5;
            border-top-right-radius: $fieldSize/2;
            border-bottom-right-radius: $fieldSize/2;
        }
    }
    .home-hktdc-events__filters-cat-container {
        /* this only appears in mobile */
        display: inline-block;
        position: relative;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        .home-hktdc-events__filters-cat__title {
            // @extend .title-lv6;
            font-weight: bold;
            background-color: #fc5e08;
            margin: 0 auto;
            border-radius: 45px;
            padding: 20px 10px;
            @media screen and (max-width: 767px) {
                padding: 12px 10px;
            }
            max-width: 80%;
            font-size: 16px;
            border-right: 1px solid rgba($color: #bebebe, $alpha: 0.2);
            color: white;

            &:after {
                content: '';
                display: inline-block;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid white;
                margin-left: 10px;
            }
        }
        .home-hktdc-events__filters-condList {
            position: absolute;
            top: -5px;
            left: 0;
            width: 100%;
            opacity: 0;
            padding: 20px 0;
        }
    }


    .hktdc-events__item {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 10px;
        padding-right: 20px;
        padding-left: 0;
    }

    .hktdc-events__list {
        position: relative;
        max-height: 240px;
        overflow: auto;
        @extend .gb-fontsize-s;
    }

    .hktdc-events__list-title {
        @extend .title-lv4;
        font-weight: 300;
        margin: 0 0 15px;
        @media screen and (max-width: 767px) {
            text-align:center;
        }
    }

    .hktdc-events__date {
        width: 60px;
        height: 60px;
        color: #FFF;
        background: $colorOrange;
        text-align: center;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 15px;
        .hktdc-events__item--os & {
            background: $colorPurple;
        }
        @media screen and (max-width: 1280px) {
            width: 55px;
            height: 55px;
        }
    }

    .hktdc-events__details {
        width: 100%;
    }
    .hktdc-events__info {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 5px;
        @extend .gb-fontsize-s;
    }

    .hktdc-events__info-loc {
        font-weight: bold;
        color: $colorOrange;
        .hktdc-events__item--os & {
            color: $colorPurple;
        }
        padding-right:10px;
        margin-right:10px;
        border-right:1px solid $colorTxt;
        @media screen and (max-width: 1280px) {
            padding-right: 7px;
            margin-right: 7px;
        }
    }

    .hktdc-events__days {
        display: block;
    }

    .hktdc-events__legends {
        position: relative;
        display: block;
        padding: 0 8%;
        @media screen and (min-width: 769px) {
            max-width: 450px;
            margin-left: auto;
            /* make self align right */
        }        
        @media screen and (max-width: 767px) {
            text-align: center;
        }
    }

    .hktdc-events__legend {
        display: inline-block;
        position: relative;
        padding-left: 15px;
        margin-right: 25px;
        @extend .gb-fontsize-s;
        &:before {
            content: '';
            display: block;
            $size: 10px;
            width: $size;
            height: $size;
            background: $colorOrange;
            position: absolute;
            left: 0;
            @include centerY();
            border-radius: 50%;
        }
        &.hktdc-events__legend--hk {
            &:before {
                background: $colorOrange;
            }
        }
        &.hktdc-events__legend--os {
            &:before {
                background: $colorPurple;
            }
        }
    }

    .hktdc-events {
        .DayPicker-Month {
            margin-top: 0;
            @media screen and (min-width: 769px) {
                width: 100%;
            }
        }
        .DayPicker {
            display: block;
            margin: 0 auto;
            @media screen and (min-width: 769px) {
                max-width: 450px;
                margin-left: auto;
                /* make self align right */
            }
        }
        .DayPicker-NavButton {
            top: 0;
        }
        .DayPicker-NavButton--prev {
            right: 50%;
            margin: -1px 0 0;
            margin-right: 75px;
        }
        .DayPicker-NavButton--next {
            left: 50%;
            margin: -1px 0 0;
            margin-left: 75px;
        }
        .DayPicker-Caption {
            text-align: center;
        }
        .DayPicker-Day {
            padding: 0.7rem 1rem;
            @media screen and (max-width: 1280px) {
                padding: 0.5rem 0.8rem;
            }
        }
        .DayPicker-Day--hlhk,
        .DayPicker-Day--hlos {
            position: relative;
            &:after {
                $size: 5px;
                content: '';
                display: block;
                border-radius: 50%;
                width: $size;
                height: $size;
                background: $colorOrange;
                bottom: 1px;
                left: 50%;
                margin-left: -$size/2;
                position: absolute;
            }
        }
        .DayPicker-Day--hlhk {
            &:after {
                background: $colorOrange;
            }
        }
        .DayPicker-Day--hlos {
            &:after {
                background: $colorPurple;
            }
        }
        .DayPicker-Day--hlhk.DayPicker-Day--hlos {
            $spacing:8px;
            position: relative;
            &:before,
            &:after {
                $size: 5px;
                content: '';
                display: block;
                border-radius: 50%;
                width: $size;
                height: $size;
                background: $colorOrange;
                bottom: 1px;
                left: 50%;
                margin-left: -$size/2;
                position: absolute;
            }
            &:before {
                left: calc(50% - #{$spacing/2});
                background: $colorOrange;
            }
            &:after {
                left: calc(50% + #{$spacing/2});
                background: $colorPurple;
            }
        }
        .DayPicker-Day--picked {
            &.DayPicker-Day--hlhk,
            &.DayPicker-Day--hlos {
                color: #FFF;
                &:before {
                    $size: 15px;
                    content: '';
                    display: block;
                    position: absolute;
                    width: $size;
                    height: $size;
                    top: 50%;
                    left: 50%;
                    margin-top: -$size/2;
                    margin-left: -$size/2;
                }
            }
            &.DayPicker-Day--hlhk {
                background: $colorOrange;
            }
            &.DayPicker-Day--hlos {
                background: $colorPurple;
            }
        }
    }

    /* END Style #{$componentsTitle}  */

    /* ------------------------------------------ */