@charset "utf-8";
$componentsTitle: 'access-elem';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify
.access-elem{
    position: absolute;
    left: 0px;
    top: -5000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */