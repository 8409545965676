@charset "utf-8";
$componentsTitle: 'side-tabs';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.side-tabs {
    @extend .basic-section;
    padding-bottom: 30px;
    @extend .gb-fontsize-m;
}

.side-tabs__title {
    position: relative;
    @extend .title-lv2;
    color: $colorOrange;
    text-align: center;
    font-weight: 300;
    margin: 0 0 30px;
}

.side-tabs__items {
    position: relative; //background: #f5f5f5;
    overflow: hidden;
    @media screen and (max-width: 991px) {
        height:auto !important;
    }
}

.side-tabs__item {
    .side-tabs__content {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        display: block; //display:none;
        @media screen and (max-width: 991px) {
            display:none;
        }
    }
    &.is-active .side-tabs__content {
        opacity: 1;
        z-index: 10;
        pointer-events: all;
        @media screen and (max-width: 991px) {
            display:block;
        }
    }
    .side-tabs .content-wrapper.animated & {
        .side-tabs__tab{
            opacity: 0;
        }
    }
    .side-tabs .content-wrapper.animated.fadeIn & {
        .side-tabs__tab{
            @include animation-pure(st-fadein .6s);
        }
        $delay: 0s;
        $weight: .2s;
        &:nth-child(2) .side-tabs__tab{
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(3) .side-tabs__tab{
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(4) .side-tabs__tab{
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(5) .side-tabs__tab{
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(6) .side-tabs__tab{
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(7) .side-tabs__tab{
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(8) .side-tabs__tab{
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(9) .side-tabs__tab{
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(10) .side-tabs__tab{
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
    }
}

@include keyframes(st-fadein) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.side-tabs__item-title {
    position: relative;
    @extend .title-lv4;
    color: $colorOrange;
    font-weight: 300;
    margin: 0 0 15px;
    @media screen and (max-width: 991px) {
        display:none;
    }
}

$tabWM:32%;
$tabWL:35%;
$tabWXL:40%;
.side-tabs__tab {
    cursor: pointer;
    display: block;
    background: $colorPurple;
    color: #FFF;
    padding: 30px 45px;
    border-bottom: 1px solid #FFF;
    width: $tabWM;
    @media screen and (max-width: 1580px) {
        width: $tabWL;
    }
    @media screen and (max-width: 1280px) {
        width: $tabWXL;
    }
    @media screen and (max-width: 991px) {
        width: 100%;
        padding: 30px;
    }
    @media screen and (max-width: 380px) {
        padding: 20px;
    }
    &:last-child {
        border-bottom: 0;
    }
    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 5px;
        width: 0.4em;
        height: 0.4em;
        border-top: 2px solid $colorOrange;
        border-right: 2px solid $colorOrange;
        @include transform(rotate(45deg));
    }
    &:hover {
        background: $colorOrange;
        color: #FFF;
        opacity: 1;
        &:after {
            border-top: 2px solid #FFF;
            border-right: 2px solid #FFF;
        }
    }
    .is-active & {
        background: $colorOrange; 
        color: #FFF;
        opacity: 1;
        &:after {
            @media screen and (min-width: 992px) {
                border-top: 2px solid $colorOrange;
                border-right: 2px solid $colorOrange;
            }
            border-top: 2px solid #FFF;
            border-right: 2px solid #FFF;
        }
        @media screen and (min-width: 992px) {
            background: #f5f5f5;
            color: $colorTxt;
            font-weight: bold;
        }
        
    }
}

$tabWM:32%;
$tabWL:35%;
$tabWXL:40%;
.side-tabs__content {
    position: absolute;
    display: block;
    width: 100% - $tabWM;
    left: $tabWM;
    top: 0;
    padding: 60px;
    background: #f5f5f5; //overflow:hidden;
    @include transform-origin(center top);
    @include transition(.3s);
    &:before {
        @media screen and (min-width: 992px) {
            content:'';
            position: absolute;
            display: block;
            width: 100%;
            height: 10000px;
            left: 0;
            top: 0;
            z-index: 0;
            background: #f5f5f5;
        }
    }
    .side-tabs__item-title,
    .accordion {
        position: relative;
        z-index: 10;
    } //min-height: 100%;
    @media screen and (max-width: 1580px) {
        width: 100% - $tabWL;
        left: $tabWL;
    }
    @media screen and (max-width: 1280px) {
        width: 100% - $tabWXL;
        left: $tabWXL;
    }
    @media screen and (max-width: 991px) {
        position: relative;
        width: 100%;
        left: auto;
        top: auto;
        padding: 0;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */