@charset "utf-8";
$componentsTitle: 'main-banner';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

.main-banner {
    position: relative;
}

.main-banner__slider {
    max-width: 1600px;
    margin: 0 auto;
    padding: 15px 0 45px;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 991px) {
        padding:0;
    }
    .swiper-wrapper {
        @media screen and (min-width: 992px) {
            height:100% !important;
        }
    }
}

.main-banner__slide {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    @media screen and (min-width: 992px) {
        background-image:none !important;
    }
    @media screen and (max-width: 991px) {
        display: block;
    }
    @media screen and (max-width: 767px) {
        //height: 480px;
    }
}

$imgSizeXL:65%;
$imgSizeL:60%;
$imgSizeM:55%;
$txtSizeXL:400px;
$txtSizeL:350px;
$txtSizeM:300px;
$mobImgSizeL:500px;
$mobImgSizeM:430px;
$mobImgSizeS:280px;
$mobImgSizeXS:230px;
.main-banner__img {
    display: block;
    position: relative;
    overflow: hidden;
    width: calc(100% - #{$txtSizeXL});
    @include animation(main-banner-img-line 0s linear 1s);
    @include keyframes(main-banner-img-line) {
        0% {
            border-bottom: 6px solid #FFF;
        }
        100% {
            border-bottom: 6px solid $colorOrange;
        }
    }
    @media screen and (min-width: 992px) {
        background-color: transparent !important;
    }
    @media screen and (max-width: 1580px) {
        width: calc(100% - #{$txtSizeL});
    }
    @media screen and (max-width: 1280px) {
        width: calc(100% - #{$txtSizeM});
    }
    @media screen and (max-width: 991px) {
        display: none;
    }
    &:hover {
        opacity: 1;
    }
    img {
        display: block;
        width: 100%;
        .is-ie & {
            display: inline;
        }
        &.loading--close {
            opacity: 0;
            display: none;
        }
        &.loading--open {
            opacity: 1;
            position: absolute;
            @include centerXY();
            width: auto;
            height: auto;
        }
        &.image--loading {
            opacity: 0;
            display: none;
        }
        &.image--loaded {
            opacity: 1;
            display: block;
        }
        .swiper-slide-active & {
            @include animation(main-banner-img 5.5s linear);
            // Churix, 21/8/2018, PHR-191, Fix IE shaking issue, referring to
            // https://stackoverflow.com/questions/45715377/rotate-and-scale-does-not-work-in-ie11#answer-45715453
            @include keyframes(main-banner-img) {
                0% {
                    @include transform(scale(1.08, 1.08) rotate(0.02deg));
                }
                100% {
                    @include transform(scale(1, 1));
                }
            }
        } 
        .swiper-wrapper.pause-active & {
            animation-play-state: paused;
        }
        &:last-child {
            .swiper-slide-duplicate & {
                opacity: 1 !important;
                @media screen and (min-width: 992px) {
                    display: block !important;
                }
            }
        }
        @media screen and (max-width: 991px) {
            display: none;
        }
    }
}

.main-banner__img_mobile {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-bottom: 0;
    @include animation(main-banner-img-line 0s linear 1s);
    @include keyframes(main-banner-img-line) {
        0% {
            border-bottom: 6px solid #FFF;
        }
        100% {
            border-bottom: 6px solid $colorOrange;
        }
    }
    @media screen and (min-width: 992px) {
        display: none;
    }
    @media screen and (max-width: 991px) {
        height: $mobImgSizeL;
    }
    @media screen and (max-width: 767px) {
        height: $mobImgSizeM;
    }
    @media screen and (max-width: 481px) {
        height: $mobImgSizeS;
    }
    @media screen and (max-width: 391px) {
        height: $mobImgSizeXS;
    }
    &:hover {
        opacity: 1;
    }
}

.main-banner__vid {
    @extend .main-banner__img;
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    @media screen and (max-width: 991px) {
        width: auto;
        height: $mobImgSizeL;
    }
    @media screen and (max-width: 767px) {
        height: $mobImgSizeM;
    }
    @media screen and (max-width: 481px) {
        height: $mobImgSizeS;
    }
    @media screen and (max-width: 391px) {
        height: $mobImgSizeXS;
    }
    &:before {
        content: '';
        position: relative;
        width: 100%;
        padding-bottom: 50%;
        display: block;
        z-index: -1;
    }
    video {
        position: absolute;
        @include centerXY();
    }
    .is-ie & {
        >div {
            position:static !important;
        }
        video {
            @include transform(none);
        }
    }
}

$mainBannerTxtPaddingM:40px;
$mainBannerTxtPaddingS:30px;
.main-banner__txt {
    padding: 0 #{$mainBannerTxtPaddingM};
    width: $txtSizeXL;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    >* {
        width: 100%;
    }
    &:before {
        content: '';
        display: block;
        width: 100%;
    }
    @media screen and (max-width: 1580px) {
        width: $txtSizeL;
    }
    @media screen and (max-width: 1280px) {
        width: $txtSizeM;
        padding: 0 #{$mainBannerTxtPaddingS};
    }
    @media screen and (max-width: 991px) {
        background: rgb(252, 94, 8);
        background: -moz-linear-gradient(left, rgba(252, 94, 8, 1) 0%, rgba(253, 142, 20, 1) 100%);
        background: -webkit-linear-gradient(left, rgba(252, 94, 8, 1) 0%, rgba(253, 142, 20, 1) 100%);
        background: linear-gradient(to right, rgba(252, 94, 8, 1) 0%, rgba(253, 142, 20, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc5e08', endColorstr='#fd8e14', GradientType=1);
        color: #FFF;
        position: relative; //left: 0;
        padding: 60px 5%;
        width: 100%;
        height: 100%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    @media screen and (max-width: 767px) {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 50px 5%;
    }
    @media screen and (max-width: 480px) {
        padding: 30px 5%;
    }
}


.main-banner__loading {
    position: absolute;
}

$leftOffset:150px;
$startTranslateX: 200px;
$endTranslateX: -100px;
$extraWidth: 25px;

$startTranslateXMB: 30px;
$endTranslateXMB: 0px;

.main-banner__title {
    .is-firefox & {
        // Churix, PHR-288, to change firefox to solid color title
        background:none;
        color: rgb(252, 94, 8);
        @include animation(sec-intro-fadetop 1s);
        // PHR-524, change to white < 991
        @media screen and (max-width: 991px) {
            background: transparent;
            color: #FFF;
        }
    }
    margin: 45px 0 0;
    line-height: 1.2;
    width: calc(100% + #{$leftOffset});
    @include transform(translateX(-$leftOffset));
    @extend .title-xl;
    opacity: 0;
    .swiper-slide-active & {
        @include animation(main-banner-title .9s);
        width: calc(100% - #{$endTranslateX - $mainBannerTxtPaddingM - $extraWidth});
        @media screen and (max-width: 1280px) {
            width: calc(100% - #{$endTranslateX - $mainBannerTxtPaddingS - $extraWidth});
        }
        @media screen and (max-width: 991px) {
            @include animation(main-banner-title-mb .4s);
            width: calc(100% - #{$endTranslateXMB});
        }
        @include keyframes(main-banner-title) {
            0% {
                opacity: 0;
                @include transform(translateX($startTranslateX));
            }
            100% {
                opacity: 1;
                @include transform(translateX($endTranslateX - $extraWidth));
            }
        }
        @include keyframes(main-banner-title-mb) {
            0% {
                opacity: 0;
                @include transform(translateX($startTranslateXMB));
            }
            100% {
                opacity: 1;
                @include transform(translateX($endTranslateXMB));
            }
        }
    }
    @media screen and (max-width: 1280px) {
        margin: 30px 0 0;
        @include fontSize(36);
    }
    @media screen and (max-width: 991px) {
        width: 100%;
        margin: 0;
        @include transform(translateX(0));
    }
    font-weight: 900;
    @media screen and (max-width: 767px) {
        margin: 0 0 15px;
    }
    @media screen and (max-width: 480px) {
        font-size: 28px;
    }
    background: rgb(252,94,8);
    background: -moz-linear-gradient(left, rgba(252, 94, 8, 1) 0%, rgba(253, 142, 20, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(252, 94, 8, 1) 0%, rgba(253, 142, 20, 1) 100%);
    background: linear-gradient(to right, rgba(252, 94, 8, 1) 0%, rgba(253, 142, 20, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc5e08', endColorstr='#fd8e14', GradientType=1);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    .is-ie & {
        background: transparent;
        color: $colorOrange;
        @media screen and (max-width: 991px) {
            background: transparent;
            color: #FFF;
        }
    }
    @media screen and (max-width: 991px) {
        background: transparent;
        color: #FFF;
    }
}

.main-banner__detail {
    position: relative;
    opacity: 0;
    .swiper-slide-active & {
        @include animation(main-banner-detail .4s .2s);
        @include keyframes(main-banner-detail) {
            0% {
                opacity: 0;
                @include transform(translateY(30px));
            }
            100% {
                opacity: 1;
                @include transform(translateY(0));
            }
        }
    }
    @media screen and (max-width: 991px) {
        color: #FFF;
    }
}

.main-banner__detail-title {
    @include fontSize(20);
    color: $colorOrange;
    margin: 0 0 3px;
    @media screen and (max-width: 991px) {
        color:#FFF;
    }
    @media screen and (max-width: 767px) {
        @include fontSize(18);
        margin: 0 0 3px;
    }
}

.main-banner__detail-subHeading {
    font-weight: bold;
    @include fontSize(16);
    margin: 0 0 15px;
    @media screen and (max-width: 991px) {
        color:#FFF;
    }
    @media screen and (max-width: 767px) {
        @include fontSize(14);
        margin: 0 0 10px;
    }
}

.main-banner__detail-desc {
    @include fontSize(16);
    margin: 0 0 15px;
    @media screen and (max-width: 991px) {
        color:#FFF;
    }
    @media screen and (max-width: 767px) {
        @include fontSize(14);
        margin: 0 0 10px;
    }
}

.main-banner__detail-date {
    color: $colorTxt;
    font-weight: 400;
    @media screen and (max-width: 991px) {
        display:block;
        color: #FFF;
        margin-top: 15px;
    }
}

.main-banner__scrolldown {
    position: absolute;
    display: block;
    bottom: 0;
    left: 10%;
    @media screen and (max-width: 1800px) {
        left: 5%;
    }
    @media screen and (max-width: 1600px) {
        left: 2%;
    }
    opacity:0;
    @include animation(main-banner-scrolldown .4s 2s);
    @include keyframes(main-banner-scrolldown) {
        0% {
            @include transform(translateY(-15px));
            opacity: 0;
        }
        100% {
            @include transform(translateX(0));
            opacity: 1;
        }
    }
    &:before {
        $size: 24px;
        content: '';
        display: block;
        width: $size;
        height: $size;
        background: no-repeat center center;
        background-size: 100%;
        background-image: imgurl("common/icons/svg/black/icon-mouse.svg");
        margin-bottom: 8px;
    }
    &:after {
        content: '';
        display: block;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: $colorTxt transparent transparent transparent;
        @include animation(.7s scrolldown infinite);
    }
    @media screen and (max-width: 991px) {
        display: none;
    }
}

@include keyframes(scrolldown) {
    from {
        @include transform(translate(0));
    }
    to {
        @include transform(translate(0,
        60%));
    }
}

.main-banner__thumbs {
    z-index: 50;
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 45px;
    pointer-events: none;
    @include transform(translateY(50%));
    @media screen and (max-width: 991px) {
        top:$mobImgSizeL - 60px;
        bottom: auto;
    }
    @media screen and (max-width: 767px) {
        top: $mobImgSizeM - 60px;
    }
    @media screen and (max-width: 481px) {
        top: $mobImgSizeS - 60px;
    }
    @media screen and (max-width: 391px) {
        top: $mobImgSizeXS - 60px;
    }
    .content-wrapper {
        padding: 0 30px;
        @media screen and (max-width: 991px) {
            padding: 0;
            width: 90%;
        }
    }
}

$thumbSize:110px;
$thumbSizeM:85px;
$thumbSizeS:65px;
.main-banner__thumb {
    pointer-events: all;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: $thumbSize;
    margin-right: 10px;
    background-size: cover;
    @include animation(main-banner-thumb .4s);
    opacity: 0;
    $delay: 0;
    $weight: .12s;
    &:nth-child(2) {
        $delay: $delay+$weight;
        @include animation-delay($delay);
    }
    &:nth-child(3) {
        $delay: $delay+$weight;
        @include animation-delay($delay);
    }
    &:nth-child(4) {
        $delay: $delay+$weight;
        @include animation-delay($delay);
    }
    &:nth-child(5) {
        $delay: $delay+$weight;
        @include animation-delay($delay);
    }
    &:nth-child(6) {
        $delay: $delay+$weight;
        @include animation-delay($delay);
    }
    &:nth-child(7) {
        $delay: $delay+$weight;
        @include animation-delay($delay);
    }
    &:nth-child(8) {
        $delay: $delay+$weight;
        @include animation-delay($delay);
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        opacity: 1;
        animation: none;
    }
    @include keyframes(main-banner-thumb) {
        0% {
            @include transform(translateX(15px));
            opacity: 0;
        }
        100% {
            @include transform(translateX(0));
            opacity: 1;
        }
    }
    @media screen and (max-width: 1600px) {
        width: $thumbSizeM;
    }
    @media screen and (max-width: 1180px) {
        width: $thumbSizeS;
        margin-right: 8px;
    }
    @media screen and (max-width: 991px) {
        background: transparent !important;
        width: 30px;
    }
    @media screen and (max-width: 767px) {
        width: 20px;
    }
    &:last-child {
        margin-right: 0;
    }
    &:before {
        content: '';
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        @media screen and (max-width: 991px) {
            padding:0;
            height: 4px;
        }
        @media screen and (max-width: 767px) {
            height: 3px;
        }
    }
    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 0;
        @include transition(.3s);
        @media screen and (max-width: 991px) {
            background:rgba(#FFF, 0.5);
        }
    }
    &.is-active,
    &:hover {
        opacity: 1;
        &:after {
            box-sizing: border-box;
            border: 5px solid $colorOrange;
            @media screen and (max-width: 1280px) {
                border: 3px solid $colorOrange;
            }
            @media screen and (max-width: 991px) {
                border: 0;
            }
        }
    }
    &.is-active {
        @media screen and (max-width: 991px) {
            &:after {
                background: $colorOrange;
            }
        }
    }
}

.main-banner__slider-pause{
    display: inline-block;
    position: absolute;
    right: 400px; // depends on .main-banner__txt
    bottom: 20px;
    z-index: 2;
    @media screen and (max-width: 1580px) {
        right: 350px; // depends on .main-banner__txt
    }
    @media screen and (max-width: 1280px) {
        right: 300px; // depends on .main-banner__txt
    }
    @media screen and (max-width: 991px) {
        right: 10px; // depends on .main-banner__txt
        bottom: calc(100% - 530px);
    }
    @media screen and (max-width: 768px) {
        bottom: calc(100% - 450px);
    }
    @media screen and (max-width: 480px) {
        bottom: calc(100% - 390px);
    }
    .pause-button{
        display: block;
        box-sizing: border-box;
        width: 0;
        height: 15px;
        cursor: pointer;
        border-color: transparent transparent transparent #1a1a1a;
        transition: 100ms all ease;
        cursor: pointer;
        // paused state
        border-style: double;
        border-width: 0px 0 0px 15px;
        @media screen and (max-width: 991px) {
            border-color: transparent transparent transparent #fff;
        }
    }
    &.is-active{
        .pause-button {
            border-style: solid;
            border-width: 8px 0 8px 12px;
        }
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */