@charset "utf-8";
/* Style commonly used  */

/* ------------------------------------------ */

// TODO: Break into components
body,
body * {
    max-height: 1000000px;
}

@mixin bodyFontSize() {
    font-size: 16px;
    /*
    @media screen and (max-width:1580px) {
        font-size:15px;
    }
    @media screen and (max-width:991px) {
        font-size:14px;
    }
    @media screen and (max-width:767px) {
        font-size:13px;
    }
    */
}

html {
    @include bodyFontSize();
}

body {
    @include bodyFontSize();
    font-family: $fontEN;
    &:lang(zh-hk) {
        font-family: $fontTC;
    }
    &:lang(zh-cn) {
        font-family: $fontSC;
    }
    margin:0 !important;
    padding:0;
    background:#FFF;
    color:$colorTxt;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    &.dt-only {
        min-width: 992px;
    }
    &.search-panel-open,
    &.mob-menu-open,
    &.scroll-locked {
        overflow: hidden;
        #root {
            overflow: scroll;
        }
    }
    @media screen and (max-width:991px) {
        &.mob-menu-open, 
        &.search-panel-open {
            overflow: hidden;
            position: fixed;
        }
    }
    min-width: 300px;
    @media screen and (max-width:991px) {
        overflow-x: auto;
    }
    @media (max-height: 414px), screen and (orientation: landscape) { 
        &.mob-menu-open {
            overflow: scroll;
            position: relative;
        }
    }
    // &.homepage {
    //     &:lang(zh-hk), &:lang(zh-cn) {            
    //         -moz-filter: grayscale(100%);
    //         -webkit-filter: grayscale(100%);
    //         filter: gray;
    //         filter: grayscale(100%);
    //     }
    // }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    color: $colorTxt;
    text-decoration: none;
    &:hover,
    &:active {
        color: $colorTxt;
        opacity: 0.8;
        text-decoration: none;
    }
    &:focus {
        text-decoration: none;
        // outline: none;
    }
}

img {
    image-rendering: -webkit-optimize-contrast;
    max-width: 100%;
    height: auto;
}

br {
    display: block;
    opacity: 0;
    font-family: "Arial", sans-serif !important;
    font-size: 0;
    color: transparent;
    line-height: 1em;
}

p {
    @include bodyFontSize();
    margin-top: 0;
    margin-bottom: $cmSpacing;
    &:last-child {
        margin-bottom: 0;
    }
}

ul,
ol {
    padding-left: 30px;
    margin-top: 0;
    margin-bottom: $cmSpacing;
    &:last-child {
        margin-bottom: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $fontTitleEN;
    font-weight: bold;
    margin-top: 0;
}

.title-xxl {
    font-weight: bold;
    font-family: $fontTitleEN;
    line-height: 1em;
    @include fontSize(78);
    @media screen and (max-width: 1580px) {
        @include fontSize(64);
    }
    @media screen and (max-width:991px) {
        @include fontSize(56);
    }
    @media screen and (max-width:640px) {
        @include fontSize(40);
    }
}

.title-xl {
    font-weight: bold;
    font-family: $fontTitleEN;
    line-height: 1em;
    @include fontSize(73);
    @media screen and (max-width: 1580px) {
        @include fontSize(64);
    }
    @media screen and (max-width:991px) {
        @include fontSize(52);
    }
    @media screen and (max-width:640px) {
        @include fontSize(36);
    }
}

.title-lv1 {
    font-weight: bold;
    font-family: $fontTitleEN;
    @include fontSize(60);
    @media screen and (max-width: 1580px) {
        @include fontSize(44);
    }
    @media screen and (max-width:991px) {
        @include fontSize(42);
    }
    @media screen and (max-width:767px) {
        @include fontSize(32);
    }
    @media screen and (max-width:350px) {
        @include fontSize(28);
    }
}

.title-lv2 {
    font-family: $fontTitleEN;
    font-weight: bold;
    @include fontSize(48);
    @media screen and (max-width: 1580px) {
        @include fontSize(42);
    }
    @media screen and (max-width:991px) {
        @include fontSize(36);
    }
    @media screen and (max-width:640px) {
        @include fontSize(32);
    }
}

.title-lv3 {
    font-family: $fontTitleEN;
    font-weight: bold;
    @include fontSize(42);
    @media screen and (max-width: 1580px) {
        @include fontSize(32);
    }
    @media screen and (max-width:991px) {
        @include fontSize(28);
    }
    @media screen and (max-width:640px) {
        @include fontSize(24);
    }
}

.title-lv4 {
    font-family: $fontTitleEN;
    font-weight: bold;
    @include fontSize(30);
    @media screen and (max-width: 1580px) {
        @include fontSize(24);
    }
    @media screen and (max-width:991px) {
        @include fontSize(20);
    }
    @media screen and (max-width:640px) {
        @include fontSize(20);
    }
}

.title-lv5 {
    font-family: $fontTitleEN;
    font-weight: bold;
    @include fontSize(20);
    @media screen and (max-width: 1580px) {
        @include fontSize(18);
    }
    @media screen and (max-width:991px) {
        @include fontSize(16);
    }
    @media screen and (max-width:640px) {
        @include fontSize(16);
    }
}

.title-lv6 {
    font-family: $fontTitleEN;
    font-weight: bold;
    @include fontSize(16);
    @media screen and (max-width: 1580px) {
        @include fontSize(12);
    }
    @media screen and (max-width:991px) {
        @include fontSize(12);
    }
    @media screen and (max-width:640px) {
        @include fontSize(12);
    }
}

.gb-fontsize-xl{
    @include fontSize(20);
    @include testStyle() {
        @include fontSize(22);
    }
    @media screen and (max-width:640px) {
        @include fontSize(20);
        @include testStyle() {
            @include fontSize(18);
        }
    }
    
}
.gb-fontsize-l{
    @include fontSize(18);
    @include testStyle() {
        @include fontSize(20);
    }
    @media screen and (max-width:640px) {
        @include fontSize(16);
        @include testStyle() {
            @include fontSize(18);
        }
    }
    
}
.gb-fontsize-m{
    @include fontSize(16);
    @include testStyle() {
        @include fontSize(18);
    }
    @media screen and (max-width:640px) {
        @include fontSize(14);
        @include testStyle() {
            @include fontSize(16);
        }
    }
    
}
.gb-fontsize-s{
    @include fontSize(14);
    @include testStyle() {
        @include fontSize(16);
    }
    @media screen and (max-width:640px) {
        @include fontSize(13);
        @include testStyle() {
            @include fontSize(14);
        }
    }
}
.gb-fontsize-xs{
    @include fontSize(13);
    @include testStyle() {
        @include fontSize(14);
    }
    @media screen and (max-width:640px) {
        @include fontSize(12);
        @include testStyle() {
            @include fontSize(13);
        }
    }
}
.gb-fontsize-xxs{
    @include fontSize(12);
}

main {
    background: #FFF;
    position: relative;
    width: 100%;
    //overflow: hidden;
    box-sizing: border-box;
    @include transition(.2s);
    @include menuBlur();
}

.access {
    position: absolute;
    left: 0px;
    top: -500px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.clearfix {
    @include clearfix();
}

//Common Layout
.common-wrapper {
    width: $gridPercent;
    @media screen and (max-width: 767px) {
        width:$gridPercentS;
    }
}

.content-wrapper {
    margin: 0 auto;
    display: block;
    max-width: $gridMaxWidth;
    position: relative;
    @extend .common-wrapper;
}

.content-paragraph {
    margin: 0 auto 15px;
    line-height: 1.5em;
    &:last-child {
        margin-bottom: 0;
    }
}

.content-link {
    &:hover {
        opacity: 1;
        text-decoration: underline;
    }
}

.more-link {
    @extend .content-link;
    @include fontSize(20);
    @media screen and (max-width: 640px) {
        @include fontSize(16);
    }
}

.basic-section {
    position: relative;
    padding: 100px 0;
    @media screen and (max-width: 1580px) {
        padding:80px 0;
    }
    @media screen and (max-width:991px) {
        padding: 60px 0;
    }
    @media screen and (max-width:767px) {
        padding: 40px 0;
    }
}

.basic-desc {
    display: block;
    width: 100%;
    max-width: 640px;
    margin: 0 auto 15px;
    line-height: 1.5em;
    &:last-child {
        margin-bottom: 0;
    }
}

//Line breaking
.force-oneword {
    display: inline-block;
    white-space: nowrap;
}

.force-nobreak {
    white-space: nowrap;
}

.force-break {
    word-break: break-all;
}

//Fast Display 
.display--inline-block {
    display: inline-block;
}

.display--block {
    display: inline-block;
}

.display--inline {
    display: inline;
}

//Fast Alignment 
.align {
    &--hc {
        text-align: center;
    }
    &--hl {
        text-align: left;
    }
    &--hr {
        text-align: right;
    }
    &--vt {
        vertical-align: top;
    }
    &--vb {
        vertical-align: bottom;
    }
    &--vm {
        vertical-align: middle;
    }
    &--hvc {
        text-align: center;
        vertical-align: middle;
    }
}

//Shorthand to set visibility
$breakPt_tbMax: $max-w-tb;
$breakPt_mbMax: $max-w-mb;
$mq_dtMin: $breakPt_tbMax+1;
$mq_tbMax: $breakPt_tbMax;
$mq_tbMin: $breakPt_mbMax+1;
$mq_mbMax: $breakPt_mbMax;
.visible-dt {
    @media screen and (max-width: $mq_tbMax) {
        display: none !important;
    }
}

.visible-tb {
    @media screen and (min-width: $mq_dtMin), screen and (max-width: $mq_mbMax) {
        display: none !important;
    }
}

.visible-mb {
    @media screen and (min-width: $mq_tbMin) {
        display: none !important;
    }
}

.hidden-dt {
    @media screen and (min-width: $mq_dtMin) {
        display: none !important;
    }
}

.hidden-tb {
    @media screen and (max-width: $mq_tbMax) and (min-width: $mq_tbMin) {
        display: none !important;
    }
}

.hidden-mb {
    @media screen and (max-width: $mq_mbMax) {
        display: none !important;
    }
}

.hidden-all {
    display: none !important;
}

/* Loading Icon */

.loader {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    &.mf {
        display: block;
        position: relative;
    }
    &.ss{
        display: block;
        z-index: 600;
        .swiper-slide-active & {
            -webkit-animation: ss-fadeleft 0.6s 0.2s;
            animation: ss-fadeleft 0.6s 0.2s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            will-change: transform;
        }

        @-webkit-keyframes ss-fadeleft {
            0% {
                -webkit-transform: translateX(-10%);
                -ms-transform: translateX(-10%);
                transform: translateX(-10%);
                opacity: 0;
            }
            100% {
                -webkit-transform: translateX(0);
                -ms-transform: translateX(0);
                transform: translateX(0);
                opacity: 1;
            }
        }

        @keyframes ss-fadeleft {
            0% {
                -webkit-transform: translateX(-10%);
                -ms-transform: translateX(-10%);
                transform: translateX(-10%);
                opacity: 0;
            }
            100% {
                -webkit-transform: translateX(0);
                -ms-transform: translateX(0);
                transform: translateX(0);
                opacity: 1;
            }
        }

        .swiper-slide-next & {
            -webkit-animation: ss-fadeleft 0.6s 0.2s;
            animation: ss-fadeleft 0.6s 0.2s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            will-change: transform;
        }
        .content {
            position: absolute;
        }
    }
    .content {
        top: 50%;
        left: 50%;
        /* bring your own prefixes */
        transform: translate(-50%, -50%);
        position: relative;
        text-align: center;
        .message {
            &:after {
                content: ' .';
                animation: dots 1s steps(5, end) infinite;
            }
        }
        .loading {
            margin: 0 auto 10px;
            border: 5px solid #f3f3f3;
            border-radius: 50%;
            border-top: 5px solid #888888;
            border-right: 5px solid #888888; // border-bottom: 5px solid white;
            border-left: 5px solid #888888;
            width: 40px;
            height: 40px;
            -webkit-animation: spin 0.7s linear infinite;
            animation: spin 0.7s linear infinite;
        }
    }
}

@keyframes dots {
    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
        color: #333333;
        text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
        text-shadow: .25em 0 0 #333333, .5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
        text-shadow: .25em 0 0 #333333, .5em 0 0 #333333;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* END Loading Icon */

/* no selection highlight */

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    :focus {
        // outline: none !important;
    }
}

/* no selection highlight END */

/* back to top button */
#backtotop {
    margin: 50px 20px;
    background: imgurl('common/icons/svg/white/icon-backtotop.svg') no-repeat center center;
    width: 45px;
    height: 45px;
    text-indent: -99999px;
    display: block;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.align-center {
    text-align: center;
}
.align-right {
    text-align: right;
}
.align-left {
    text-align: left;
}
.align-justify {
    text-align: justify;
}

/* END Style commonly used  */

/* ------------------------------------------ */