@charset "utf-8";
//******************Variables****************//

//Dev mode: test=triggering test style;
$devMode:'test';
$devMode:'default';


//Common Break Points
$max-w-tb:991px;
$max-w-mb:767px;

$min-w-dt:992px;
$mim-w-tb:768px;

//Fonts
$fontTitleTC: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
$fontTC: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
$fontTitleSC: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
$fontSC: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
$fontTitleEN: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
$fontEN: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;

//Image Path
$img-folder: './assets/images';
@function imgurl ($path) {
    @return url($img-folder + '/' + unquote($path));
}

//Color
$colorTxt: #1a1a1a;
$colorGrey: #777;
$colorDkBlue: #0d0e3a;

$colorBorderGrey:#e5e5e5;

$colorOrange:#fb5004;
$colorPurple:#242655;
$colorBgLtGrey:#ececec;
$colorBgLtOrange:#fff3ec;

$colorPhotoBorderGrey: #e6e6e6;
$colorRowBorderBottom: #dadada;

//Common Values
$headerHeight:160px;
$headerHeightSmall:75px;
$headerHeightSm:80px;
$headerHeightTb:70px;
$headerHeightMb:60px;

$gridMaxWidth:1380px;
$gridPercent: 94%;
$gridPercentS: 90%;
$headerGridPercent: 100%;
$headerGridPercentS: 100%;

$cmSpacing:20px;
$cmSpacingTb:$cmSpacing;

$fieldIndentGlobal:15px;
$formRowHeightGlobal:45px;
$formRowHeightGlobalTb:40px;
$formRowHeightGlobalMb:40px;
$fieldHeight:$formRowHeightGlobal;
