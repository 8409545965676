@charset "utf-8";
$componentsTitle: 'app-intro';

/* Style for #{$componentsTitle} */


/* ------------------------------------------ */

// TODO: Simplify
.app-intro {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 0;
    @media screen and (max-width: 380px) {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.app-intro__icon {
    position: relative;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    @media screen and (max-width: 380px) {
        margin:0 auto;
    }
    img {
        display: block;
        width: 70px;
        @media screen and (max-width: 1200px) {
            width: 55px;
        }
    }
}

.app-intro__intro {
    @extend .gb-fontsize-m;
    flex-basis: 100%;
    padding: 0 30px;
    @media screen and (max-width: 1200px) {
        padding: 0 15px;
    }
    @media screen and (max-width: 380px) {
        display:block;
        width: calc(100% - 55px);
        padding: 15px 0 0;
        text-align: center;
    }
}

.app-intro__title {
    @extend .title-lv5;
    margin: 0 0 10px;
}

.app-intro__desc {
    width:100%;
    @extend .gb-fontsize-m;
}

.app-intro__btn {
    @extend .gb-fontsize-m;
    @media screen and (max-width: 380px) {
        margin-top:20px;
        width: 100%;
        text-align:center;
    }
}


/* END Style #{$componentsTitle}  */


/* ------------------------------------------ */