@charset "utf-8";
$componentsTitle: '.page-item-multi-slider';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

.page-hktdc-events-slider {
    position: relative;
    // &:before {
    //     content: '';
    //     position: absolute;
    //     display: block;
    //     width: 100%;
    //     right: 0;
    //     top: 10%;
    //     height: 90%;
    //     background: rgba(36, 38, 85, 1);
    // }
    .item-sliders {
        position: relative;
        max-width: 1340px;
        width: 70%; 
        margin: 0 auto;
        @media screen and (max-width: 1240px) {
            width: 80%;
        }
    }
}

.multi-sliders-container {
    width: 100%;
    overflow: hidden;
    padding: 0 30px;
    .item-slider__btn {
        top: 45%;
        cursor: pointer;
        background: none;
        border-color: #777;
        &:after {
            border-color: #777;
        }
    }
    .item-slider__btn--prev {
        left: -30px;
        &:after {
            border-color: #777;
        }
    }
    .item-slider__btn--next {
        right: -30px;
        &:after {
            border-color: #777;
        }
    }
    .item-slider__pagination {
        position: absolute;
        bottom: 10px;
        .swiper-pagination-bullet {
            background: #000;
            opacity: 0.2;
            &.swiper-pagination-bullet-active {
                background: $colorOrange;
                opacity: 1;
            }
        }
    }
}

.multi-slider-container {
    margin-bottom: 60px;
    opacity: 0;
    @include transform(translateX(60px));
    @include transition(.3s);
    
    &.swiper-slide-active,
    &.swiper-slide-active + .multi-slider-container,
    &.swiper-slide-active + .multi-slider-container + .multi-slider-container{
        opacity: 1;
        @include transform(translateX(0));
    }
    &.swiper-slide-active{
        @include transition-delay(0.8s);
    }
    &.swiper-slide-active + .multi-slider-container{
        @include transition-delay(1s);
    }
    &.swiper-slide-active + .multi-slider-container + .multi-slider-container{
        @include transition-delay(1.2s);
    }
    .slider__img {
        a{
            &:hover{
                opacity: 1;
            }
        }
        img {
            width: 100%;
        }
    }
    .slider__title {
        // color: #fff;
        margin-top: 15px;
        width: 75%;
        font-weight: bold;
        font-size: 18px;
        a{
            // color: #fff;
            &:hover{
                opacity: 1;
            }
        }
    }
    .slider__desc {
        // color: #fff;
        margin-top: 5px;
        width: 100%;
        font-size: 15px;
    }
    .slider-location-date {
        margin-top: 10px;
    }
    .slider__location {
        display: inline-block;
        color: #fc5e08;
        padding-right: 15px;
    }
    .slider__date {
        display: inline-block;
        // color: #fff;
        border-left: 1px solid $colorTxt;
        padding-left: 15px;
    }
}

.multi-sliders__slider-pause{
    display: inline-block;
    position: absolute;
    right: 35px;
    bottom: 30px;
    z-index: 10;
    .pause-button{
        display: block;
        box-sizing: border-box;
        width: 0;
        height: 15px;
        cursor: pointer;
        border-color: transparent transparent transparent #1a1a1a;
        transition: 100ms all ease;
        cursor: pointer;
        // paused state
        border-style: double;
        border-width: 0px 0 0px 15px;
    }
    &.is-active{
        .pause-button {
            border-style: solid;
            border-width: 8px 0 8px 12px;
        }
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */