.previewDateInput_wrapper {
    z-index: 30000;
    position: absolute;
    width: 100%;
    top:0;
    left:0;
    input {
        width: auto;
    }
    button {
        cursor: pointer;
    }
}