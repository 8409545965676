@charset "utf-8";
$componentsTitle: 'sec-intro';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.sec-intro {
    text-align: center;
    padding: 45px 0;
    .button-share__title {
        opacity: 0;
        @include animation(sec-intro-faderight .6s);
    }
    .button-share {
        opacity: 0;
        @include animation(sec-intro-fadeleft .6s);
        $delay: 0s;
        $weight: .12s;
        opacity: 0;
        &:nth-child(2) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(3) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(4) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(5) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(6) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(7) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(8) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(9) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
        &:nth-child(10) {
            $delay: $delay+$weight;
            @include animation-delay($delay);
        }
    }
}

main .sec-intro:first-child {
    background:imgurl('common/bg-sec-intro.jpg') no-repeat center center;
    background-size:cover;
    padding-top: 175px;
    @media screen and (max-width: 1580px) {
        padding-top:170px;
    }
    @media screen and (max-width: 991px) {
        padding-top: 115px;
    }
    margin-top:-130px;
    @media screen and (max-width: 1580px) {
        margin-top: -125px;
    }
    @media screen and (max-width: 991px) {
        margin-top: -70px;
    }
}

.sec-intro__title {
    opacity: 0;
    @include animation(sec-intro-fadetop .6s);
    .is-firefox & {
        // Churix, PHR-288, to change firefox to solid color title
        background:none;
        color: rgb(252, 94, 8);
        @include animation(sec-intro-fadetop 1s);
    }
    display: block;
    //@extend .title-xl;
    @extend .title-lv2;
    max-width: 800px;
    font-weight: 400;
    margin: 0 auto 30px;
    background: rgb(252, 94, 8);
    background: -moz-linear-gradient(left, rgba(252, 94, 8, 1) 0%, rgba(253, 142, 20, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(252, 94, 8, 1) 0%, rgba(253, 142, 20, 1) 100%);
    background: linear-gradient(to right, rgba(252, 94, 8, 1) 0%, rgba(253, 142, 20, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc5e08', endColorstr='#fd8e14', GradientType=1);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
    @media screen and (min-width: 1581px) {
        //@include fontSize(60);
    }
    .is-ie & {
        background: transparent;
        color: $colorOrange;
    }
}

.sec-intro__desc {
    max-width: 768px;
    display: block;
    line-height: 1.5em;
    margin: 0 auto 30px;
    opacity: 0;
    @include animation(sec-intro-fadetop .6s .3s);
    @extend .gb-fontsize-m;
}
.sec-intro__detail{
    display: block;
    margin: 0 auto;
    padding:100px 0;
    background: #f5f5f5;
    
    @media screen and (max-width: 991px) {
        padding:80px 0;
    }
    @media screen and (max-width: 640px) {
        padding:60px 0;
    }
}
.sec-intro__detail-wrapper{
    @extend .content-wrapper;
    max-width: 960px;
    iframe {
        max-width: 960px;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
}
.sec-intro__detail-item{
    display: block;
    margin: 0 auto 30px;
    &:last-child{
        margin-bottom:0;
    }
}
.sec-intro__detail-title{
    @extend .title-lv4;
    font-weight:bold;
    margin: 0 auto 20px;
    .sec-intro__detail-desc + & {
        margin-top: 30px;
    }
}
.sec-intro__detail-subtitle{
    @extend .title-lv5;
    text-decoration: underline;
    font-weight:normal;
    margin: 0 auto 10px;

    .sec-intro__detail-desc+& {
        margin-top: 30px;
    }
}
.sec-intro__detail-desc{
    @extend .gb-fontsize-m;
    line-height: 1.5em;
}




@include keyframes(sec-intro-fadein) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(sec-intro-fadetop) {
    0% {
        opacity: 0;
        @include transform(translateY(30px));
    }
    100% {
        opacity: 1;
        @include transform(translateY(0));
    }
}

@include keyframes(sec-intro-fadeleft) {
    0% {
        opacity: 0;
        @include transform(translateX(10px));
    }
    100% {
        opacity: 1;
        @include transform(translateX(0));
    }
}

@include keyframes(sec-intro-faderight) {
    0% {
        opacity: 0;
        @include transform(translateX(-10px));
    }
    100% {
        opacity: 1;
        @include transform(translateX(0));
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */