@charset "utf-8";
$componentsTitle: 'submenu';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.submenu {
    display: none;
    @media screen and (min-width: 992px) {
        display: block;
        max-height: 0;
        pointer-events: none; //@include transition(max-height .6s);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.08);
        .nav-link.is-hover & {
            pointer-events: all;
            max-height: 640px;
            @include transition(max-height .8s);
        }
    }
    position: absolute;
    overflow:hidden;
    width: 100vw;
    top: 100%;
    /*margin-top: 35px;*/
    left: calc(720px - 50vw);
    background: #FFF;
    @media screen and (max-width: 1529px) {
        left: -3.1948%;
    }
    @media screen and (max-width: 991px) {
        position: relative;
        background: transparent;
        top: auto;
        left: auto;
        width: 100%;
        .nav-link.is-open & {
            display: block;
        }
    }
}

.submenu__wrapper {
    @extend .content-wrapper;
    padding: 30px 45px;
    max-width: 1440px;
    border-top: 1px solid $colorBorderGrey;
    margin-top: 35px;
    @media screen and (max-width: 991px) {
        border-top: 0;
        margin-top: 15px;
        padding: 15px 0;
        &:before {
            content: '';
            $size: 60px;
            position: absolute;
            top: 10px;
            left: 50%;
            display: block;
            width: $size;
            height: 1px;
            margin-left: -$size/2;
            background: $colorBorderGrey;
        }
    }
}

.submenu__body {
    position: relative;
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.submenu__intro {
    width: 33.3333%;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    font-size: 0;
    padding-right: 30px;
    @media screen and (max-width: 991px) {
        display:none;
    }
    @include transform(translateX(-60px));
    @include transition(.4s .3s);
    @media screen and (min-width: 992px) {
        opacity:0;
        .nav-link.is-hover & {
            opacity: 1;
            @include transform(translateX(0));
        }
    }
}

.submenu__intro-title {
    @extend .title-lv4;
    margin: 0 0 15px;
    font-weight: 900;
    :lang(en) &{
        font-weight: 300;
    }
    strong {
        font-weight: 900;
        :lang(en) &{
            display: block;
        }
        //display: block;
    }
    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 5px;
        width: 0.4em;
        height: 0.4em;
        border-top: 2px solid $colorOrange;
        border-right: 2px solid $colorOrange;
        @include transform(rotate(45deg));
    }
}

.submenu__intro-desc {
    @extend .gb-fontsize-s;
    line-height: 1.5;
}

.submenu__intro-img {
    position: relative;
    width: 100%;
}

.submenu__intro-btns {
    .button {
        margin-top: 15px;
    }
}

.submenu__intro-links {
    a:first-child:before {
        content: '';
        display: block;
        width: 60px;
        height: 1px;
        margin-bottom: 30px;
        background: $colorOrange;
    }
}

.submenu__items {
    width: 66.6666%;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
        width: 100%;
    }
    @media screen and (min-width: 992px) {
        opacity: 0;
        @include transform(translateY(30px));
        @include transition(.4s .3s);
        .nav-link.is-hover & {
            opacity: 1;
            @include transform(translateY(0));
        }
    }
    &.submenu__items--4col {
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        .submenu__col {
            width: 25%;
            padding: 0 18px;
            @media screen and (max-width: 1280px) {
                width: calc(100% / 3);
                padding: 0 30px;
            }
            @media screen and (max-width: 991px) {
                width: 100%;
            }
        }
    }
}

.submenu__col {
    width: 33.3333%;
    display: inline-block;
    vertical-align: middle;
    padding: 0 30px;
    margin: 20px 0;
    @media screen and (max-width: 991px) {
        padding:0;
        width: 100%;
        text-align: center;
        margin: 5px 0;
    }
}

.submenu__event {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    padding: 0 30px;
    margin: 20px 0;
    @media screen and (max-width: 991px) {
        display:none;
    }
    @media screen and (max-width: 767px) {
        padding:0 15px;
    }
    @media screen and (max-width: 480px) {
        max-width: 420px;
        width: 100%;
        padding: 0;
    }
}

.submenu__event-img {
    position: relative;
    .submenu__event:hover & {
        @include transform(translateY(0));
    }
}

.submenu__event-title {
    @extend .title-lv5;
    margin: 10px 0;
}

.submenu__event-desc {
    @extend .gb-fontsize-s;
    line-height: 1.5;
}

.submenu__event-info {
    position: relative;
    font-size: 0;
}

.submenu__event-break {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 14px;
    width: 1px;
    background: $colorTxt;
    margin: 0 8px;
}

.submenu__event-label {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    @extend .gb-fontsize-s;
    line-height: 1.5;
    color: $colorOrange;
    font-weight: bold;
    margin: 0;
}

.submenu__event-date {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    @extend .gb-fontsize-s;
    line-height: 1.5;
    margin: 0;
}

.submenu__event-mob {
    display: none;
    @media screen and (max-width: 991px) {
        display: block;
    }
}

.submenu__headlink {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @extend .gb-fontsize-m;
    &+.submenu__headlink {
        margin-top: 20px;
    }
    &:hover {
        color: $colorOrange;
    }
}

.submenu__head{
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @extend .gb-fontsize-m;
}

.submenu__link {
    display: block;
    margin-bottom: 10px;
    @extend .gb-fontsize-s;
    &:hover {
        color: $colorOrange;
    }
    @media screen and (max-width: 991px) {
        display: none;
    }
}

.submenu__col-more {
    display: block;
    font-weight: bold;
    @extend .gb-fontsize-s;
    @media screen and (max-width: 991px) {
        display: none;
    }
    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 5px;
        width: 0.4em;
        height: 0.4em;
        border-top: 2px solid #fb5004;
        border-right: 2px solid #fb5004;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.submenu__bar {
    padding: 0;
    text-align: right;
    @media screen and (max-width: 991px) {
        text-align: center;
    }
}

.submenu__more-link {
    @extend .gb-fontsize-s;
    font-weight: bold;
    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 5px;
        width: 0.4em;
        height: 0.4em;
        border-top: 2px solid $colorOrange;
        border-right: 2px solid $colorOrange;
        @include transform(rotate(45deg));
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */