@charset "utf-8";
$componentsTitle: 'alert-bar';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify
.alert-bar{
    padding:20px 0;
    //background:$colorOrange;
    background:#ededed;
    color:$colorTxt;
    display:none;
    [data-page=home] &,
    [data-page=service] &{
        display:block;
    }
    &.is-hidden{
        display:none !important;
    }
    @include menuBlur();
    @media screen and (max-width: 480px) {
        .content-wrapper{
            padding-right:35px;
        }
    }
    .mob-menu-open &, .search-panel-open & {
        display: none;
    }

    /* PHR-757: Change color/font size of announcement bar */
    &.home,
    &.source {      
        background: $colorOrange;
        a, a:visited {
            color: #fff;
            text-decoration: underline;
        }
        .alert-bar {
            &__desc {
                font-size: 17px;
                color: #fff;
            }
            &__title {
                font-size: 18px;
                color: #fff;
            }
            &__close:before,
            &__close:after {
                background: #fff;
            }
        }
    }
    /* END PHR-757: Change color/font size of announcement bar */

    &.promotion, &.join, &.sourcePromotion {
        background: transparent;
        padding: 0;
        display: block;
        .content-wrapper {
            max-width: 100%;
            width: 100%;
            padding: 0;
            margin: 0;
        }        
        .alert-bar {
            &__holder {
                position: relative;
            }
            &__control {
                position: absolute;
                right: 10px;
                top: 15px;
            }
            &__desc {
                width: 100%;
            }
            &__title {
                display: none;
            }
            &__close:before,
            &__close:after {
                background: #fff;
            }
        }
        .video-onload{
            display: flex;
            align-items: center;
            justify-content: center;
            position:relative;
            top:-20%;
        }
        .loadingImg{
            z-index: 1;
            position: relative;
            margin-top: 20%;
        }
        .overlay-video-element{
            cursor:pointer;
        }
        .overlay-video-size{
            z-index: 2;
            position:absolute;
            top: 0;
            bottom: -60px;
            right: 0;
            left: 0;
        }
        .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_ {
            max-width: none !important;
            background-color: transparent !important;
            width: 70%;
            outline: none !important;
            top:-20%;
        }
        @media screen and (max-width:900px) {
            .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_ {
              max-width: none !important;
              background-color: transparent !important;
              width: 90%;
              outline: none !important;
              top:-5%;
            }
        }
          
        .overlay-video-size {
            aspect-ratio: 1600 / 900 !important;
            width: 100% !important;
        }
          
        .styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_ .styles_overlay-close__3e1ke {
            top: -5px !important;
            right: -10px !important;
        }
        svg {
            fill:white;
        }
    }
}

.alert-bar__holder{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 480px) {
        display:block;
    }
}
.alert-bar__list{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.alert-bar__row{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    @media screen and (max-width: 480px) {
        display:block;
    }
}
.alert-bar__title{
    @extend .gb-fontsize-xs;
    margin:0;
    padding-right:30px;
    font-weight:bold;
    color:$colorTxt;
    width:20%;
    @media screen and (max-width: 767px) {
        width:100%;
        padding-right:20px;
    }
}
.alert-bar__desc{
    @extend .gb-fontsize-xs;
    color:$colorTxt;
    width:80%;
    /*&:active,
    &:focus,
    &:hover{
        opacity:1;
        color:#FFF;
        text-decoration: underline;
    }*/
    @media screen and (max-width: 480px) {
        width: 95%;
        margin-top: 2px;
        font-size:12px;
    }
}
.alert-bar__close{
    cursor:pointer;
    -webkit-appearance: none;
    position:relative;
    display:block;
    //$color:#FFF;
    color:$colorTxt;

    $size: 20px;
    $lineWidth: 2px;
    width: $size;
    height: $size;
    box-shadow: none;
    background:transparent;
    border:0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    @include transform-origin(center center);
    @include transform(rotate(45deg));
    @media screen and (max-width: 480px) {
        position:absolute;
        top:50%;
        right:0;
        margin-top:-$size/2;
    }
    &:before,
    &:after {
        content: '';
        background: $colorTxt;
        position: absolute;
        display: block;
    }
    &:before {
        width: $size;
        height: $lineWidth;
        left: 0;
        top: $size/2;
        margin-top: -$lineWidth/2;
    }
    &:after {
        height: $size;
        width: $lineWidth;
        top: 0;
        left: $size/2;
        margin-left: -$lineWidth/2;
    }
}


/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */