@charset "utf-8";
$componentsTitle: '.page-item-search';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */
.item-content{
    &.item--full__child--col{
        >.item-content__desc{
            width: 30%;
            @media screen and (max-width:1240px) {
                width: 45%;
            }
            @media screen and (max-width:767px) {
                width: 100%;
            }
        }
        >.item-links{
            left: 40%;
            transform: translate(0%, -50%);
            @media screen and (max-width:1240px) {
                position: initial;
                right: initial;
                top: initial;
                transform: translate(0%, 0%);
            }
        }
        .item-arrow{
            .item-arrow__link{
                @media screen and (max-width:767px) {
                    display: inline-block;
                }
            }
        }
    }
    &.item--full__child--cols{
        .item-icon{
            text-align: center;
        }
        .item-content__title{
            text-align: center;
        }
        .item-content__desc{
            width: 100%;
            text-align: center;
        }
    }
}

$contentColor: (
    white: #ffffff,
    orange: $colorOrange
);  

.child-item-container{
    width: 360px;
    @media screen and (max-width:1580px) { width: 320px; }
    @media screen and (max-width:1240px) { width: 260px; }
    @media screen and (max-width:991px) { width: 200px; }        
    @media screen and (max-width:767px) { width: 360px; } 
    @media screen and (max-width:480px){ width: 240px; }
    .child-item__img{
        overflow: hidden;
        width: 100%;
        height: 180px;
        @media screen and (max-width:1580px) { height: 160px; }
        @media screen and (max-width:1240px) { height: 130px; }
        @media screen and (max-width:991px) { height: 100px; }        
        @media screen and (max-width:767px) { height: 180px; }
        @media screen and (max-width:480px){ height: 120px; }

        @include image-fill-container(flex);
        
        a{
            &:hover{
                opacity: 1;
            }
        }

        &.fillImg a {
            height: 180px;
            width: 360px;
            overflow: hidden;
            position: relative;
            @media screen and (max-width:1580px) { width: 320px; }
            @media screen and (max-width:1240px) { width: 260px; }
            @media screen and (max-width:991px) { width: 200px; }        
            @media screen and (max-width:767px) { width: 360px; } 
            @media screen and (max-width:480px){ width: 240px; }
            @media screen and (max-width:1580px) { height: 160px; }
            @media screen and (max-width:1240px) { height: 130px; }
            @media screen and (max-width:991px) { height: 100px; }        
            @media screen and (max-width:767px) { height: 180px; }
            @media screen and (max-width:480px){ height: 120px; }
            img {
                position:absolute;
                width:100%;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                max-width: none;
                max-height: none;
                left: 0;
                &.landscape {
                    width: auto;
                    height: 100%;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                }
            }
        }
    }
    .child-item__title{
        @extend .title-lv5;
        font-weight: 300;
        margin: 10px 0;
        line-height:1.5em;
        a{
            &:hover{
                opacity: 1;
            }
        }
    }
    @each $name, $colorVal in $contentColor {
        .child-item__title--#{$name} {
            color: $colorVal;
        } 
    }
}

.child-item-content{
    @each $name, $colorVal in $contentColor {
        .child-item__desc--#{$name} {
            color: $colorVal;
        }
    }
    .child-item__link-container{
        text-align: left;
        @media screen and (max-width:767px) {
            text-align: center;
        }
    }
    * {
        display: inline;
    }
}

.child-item-arrow{
    margin-top: 15px;
    $arrowPath: (
        white: white,
        orange: orange
    );
    @each $name, $path in $arrowPath {
        .child-item-arrow__link--#{$name} {
            &:before{
                $size:40px;
                content:'';
                width:$size;
                height:$size;
                display:block;
                background:no-repeat center center;
                background-image:imgurl("common/icons/svg/"+$path+"/icon-long-arrow.svg");
                background-size:100%;
                @media screen and (max-width:767px) {
                    margin: 0 auto;
                }
            }
        }  
    }
}  
.child-item__link{
    text-decoration: underline;
    margin-left: 5px;
    font-weight: bold;
    font-size: 14px;
    &--orange {
        color: #fb5004;
    }
    &--white {
        color: #FFF;
    }
}

.child-more-item{
    text-align: center;
    @each $name, $colorVal in $contentColor {
        .child-more-item-text__link--#{$name} {
            color: $colorVal;
            border: 1px solid $colorVal;
            padding: 12px 35px;
            border-radius: 20px;
        }
    }
}

.item-child-item--col{
    position: relative;
    .child-item-container{
        &:before{
            content: '';
            position: absolute;
            left: -50px;
            width: 1px;
            height: 100%;
            background: #efefef;
            margin-right: 200px;
        }
        @media screen and (max-width:767px) {
            width: 100%;
        }
        @media screen and (max-width:1240px) {
            &:before{
                content: none;
            }
        }
    }
    .child-item-content{
        .child-item__link{
            margin-top: 10px;
        }
    }
}

.item-child-item--cols{
    .child-items-container{
        margin: 35px auto 0;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        @media screen and (max-width:1240px) {
            width: 100%;
        }
        @media screen and (max-width:767px) {
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }
    }
    .child-item-container{
        margin: 0 30px;        
        @media screen and (max-width:991px) { margin: 0 15px;}      
        &:first-child {
            margin: 0;
        }
        &:last-child {
            margin: 0;
        }
        @media screen and (max-width:767px) {
            margin: 0 auto;
        }

        padding-bottom: 30px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        @media screen and (max-width:767px) {
            @for $i from 2 to 10 {
                &:first-child:nth-last-child(#{$i}),
                &:first-child:nth-last-child(#{$i}) ~ div{ 
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            margin: 0;
        }
        .child-item-arrow{
            position: absolute;
            bottom: 0;
            @media screen and (max-width:767px) {
                left: 46%;
            }
        }
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */