@charset "utf-8";
$componentsTitle: 'footer';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify
footer{
    position:relative;
    @include menuBlur();
}

.footer__body{
    position:relative;
    padding:80px 0;
    @media screen and (max-width: 991px) {
        padding:60px 0;
    }
}
/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */