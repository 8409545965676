@charset "utf-8";
$componentsTitle: 'button-share';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.button-share__holder {
    position: relative;
    font-size: 0;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    .footer-bottom &,
    &.button-share__holder--big {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        @media screen and (max-width: 480px) {
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
    }
    .footer-bottom & {
        margin-top: 15px;
        @media screen and (max-width: 640px) {
            text-align: left;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
}

.button-share__btn-holder {
    margin-top: -3px;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    .footer-bottom & {
        @media screen and (max-width: 640px) {
            margin-top: 5px;
            text-align: left;
            width: 100%;
        }
    }
}

.button-share {
    cursor: pointer;
    $size: 19px;
    border: 0;
    box-shadow: none;
    display: inline-block;
    vertical-align: middle;
    margin-right: 19px;
    margin-bottom: 20px;
    width: $size;
    height: $size;
    background: no-repeat center center;
    background-size: 100%;
    @media screen and (max-width: 1580px) {
        margin-right: 5px;
    }
    @media screen and (max-width: 991px) {
        margin-right: 15px;
    }
    @media screen and (max-width: 767px) {
        $size: 15px;
        width: $size;
        height: $size;
        margin-right: 15px;
    }
    .footer-bottom & {
        @media screen and (max-width: 640px) {
            margin-bottom: 0;
        }
    }
    .button-share__holder--big & {
        $size: 35px;
        width: $size;
        height: $size;
        margin: 0;
        margin-right: 30px;
        &:last-child {
            margin-right: 0;
        }
        @media screen and (max-width: 1200px) {
            $size: 28px;
            width: $size;
            height: $size;
            margin-right: 20px;
        }
        @media screen and (max-width: 480px) {
            $size: 24px;
            width: $size;
            height: $size;
            margin-right: 15px;
            margin-bottom: 12px;
        }
    }
    &:focus {
        // outline: none;
    }
    &.button-share--email {
        background-image: imgurl("common/icons/svg/black/icon-share-email.svg");
    }
    &.button-share--wa {
        background-image: imgurl("common/icons/svg/black/icon-share-wa.svg");
    }
    &.button-share--fb {
        background-image: imgurl("common/icons/svg/black/icon-share-fb.svg");
        background-size: 76%;
    }
    &.button-share--tw {
        background-image: imgurl("common/icons/svg/black/icon-share-tw.svg");
        background-size: 76%;
    }
    &.button-share--ln {
        background-image: imgurl("common/icons/svg/black/icon-share-ln.svg");
        background-size: 76%;
    }
    &.button-share--wb {
        background-image: imgurl("common/icons/svg/black/icon-share-wb.svg");
        background-size: 100%;
    }
    &.button-share--yt {
        background-image: imgurl("common/icons/svg/black/icon-share-yt.svg");
        background-size: 100%;
    }
    &.button-share--sp {
        background-image: imgurl("common/icons/svg/black/icon-share-sp.svg");
    }
    &.button-share--wc {
        background-image: imgurl("common/icons/svg/black/icon-share-wc.svg");
        background-size: 95%;
    }
    &.button-share--yk {
        background-image: imgurl("common/icons/svg/black/icon-share-yk.svg");
    }
    &.button-share--fbm {
        background-image: imgurl("common/icons/svg/black/icon-share-fbmessenger.svg");
    }
    .main-banner & {
        margin-bottom: 15px;
        @media screen and (max-width: 991px) {
            &.button-share--email {
                background-image: imgurl("common/icons/svg/white/icon-share-email.svg");
            }
            &.button-share--wa {
                background-image: imgurl("common/icons/svg/white/icon-share-wa.svg");
            }
            &.button-share--fb {
                background-image: imgurl("common/icons/svg/white/icon-share-fb.svg");
            }
            &.button-share--tw {
                background-image: imgurl("common/icons/svg/white/icon-share-tw.svg");
            }
            &.button-share--ln {
                background-image: imgurl("common/icons/svg/white/icon-share-ln.svg");
            }
            &.button-share--wb {
                background-image: imgurl("common/icons/svg/white/icon-share-wb.svg");
            }
            &.button-share--wc {
                background-image: imgurl("common/icons/svg/white/icon-share-wc.svg");
            }
            &.button-share--yt {
                background-image: imgurl("common/icons/svg/white/icon-share-yt.svg");
            }
            &.button-share--yk {
                background-image: imgurl("common/icons/svg/white/icon-share-yk.svg");
            }
            &.button-share--fbm {
                background-image: imgurl("common/icons/svg/white/icon-share-fbmessenger.svg");
            }
        }
    }
}

.button-share__title {
    margin-bottom: 0;
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    @media screen and (max-width: 1580px) {
        margin-right: 5px;
    }
    @media screen and (max-width: 991px) {
        margin-right: 15px;
    }
    .footer-bottom & {
        margin-bottom: 20px;
        @media screen and (max-width: 640px) {
            margin-top: 4px;
            margin-bottom: 0;
            text-align: left;
        }
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }
    @extend .gb-fontsize-s;
    .button-share__holder--big & {
        margin: 0;
        margin-right: 30px;
        font-weight: 400;
        white-space: nowrap;
        @extend .gb-fontsize-xl;
        @media screen and (max-width: 480px) {
            margin: 0 auto 10px;
        }
    }
}

.button-share__wcqr {
    text-align: center;
    padding: 30px 0 0;
    max-width: 200px;
    &+button {
        cursor: pointer;
    }
}

.button-share__wcqr-txt {
    text-align: center;
    margin-top: 15px;
    padding: 0 30px;
    font-size: 14px;
    line-height: 1.5em;
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */