@charset "utf-8";
$componentsTitle: 'cookies-bar';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.cookies-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    //background: $colorPurple;
    background: rgba(#000, 0.7);
    color: #FFF;
    z-index: 200;
    &.is-hidden {
        display: none;
    }
    @include menuBlur();
    @media screen and (max-width: 767px) {
        text-align: center;
    }
}

.cookies-bar__holder {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 480px) {
        display:block;
    }
}

.cookies-bar__title {
    @extend .gb-fontsize-xs;
    margin: 0;
    padding-right: 30px;
    font-weight: bold;
    color: #FFF;
    @media screen and (max-width: 767px) {
        padding-right:20px;
    }
}

.cookies-bar__desc {
    @extend .gb-fontsize-xs;
    color: #FFF;
    width: 80%;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
    a{
        color:$colorOrange;
        &:hover{
            opacity:1;
            color:$colorOrange;
            text-decoration: underline;
        }
    }
}

.cookies-bar__close {
    cursor: pointer;
    position: relative;
    display: block;
    $size: 18px;
    width: $size;
    height: $size;
    background: imgurl('common/icons/svg/white/icon-close.svg') no-repeat center center transparent;
    background-size: 100%;
    box-shadow: none;
    border: 0;
}

.cookies-bar__control {
    white-space: nowrap;
    @media screen and (max-width: 480px) {
        margin-top:10px;
    }
    .button {
        @extend .gb-fontsize-xxs;
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */