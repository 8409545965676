@charset "utf-8";
$componentsTitle: 'button';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

// TODO: Simplify
.button {
    display: inline-block;
    margin: 0;
    padding: 12px 40px;
    box-sizing: border-box;
    font-weight: bold;
    border-radius: 30px;
    @extend .gb-fontsize-xs;
    background:transparent;
    color:$colorOrange;
    border:1px solid $colorOrange;
    cursor:pointer;
    +.button {
        margin-left: 5px;
    }
    >span {
        display: inline-block;
        vertical-align: middle;
    }
    &:hover {
        opacity: 1;
        background: $colorOrange;
        color: #FFF;
        &.button--right-arr {
            &:after {
                border-top: 2px solid #FFF;
                border-right: 2px solid #FFF;
            }
        }
    } //Colors
    &.button--white {
        background: #FFF;
        color: $colorOrange;
        &:hover {
            background: $colorOrange;
            color: #FFF;
        }
    }
    &.button--whiteOrange {
        background: #FFF;
        color: $colorOrange;
        &:hover {
            background:transparent;
            color:#FFF;
            border:1px solid #FFF;
        }
    }
    &.button--orange {
        background: $colorOrange;
        color: #FFF;
        &:hover {
            background: #FFF;
            color: $colorOrange;
            
        }
    } //Size

    &.button--ghost-black {
        background: transparent;
        color: $colorTxt;
        border-color: $colorTxt;
        &:hover {
            background: $colorOrange;
            color: #FFF;
            border-color: $colorOrange;
        }
    }
    &.button--ghost-white {
        background: transparent;
        color: #FFF;
        border-color: #FFF;
        &:hover {
            background: #FFF;
            color: $colorOrange;
            border-color: #FFF;
        }
    }
    &.button--ghost-orange {
        background: transparent;
        color: $colorOrange;
        border-color: $colorOrange;
        &:hover {
            background: $colorOrange;
            color: #FFF;
            border-color: $colorOrange;
        }
    }

    &.button--disable {
        background: transparent;
        color: #888888;
        border-color: #888888;
    }

    &.button--wide {
        padding: 12px 45px;
    }
    &.button--s {
        padding: 10px 30px;
        @media screen and (max-width: 767px) {
            padding: 8px 18px;
        }
    } //Type
    &.button--right-arr {
        white-space: nowrap;
        span {
            display: inline-block;
            vertical-align: middle;
            white-space: all;
        }
        &:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-top: 0;
            margin-left: 5px;
            width: 0.4em;
            height: 0.4em;
            border-top: 2px solid #fb5004;
            border-right: 2px solid #fb5004;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */