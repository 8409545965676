@charset "utf-8";
$componentsTitle: 'mob-lang';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify
.mob-lang__headlink{
    display:block;
    @extend .title-lv3;
    margin:0 0 20px;
}
.mob-lang__link{
    display:block;
    margin:0 auto 15px;
}

/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */