@charset "utf-8";
$componentsTitle: 'top-bar';
/* Style for #{$componentsTitle} */

/* ------------------------------------------ */

$itemSp:14px;
.top-bar {
    position: relative;
    display: block;
    width: 100%;
    white-space: nowrap;
    text-align: right;
    @include fontSize(14);
    z-index: 30;
    &.top-bar--mob {
        width: 90%;
        margin: 60px auto 15px;
        text-align: center;
        display: none;
        @media screen and (max-width: 991px) {
            display:block;
            white-space: normal;
        }
    }
}

.top-bar__holder {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 0 $itemSp;
    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 1px;
        height: 15px;
        right: 0;
        @include centerY();
        background: $colorGrey;
    }
    &:last-child {
        padding-right: 0;
        &:after {
            display: none;
        }
    }
}

.top-bar__subholder {
    display:none;
    .top-bar__holder:hover & {
        display: block;
    }
    $size:120px;
    position:absolute;
    top:100%;
    left:50%;
    padding: 15px 20px;
    background:#FFF;
    box-shadow: 0px 3px 4px rgba(150, 150, 150, 0.5);
    @include transform(translateX(-50%));
    @media screen and (max-width: 1480px) {
        right: -30px;
        left: auto;
        @include transform(translateX(0));
    }
    .button-share__title{
        display:none;
    }
    .button-share{
        margin-right:12px;
        margin-bottom:0;
        &:last-child{
            margin-right:0;
        }
    }
}

.top-bar__link {
    display: inline-block;
    vertical-align: middle;
    margin-right: $itemSp;
    color: $colorGrey;
    @include fontSize(13);
    &:last-child {
        margin-right: 0;
        @media screen and (max-width: 991px) {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    span {
        display: inline-block;
        vertical-align: middle;
    }
}
.top-bar__txt{
    color: $colorGrey;
    display: inline-block;
    cursor: pointer;
    &:hover {
        color: $colorTxt;
        opacity: 0.8;
    }
}

.top-bar__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    $size: 17px;
    width: $size;
    height: $size;
    background: url('') no-repeat center center;
    background-size: 90%;
    opacity: .6;
    &:last-child {
        margin-right: 0;
    }
    &.top-bar__icon--login {
        background-image: imgurl('common/icons/svg/black/icon-profile.svg');
    }
    &.top-bar__icon--share {
        background-image: imgurl('common/icons/svg/black/icon-share.svg');
    }
}

.top-bar__lang{
    text-transform: uppercase;
}

/* END Style #{$componentsTitle}  */

/* ------------------------------------------ */