@charset "utf-8";
$componentsTitle: 'page-items';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify

.items-container{
    max-width: 1920px;
    margin: 0 auto 50px auto;
    @media screen and (max-width:1240px) {
        width: 100%;
    }
}

$contentColor: (
    white: #ffffff,
    orange: $colorOrange
);  

.items-row{
    &:last-of-type{
        border-bottom: 1px solid #d2d2d2;
    }
    .row-container{
        width: 100%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        &.row-container--slider{
            .item-container{
                padding: 120px 0;
                @media screen and (max-width:1240px) {
                    padding: 80px 0;
                }
                @media screen and (max-width:767px) {
                    padding: 45px 0;
                }
            }
        }
        @media screen and (max-width:1240px) {
            &.row-container--slider{
                height: auto;
            }
        }
        @media screen and (max-width:767px) {
            height: auto;
        }
        >.item-container{
            padding: 80px 0;
            float: inherit;
            width: 100%;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-align-content: stretch;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            &.item-container--bg{
                top: 0;
                left: 0;
                background: no-repeat center center;
                background-size: cover;
                $bgPosition: (
                    100: 100%,
                    0: 0%
                );
                @each $name, $posVal in $bgPosition {
                    &.item-container--bg--x-#{$name} {
                        background-position-x: $posVal;
                    }
                    &.item-container--bg--y-#{$name} {
                        background-position-y: $posVal;
                    }
                }
            }
            @media screen and (max-width:767px) {
                padding: 45px 0;
                &:nth-of-type(n){
                    .item-content{
                        left: initial;
                        text-align: center;
                    }
                }
            }  
        }
    }
}
          
.item-content{
    // position: absolute;
    @media screen and (max-width:767px) {
        position: relative;
    }               
    @each $name, $colorVal in $contentColor {
        .side-title--#{$name} {
            color: $colorVal;
            &:after{
                background: $colorVal;
            }
        }
    }
    .item-icon{
        margin-bottom: 10px;
        img {
            max-width: 240px;
        }
    }
    @each $name, $colorVal in $contentColor {
        .item-content__title--#{$name} {
            color: $colorVal;
        }
    }
    .item-content__title{
        margin-bottom: 10px;
        @extend .title-lv3;
        font-weight: 200;
    }                        
    @each $name, $colorVal in $contentColor {
        .item-content__desc--#{$name} {
            color: $colorVal;
        }
    }
    .item-content__desc{
        line-height: 25px;
        text-align: left;
        margin-bottom: 20px;
        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }
    .item-tips{
        @each $name, $colorVal in $contentColor {
            .item-tips__title--#{$name} {
                color: $colorVal;
            }
        }
        .item-tips__title{
            @extend .title-lv5;
            font-weight: 300;
            padding-bottom: 5px; 
            &:after{
                content: ":";
            }
        }
        .item-tips__desc{
            white-space: pre-line;
            line-height: 25px;
            text-align: left;
        }
    }
}

.item-links{
    @each $name, $colorVal in $contentColor {
        .item-links__title--#{$name} {
            color: $colorVal;
        }
    }
    .item-links__title{
        @extend .title-lv5;
        margin-bottom: 8px;
        font-weight: 600;
    } 
    $columnCount: (
        col: 1,
        cols: 2
    );
    @each $name, $colVal in $columnCount {
        .item-links-content--#{$name} {
            left: 0;
            padding-top: 20px;
            column-count: $colVal;
            column-fill: balance;
            overflow: hidden;
            column-rule: 0.5px solid;
            column-rule-color: transparent;
            z-index: inherit;
            list-style-type: none;
            padding-top: 0;
            padding-left: 0;
            margin-bottom: 10px;
            @media screen and (max-width:767px) {
                column-count: 1;
            }
        }
    }
    .item-links-content{
        li{
            margin-bottom: 5px;
            text-align: left;
            a{
                &:after{
                    content: ">";
                    padding-left: 5px;
                }
            }
        }
    }
}

.item-links__more{
    text-align: left;
    &.item-links__more--white{
        a{
            color: #ffffff;
        }
    }
    &.item-links__more--orange{
        a{
            color: $colorOrange;
        }
    }
}

.item-arrow{
    margin-top: 15px;
    @media screen and (max-width:767px) {
        margin-top: 5px;
    }
    $arrowPath: (
        white: white,
        orange: orange
    );
    @each $name, $path in $arrowPath {
        .item-arrow__link--#{$name} {
            &:before{
                $size:50px;
                content:'';
                width:$size;
                height:$size;
                display:block;
                background:no-repeat center center;
                background-image:imgurl("common/icons/svg/"+$path+"/icon-long-arrow.svg");
                background-size:100%;
                @media screen and (max-width:767px) {
                    height:30px;
                }
            }
        }  
    }
    a{
        display:block;
    }
}

.item-more{
    display:inline-block;
    margin-top: 25px;
    @media screen and (max-width:767px) {
        margin-top: 10px;
    }
}

.items-row--full{
    .item-content{
        width: 86%;
        position: relative;
        max-width: 1440px;
        .side-title{
            left: 98%;
            margin-bottom: 30px;
            text-indent: 0;
            @media screen and (max-width:1240px) {
                margin-bottom: 50px;
            }
        }
        &.item--full__links--col{
            .item-icon{
                width: 30%;
                @media screen and (max-width:1240px) {
                    width: 45%;
                } 
                @media screen and (max-width:768px) {
                    width: initial;
                } 
            }
            .item-content__title{
                width: 30%;
                @media screen and (max-width:1240px) {
                    width: 45%;
                } 
                @media screen and (max-width:768px) {
                    width: initial;
                } 
            }   
        }
        &.item--full__child--cols{
            .item-icon{
                width: initial;
            }
            .item-content__title{
                width: initial;
            }   
        }
        &.item--full__links--cols{                         
            .item-content__desc{
                width: 45%;
                @media screen and (max-width:767px) {
                    width: 100%;
                }
            }
        }
        &.item--full__slider--cols{
            .side-title{
                margin-bottom: 60px;
            }
            .item-content__desc{
                width: 45%;
            }
            @media screen and (max-width:1240px) {
                position: relative;
                transform: translate(4%, 0%);
                left: initial;
                .side-title{
                    margin-bottom: -25px;
                }
                .item-content__desc{
                    width: 95%;
                }
            }
        }
        &.item--search-bar{
            .item-links{
                top: 80%;
            }
        }
    }
    .item-content__desc{
        
        @media screen and (max-width:767px) {
            width: 100%;
        }
    }

    
    // For specify pages
    & .business-item, & .insights-item {
        .item-content__left {
            width: 55%;            
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }         
        .item-content__right {
            width: 40%;            
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
    & .bnr-item {
        .item-content__left {
            width: 60%;
            margin-right: 5%;
            @media screen and (max-width: 1240px) {
                width: 50%;
            }                        
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            .item-content__sub1 {
                width: 60%;
                @media screen and (max-width: 1240px) {
                    width: 100%;
                }            
            }
            .item-content__sub2 {
                width: 40%;
                padding: 100px 5% 0px 5%;
                vertical-align: top;
                @media screen and (max-width: 1240px) {
                    width: 100%;
                    padding: 10px 0 0 0;
                }
            }
            .item-content__sub1, .item-content__sub2 {
                display: inline-block;
                @media screen and (max-width: 1240px) {
                    display: block;
                }
            }
        }
        .item-content__right {
            width: 30%;            
            @media screen and (max-width: 1240px) {
                width: 45%;
            }
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }

    // For whole section 
    .item-content {
        @media screen and (max-width: 767px) {
            width: 70%;
        }
    }

    .item-content__left, .item-content__right {
        display: inline-block;
        vertical-align: middle;  
        @media screen and (max-width: 767px) {
            display: block;
        }
    }
    .item-content__left {
        margin-right: 5%;
        width: 45%;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
        
    }
    .item-content__right {       
        width: 50%;              
        @media screen and (max-width: 767px) {
            margin: 0px;
            width: 100%;
        }
        &-item-links-row {
            // column-count: 2;
            // @media screen and (max-width: 767px) {
            //     column-count: 1;
            // }
            .item-links {
                display: inline-block;
                width: 50%;
                vertical-align: top;
                @media screen and (max-width: 767px) {
                    display: block;
                    width: 100%;
                }
            }
            .item-links-content--col {
                -webkit-column-break-inside: avoid;
                /* Chrome, Safari */
                page-break-inside: avoid;
                /* Theoretically FF 20+ */
                break-inside: avoid-column;
                /* IE 11 */
            }
            .upcoming {
                @media screen and (max-width: 767px) {
                    text-align: center;
                    .item-links__link {
                        display: block;
                    }
                }
                img {
                    display: block;
                    margin-bottom: 5px;
                    max-width: 160px;
                    @media screen and (max-width: 767px) {
                        margin: 0 auto 5px;
                    }
                }
            }
            .item-links__mobileonly {
                display: none;

                @media screen and (max-width: 767px) {
                    display: block;
                }
            }
            .item-links__desktopOnly {
                display: block;

                @media screen and (max-width: 767px) {
                    display: none;
                }
            }
        }
    }
}

.items-row--cols{
    .row-container{
        @media screen and (max-width:767px) {
            display: block;
        }
        >.item-container{
            &:first-child:nth-last-child(1) {
                    width: 100%;
            }
            @for $i from 2 to 10 {
                &:first-child:nth-last-child(#{$i}),
                &:first-child:nth-last-child(#{$i}) ~ div{ 
                    width: (100% / ($i) )                            
                }
            }
            @media screen and (max-width:767px) {
                @for $i from 2 to 10 {
                    &:first-child:nth-last-child(#{$i}),
                    &:first-child:nth-last-child(#{$i}) ~ div{ 
                        width: 100%
                    }
                }
            }
            @media screen and (max-width:767px) {
                &:nth-of-type(n){
                    .item-content{
                        left: initial;
                        text-align: center;
                    }
                }
            }
            .item-content{
                width: 71.5%;
                height: auto;
                overflow: hidden;
                top: 50%;
                margin: 0px auto;
            }
            @media screen and (min-width:1680px) {
                &:first-child{
                    .item-content{
                        margin-left: calc(100% - 720px);
                        padding-right: 72px;
                    }
                }
                &:last-child{
                    .item-content{
                        margin-right: calc(100% - 720px);
                        padding-left: 72px;
                    }
                }
            }
        }
    }
}
/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */