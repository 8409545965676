@charset "utf-8";
$componentsTitle: 'app';
/* Style for #{$componentsTitle} */
/* ------------------------------------------ */
// TODO: Simplify

.app{
    position:relative;
    width:100%;

}

.app__top{
    position:relative;
    width:100%;
}
.app__main{
    position:relative;
    overflow: hidden; 
    width:100%;
    padding-top:130px;
    @media screen and (max-width: 1580px) {
        padding-top:125px;
    }
    @media screen and (max-width: 991px) {
        //padding-top:$headerHeightTb;
        padding-top:70px;
    }
    .inner-page & {
        @include getFullPageHeaderOffset(padding-top);
    }
}

/* END Style #{$componentsTitle}  */
/* ------------------------------------------ */