@charset "utf-8";
$componentsTitle: 'sitemap';

/* Style for #{$componentsTitle} */


/* ------------------------------------------ */

// TODO: Simplify
.sitemap {
    position: relative;
    @media screen and (max-width: 991px) {
        display:none;
    }
}

.sitemap__row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.sitemap__col {
    display: inline-block;
    width: 20%;
    padding: 0 10px 0 30px;
    @media screen and (max-width: 1200px) {
        padding:0 15px;
    }
}

.sitemap__head {
    display: block;
    margin-bottom: 15px;
    //font-weight: normal;
    line-height:1.3;
    font-weight: 900;
    strong {
        font-weight: 900;
        display: block;
    }
    &:hover {
        color: $colorOrange;
    }
    @extend .gb-fontsize-m;

    /* PHR-53 */
    :lang(zh-hk) &, :lang(zh-cn) & {
        font-size: 18px;
        strong {
            display: inline-block;
        }
    }
}

.sitemap__link {
    display: block;
    @extend .gb-fontsize-s;
    margin-bottom: 8px;
    &:hover {
        color: $colorOrange;
    }    
}

.sitemap__quicklinks {
    position: relative;
}

.sitemap__quicklink {
    display: block;
    @extend .gb-fontsize-s;
    margin-bottom: 8px;
    &:hover {
        color: $colorOrange;
    }    
}


/* END Style #{$componentsTitle}  */


/* ------------------------------------------ */